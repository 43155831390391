import * as amplitude from "@amplitude/analytics-browser";
import { replaceMacroVariables } from "@redotech/redo-merchant-app/client/macro";
import {
  ConversationPlatform,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { UploadedFile } from "@redotech/redo-model/file/uploaded-file";
import {
  Macro,
  MacroAutomation,
  MacroAutomationsData,
  MacroStatusToSet,
} from "@redotech/redo-model/macro";
import { Team } from "@redotech/redo-model/team";
import { toast } from "@redotech/redo-web/alert";
import { Dispatch, SetStateAction } from "react";
import { RedoMerchantClient } from "../../client";
export const materializeMacroIntoPendingAutomations = async ({
  client,
  team,
  newMacroToInclude,
  email,
  firstName,
  lastName,
  fullName,
  agentFirstName,
  agentLastName,
  agentFullName,
  addAttachments,
  setErrorMessage,
  setShowErrorMessage,
  setMacroAutomations,
  conversationPlatform,
  visibilityOfMessage,
}: {
  client: RedoMerchantClient;
  team: Team;
  existingPendingAutomations: MacroAutomationsData;
  newMacroToInclude: Macro;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  agentFirstName: string;
  agentLastName: string;
  agentFullName: string;
  addAttachments: (attachments: UploadedFile[]) => void;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  setMacroAutomations: Dispatch<SetStateAction<MacroAutomationsData>>;
  conversationPlatform: ConversationPlatform;
  visibilityOfMessage: MessageVisibility;
}): Promise<[string | undefined, string | undefined]> => {
  let htmlContentWithVariablesReplaced;
  const contentWithVariablesReplaced = await replaceMacroVariables(client, {
    message: newMacroToInclude.content,
    email,
    firstName,
    lastName,
    fullName,
    agentFirstName,
    agentLastName,
    agentFullName,
  });
  if (newMacroToInclude.htmlContent) {
    htmlContentWithVariablesReplaced = await replaceMacroVariables(client, {
      message: newMacroToInclude.htmlContent,
      email,
      firstName,
      lastName,
      fullName,
      agentFirstName,
      agentLastName,
      agentFullName,
    });
  }

  amplitude.logEvent("use-macro", { name: newMacroToInclude.name });

  if (newMacroToInclude.attachments) {
    addAttachments(newMacroToInclude.attachments);
  }

  if (!contentWithVariablesReplaced.valuesExistForAllVariables) {
    setErrorMessage(
      "Some variables in the template didn't have a value. Defaults were used.",
    );
    setShowErrorMessage(true);
  }

  let subbedNoteToAddContent: string | undefined = undefined;
  let subbedNoteToAddHtmlContent: string | undefined = undefined;

  const performAsyncAspectsOfApplyingMacroAutomations: Record<
    MacroAutomation,
    (() => Promise<void>) | undefined
  > = {
    [MacroAutomation.SET_STATUS]: undefined,
    [MacroAutomation.ADD_TAGS]: undefined,
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: undefined,
    [MacroAutomation.ADD_INTERNAL_NOTE]: async () => {
      if (newMacroToInclude.shouldAddNote) {
        if (newMacroToInclude.noteToAddContent) {
          /** We want to perform macro substitutions on the note content */
          const { message, valuesExistForAllVariables } =
            await replaceMacroVariables(client, {
              message: newMacroToInclude.noteToAddContent ?? "",
              email,
              firstName,
              lastName,
              fullName,
              agentFirstName,
              agentLastName,
            });
          if (!valuesExistForAllVariables) {
            setErrorMessage(
              "Some variables in the note didn't have a value. Defaults were used.",
            );
            setShowErrorMessage(true);
          }
          subbedNoteToAddContent = message;
        }
        if (newMacroToInclude.noteToAddHtmlContent) {
          const { message, valuesExistForAllVariables } =
            await replaceMacroVariables(client, {
              message: newMacroToInclude.noteToAddHtmlContent ?? "",
              email,
              firstName,
              lastName,
              fullName,
              agentFirstName,
              agentLastName,
            });
          if (!valuesExistForAllVariables) {
            setErrorMessage(
              "Some variables in the note didn't have a value. Defaults were used.",
            );
            setShowErrorMessage(true);
          }
          subbedNoteToAddHtmlContent = message;
        }
      }
    },
    [MacroAutomation.FORWARD_MESSAGE]: undefined,
  };

  for (const automation of Object.values(MacroAutomation)) {
    await performAsyncAspectsOfApplyingMacroAutomations[automation]?.();
  }

  setMacroAutomations((oldMacroAutomations: MacroAutomationsData) => {
    let statusToSet = oldMacroAutomations.statusToSet;
    let snoozeDuration = oldMacroAutomations.snoozeDuration;
    const tagsToAdd = oldMacroAutomations.tagsToAdd || [];
    let emailSubjectToSet = oldMacroAutomations.emailSubjectToSet;
    let shouldAddNote = oldMacroAutomations.shouldAddNote;
    let noteToAddContent = oldMacroAutomations.noteToAddContent;
    let noteToAddHtmlContent = oldMacroAutomations.noteToAddHtmlContent;
    const noteToAddUsersMentioned =
      oldMacroAutomations.noteToAddUsersMentioned || [];
    let shouldForwardMessage = oldMacroAutomations.shouldForwardMessage;
    let messageToForwardExtraContent =
      oldMacroAutomations.messageToForwardExtraContent;
    let messageToForwardEmailAddress =
      oldMacroAutomations.messageToForwardEmailAddress;

    const mergeInMacroAutomations: Record<MacroAutomation, () => void> = {
      [MacroAutomation.SET_STATUS]: () => {
        if (
          newMacroToInclude.statusToSet === "snoozed" &&
          !!newMacroToInclude.snoozeDuration
        ) {
          statusToSet = MacroStatusToSet.SNOOZED;
          snoozeDuration = newMacroToInclude.snoozeDuration;
        } else {
          statusToSet = newMacroToInclude.statusToSet;
          snoozeDuration = undefined;
        }
      },
      [MacroAutomation.ADD_TAGS]: () => {
        if (tagsToAdd.length) {
          newMacroToInclude.tagsToAdd?.forEach((tag) => {
            if (!tagsToAdd.includes(tag)) {
              tagsToAdd.push(tag);
            }
          });
        } else if (newMacroToInclude.tagsToAdd?.length) {
          tagsToAdd.push(...newMacroToInclude.tagsToAdd);
        }
      },
      [MacroAutomation.CHANGE_EMAIL_SUBJECT]: () => {
        if (newMacroToInclude.emailSubjectToSet !== emailSubjectToSet) {
          emailSubjectToSet = newMacroToInclude.emailSubjectToSet;
        }
      },
      [MacroAutomation.ADD_INTERNAL_NOTE]: () => {
        /** If we used the "add internal note" automation, update it. Else leave it alone */
        if (newMacroToInclude.shouldAddNote) {
          shouldAddNote = newMacroToInclude.shouldAddNote;

          if (subbedNoteToAddContent !== noteToAddContent) {
            noteToAddContent = subbedNoteToAddContent;
          }
          if (subbedNoteToAddHtmlContent !== noteToAddHtmlContent) {
            noteToAddHtmlContent = subbedNoteToAddHtmlContent;
          }
        }

        /** If the note is updated, it will have an entire well-formed list of users mentioned. */
        noteToAddUsersMentioned.splice(0, noteToAddUsersMentioned.length);
        noteToAddUsersMentioned.push(
          ...(newMacroToInclude.noteToAddUsersMentioned || []),
        );
      },
      [MacroAutomation.FORWARD_MESSAGE]: () => {
        if (
          conversationPlatform !== ConversationPlatform.EMAIL ||
          visibilityOfMessage !== MessageVisibility.PUBLIC
        ) {
          toast(
            "Forwarding message automation not applied: only applies to email messages that aren't notes.",
            {
              variant: "warning",
            },
          );
          return;
        }

        if (newMacroToInclude.shouldForwardMessage) {
          shouldForwardMessage = newMacroToInclude.shouldForwardMessage;
          messageToForwardExtraContent =
            newMacroToInclude.messageToForwardExtraContent;
          messageToForwardEmailAddress =
            newMacroToInclude.messageToForwardEmailAddress;
        }
      },
    };

    for (const automation of Object.values(MacroAutomation)) {
      mergeInMacroAutomations[automation]();
    }

    const updatedMacroAutomations: MacroAutomationsData = {
      statusToSet,
      snoozeDuration,
      tagsToAdd,
      emailSubjectToSet,
      shouldAddNote,
      noteToAddContent,
      noteToAddHtmlContent,
      noteToAddUsersMentioned,
      shouldForwardMessage,
      messageToForwardExtraContent,
      messageToForwardEmailAddress,
    };
    return updatedMacroAutomations;
  });

  return [
    contentWithVariablesReplaced?.message,
    htmlContentWithVariablesReplaced?.message,
  ];
};
