// extracted by mini-css-extract-plugin
export var actionButton = "_5n-t";
export var buttonContainer = "_5n-0";
export var buttonContainer2 = "_5n-1";
export var buttonSection = "_5n-2";
export var details = "_5n-3";
export var dropdownItem = "_5n-5";
export var dropdownItemsContainer = "_5n-4";
export var fileInput = "_5n-r";
export var headerButton = "_5n-6";
export var icon = "_5n-7";
export var input = "_5n-8";
export var macroListItem = "_5n-9";
export var mainContent = "_5n-a";
export var microText = "_5n-b";
export var modal = "_5n-w";
export var optionsButton = "_5n-c";
export var paperclip = "_5n-s";
export var pillContent = "_5n-d";
export var preview = "_5n-e";
export var preview2 = "_5n-f";
export var quillEditor = "_5n-q";
export var selectedMacro = "_5n-g";
export var sidebar = "_5n-h";
export var sidebarHeader = "_5n-j";
export var sidebarList = "_5n-i";
export var sidebarListItem = "_5n-k";
export var sidebarSearch = "_5n-l";
export var snoozeDurationWrapper = "_5n-v";
export var statusPillContainer = "_5n-m";
export var tagsContainer = "_5n-n";
export var unselectedMacro = "_5n-o";
export var variable = "_5n-u";
export var wrapper = "_5n-p";