import { Macro } from "@redotech/redo-model/macro";
import { PutMacroBody } from "@redotech/redo-model/put-macro-body";
import { RedoMerchantClient } from ".";

/**
 * GET /team/macros
 */
export async function getMacros(
  client: RedoMerchantClient,
  {
    signal,
  }: {
    signal?: AbortSignal;
  },
): Promise<{ data: Macro[] }> {
  const response = await client.client.get("team/macros", {
    headers: client.authorization(),
    signal,
  });

  const data = (response.data as Macro[]).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return {
    data,
  };
}

/**
 * POST /macro
 */
export async function createMacro(
  client: RedoMerchantClient,
  body: PutMacroBody,
  signal?: AbortSignal,
): Promise<{ data: Macro }> {
  const response = await client.client.post("macro", body, {
    headers: client.authorization(),
    signal,
  });
  return {
    data: response.data,
  };
}

/**
 * PUT /macro/:id
 */
export async function editMacro(
  client: RedoMerchantClient,
  macroId: string,
  body: PutMacroBody,
  signal?: AbortSignal,
): Promise<{ data: Macro }> {
  const response = await client.client.put(`macro/${macroId}`, body, {
    headers: client.authorization(),
    signal,
  });
  return {
    data: response.data,
  };
}

/**
 * DELETE /macro/:id
 */
export async function deleteMacro(
  client: RedoMerchantClient,
  {
    id,
    signal,
  }: {
    id: string;
    signal?: AbortSignal;
  },
): Promise<void> {
  await client.client.delete(`macro/${id}`, {
    headers: client.authorization(),
    signal,
  });
}

/**
 * POST /macro/replaceVariables
 */
export async function replaceMacroVariables(
  client: RedoMerchantClient,
  {
    message,
    email,
    firstName,
    lastName,
    fullName,
    agentFirstName,
    agentLastName,
    agentFullName,
    signal,
  }: {
    message: string;
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    fullName: string | undefined;
    agentFirstName: string;
    agentLastName: string;
    agentFullName?: string;
    signal?: AbortSignal;
  },
): Promise<{ message: string; valuesExistForAllVariables: boolean }> {
  const response = await client.client.post(
    "macro/replaceVariables",
    {
      message,
      email,
      firstName,
      lastName,
      fullName,
      agentFirstName,
      agentLastName,
      agentFullName,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}
