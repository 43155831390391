import * as skeletonCss from "./skeleton.module.css";

export function SkeletonBlock() {
  return <div className={skeletonCss.skeleton} />;
}

export function SkeletonText({
  length,
  animated = false,
}: {
  length: number;
  animated?: boolean;
}) {
  return (
    <span
      className={
        animated ? skeletonCss.skeletonTextAnimation : skeletonCss.skeletonText
      }
    >
      {"_".repeat(length)}
    </span>
  );
}
