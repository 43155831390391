import {
  Condition,
  SubjectLineMatchType,
} from "@redotech/redo-model/return-flow/condition";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { ConditionType } from "../../return-flow/condition";
import { displayStringForMatchCondition } from "../rule-utils";

type State = Required<Omit<Condition.SubjectLine, "type">>;

export const SUBJECT_LINE: ConditionType<State, Condition.SubjectLine> = {
  name: "Subject line",
  description(state) {
    return displayStringForMatchCondition(
      "Subject line",
      state.matchMode,
      state.queryStrings,
    );
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Match mode">
          <SelectDropdown
            options={Object.values(SubjectLineMatchType)}
            value={state.matchMode}
            valueChange={(e) => {
              if (e) {
                setState({ ...state, matchMode: e as typeof state.matchMode });
              }
            }}
          >
            {(topic) => (
              <p>
                {topic === SubjectLineMatchType.EXACT
                  ? "entire subject line is exactly"
                  : "contains"}
              </p>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Subject text to match">
          <ChipInput
            delimiter={ChipDelimiter.NEWLINE}
            showPlaceholderWithoutFocus
            value={state.queryStrings || []}
            valueChange={(value) => {
              setState({ ...state, queryStrings: [...value] });
            }}
          />
        </LabeledInput>

        <Flex>
          <LabeledInput label="Case sensitive">
            <Checkbox
              onChange={(v) => setState({ ...state, caseSensitive: v })}
              value={state.caseSensitive}
            />
          </LabeledInput>{" "}
        </Flex>
      </>
    );
  },
  empty: {
    fuzzy_match: "",
    queryStrings: [],
    caseSensitive: false,
    matchMode: SubjectLineMatchType.CONTAINS,
  },
  fromModel(model) {
    return {
      ...model,
      queryStrings: model.queryStrings || [model.fuzzy_match || ""],
      caseSensitive: model.caseSensitive ?? false,
      matchMode: model.matchMode ?? SubjectLineMatchType.CONTAINS,
    };
  },
  toModel(state) {
    return { type: Condition.SUBJECT_LINE, ...state };
  },
  valid() {
    return true;
  },
};
