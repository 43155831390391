import { useHandler } from "@redotech/react-util/hook";
import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import * as fiveStarRatingCss from "./five-star-rating.module.css";
import { Flex } from "./flex";

/**
 * @param uniqueId -- In order to dynamically fill the svg, we need to create
 * HTML elements that define how to fill the star. These HTML elements must be globally unique
 * or else all stars will be broken
 *
 * @param rating -- Must be a number between 1 and 5
 */
export const FiveStarRating = memo(function FiveStarRating({
  rating,
  setRating,
  uniqueId,
  className,
}: {
  rating: number;
  setRating?: (rating: number) => void;
  uniqueId: string;
  className?: string;
}) {
  const safeRating = Math.min(5, Math.max(0, rating));

  const getFillPercentage = useHandler((star: number) => {
    if (star <= safeRating) {
      return 100;
    }
    if (star >= safeRating + 1) {
      return 0;
    }
    return (safeRating % 1) * 100;
  });

  return (
    <Flex className={className} gap="none">
      {[1, 2, 3, 4, 5].map((star) => (
        <ReviewStar
          fillPercentage={getFillPercentage(star)}
          key={star}
          onClick={() => setRating?.(star)}
          readonly={!setRating}
          uniqueId={`${uniqueId}-star-${star}`}
        />
      ))}
    </Flex>
  );
});

const ReviewStar = memo(function ReviewStar({
  uniqueId,
  fillPercentage,
  onClick,
  readonly,
}: {
  uniqueId: string;
  fillPercentage: number;
  onClick: () => void;
  readonly: boolean;
}) {
  const [gradientRef, setGradientRef] = useState<SVGGradientElement | null>(
    null,
  );

  useEffect(() => {
    if (gradientRef) {
      gradientRef.setAttribute("x2", fillPercentage + "%");
    }
  }, [fillPercentage, gradientRef]);

  return (
    <svg
      className={classNames(
        readonly && fiveStarRatingCss.readonly,
        fiveStarRatingCss.star,
      )}
      height="36"
      onClick={onClick}
      viewBox="0 0 40 40"
      width="36"
    >
      <defs>
        <linearGradient
          className={fiveStarRatingCss.gradient}
          id={uniqueId}
          ref={setGradientRef}
          x1="0"
          x2="100%"
          y1="0"
          y2="0"
        >
          <stop offset="100%" />
          <stop offset="100%" />
        </linearGradient>
        <symbol id="review-star" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.8377 1.12283C18.0077 0.709992 18.5923 0.709991 18.7623 1.12282L23.4205 12.4356C23.4921 12.6094 23.6551 12.7284 23.8425 12.7436L35.9424 13.7234C36.3843 13.7592 36.5645 14.3098 36.2292 14.5999L27.003 22.5829C26.8622 22.7048 26.8007 22.8948 26.8435 23.076L29.661 35.0067C29.7633 35.4401 29.2908 35.7809 28.9118 35.5471L18.5625 29.162C18.4016 29.0627 18.1984 29.0627 18.0375 29.162L7.68816 35.5471C7.3092 35.7809 6.83667 35.4401 6.93901 35.0067L9.75647 23.076C9.79926 22.8948 9.73781 22.7048 9.59701 22.5829L0.370812 14.5999C0.0355358 14.3098 0.215702 13.7592 0.657615 13.7234L12.7575 12.7436C12.9449 12.7284 13.1079 12.6094 13.1795 12.4356L17.8377 1.12283Z" />
        </symbol>
      </defs>
      <use fill={`url(#${uniqueId})`} href="#review-star" />
    </svg>
  );
});
