import { memo, useState } from "react";
import { getDateTimeString } from "./date-utils";
import ChevronDownIcon from "./icon-old/chevron-down.svg";
import * as timelineCss from "./timeline.module.css";

export interface Event {
  message: string;
  at: Temporal.Instant;
  detail?: string;
}

export const Timeline = memo(function Timeline({
  events,
}: {
  events: Event[];
}) {
  const startCount = 2;
  const endCount = 2;
  const [showAll, setShowAll] = useState(false);
  const more =
    !showAll && startCount + endCount + 1 < events.length ? (
      <button
        className={timelineCss.additional}
        key="additional"
        onClick={() => setShowAll(true)}
        type="button"
      >
        <ChevronDownIcon />
        {events.length - startCount - endCount} more events
      </button>
    ) : undefined;
  return (
    <div className={timelineCss.timeline}>
      <div className={timelineCss.line} />
      {events.map((event, index, events) =>
        more && index == startCount
          ? more
          : (!more ||
              index < startCount ||
              events.length - endCount <= index) && (
              <TimelineEvent event={event} key={index} />
            ),
      )}
    </div>
  );
});

const TimelineEvent = memo(function TimelineEvent({ event }: { event: Event }) {
  let subtitle = getDateTimeString(new Date(event.at.epochMilliseconds));
  if (event.detail) {
    subtitle += " — " + event.detail;
  }
  return (
    <div className={timelineCss.event}>
      <div className={timelineCss.point} />
      <div className={timelineCss.eventMain}>
        <div className={timelineCss.title}>{event.message}</div>
        <div className={timelineCss.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
});
