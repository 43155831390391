// extracted by mini-css-extract-plugin
export var brand = "_3g-4";
export var compact = "_3g-7";
export var extraSmall = "_3g-8";
export var icon = "_3g-0";
export var large = "_3g-1";
export var medium = "_3g-2";
export var outline = "_3g-9";
export var primaryDark = "_3g-5";
export var primaryLight = "_3g-3";
export var primaryModern = "_3g-6";