import {
  PhoneNumberProvider,
  PhoneNumberUseCase,
  TextMessagingVerificationStatus,
} from "@redotech/redo-model/phone/phone-number";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const PhoneNumberSchema = z.object({
  _id: zExt.objectId(),
  number: z.string(),
  team: zExt.objectId(),
  provider: z.nativeEnum(PhoneNumberProvider),
  useCase: z.nativeEnum(PhoneNumberUseCase),
  messagingMetadata: z
    .object({
      verificationStatus: z.nativeEnum(TextMessagingVerificationStatus),
    })
    .optional(),
  voiceMetadata: z
    .object({
      billingGroupId: z.string(),
      sipConnectionId: z.string(),
    })
    .optional(),
});
