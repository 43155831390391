import { countries } from "@redotech/locale/countries";
import { useInput } from "@redotech/react-util/form";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { memo, useEffect } from "react";
import * as addressEditModalCss from "./address-edit-modal.module.css";
import { Button, ButtonTheme } from "./button";
import { Modal, ModalSize } from "./modal";
import { FormSelectDropdown } from "./select-dropdown";
import { FormTextInput } from "./text-input";

export interface Address {
  street1: string;
  street2: string;
  state: string;
  city: string;
  country: string;
  zip: string;
  firstName?: string;
  lastName?: string;
}

const addressForm = groupInput({
  street1: input<string>(),
  street2: input<string>(),
  state: input<string>(),
  city: input<string>(),
  country: input<string>(),
  zip: input<string>(),
});

const defaultAddress: InputProvider.Value<typeof addressForm> = {
  street1: "",
  street2: "",
  state: "",
  city: "",
  country: "",
  zip: "",
};

const nameForm = groupInput({
  firstName: input<string>(),
  lastName: input<string>(),
});

export const AddressEditModal = memo(function AddressEditModal({
  open,
  setOpen,
  setAddress,
  initial,
  title,
  includeNames = false,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setAddress: (address: Address) => void;
  initial: any;
  title?: string;
  includeNames?: boolean;
}) {
  const input = useInput(addressForm, initial || defaultAddress);
  const nameInput = useInput(nameForm, {
    firstName: initial?.firstName || "",
    lastName: initial?.lastName || "",
  });
  useEffect(() => {
    if (initial) {
      input.setValue(initial);
      nameInput.setValue({
        firstName: initial.firstName || "",
        lastName: initial.lastName || "",
      });
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  const footer = (
    <div className={addressEditModalCss.modalFooter}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        disabled={
          !input.value.street1 ||
          !input.value.city ||
          !input.value.state ||
          !input.value.country ||
          !input.value.zip ||
          (includeNames &&
            (!nameInput.value.firstName || !nameInput.value.lastName)) ||
          (!includeNames && !input.changed) ||
          (includeNames && !nameInput.changed && !input.changed)
        }
        onClick={() => {
          includeNames
            ? setAddress({ ...input.value, ...nameInput.value })
            : setAddress(input.value);
          setOpen(false);
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Save address
      </Button>
    </div>
  );
  return (
    <Modal
      footer={footer}
      onClose={() => setOpen(false)}
      open={open}
      size={ModalSize.SMALL}
      title={title || "Edit address"}
    >
      <>
        {includeNames && (
          <div className={addressEditModalCss.addressFields}>
            <div className={addressEditModalCss.grow}>
              <FormTextInput
                input={nameInput.inputs.firstName}
                label="First name"
              />
            </div>
            <div className={addressEditModalCss.grow}>
              <FormTextInput
                input={nameInput.inputs.lastName}
                label="Last name"
              />
            </div>
          </div>
        )}
        <FormTextInput input={input.inputs.street1} label="Street" />
        <FormTextInput
          input={input.inputs.street2}
          label="Apartment, suite, etc. (optional)"
        />
        <div className={addressEditModalCss.addressFields}>
          <FormTextInput input={input.inputs.city} label="City" />
          <FormTextInput input={input.inputs.state} label="State/Province" />
          <FormTextInput input={input.inputs.zip} label="Zip/Postal" />
        </div>
        <FormSelectDropdown
          input={input.inputs.country}
          label="Country"
          options={countries.map((country: any) => country.code)}
        >
          {(code) =>
            countries.find((country: any) => code === country.code)?.name || ""
          }
        </FormSelectDropdown>
      </>
    </Modal>
  );
});
