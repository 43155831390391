import { ConciergeBehaviorSettingsParser } from "@redotech/redo-model/concierge-settings/general-behavior-db-parser";
import { object, string } from "zod";

export const getConciergeBehaviorSettingsSchema = {
  input: object({
    teamId: string(),
  }),
  output: object({
    conciergeBehaviorSettings: ConciergeBehaviorSettingsParser.optional(),
  }),
};
