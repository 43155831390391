import { MacroVariable as MacroVariableType } from "@redotech/redo-model/macro";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill/quill-editor.module.css";
import * as classNames from "classnames";
import Quill from "quill";
import { memo, useEffect, useState } from "react";
import { updateLinksToOpenInNewTab } from "./conversation-email-view/format-email-message-content";
import * as renderedMessageContentCss from "./rendered-message-content.module.css";
const Delta = Quill.import("delta");
// Styles for quill editor
import "quill/dist/quill.snow.css";

export const MacroVariable = memo(function MacroVariable({
  variable,
}: {
  variable: MacroVariableType;
}) {
  return (
    <span className={renderedMessageContentCss.variable}>
      {variable.displayName}
    </span>
  );
});

export const RenderedMessageContent = memo(function RenderedMessageContent({
  rawText,
  htmlContent,
  fillSpace = false,
}: {
  rawText: string;
  htmlContent: string;
  fillSpace?: boolean;
}) {
  const [quill, setQuill] = useState<Quill | null>(null);

  useEffect(() => {
    if (!quill) {
      return;
    }
    if (htmlContent) {
      const delta = quill.clipboard.convert({ html: htmlContent });
      quill.setContents(delta);
    } else if (rawText) {
      quill.setText(rawText);
    }
    updateLinksToOpenInNewTab(quill.root);
  }, [quill, htmlContent, rawText]);
  return (
    <div>
      <div>
        <QuillEditor
          defaultValue={new Delta().insert("")}
          editorClassName={classNames(
            renderedMessageContentCss.quillEditor,
            quillEditorCss.removePadding,
          )}
          placeholder="Start typing..."
          readOnly
          ref={setQuill}
          toolbar="macro-display-toolbar"
        />
      </div>
    </div>
  );
});
