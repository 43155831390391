import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { View } from "@redotech/redo-model/view";
import {
  RedoModal,
  RedoModalButtonProps,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReloadViewsContext } from "../app/views";
import { RedoMerchantClientContext } from "../client/context";
import { deleteView } from "../client/view";

export const DeleteViewModal = memo(function DeleteViewModal({
  open,
  setOpen,
  view,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  view: View;
}) {
  const reloadViews = useContext(ReloadViewsContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const navigate = useNavigate();

  const handleDeleteView = async () => {
    await deleteView(client, { id: view._id });
    amplitude.logEvent("delete-view", { view });
    if (reloadViews) {
      reloadViews();
    }
    setOpen(false);
    navigate(`/stores/${view.team}/support/table/all`);
  };

  const primaryButtonProps: RedoModalButtonProps = {
    onClick: handleDeleteView,
    text: "Delete",
  };

  const secondaryButtonProps: RedoModalButtonProps = {
    onClick: () => setOpen(false),
    text: "Cancel",
  };

  const modalTitle = `Delete view - ${view.name}`;

  return (
    <RedoModal
      isOpen={open}
      onModalCloseRequested={() => setOpen(false)}
      primaryButton={primaryButtonProps}
      secondaryButton={secondaryButtonProps}
      subtitle="Are you sure you want to delete this view?"
      theme={RedoModalTheme.ERROR}
      title={modalTitle}
    />
  );
});
