import MuiModal from "@mui/material/Modal";
import * as classNames from "classnames";
import { memo, ReactNode } from "react";
import * as redoModalCss from "./redo-modal.module.css";

/**
 * This component will get you the bare minimum of what makes a modal a modal.
 * You can use this on its own if you want to make a nonstandard modal
 */
export const RedoBaseModal = memo(function RedoModalContainer({
  children,
  onModalCloseRequested,
  isOpen,
  otherClasses = [],
}: {
  children?: ReactNode;
  onModalCloseRequested(): void;
  isOpen: boolean;
  otherClasses?: classNames.ArgumentArray;
}) {
  return (
    <MuiModal onClose={onModalCloseRequested} open={isOpen}>
      <article className={classNames(redoModalCss.modal, ...otherClasses)}>
        {children}
      </article>
    </MuiModal>
  );
});
