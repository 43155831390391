import { ConversationCustomer, ConversationPlatform } from "../conversation";

export enum SatisfactionResponseStatusTypes {
  PENDING = "pending",
  SENT = "sent",
  COMPLETED = "completed",
}

export enum CsatSortableField {
  COMPLETION_DATE = "updatedAt",
}

export interface CsatResponse {
  _id: string;
  team: string;
  conversation: string;
  rating: number;
  comment: string;
  channel: ConversationPlatform;
  status: SatisfactionResponseStatusTypes;
  updatedAt: Date;
  createdAt: Date;
}

export interface AnalyticsCsatResponse extends CsatResponse {
  customer?: ConversationCustomer;
  assignee?: string;
}
