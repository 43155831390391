import { z } from "zod";

export const resendEmailSchema = {
  input: z.object({
    emailURL: z.string(),
    orderId: z.string(),
    templateId: z.string(),
  }),
  output: z.literal("success"),
};
