export const DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
});

export const DATE_TIME_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export function formatDate(
  temporal: Temporal.PlainDate | Temporal.PlainDateTime | Temporal.Instant,
) {
  return DATE_FORMAT.format(new Date(String(temporal)));
}

export function formatDateTime(
  temporal: Temporal.PlainDate | Temporal.PlainDateTime | Temporal.Instant,
) {
  return DATE_TIME_FORMAT.format(new Date(String(temporal)));
}
