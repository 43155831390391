import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  ReviewFieldSchemaType,
  ReviewRating,
  ReviewStatus,
} from "./review-field-schema";

const REVIEW_FIELD_DISCRIMINATOR_KEY = "fieldType";

const _ReviewFieldDbParser = z.object({
  semanticTagId: z.string(),
  fieldType: z.nativeEnum(ReviewFieldSchemaType),
});

const ReviewShortAnswerReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_SHORT_ANSWER),
  text: z.string(),
});

const ReviewParagraphReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_PARAGRAPH),
  text: z.string(),
});

const ReviewSingleChoiceReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_SINGLE_CHOICE),
  text: z.string(),
});

const ReviewMediaUploadReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_MEDIA_UPLOAD),
  text: z.string(),
});

const ReviewRatingReviewFieldDbParser = _ReviewFieldDbParser.extend({
  fieldType: z.literal(ReviewFieldSchemaType.REVIEW_RATING),
  rating: z.nativeEnum(ReviewRating),
});

export const ReviewFieldDbParser = z.discriminatedUnion(
  REVIEW_FIELD_DISCRIMINATOR_KEY,
  [
    ReviewShortAnswerReviewFieldDbParser,
    ReviewParagraphReviewFieldDbParser,
    ReviewSingleChoiceReviewFieldDbParser,
    ReviewMediaUploadReviewFieldDbParser,
    ReviewRatingReviewFieldDbParser,
  ],
);

export type ReviewField = z.infer<typeof ReviewFieldDbParser>;

export const ReviewDbParser = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  status: z.nativeEnum(ReviewStatus),
  verifiedPurchase: z.boolean(),
  shopifyProductId: z.string(),
  customer: z
    .object({
      email: z.string(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
    })
    .optional(),
  reviewFields: z.array(ReviewFieldDbParser),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type IReview = z.infer<typeof ReviewDbParser>;

export const ReviewDbParserArray = z.array(ReviewDbParser);

export type ReviewWithRatingField = [IReview, ReviewRating];

export const isRatingFieldDefined = (
  review: [IReview, ReviewRating | undefined],
): review is ReviewWithRatingField => {
  return review[1] !== undefined;
};
