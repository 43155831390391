import { useEffect } from "react";
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { analyticsRoutes } from "./analytics/route";
import { ErrorPage } from "./app/error-page";
import { approvalRoutes } from "./approvals/route";
import { conciergeRoutes } from "./concierge/route";
import { customerRoutes } from "./customers/route";
import { gorgiasRoutes } from "./gorgias/route";
import { loginRoutes } from "./login/route";
import { marketingRoutes } from "./marketing/route";
import { orderRoutes } from "./order/route";
import { claimRoutes, returnRoutes, warrantyRoutes } from "./return/route";
import { reviewsRoutes } from "./reviews/route";
import { riseRoutes } from "./rise.ai/route";
import { RouterRoot } from "./root";
import { aboutTrackingRoutes } from "./setting/about-tracking/route";
import { settingRoutes } from "./setting/route";
import { shopifyRoutes } from "./shopify/route";
import "./styles.css";
import { summaryRoutes } from "./summary/route";
import { supportRoutes } from "./support/route";

export const RETURN_TYPE_ROUTE = {
  return: "returns",
  claim: "claims",
  warranty: "warranties",
  managed_claim: "claims",
};

/**
 * "Reports" used to not be under "Analytics", so if merchants have old URLs saved
 * and try to visit them, we need to redirect them to our new URLs so they don't get 404 errors.
 * This component redirect all subpaths of "/reports/*" to "/analytics/reports/*" because
 * <Navigate> doesn't support wildcard 'to' paths.
 */
function RedirectReports() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const newPath =
      location.pathname.replace("/reports", "/analytics/reports") +
      location.search;
    navigate(newPath, { replace: true });
  }, [location, navigate]);

  return null;
}

const children = [
  {
    index: true,
    element: <Navigate to="summary" />,
  },
  {
    path: "analytics",
    children: analyticsRoutes,
  },
  {
    // Redirect old reports/* URLs to analytics/reports/*
    path: "reports/*",
    element: <RedirectReports />,
  },
  {
    path: "login",
    children: loginRoutes,
  },
  {
    path: "orders",
    children: orderRoutes,
  },
  {
    path: "claims",
    children: claimRoutes,
  },
  {
    path: "reviews",
    children: reviewsRoutes,
  },
  {
    path: "support",
    children: supportRoutes,
  },
  {
    path: "tracking",
    children: aboutTrackingRoutes,
  },
  {
    path: "customers",
    children: customerRoutes,
  },
  {
    path: "returns",
    children: returnRoutes,
  },
  {
    path: "settings",
    children: settingRoutes,
  },
  {
    path: "shopify",
    children: shopifyRoutes,
  },
  {
    path: "summary",
    children: summaryRoutes,
  },
  {
    path: "gorgias",
    children: gorgiasRoutes,
  },
  {
    path: "rise-ai",
    children: riseRoutes,
  },
  {
    path: "warranties",
    children: warrantyRoutes,
  },
  {
    path: "marketing",
    children: marketingRoutes,
  },
  {
    path: "concierge",
    children: conciergeRoutes,
  },
  {
    path: "approvals",
    children: approvalRoutes,
  },
];

export const routes: RouteObject[] = [
  {
    path: "stores/:teamId/",
    Component: RouterRoot,
    children,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    Component: RouterRoot,
    errorElement: <ErrorPage />,
    children,
  },
];
