import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import ShippingIcon from "@redotech/redo-web/icon-old/shipping-box.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Switch } from "@redotech/redo-web/switch";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  greenReturnOverride: boolean;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State) {
  return state.greenReturnOverride !== undefined;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const handleGreenReturn = useHandler((greenReturnOverride: boolean) => {
    setState((state) => ({ ...state, greenReturnOverride }));
  });

  return (
    <>
      <LabeledInput
        description={
          state.greenReturnOverride
            ? "Require customer to return item(s),\noverriding by item settings"
            : "Allow customer to keep item(s),\noverriding by item settings"
        }
        label="Require Send Back"
      >
        <Switch
          disabled={!canEditSettings}
          onChange={handleGreenReturn}
          value={state.greenReturnOverride}
        />
      </LabeledInput>
      <LabeledInput label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const GREEN_RETURN_OVERRIDE: StepType<
  State,
  ModelStep.OverrideGreenReturn
> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Require Send Back",
  valid,
  Icon: ShippingIcon,
  description(state) {
    return state.greenReturnOverride
      ? "Require customer to return item(s)"
      : "Allow customer to keep item(s)";
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  empty: { next: undefined, greenReturnOverride: false },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): ModelStep.OverrideGreenReturn {
    return {
      type: ModelStep.OVERRIDE_GREEN_RETURN,
      next: state.next ? id(state.next) : undefined,
      greenReturnOverride: state.greenReturnOverride,
    };
  },
  fromModel(model, id) {
    return {
      next: model.next ? id(model.next) : undefined,
      greenReturnOverride: model.greenReturnOverride,
    };
  },
};
