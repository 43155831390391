import {
  ForwardedRef,
  forwardRef,
  JSXElementConstructor,
  memo,
  ReactNode,
} from "react";
import XIcon from "../../arbiter-icon/x-close.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { TextColorValue, TextWeightValue } from "../../theme/typography";
import { OptionalTooltip } from "../../tooltip/optional-tooltip";
import * as redoFilterGroupCss from "./redo-filter-group.module.css";

/**
 * Query and value are designed to be supplied with components like
 * `RedoFilterDropdownAnchor` and `FilterSection`.
 */
interface RedoFilterGroupProps {
  propertyName: string;
  Icon: JSXElementConstructor<any>;
  query: ReactNode;
  value: ReactNode;
  removeFilter(): void;
}

export const RedoFilterGroup = memo(function RedoFilterGroup({
  propertyName,
  Icon,
  query,
  value,
  removeFilter,
}: RedoFilterGroupProps) {
  return (
    <Flex className={redoFilterGroupCss.boxItems} gap="none">
      <Flex align="center" px="md" py="xxs">
        <Flex className={redoFilterGroupCss.iconWrapper}>
          <Icon />
        </Flex>
        <Text fontSize="sm" fontWeight="semibold">
          {propertyName}
        </Text>
      </Flex>

      {query}
      {value}
      <button className={redoFilterGroupCss.button} onClick={removeFilter}>
        <Flex className={redoFilterGroupCss.iconWrapper}>
          <XIcon />
        </Flex>
      </button>
    </Flex>
  );
});

/**
 * Forward the ref, and supply it to a dropdown that applies the on click
 */
export const RedoFilterDropdownAnchor = memo(
  forwardRef(function RedoFilterGroupButton(
    {
      text,
      color,
      weight,
      tooltip,
      onClick,
    }: {
      text: string;
      color?: TextColorValue;
      weight?: TextWeightValue;
      tooltip?: ReactNode;
      onClick?(): void;
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    return (
      <OptionalTooltip
        meetsCondition={!!tooltip}
        tooltipProps={{ title: tooltip }}
      >
        <button
          className={redoFilterGroupCss.button}
          onClick={onClick}
          ref={ref}
        >
          <Text fontSize="sm" fontWeight={weight} textColor={color}>
            {text}
          </Text>
        </button>
      </OptionalTooltip>
    );
  }),
);

export const RedoFilterSection = memo(function FilterSection({
  text,
  color,
  weight,
}: {
  text: string;
  color?: TextColorValue;
  weight?: TextWeightValue;
}) {
  return (
    <Flex align="center" px="md" py="xxs">
      <Text fontSize="sm" fontWeight={weight} textColor={color}>
        {text}
      </Text>
    </Flex>
  );
});
