export function hotkeyTooltip({
  action,
  code,
  name,
  isMac,
  trimmed,
  useModifierKey = true,
  modifierKey = "ctrl",
}: {
  action: string | undefined;
  code: string;
  name: string;
  isMac: boolean;
  trimmed?: boolean;
  useModifierKey?: boolean;
  modifierKey?: "alt" | "ctrl";
}): string {
  const controlKey = isMac ? "⌘" : "Ctrl";
  const altKey = isMac ? "⌥" : "Alt";
  const modifier = modifierKey === "alt" ? altKey : controlKey;
  if (trimmed) {
    return `${useModifierKey ? modifier + "+" : ""}${code}`;
  }
  return `${action ?? ""}${action ? " " : ""}${name} (${useModifierKey ? modifier + "+" : ""}${code})`;
}
