// extracted by mini-css-extract-plugin
export var addButton = "_5k-0";
export var border = "_5k-8";
export var check = "_5k-h";
export var danger = "_5k-6";
export var dropdownContainer = "_5k-1";
export var editModalBody = "_5k-j";
export var footerContainer = "_5k-i";
export var fullWidth = "_5k-a";
export var hide = "_5k-9";
export var icon = "_5k-3";
export var input = "_5k-7";
export var inputFlex = "_5k-b";
export var inputWrapper = "_5k-c";
export var manualFocus = "_5k-5";
export var optionButton = "_5k-4";
export var pillWrapper = "_5k-e";
export var pointer = "_5k-2";
export var showOverflow = "_5k-f";
export var tagsFlex = "_5k-d";
export var xButton = "_5k-g";