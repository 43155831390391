import { FlowType } from "@redotech/redo-model/return-flow";
import {
  Condition as ModelCondition,
  SkuOption,
} from "@redotech/redo-model/return-flow/condition";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { ConditionType } from "../condition";
import * as selectCss from "../select.module.css";

export const SKU: ConditionType<
  { codes: readonly string[]; skuOption: SkuOption | null },
  ModelCondition.Sku
> = {
  name: "SKU",
  description(state) {
    switch (state.skuOption!) {
      case SkuOption.EQUALS:
        return `SKU matches one from list`;
      case SkuOption.STARTS_WITH:
        return `SKU starts with one from list`;
      case SkuOption.ENDS_WITH:
        return `SKU ends with one from list`;
      case SkuOption.INCLUDES:
        return `SKU includes one from list`;
    }
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <>
        <LabeledInput label="Match type">
          <SelectDropdown
            disabled={disabled}
            options={Object.values(SkuOption)}
            value={state.skuOption}
            valueChange={(e) => {
              setState({ codes: state.codes, skuOption: e! });
            }}
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{type}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput
          description={`List of complete or partial SKU codes to check, case-sensitive${
            flowType === FlowType.FINALIZE_RETURN ||
            flowType === FlowType.FINALIZE_CLAIM
              ? ". If the SKU of any selected item matches, this condition will match."
              : ""
          }`}
          label="SKU codes (complete or partial)"
        >
          <ChipInput
            delimiter={ChipDelimiter.NEWLINE}
            disabled={disabled}
            trimWhitespace
            value={state.codes}
            valueChange={(e) =>
              setState({ codes: e, skuOption: state.skuOption })
            }
          />
        </LabeledInput>
      </>
    );
  },
  empty: { codes: [], skuOption: SkuOption.EQUALS },
  fromModel(model: ModelCondition.Sku) {
    return { codes: model.codes, skuOption: model.skuOption || null };
  },
  toModel(state) {
    return {
      type: ModelCondition.SKU,
      codes: state.codes,
      skuOption: state.skuOption || null,
    };
  },
  valid() {
    return true;
  },
};
