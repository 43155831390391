import { FilterGroupFilterOption } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { JSXElementConstructor } from "react";
import AtSignIcon from "../arbiter-icon/at-sign_filled.svg";
import CalendarIcon from "../arbiter-icon/calendar.svg";
import MessageIcon from "../arbiter-icon/message-text-square-02.svg";
import NotificationMessageIcon from "../arbiter-icon/notification-message.svg";
import PhoneIcon from "../arbiter-icon/phone-01.svg";
import TagIcon from "../arbiter-icon/tag-01.svg";
import UserIcon from "../arbiter-icon/user-01.svg";

export const ConversationFilterOptionToIcon: Record<
  FilterGroupFilterOption,
  JSXElementConstructor<any>
> = {
  [FilterGroupFilterOption.ASSIGNEES]: UserIcon,
  [FilterGroupFilterOption.CHANNELS]: PhoneIcon,
  [FilterGroupFilterOption.CONVERSATION_TAGS]: TagIcon,
  [FilterGroupFilterOption.CREATED_DATE]: CalendarIcon,
  [FilterGroupFilterOption.CLOSED_DATE]: CalendarIcon,
  [FilterGroupFilterOption.LAST_RESPONSE_AT]: CalendarIcon,
  [FilterGroupFilterOption.WORDS]: MessageIcon,
  [FilterGroupFilterOption.CUSTOMER_TAGS]: UserIcon,
  [FilterGroupFilterOption.READ]: NotificationMessageIcon,
  [FilterGroupFilterOption.MENTIONS]: AtSignIcon,
};

export const ConversationFilterOptionToName: Record<
  FilterGroupFilterOption,
  string
> = {
  [FilterGroupFilterOption.ASSIGNEES]: "Assignees",
  [FilterGroupFilterOption.CHANNELS]: "Channels",
  [FilterGroupFilterOption.CONVERSATION_TAGS]: "Chat tags",
  [FilterGroupFilterOption.CREATED_DATE]: "Created date",
  [FilterGroupFilterOption.CLOSED_DATE]: "Closed date",
  [FilterGroupFilterOption.LAST_RESPONSE_AT]: "Last received message",
  [FilterGroupFilterOption.WORDS]: "Message content",
  [FilterGroupFilterOption.CUSTOMER_TAGS]: "Customer tags",
  [FilterGroupFilterOption.READ]: "Read status",
  [FilterGroupFilterOption.MENTIONS]: "User mentions",
};
