import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { TextInput } from "@redotech/redo-web/text-input";
import { memo } from "react";

export const RuleDetailsDrawer = memo(function RuleDetailsDrawer({
  name,
  description,
  setName,
  setDescription,
}: {
  name: string | undefined;
  description: string | undefined;
  setName: (val: string) => void;
  setDescription: (val: string) => void;
}) {
  return (
    <>
      <LabeledInput label="Rule name">
        <TextInput onChange={setName} value={name || ""} />
      </LabeledInput>
      <LabeledInput label="Description">
        <TextInput onChange={setDescription} value={description || ""} />
      </LabeledInput>
    </>
  );
});
