import { assertNever } from "@redotech/util/type";

// These should mirror the widget types in the form builder. Inclusive to the widgets that require user input.
export enum ReviewFieldSchemaType {
  REVIEW_SHORT_ANSWER = "REVIEW_SHORT_ANSWER",
  REVIEW_PARAGRAPH = "REVIEW_PARAGRAPH",
  REVIEW_SINGLE_CHOICE = "REVIEW_SINGLE_CHOICE",
  REVIEW_MEDIA_UPLOAD = "REVIEW_MEDIA_UPLOAD",
  REVIEW_RATING = "REVIEW_RATING",
}

export enum ReviewStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  MANUAL_REVIEW = "MANUAL_REVIEW",
}

export const reviewStatusToDisplayText = (status: ReviewStatus): string => {
  switch (status) {
    case ReviewStatus.PENDING:
      return "Pending";
    case ReviewStatus.APPROVED:
      return "Approved";
    case ReviewStatus.REJECTED:
      return "Rejected";
    case ReviewStatus.MANUAL_REVIEW:
      return "Manual review";
    default:
      assertNever(status, `Unknown status: ${status}`);
  }
};

export enum ReviewRating {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
}

export const reviewRatingToNumber = (rating: ReviewRating): number => {
  switch (rating) {
    case ReviewRating.ONE:
      return 1;
    case ReviewRating.TWO:
      return 2;
    case ReviewRating.THREE:
      return 3;
    case ReviewRating.FOUR:
      return 4;
    case ReviewRating.FIVE:
      return 5;
    default:
      assertNever(rating, `Unknown rating: ${rating}`);
  }
};

export const numberToReviewRating = (rating: number): ReviewRating => {
  switch (rating) {
    case 1:
      return ReviewRating.ONE;
    case 2:
      return ReviewRating.TWO;
    case 3:
      return ReviewRating.THREE;
    case 4:
      return ReviewRating.FOUR;
    case 5:
      return ReviewRating.FIVE;
    default:
      throw new Error(`Unknown rating: ${rating}`);
  }
};
