import { Json } from "@redotech/json/json";
import { v3FiltersFormat } from "./conversation-filters/conversation-filter-formats";
import { ConversationFiltersV3 } from "./conversation-filters/conversation-filters";

interface SerializedViewFilterPair {
  viewName: string;
  filters: Json;
}

export interface SerializedGetConversationCountsBody {
  views: SerializedViewFilterPair[];
}

interface ViewFilterPair {
  viewName: string;
  filters: ConversationFiltersV3;
}

export interface GetConversationCountsBody {
  views: ViewFilterPair[];
}

export function deserializeGetConversationCountsBody(
  body: SerializedGetConversationCountsBody,
): GetConversationCountsBody {
  return {
    views: body.views.map((filterPair) => ({
      viewName: filterPair.viewName,
      filters: v3FiltersFormat.read(filterPair.filters),
    })),
  };
}

export function serializeGetConversationCountsBody(
  body: GetConversationCountsBody,
): SerializedGetConversationCountsBody {
  return {
    views: body.views.map((filterPair) => ({
      viewName: filterPair.viewName,
      filters: v3FiltersFormat.write(filterPair.filters),
    })),
  };
}
