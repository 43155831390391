import { NonIndexRouteObject } from "react-router-dom";

export const FAQ_HOME_ROUTE = "faq/home";
export function inFaqHomeView(url: string) {
  return url.includes(FAQ_HOME_ROUTE);
}
export function inFaqBuilder(url: string) {
  return url.includes("faq/editor/");
}

export const faqRoutes: NonIndexRouteObject = {
  path: "faq",
  lazy: async () => {
    const { FaqProvider } = await import("./faq-context-provider");
    return { Component: FaqProvider };
  },
  children: [
    {
      handle: { breadcrumb: "FAQ's" },
      path: "home",
      lazy: async () => {
        const { FaqManagerHome } = await import("./faq-manager-home");
        return { Component: FaqManagerHome };
      },
      children: [
        {
          path: "collections",
          lazy: async () => {
            const { FaqCollections } = await import("./faq-collections");
            return { Component: FaqCollections };
          },
        },
        {
          path: "articles",
          lazy: async () => {
            const { FaqArticles: FaqAllArticles } = await import(
              "./faq-articles"
            );
            return { Component: FaqAllArticles };
          },
        },
      ],
    },
    {
      path: "editor/theme",
      lazy: async () => {
        const { FaqThemeEditor } = await import("./faq-theme-editor");
        return { Component: FaqThemeEditor };
      },
    },
    {
      path: "editor/:id",
      lazy: async () => {
        const { FaqBuilder: FaqEditor } = await import("./faq-builder");
        return { Component: FaqEditor };
      },
    },
  ],
};
