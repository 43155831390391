import { XRequestedWithField } from "@redotech/http/ajax";
import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { MERCHANT_APP_ORIGIN } from "@redotech/redo-model/headers/requested-with-field";
import axios, { AxiosHeaders, AxiosInstance } from "axios";
import { REDO_MERCHANT_APP_VERSION } from "../config";

export class RedoMerchantClient {
  readonly client: AxiosInstance;

  constructor(
    base: string,
    private readonly token?: string,
  ) {
    const headers = new AxiosHeaders();
    headers.set(
      XRequestedWithField.name.value,
      `${MERCHANT_APP_ORIGIN}/${REDO_MERCHANT_APP_VERSION}`,
    );
    this.client = axios.create({ baseURL: base, headers });
  }

  authorization(): { Authorization: string } {
    if (!this.token) {
      throw new Error("Missing token");
    }
    const result = {
      [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
        bearerCredentialsFormat.write(this.token),
      ),
    };
    return result as { Authorization: string };
  }

  readonly $type: typeof RedoMerchantClient.$TYPE = RedoMerchantClient.$TYPE;

  static readonly $TYPE = Symbol(this.name);
}
