import { z } from "zod";
import { upsellPageDataSchema } from "./upsell-page-data-schema";

export const getActiveUpsellPageForTeamSchema = {
  input: z.null(),
  output: z
    .object({
      upsellPageId: z.string(),
      upsellPageData: upsellPageDataSchema,
    })
    .nullable(),
};
