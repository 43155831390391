import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { PopperPlacementType } from "@mui/base/Popper";
import { useHandler } from "@redotech/react-util/hook";
import { useOnHover } from "@redotech/react-util/hover";
import { ReactNode, memo, useEffect, useState } from "react";
import { Button, ButtonSize, ButtonTheme } from "./button";
import * as buttonDropdownCss from "./button-dropdown.module.css";
import { Dropdown } from "./dropdown";

export const ButtonDropdown = memo(function ButtonDropdown<T>({
  dropdown,
  children,
  onHover,
  theme = ButtonTheme.GHOST,
  size = ButtonSize.MEDIUM,
  restrictDropdownSizeToParent = true,
  multipleClicks = false,
  clickStopPropagation = false,
  open,
  setOpen,
  fireViewEvent,
  darkMode,
  pending,
  disabled,
  dropdownPlacement,
}: {
  children: ReactNode;
  dropdown: ReactNode | ReactNode[];
  theme?: ButtonTheme;
  size?: ButtonSize;
  onHover?: (value: boolean) => void;
  restrictDropdownSizeToParent?: boolean;
  multipleClicks?: boolean;
  clickStopPropagation?: boolean;
  open?: boolean;
  setOpen?: (value: boolean) => void;
  fireViewEvent?: () => void;
  darkMode?: boolean;
  pending?: boolean;
  disabled?: boolean;
  dropdownPlacement?: PopperPlacementType;
}) {
  const [open_, setOpen_] = useState(false);
  const dropdownOpen = open || open_;
  const setDropdownOpen = setOpen || setOpen_;

  useEffect(() => {
    if (dropdownOpen && fireViewEvent) {
      fireViewEvent();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen]);

  const onClickAway = useHandler(async () => {
    // Weird race condition, the click event needs to process before the dropdown closes
    // (this was causing problems on iPads)
    await new Promise((r) => setTimeout(r, 1));
    setDropdownOpen(false);
  });

  const [button, setButton] = useState<HTMLElement | null>(null);

  useOnHover(button, onHover || (() => {}));

  // FIXME Does not implement every possible value for ButtonSize - if given ButtonSize.LARGE, styles will be set to "micro"
  const containerClass =
    size === ButtonSize.MEDIUM
      ? buttonDropdownCss.containerMedium
      : size === ButtonSize.SMALL
        ? buttonDropdownCss.containerSmall
        : buttonDropdownCss.containerMicro;

  return (
    <>
      {multipleClicks && (
        <ClickAwayListener onClickAway={onClickAway}>
          <div className={containerClass}>
            <Button
              disabled={disabled}
              onClick={(event) => {
                clickStopPropagation && event.stopPropagation();
                setDropdownOpen(!dropdownOpen);
              }}
              pending={pending}
              ref={setButton}
              size={size}
              theme={theme}
            >
              {children}
            </Button>
            <Dropdown
              anchor={button}
              darkMode={darkMode !== false && theme === ButtonTheme.DARK}
              fitToAnchor={restrictDropdownSizeToParent}
              open={dropdownOpen}
              placement={dropdownPlacement}
            >
              {dropdown}
            </Dropdown>
          </div>
        </ClickAwayListener>
      )}
      {!multipleClicks && (
        <div className={containerClass}>
          <ClickAwayListener onClickAway={onClickAway}>
            <Button
              disabled={disabled}
              onClick={(event) => {
                clickStopPropagation && event.stopPropagation();
                setDropdownOpen(!dropdownOpen);
              }}
              pending={pending}
              ref={setButton}
              size={size}
              theme={theme}
            >
              {children}
            </Button>
          </ClickAwayListener>
          <Dropdown
            anchor={button}
            darkMode={darkMode !== false && theme === ButtonTheme.DARK}
            fitToAnchor={restrictDropdownSizeToParent}
            open={dropdownOpen}
          >
            {dropdown}
          </Dropdown>
        </div>
      )}
    </>
  );
});
