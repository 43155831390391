import { getUsagePlanPricing } from "../../shopify-subscription/shopify-subscription";
import { Team } from "../../team";

export const DEFAULT_MMS_PRICE_IN_TENTH_CENTS = 45;
export const DEFAULT_SMS_PRICE_IN_TENTH_CENTS = 20;
export const DEFAULT_MONTHLY_MINIMUM_IN_TENTH_CENTS = 10000;

export interface TextMessagingBilling {
  enabled: boolean;
  subscriptionActive: boolean;
  pricePerSMSInTenthCents: number;
  pricePerMMSInTenthCents: number;
  monthlyMinimumInTenthCents: number;
  currentBillingCycle?: BillingCycle | null;
  unbilled: MessageCount;
  previousCycles: BillingCycle[];
  billingFailed: boolean;
}

export const TextMessagingBillingDefault: TextMessagingBilling = {
  enabled: false,
  subscriptionActive: false,
  pricePerSMSInTenthCents: DEFAULT_SMS_PRICE_IN_TENTH_CENTS,
  pricePerMMSInTenthCents: DEFAULT_MMS_PRICE_IN_TENTH_CENTS,
  monthlyMinimumInTenthCents: DEFAULT_MONTHLY_MINIMUM_IN_TENTH_CENTS,
  unbilled: {
    mmsMessagesSent: 0,
    smsMessagesSent: 0,
    mmsMessagesReceived: 0,
    smsMessagesReceived: 0,
  },
  previousCycles: [],
  billingFailed: false,
};

export interface MessageCount {
  mmsMessagesSent: number;
  smsMessagesSent: number;
  mmsMessagesReceived: number;
  smsMessagesReceived: number;
}
export interface BillingCycle {
  billed: MessageCount;
  monthlyMinimumRemainingInTenthCents: number;
  lastBilledAt: Date;
  billingCycleStartDate: Date;
}

export function shouldAllowSMSActions(team: Team): boolean {
  const usagePlan = getUsagePlanPricing(team.settings.shopifySubscription?.[0]);

  if (!usagePlan) {
    return false;
  }

  if (!team.settings.support?.billing?.textMessaging) {
    return false;
  }
  const textMessageBilling = team.settings.support.billing.textMessaging;
  if (!textMessageBilling.enabled) {
    return false;
  }
  if (textMessageBilling.billingFailed) {
    return false;
  }
  if (!textMessageBilling.currentBillingCycle) {
    return false;
  }
  if (textMessageBilling.subscriptionActive) {
    return true;
  }

  const unbilledCostInTenthCents = calculatePriceInTenthCentsForMessages(
    textMessageBilling.unbilled,
    textMessageBilling,
  );

  return (
    textMessageBilling.currentBillingCycle.monthlyMinimumRemainingInTenthCents -
      unbilledCostInTenthCents >
    0
  );
}

export function calculatePriceInTenthCentsForMessages(
  messages: MessageCount,
  textMessagingBilling: TextMessagingBilling,
): number {
  const pricePerSMSInTenthCents = textMessagingBilling.pricePerSMSInTenthCents;
  const pricePerMMSInTenthCents = textMessagingBilling.pricePerMMSInTenthCents;

  return (
    messages.smsMessagesSent * pricePerSMSInTenthCents +
    messages.mmsMessagesSent * pricePerMMSInTenthCents +
    messages.smsMessagesReceived * pricePerSMSInTenthCents +
    messages.mmsMessagesReceived * pricePerMMSInTenthCents
  );
}

export function addMessageCounts(
  messageCounts: MessageCount,
  newMessages: MessageCount,
): MessageCount {
  return {
    mmsMessagesSent:
      messageCounts.mmsMessagesSent + newMessages.mmsMessagesSent,
    smsMessagesSent:
      messageCounts.smsMessagesSent + newMessages.smsMessagesSent,
    mmsMessagesReceived:
      messageCounts.mmsMessagesReceived + newMessages.mmsMessagesReceived,
    smsMessagesReceived:
      messageCounts.smsMessagesReceived + newMessages.smsMessagesReceived,
  };
}
