// extracted by mini-css-extract-plugin
export var aboveInput = "_7y-0";
export var actionButton = "_7y-5";
export var actionButtonContainer = "_7y-1";
export var actionIcon = "_7y-6";
export var aiInfoContainer = "_7y-2";
export var attachmentWrapper = "_7y-7";
export var bold = "_7y-3";
export var checkIcon = "_7y-8";
export var clickingRecipientBar = "_7y-11";
export var commentThreadSwitch = "_7y-a";
export var draftImage = "_7y-b";
export var draftImageContainer = "_7y-c";
export var draftImages = "_7y-e";
export var editButton = "_7y-g";
export var editor = "_7y-f";
export var editorContainer = "_7y-y";
export var editorErrorTooltipWrapper = "_7y-9";
export var headerDivider = "_7y-h";
export var icon = "_7y-i";
export var info = "_7y-j";
export var internal = "_7y-n";
export var internalDivider = "_7y-k";
export var internalMessage = "_7y-l";
export var messageInputCard = "_7y-m";
export var messageInputForm = "_7y-o";
export var messageInputFormInternal = "_7y-p";
export var options = "_7y-q";
export var publicMessageCustomer = "_7y-s";
export var publicMessageMerchant = "_7y-r";
export var removeImageButton = "_7y-d";
export var replyButtons = "_7y-t";
export var showSignatureButton = "_7y-u";
export var spinner = "_7y-v";
export var verticalButtonDivider = "_7y-4";
export var visibilitySelector = "_7y-w";
export var warning = "_7y-x";
export var wrappingText = "_7y-z";
export var writingInternalNoteTopBorder = "_7y-10";