import { asyncIterableMap } from "@redotech/util/async-iterator";
import { stringStreamSplit } from "@redotech/util/string-stream";
import { Json } from "./json";

export async function* ndjsonParse(
  stream: AsyncIterable<string>,
): AsyncIterableIterator<Json> {
  for await (const line of stringStreamSplit(stream, "\n")) {
    yield JSON.parse(line);
  }
}

export function ndjsonSerialize(
  stream: AsyncIterable<Json>,
): AsyncIterable<string> {
  return asyncIterableMap(stream, (json) => JSON.stringify(json) + "\n");
}
