import { TooltipProps } from "@mui/material/Tooltip";
import { memo } from "react";
import { Flex } from "../flex";
import { Tooltip } from "../tooltip/tooltip";

interface OptionalTooltipProps {
  meetsCondition: boolean;
  children: React.ReactElement;
  tooltipProps: Omit<TooltipProps, "children">;
  wrapperClass?: string;
}

export const OptionalTooltip = memo(function OptionalTooltip({
  meetsCondition,
  children,
  tooltipProps,
  wrapperClass,
}: OptionalTooltipProps): React.ReactElement {
  if (meetsCondition) {
    return (
      <Tooltip {...tooltipProps}>
        <Flex as="span" className={wrapperClass}>
          {children}
        </Flex>
      </Tooltip>
    );
  } else {
    return (
      <Flex as="span" className={wrapperClass}>
        {children}
      </Flex>
    );
  }
});
