import { getReturner, type Return } from "@redotech/redo-model/return";
import { Card, CardSize } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { memo } from "react";
import { shopifyAddressString } from "../return";

export const ReturnerCard = memo(function ReturnerCard({
  return: return_,
}: {
  return?: Return;
}) {
  if (!return_) {
    return (
      <Card size={CardSize.MEDIUM} title="Contact info">
        <div className={cardCss.title}>
          <SkeletonText length={30} />
        </div>
        <div className={cardCss.body}>
          <SkeletonText length={14} />
        </div>
        <div className={cardCss.body}>
          <SkeletonText length={28} />
        </div>
      </Card>
    );
  }
  const returner = getReturner(return_);

  return (
    <Card size={CardSize.MEDIUM} title="Contact info">
      <div className={cardCss.title}>{returner.name}</div>
      <a className={cardCss.body} href={`mailto:${returner.email}`}>
        {returner.email}
      </a>
      {!!returner.phone && <a className={cardCss.body}>{returner.phone}</a>}
      <address className={cardCss.body}>
        {return_.shipping_address
          ? shopifyAddressString(return_.shipping_address)
          : shopifyAddressString(
              return_.products[0].order.shopify.shipping_address,
            )}
      </address>
    </Card>
  );
});
