import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import {
  ConversationPlatform,
  ConversationStatus,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCommandMenu } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import CheckIcon from "@redotech/redo-web/arbiter-icon/check.svg";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import StatusIcon from "@redotech/redo-web/arbiter-icon/status.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import ArchiveIcon from "@redotech/redo-web/icon-old/archive.svg";
import EnvelopeOpen from "@redotech/redo-web/icon-old/envelope-open.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import MergeIcon from "@redotech/redo-web/icon-old/merge.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import SpamIcon from "@redotech/redo-web/icon-old/spam.svg";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/icon-old/three-dots-horizontal.svg";
import UndoArrow from "@redotech/redo-web/icon-old/undo-arrow.svg";
import UserPlusIcon from "@redotech/redo-web/icon-old/user-plus.svg";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { bulkUpdateViewed, getConversations } from "../../client/conversations";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import { ArchiveTicketModal } from "../conversation-actions/archive-ticket-modal";
import { AssignTicketModal } from "../conversation-actions/assign-ticket-modal";
import { ChangeSubjectModal } from "../conversation-actions/change-subject-modal";
import {
  CloseTicketModal,
  handleCloseTickets,
} from "../conversation-actions/close-ticket-modal";
import { InProgressTicketModal } from "../conversation-actions/in-progress-ticket-modal";
import { MarkSpamModal } from "../conversation-actions/mark-spam-modal";
import { MergeModal } from "../conversation-actions/merge-modal";
import { ReopenTicketModal } from "../conversation-actions/reopen-ticket-modal";
import { SnoozeModal } from "../conversation-actions/snooze-modal";
import { TagsModal } from "../conversation-actions/tags-modal";
import { FinalizedFiltersContext } from "../conversations-table-filters/filters-context";
import { getNextBatch } from "../utils";
import { ConversationsTableSelectionContext } from "./conversations-table-selection";
import * as conversationsTableCss from "./conversations-table.module.css";

export const ConversationsTableHeaderSelectedTicketActions = memo(
  function ConversationsTableHeaderSelectedTicketActions({
    pageLoaded,
    setBlockRefresh,
    setActiveConversation,
  }: {
    pageLoaded: boolean;
    setBlockRefresh: (block: boolean) => void;
    setActiveConversation: (
      conversation: ExpandedConversation | undefined,
    ) => void;
  }) {
    const tableSelection = useRequiredContext(
      ConversationsTableSelectionContext,
    );
    const client = useRequiredContext(RedoMerchantClientContext);
    const team = useContext(TeamContext);
    const filters = useContext(FinalizedFiltersContext);
    const user = useRequiredContext(UserContext);
    const { removeConversation, waitForRefresh, refreshIgnoreResult } =
      useRequiredContext(UpdateConversationStateContext);

    const [iteratorRefresh, setIteratorRefresh] = useState(Symbol());
    const [abort, setAbort] = useState(new AbortController());

    const [closeModalOpen, setCloseModalOpen] = useState(false);
    const [reopenModalOpen, setReopenModalOpen] = useState(false);
    const [inProgressModalOpen, setInProgressModalOpen] = useState(false);
    const [mergeModalOpen, setMergeModalOpen] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    const [tagEditMode, setTagEditMode] = useState<"add" | "remove">("add");
    const [markSpamModalOpen, setMarkSpamModalOpen] = useState(false);
    const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
    const [changeSubjectModalOpen, setChangeSubjectModalOpen] = useState(false);

    const [
      performSelectAllActionImmediately,
      setPerformSelectAllActionImmediately,
    ] = useState(false);

    const [actionsDropdownAnchor, setActionsDropdownAnchor] =
      useState<HTMLElement | null>(null);
    const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);

    const [bulkActionIterator, setBulkActionIterator] = useState<
      AsyncIterator<{
        conversations: ExpandedConversation[];
      }>
    >();

    const [progressModalInfo, setProgressModalInfo] = useState<{
      open: boolean;
      progress: number; // out of 100
      title: string;
    }>({
      open: false,
      progress: 0,
      title: "",
    });

    const selectAllMode = tableSelection.selectAllMode;
    const selectedConversationIds = tableSelection.selectedIds;
    const selectedConversations = tableSelection.selectedRecords;
    const selectAllCount = tableSelection.totalConversations;
    const deselectedConversationIds = tableSelection.deselectedIds;
    const deselectedConversations = tableSelection.deselectedRecords;

    const canReply = permitted(user.permissions, Permission.CREATE_REPLY);
    const canCloseTicket = permitted(
      user.permissions,
      Permission.CLOSE_CONVERSATION,
    );
    const canAssignTag = permitted(user.permissions, Permission.ASSIGN_TAG);
    const canArchiveTicket = permitted(
      user.permissions,
      Permission.ARCHIVE_CONVERSATION,
    );
    const canEditAssignee = permitted(
      user.permissions,
      Permission.EDIT_ASSIGNEE,
    );

    const isShowingClose =
      (canCloseTicket &&
        !selectAllMode &&
        selectedConversations.some(
          (conversation) =>
            conversation.status !== ConversationStatus.CLOSED.toLowerCase(),
        )) ||
      (selectAllMode &&
        selectAllCount &&
        filters.status !== ConversationStatus.CLOSED.toLowerCase());

    const bulkCleanupFn = (clearSelected?: boolean) => {
      if (selectAllMode) {
        setIteratorRefresh(Symbol());
      }
      if (clearSelected) {
        tableSelection.clearSelection();
      }
      refreshIgnoreResult();
    };

    useEffect(() => {
      let signal = abort.signal;
      if (abort) {
        abort.abort();
        const newAbort = new AbortController();
        setAbort(newAbort);
        signal = newAbort.signal;
      }
      setBulkActionIterator(bulkActionFetcher(10, signal, filters));
      if (bulkActionIterator?.return) {
        void bulkActionIterator.return();
      }
    }, [filters, iteratorRefresh]);

    async function* bulkActionFetcher(
      pageSize?: number,
      signal?: AbortSignal,
      passThroughValues?: ConversationFiltersV3,
    ): AsyncIterator<{
      conversations: ExpandedConversation[];
    }> {
      if (pageSize === undefined) {
        pageSize = 10;
      }
      for (let pageContinue: string | undefined; ; ) {
        const { data, pageNext } = await getConversations(client, {
          pageContinue,
          pageSize,
          filters: passThroughValues,
          signal,
        });
        yield { conversations: data };
        if (pageNext === undefined) {
          break;
        }
        pageContinue = pageNext;
      }
    }

    function logBulkConversationActionEvent(name: string) {
      if (selectAllMode) {
        amplitude.logEvent(name, { mode: "all" });
      } else if (selectedConversationIds.length > 0) {
        const channels = [
          ...new Set(
            selectedConversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent(name, {
          mode: "multiple",
          conversationIds: selectedConversationIds,
          channels,
        });
      }
    }

    const updateBatchedViewed = async (
      markRead: boolean,
    ): Promise<{
      batchSize: number;
      done: boolean;
    }> => {
      if (!bulkActionIterator) {
        return { batchSize: 0, done: true };
      }
      const { done, value } = await getNextBatch(bulkActionIterator);
      if (done) {
        return { batchSize: 0, done: true };
      }
      const conversationIds = value.conversations
        .filter(
          (conversation) =>
            !deselectedConversationIds.some(
              (conversationId) => conversationId === conversation._id,
            ),
        )
        .map((conversation) => conversation._id);
      if (conversationIds.length > 0) {
        await bulkUpdateViewed(client, {
          markRead,
          conversationIds,
        });
      }
      return { batchSize: conversationIds.length, done: false };
    };

    const handleBulkMarkUnread = async () => {
      if (selectAllMode) {
        if (!selectAllCount || !bulkActionIterator) {
          return;
        }
        setProgressModalInfo({
          open: true,
          progress: 0,
          title: "Marking unread",
        });
        setBlockRefresh(true);
        let done = false;
        do {
          const result = await updateBatchedViewed(false);
          setProgressModalInfo((prev) => ({
            ...prev,
            progress: prev.progress + (result.batchSize / selectAllCount) * 100,
          }));
          done = result.done;
        } while (!done);
        setProgressModalInfo({
          open: false,
          progress: 0,
          title: "",
        });
        setBlockRefresh(false);
        bulkCleanupFn(true);
      } else if (selectedConversationIds.length > 0) {
        if (selectedConversationIds.length > 0) {
          await bulkUpdateViewed(client, {
            markRead: false,
            conversationIds: selectedConversationIds,
          });
        }
        bulkCleanupFn(true);
        await waitForRefresh();
      }
    };

    const handleBulkMarkRead = async () => {
      if (selectAllMode) {
        if (!selectAllCount || !bulkActionIterator) {
          return;
        }
        setProgressModalInfo({
          open: true,
          progress: 0,
          title: "Marking read",
        });
        setBlockRefresh(true);
        let done = false;
        do {
          const result = await updateBatchedViewed(true);
          setProgressModalInfo((prev) => ({
            ...prev,
            progress: prev.progress + (result.batchSize / selectAllCount) * 100,
          }));
          done = result.done;
        } while (!done);
        setProgressModalInfo({
          open: false,
          progress: 0,
          title: "",
        });
        setBlockRefresh(false);
        bulkCleanupFn(true);
      } else if (selectedConversationIds.length > 0) {
        await bulkUpdateViewed(client, {
          markRead: true,
          conversationIds: selectedConversationIds,
        });

        bulkCleanupFn(true);
        await waitForRefresh();
      }
    };

    const handleChangeSubject = () => {
      setChangeSubjectModalOpen(true);
    };

    const handleBulkAssign = () => {
      logBulkConversationActionEvent("view-assignConversationModal");
      setAssignModalOpen(true);
    };

    const handleBulkAddTags = () => {
      logBulkConversationActionEvent("view-tagDropdown");
      setTagEditMode("add");
      setTagModalOpen(true);
    };

    const handleBulkRemoveTags = () => {
      logBulkConversationActionEvent("view-removeTagDropdown");
      setTagEditMode("remove");
      setTagModalOpen(true);
    };

    const handleBulkMarkSpam = () => {
      logBulkConversationActionEvent("view-markSpamModal");
      setMarkSpamModalOpen(true);
    };

    const handleBulkSnooze = () => {
      logBulkConversationActionEvent("view-snoozeConversationModal");
      setSnoozeModalOpen(true);
    };

    const handleBulkArchive = () => {
      logBulkConversationActionEvent("view-archiveConversationModal");
      setArchiveModalOpen(true);
    };

    const handleBulkReopen = async () => {
      logBulkConversationActionEvent("view-reopenConversationModal");
      setReopenModalOpen(true);
    };

    const handleBulkMarkInProgress = () => {
      logBulkConversationActionEvent("view-inProgressConversationModal");
      setInProgressModalOpen(true);
    };

    const handleBulkClose = async () => {
      if (selectAllMode) {
        if (localStorage.getItem("redo.doNotShowCloseTicketModal") === "true") {
          setPerformSelectAllActionImmediately(true);
        }
        amplitude.logEvent("view-closeConversationModal", { mode: "all" });
        setCloseModalOpen(true);
      } else if (selectedConversationIds.length > 0) {
        const channels = [
          ...new Set(
            selectedConversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent("view-closeConversationModal", {
          mode: "multiple",
          conversationIds: selectedConversationIds,
          channels,
        });
        if (
          localStorage.getItem("redo.doNotShowCloseTicketModal") === "true" &&
          !!team
        ) {
          await handleCloseTickets({
            client,
            conversations: selectedConversations,
            setActiveConversation,
            team,
            inDetailView: false,
            cleanupFn: bulkCleanupFn,
            removeConversation,
          });
        } else {
          setCloseModalOpen(true);
        }
      }
    };

    const handleBulkMerge = () => {
      logBulkConversationActionEvent("view-mergeConversationModal");
      setMergeModalOpen(true);
    };

    const actionButtons = [
      // Assign
      {
        show:
          canEditAssignee &&
          ((!selectAllMode && selectedConversationIds.length > 0) ||
            (selectAllMode && selectAllCount)),
        commandMenuItem: {
          Icon: UserPlusIcon,
          text: "Assign",
          onClick: handleBulkAssign,
        },
      },
      // Add tags
      {
        show: canAssignTag,
        commandMenuItem: {
          Icon: TagIcon,
          text:
            selectedConversationIds.length > 1 || selectAllMode
              ? "Add tags"
              : "Edit tags",
          onClick: handleBulkAddTags,
        },
      },
      // Remove tags
      {
        show:
          canAssignTag && (selectedConversationIds.length > 1 || selectAllMode),
        commandMenuItem: {
          Icon: XIcon,
          text: "Remove tags",
          onClick: handleBulkRemoveTags,
        },
      },
      // Mark in progress
      {
        show: team?.settings.support?.useInProgressStatus,
        commandMenuItem: {
          Icon: StatusIcon,
          text: "Mark in progress",
          onClick: handleBulkMarkInProgress,
        },
      },
      // Mark unread
      {
        show: true,
        commandMenuItem: {
          Icon: EnvelopeIcon,
          text: "Mark unread",
          onClick: handleBulkMarkUnread,
        },
      },
      // Mark read
      {
        show: true,
        commandMenuItem: {
          Icon: EnvelopeOpen,
          text: "Mark read",
          onClick: handleBulkMarkRead,
        },
      },
      // Mark as spam
      {
        show:
          canCloseTicket &&
          ((!selectAllMode &&
            selectedConversations.some(
              (conversation) =>
                conversation.platform === ConversationPlatform.EMAIL,
            )) ||
            (selectAllMode && selectAllCount && false)), // How can we check that we're only looking at emails?
        commandMenuItem: {
          Icon: SpamIcon,
          text: "Mark spam",
          onClick: handleBulkMarkSpam,
        },
      },
      // Change subject
      {
        show:
          canReply &&
          !selectAllMode &&
          selectedConversationIds.length === 1 &&
          selectedConversations[0].platform === ConversationPlatform.EMAIL,
        commandMenuItem: {
          Icon: Edit04Icon,
          text: "Change subject",
          onClick: () => handleChangeSubject(),
        },
      },
      // Merge
      {
        show:
          canCloseTicket &&
          ((!selectAllMode &&
            selectedConversationIds.length > 1 &&
            selectedConversationIds.length <= 20 &&
            !selectedConversations.some((conversation) =>
              [
                ConversationPlatform.INSTAGRAM_COMMENTS,
                ConversationPlatform.FACEBOOK_COMMENTS,
              ].includes(conversation.platform),
            )) ||
            (selectAllMode &&
              selectAllCount &&
              selectAllCount - deselectedConversationIds.length > 1 &&
              selectAllCount - deselectedConversationIds.length <= 20)),
        commandMenuItem: {
          Icon: MergeIcon,
          text: "Merge",
          onClick: handleBulkMerge,
        },
      },
      // Archive
      {
        show:
          canArchiveTicket &&
          ((!selectAllMode && selectedConversationIds.length > 0) ||
            (selectAllMode && selectAllCount)),
        commandMenuItem: {
          Icon: ArchiveIcon,
          text: "Delete",
          onClick: handleBulkArchive,
        },
      },
      // Snooze
      {
        show:
          canReply &&
          ((!selectAllMode &&
            selectedConversations.some(
              (conversation) =>
                conversation.status !== ConversationStatus.CLOSED.toLowerCase(),
            )) ||
            (selectAllMode &&
              selectAllCount &&
              filters.status !== ConversationStatus.CLOSED.toLowerCase())),
        commandMenuItem: {
          Icon: SnoozeClockIcon,
          text: "Snooze",
          onClick: handleBulkSnooze,
        },
      },
      // Reopen
      {
        show:
          canCloseTicket &&
          ((!selectAllMode &&
            selectedConversations.some(
              (conversation) =>
                conversation.status ===
                  ConversationStatus.CLOSED.toLowerCase() ||
                conversation.status ===
                  ConversationStatus.IN_PROGRESS.toLowerCase(),
            )) ||
            (selectAllMode &&
              selectAllCount &&
              ["closed", "all", "in_progress"].includes(filters.status || ""))),
        commandMenuItem: {
          Icon: UndoArrow,
          text: "Reopen",
          onClick: handleBulkReopen,
        },
      },
    ];

    const enabledActionButtons = pageLoaded
      ? actionButtons.filter((actionButton) => actionButton.show)
      : [];

    const notSelectAllSelectedText =
      selectedConversationIds.length === 1
        ? "1 ticket selected"
        : `${selectedConversationIds.length} tickets selected`;

    const adjustedSelectAllCount =
      selectAllCount - deselectedConversationIds.length;

    const selectedAllSelectedText =
      adjustedSelectAllCount === 1
        ? "1 ticket selected"
        : `${adjustedSelectAllCount} tickets selected`;

    const newActionsDropdown = enabledActionButtons.length > 2 && (
      <RedoCommandMenu
        anchor={actionsDropdownAnchor}
        items={enabledActionButtons.map(
          (actionButton) => actionButton.commandMenuItem,
        )}
        open={actionsDropdownOpen}
        setOpen={setActionsDropdownOpen}
      />
    );

    const showActionsSection =
      selectedConversations.length > 0 || (selectAllMode && selectAllCount > 0);
    const selectedText = selectAllMode
      ? selectedAllSelectedText
      : notSelectAllSelectedText;

    // this component keeps re-rendering and would cause the modals to re-render, this is a temporary fix until we re-work the bulk actions
    const memoizedSelectAllInfo = useMemo(() => {
      if (selectAllMode && selectAllCount && bulkActionIterator) {
        return {
          count: selectAllCount,
          deselectedConversations,
          filters,
          setBlockRefresh,
          bulkActionIterator,
        };
      }
      return undefined;
    }, [
      selectAllMode,
      selectAllCount,
      bulkActionIterator,
      deselectedConversations,
      filters,
      setBlockRefresh,
    ]);

    const modals = (
      <>
        {closeModalOpen && (
          <CloseTicketModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            inDetailView={false}
            open={closeModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                    closeImmediately: performSelectAllActionImmediately,
                  }
                : undefined
            }
            setActiveConversation={setActiveConversation}
            setOpen={setCloseModalOpen}
          />
        )}
        {reopenModalOpen && (
          <ReopenTicketModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            inDetailView={false}
            open={reopenModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setActiveConversation={setActiveConversation}
            setOpen={setReopenModalOpen}
          />
        )}
        {inProgressModalOpen && (
          <InProgressTicketModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            inDetailView={false}
            open={inProgressModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setActiveConversation={setActiveConversation}
            setOpen={setInProgressModalOpen}
          />
        )}
        {snoozeModalOpen && (
          <SnoozeModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            inDetailView={false}
            open={snoozeModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setActiveConversation={setActiveConversation}
            setOpen={setSnoozeModalOpen}
          />
        )}
        {archiveModalOpen && (
          <ArchiveTicketModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            inDetailView={false}
            open={archiveModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setActiveConversation={setActiveConversation}
            setOpen={setArchiveModalOpen}
          />
        )}
        {mergeModalOpen && (
          <MergeModal
            conversations={selectAllMode ? [] : selectedConversations}
            open={mergeModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setActiveConversation={setActiveConversation}
            setOpen={setMergeModalOpen}
          />
        )}
        {assignModalOpen && (
          <AssignTicketModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            open={assignModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setOpen={setAssignModalOpen}
          />
        )}
        {tagModalOpen && (
          <TagsModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            mode={tagEditMode}
            open={tagModalOpen}
            selectAllInfo={memoizedSelectAllInfo}
            setOpen={setTagModalOpen}
          />
        )}
        {markSpamModalOpen && (
          <MarkSpamModal
            cleanupFn={bulkCleanupFn}
            conversations={selectAllMode ? [] : selectedConversations}
            inDetailView={false}
            open={markSpamModalOpen}
            setActiveConversation={setActiveConversation}
            setOpen={setMarkSpamModalOpen}
          />
        )}
        {changeSubjectModalOpen && (
          <ChangeSubjectModal
            conversation={selectedConversations[0]!}
            resolve={() => setChangeSubjectModalOpen(false)}
          />
        )}
        <ProgressModal
          open={progressModalInfo.open}
          progress={progressModalInfo.progress}
          setOpen={(open: boolean) =>
            setProgressModalInfo({ ...progressModalInfo, open })
          }
          title={progressModalInfo.title}
        />
      </>
    );

    return (
      <div className={conversationsTableCss.actions}>
        {showActionsSection && (
          <div className={conversationsTableCss.actionButtons}>
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconTrailing={XIcon}
              onClick={() => tableSelection.clearSelection()}
              text={selectedText}
            />
            {isShowingClose && (
              <RedoButton
                hierarchy={RedoButtonHierarchy.PRIMARY}
                IconLeading={CheckIcon}
                onClick={handleBulkClose}
                text="Close"
              />
            )}
            {newActionsDropdown && (
              <>
                <RedoButton
                  hierarchy={RedoButtonHierarchy.SECONDARY}
                  IconLeading={ThreeDotsHorizontalIcon}
                  onClick={() => setActionsDropdownOpen(!actionsDropdownOpen)}
                  ref={setActionsDropdownAnchor}
                />
                {newActionsDropdown}
              </>
            )}
          </div>
        )}
        {modals}
      </div>
    );
  },
);

const ProgressModal = memo(function ProgressModal({
  open,
  setOpen,
  title,
  progress,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  progress: number;
}) {
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      size={ModalSize.SMALL}
      title={title}
    >
      <ProgressBar value={progress} />
    </Modal>
  );
});
