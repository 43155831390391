export interface FileMetadata {
  contentLength: number;
  contentType: string;
}

export const MAX_TOTAL_ATTACHMENT_SIZE_BYTES = 1000000;

export function canFileBeSentViaMMS(mimeType: string): boolean {
  return VALID_MMS_MIME_TYPES.includes(mimeType);
}
const VALID_MMS_MIME_TYPES = [
  "text/plain",
  "text/vcard",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/3gpp",
  "video/mp4",
];

export enum MMSRejectReason {
  SIZE_EXCEEDED = "size_exceeded",
  INVALID_TYPE = "invalid_type",
}
