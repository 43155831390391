import { ensureProtocolPresent, isValidLink } from "@redotech/util/link";
import Link from "quill/formats/link";

export class QuillLink extends Link {
  public static override create(value: string) {
    const link = isValidLink(value) ? ensureProtocolPresent(value) : value;
    const node = super.create(link);
    return node;
  }
}
