import { object, string, infer as zInfer } from "zod";

export const getRivoIntegrationSchema = {
  input: object({}),
  output: object({
    rivoIntegration: object({
      rewardsPageUrl: string(),
    }).optional(),
  }),
};

export type RivoIntegration = zInfer<typeof getRivoIntegrationSchema.output>;
