// These need to be imported first so we can override their styles without !important
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import { DateRangePicker } from "@wojtekmaj/react-daterange-picker";
import { memo } from "react";
import "./date-range.module.css";

export type MaybeDate = Date | null;
export type DateRangeValue = MaybeDate | [MaybeDate, MaybeDate];

/** Returns whether the start date is before the end date */
function isValidDateRange(value: DateRangeValue): boolean {
  if (value !== null && Array.isArray(value)) {
    const [startDate, endDate] = value;
    if (startDate !== null && endDate !== null) {
      return startDate <= endDate;
    }
  }
  return true;
}

export const DateRangeInput = memo(function DateRangeInput({
  value,
  valueChange,
  className,
  maxDate,
  disabled = false,
  onCalendarClose,
  onCalendarOpen,
}: {
  value: DateRangeValue;
  valueChange(value: DateRangeValue): void;
  maxDate?: Date;
  className?: string;
  disabled?: boolean;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
}) {
  return (
    <div className={className}>
      <DateRangePicker
        // Change the background color to red if the daterange is invalid
        calendarType="gregory"
        className={
          !isValidDateRange(value)
            ? "react-daterange-picker__invalid-range"
            : undefined
        }
        dayPlaceholder="DD"
        disabled={disabled}
        locale={navigator.language}
        maxDate={maxDate}
        monthPlaceholder="MM"
        onCalendarClose={onCalendarClose}
        onCalendarOpen={onCalendarOpen}
        onChange={valueChange}
        // There is a browser related bug in this library that doesn't work properly outside of Chrome browsers.
        openCalendarOnFocus={navigator.userAgent.includes("Chrome")}
        showFixedNumberOfWeeks
        value={!Array.isArray(value) || value?.find((v) => !!v) ? value : null}
        yearPlaceholder="YYYY"
      />
    </div>
  );
});
