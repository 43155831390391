/**
 * @param data
 * @param delimiter
 */
export async function* stringStreamSplit(
  data: AsyncIterable<string>,
  delimiter: string,
) {
  let buffer = "";
  for await (const chunk of data) {
    buffer += chunk;
    const parts = buffer.split(delimiter);
    yield* parts.slice(0, -1);
    buffer = parts[parts.length - 1];
  }
  if (buffer) {
    yield buffer;
  }
}
