import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import * as classNames from "classnames";
import { memo } from "react";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";

interface ConversationTagLike {
  name: string;
  pillTheme: PillTheme;
}

export const ConversationTagPillThemes = [
  PillTheme.NORMAL,
  PillTheme.PRIMARY_LIGHT,
  PillTheme.LIGHT_PINK,
  PillTheme.DANGER,
  PillTheme.NEUTRAL_YELLOW,
  PillTheme.SUCCESS,
  PillTheme.NEUTRAL_BLUE,
];

export const BadgePillThemeToColorMapping: Record<PillTheme, RedoBadgeColor> = {
  [PillTheme.NORMAL]: RedoBadgeColor.GRAY,
  [PillTheme.PRIMARY_LIGHT]: RedoBadgeColor.PURPLE,
  [PillTheme.LIGHT_PINK]: RedoBadgeColor.PINK,
  [PillTheme.DANGER]: RedoBadgeColor.ORANGE,
  [PillTheme.NEUTRAL_YELLOW]: RedoBadgeColor.WARNING,
  [PillTheme.SUCCESS]: RedoBadgeColor.SUCCESS,
  [PillTheme.NEUTRAL_BLUE]: RedoBadgeColor.BLUE_LIGHT,
  [PillTheme.PRIMARY]: RedoBadgeColor.PURPLE,
  [PillTheme.SOLID_PINK]: RedoBadgeColor.PINK,
  [PillTheme.SOLID_RED]: RedoBadgeColor.ERROR,
  [PillTheme.SOLID_ORANGE]: RedoBadgeColor.ORANGE,
  [PillTheme.SOLID_YELLOW]: RedoBadgeColor.WARNING,
  [PillTheme.SOLID_GREEN]: RedoBadgeColor.SUCCESS,
  [PillTheme.SOLID_LIGHT_BLUE]: RedoBadgeColor.BLUE,
  [PillTheme.SOLID_NAVY]: RedoBadgeColor.INDIGO,
  [PillTheme.SOLID_BLACK]: RedoBadgeColor.BLACK,
  [PillTheme.SOLID_WHITE]: RedoBadgeColor.WHITE,
};

export const ConversationTagPill = memo(function ConversationTagPill({
  tag,
  xClicked,
  showOverflow = false,
  badgeSize = RedoBadgeSize.SMALL,
}: {
  tag: ConversationTagLike;
  xClicked?(): void;
  showOverflow?: boolean;
  badgeSize?: RedoBadgeSize;
}) {
  return (
    <div
      className={classNames(
        conversationTagInputCss.pillWrapper,
        showOverflow && conversationTagInputCss.showOverflow,
      )}
    >
      <RedoBadge
        color={BadgePillThemeToColorMapping[tag.pillTheme]}
        iconTrailing={
          xClicked
            ? { type: "icon", onClick: xClicked, Icon: XIcon }
            : undefined
        }
        size={badgeSize}
        text={tag.name}
      />
    </div>
  );
});
