// extracted by mini-css-extract-plugin
export var colorSquare = "_94-0";
export var danger = "_94-5";
export var lightPink = "_94-8";
export var neutralBlue = "_94-7";
export var neutralYellow = "_94-6";
export var normal = "_94-1";
export var primary = "_94-2";
export var primaryLight = "_94-3";
export var solidBlack = "_94-g";
export var solidGreen = "_94-b";
export var solidLightBlue = "_94-d";
export var solidNavy = "_94-c";
export var solidOrange = "_94-f";
export var solidPink = "_94-e";
export var solidRed = "_94-9";
export var solidYellow = "_94-a";
export var success = "_94-4";