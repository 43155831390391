import Popper from "@mui/material/Popper";
import { useExpandHeight } from "@redotech/react-animation/transition";
import { useHandler } from "@redotech/react-util/hook";
import { useHover } from "@redotech/react-util/hover";
import * as classnames from "classnames";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useMatch, useMatches, useNavigate } from "react-router-dom";
import { Flex } from "./flex";
import ChevronDown from "./icon-old/chevron-down.svg";
import SettingsIcon from "./icon-old/settings.svg";
import * as sideNavCss from "./side-nav.module.css";
import { Text } from "./text";

export enum SideNavVisibility {
  INVISIBLE = 0,
  COLLAPSED = 1,
  EXPANDED = 2,
}

const SideNavVisibilityContext = createContext<SideNavVisibility>(
  SideNavVisibility.EXPANDED,
);

export const SideNav = memo(function SideNav({
  children,
  top,
  visibility,
  onVisibility,
}: {
  children?: ReactNode | ReactNode[];
  top: ReactNode;
  visibility: SideNavVisibility;
  onVisibility(value: SideNavVisibility): void;
}) {
  return (
    <SideNavVisibilityContext.Provider value={visibility}>
      <nav
        className={classnames(sideNavCss.nav, {
          [sideNavCss.collapsed]: visibility === SideNavVisibility.COLLAPSED,
        })}
      >
        <div
          className={classnames(sideNavCss.logoSection, {
            [sideNavCss.collapsed]: visibility === SideNavVisibility.COLLAPSED,
          })}
        >
          {top}
        </div>
        <div
          className={classnames(sideNavCss.section, {
            [sideNavCss.collapsed]: visibility === SideNavVisibility.COLLAPSED,
          })}
        >
          {children}
        </div>
      </nav>
    </SideNavVisibilityContext.Provider>
  );
});

export const SideNavButton = memo(function SideNavButton({
  icon,
  children,
  action,
  id,
}: {
  children?: ReactNode | ReactNode[];
  action: () => void;
  icon: ReactNode;
  id?: string;
}) {
  const visibility = useContext(SideNavVisibilityContext);
  const [element, setElement] = useState<HTMLElement | null>(null);
  const hover = useHover(element);

  return (
    <>
      <button
        className={sideNavCss.button}
        id={id}
        onClick={action}
        ref={setElement}
        type="button"
      >
        <div className={sideNavCss.icon}>{icon}</div>
        {visibility !== SideNavVisibility.COLLAPSED && (
          <div className={sideNavCss.label}>{children}</div>
        )}
      </button>
      <Popper
        anchorEl={element}
        open={hover && visibility === SideNavVisibility.COLLAPSED}
      >
        {children}
      </Popper>
    </>
  );
});

export const SettingsButton = memo(function SettingsButton({
  handleSetSettingsOpen,
}: {
  handleSetSettingsOpen: (value: boolean) => void;
}) {
  const buttonRef = useRef(null);
  return (
    <Flex className={sideNavCss.dropdown} w="full">
      <Flex basis="100%">
        <button
          className={sideNavCss.button}
          onClick={() => {
            handleSetSettingsOpen(true);
          }}
          ref={buttonRef}
        >
          <div className={sideNavCss.icon}>
            <SettingsIcon />
          </div>
          <div
            className={classnames(sideNavCss.label, sideNavCss.labelWithBubble)}
          >
            <Text fontSize="sm" ml="md">
              Settings
            </Text>
          </div>
        </button>
      </Flex>
    </Flex>
  );
});
export const SideNavDropdown = memo(function SideNavDropdown({
  icon,
  activeIcon,
  children,
  options,
  link,
  baseLink,
  id,
  bubble,
}: {
  children?: ReactNode | ReactNode[];
  link?: string;
  baseLink: string;
  activeIcon: ReactNode;
  options: ReactNode | ReactNode[];
  icon: ReactNode;
  id?: string;
  bubble?: ReactNode | undefined;
}) {
  const buttonRef = useRef(null);

  const match = useMatch({ caseSensitive: true, path: baseLink, end: false });
  const visibility = useContext(SideNavVisibilityContext);
  const [expanded, setExpanded] = useState(!!match);

  useEffect(() => {
    if (match) {
      setExpanded(true);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseLink]);

  const matches = useMatches();
  useEffect(() => {
    if (!match) {
      setExpanded(false);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const [options_, setOptions] = useState<HTMLElement | null>(null);
  const [animateStyles, animating] = useExpandHeight(expanded, options_);

  return (
    <>
      <Flex
        className={classnames(sideNavCss.dropdown, {
          [sideNavCss.expand]: expanded,
        })}
        dir="column"
        grow={0}
        id={id}
        style={{ rowGap: "0px" }}
      >
        <button
          className={classnames(sideNavCss.button, {
            [sideNavCss.noBackground]: true,
            [sideNavCss.active]: match,
          })}
          onClick={() => {
            setExpanded((value) => !value);
          }}
          ref={buttonRef}
        >
          <div className={sideNavCss.icon}>{match ? activeIcon : icon}</div>
          {visibility !== SideNavVisibility.COLLAPSED && (
            <>
              {link ? (
                <NavLink
                  className={classnames(
                    sideNavCss.label,
                    sideNavCss.labelWithBubble,
                    {
                      [sideNavCss.active]: match,
                    },
                  )}
                  to={link}
                >
                  <Text fontSize="xs" pl="md">
                    {children}
                  </Text>
                  {bubble && !expanded && <Flex pr="sm">{bubble}</Flex>}
                </NavLink>
              ) : (
                <Text fontSize="xs" pl="md">
                  {children}
                </Text>
              )}
              <ChevronDown className={sideNavCss.dropdownChevron} />
            </>
          )}
        </button>
        {visibility !== SideNavVisibility.COLLAPSED && (
          <div
            className={sideNavCss.optionsContainer}
            ref={setOptions}
            style={animateStyles}
          >
            {(expanded || animating) && (
              <div className={sideNavCss.options}>{options}</div>
            )}
          </div>
        )}
      </Flex>
      {buttonRef.current && (
        <SideNavMenu anchor={buttonRef.current} header={children}>
          {options}
        </SideNavMenu>
      )}
    </>
  );
});

export const SideNavLink = memo(function SideNavLink({
  icon,
  activeIcon,
  children,
  link,
  newTab = false,
  id,
  bubble,
}: {
  children?: ReactNode | ReactNode[];
  link: string;
  activeIcon?: ReactNode;
  icon: ReactNode;
  newTab?: boolean;
  id?: string;
  bubble?: ReactNode | undefined;
}) {
  const match = useMatch(link);
  const visibility = useContext(SideNavVisibilityContext);

  const [element, setElement] = useState<HTMLElement | null>(null);
  return (
    <Flex dir="row" grow={1}>
      <NavLink
        className={classnames(sideNavCss.button, {
          [sideNavCss.active]: match,
        })}
        id={id}
        ref={setElement}
        target={newTab ? "_blank" : "_self"}
        to={link}
      >
        <Flex align="center" dir="row" gap="md" grow={1}>
          <div className={sideNavCss.icon}>
            {match && activeIcon ? activeIcon : icon}
          </div>
          <Text className={sideNavCss.label} fontSize="xs" fontWeight="regular">
            {visibility !== SideNavVisibility.COLLAPSED && children}
          </Text>
          {bubble}
        </Flex>
      </NavLink>
      {element && <SideNavMenu anchor={element} header={children} />}
    </Flex>
  );
});

export const SideNavMenu = memo(function SideNavMenu({
  header,
  anchor,
  children,
}: {
  anchor: HTMLElement;
  header: ReactNode;
  children?: ReactNode | ReactNode[];
}) {
  const visibility = useContext(SideNavVisibilityContext);
  const hover = useHover(anchor);
  const [popper, setPopper] = useState<HTMLElement | null>(null);
  const popperHover = useHover(popper);
  return (
    <Popper
      anchorEl={anchor}
      open={
        (hover || popperHover) && visibility === SideNavVisibility.COLLAPSED
      }
      placement="right-start"
      ref={setPopper}
      style={{ zIndex: 50 }}
    >
      <div className={sideNavCss.menu}>
        <div className={sideNavCss.menuHeader}>
          <Text fontSize="xs" fontWeight="semibold" pl="md">
            {header}
          </Text>
        </div>
        {children}
      </div>
    </Popper>
  );
});

export const SideNavLogo = memo(function SideNavLogo({
  children,
  collapsed,
}: {
  collapsed: ReactNode;
  children: ReactNode;
}) {
  const visibility = useContext(SideNavVisibilityContext);

  return (
    <div className={sideNavCss.logo}>
      {visibility === SideNavVisibility.COLLAPSED ? collapsed : children}
    </div>
  );
});

/**
 * Page with side navigation
 */
export const SideNavPage = memo(function SideNavPage({
  top,
  nav,
  children,
  hidden = false,
}: {
  top: ReactNode | ReactNode[];
  nav: ReactNode | ReactNode[];
  children: ReactNode;
  hidden?: boolean;
}) {
  // null represented using default
  let [visibility, setVisibility] = useState<SideNavVisibility | null>(null);

  // In case we bring back collapsed state
  // const { width } = useWindowSize();
  const defaultVisibility = SideNavVisibility.EXPANDED;
  // width && width < 900
  //   ? SideNavVisibility.COLLAPSED
  //   : SideNavVisibility.EXPANDED;

  if (visibility === null) {
    visibility = defaultVisibility;
  }

  const handleVisibility = useHandler((value: SideNavVisibility) => {
    setVisibility(value === defaultVisibility ? null : value);
  });

  return (
    <div
      className={classnames(sideNavCss.page, {
        [sideNavCss.collapsed]: visibility !== SideNavVisibility.EXPANDED,
        [sideNavCss.invisible]: visibility === SideNavVisibility.INVISIBLE,
      })}
    >
      {!hidden && (
        <SideNav
          onVisibility={handleVisibility}
          top={top}
          visibility={visibility}
        >
          {nav}
        </SideNav>
      )}
      <main
        className={classnames(sideNavCss.main, {
          [sideNavCss.fullscreen]: hidden,
        })}
      >
        {children}
      </main>
    </div>
  );
});

export const SideNavSubitem = memo(function SideNavSubitem({
  children,
  link,
  id,
  bubble,
  suffix,
}: {
  children: ReactNode | ReactNode[];
  link: string;
  id?: string;
  bubble?: ReactNode | undefined;
  suffix?: ReactNode | undefined;
}) {
  // Match and show active for subpaths of the original path too
  const match = useMatch({ path: `${link}/*`, caseSensitive: true });

  const visibility = useContext(SideNavVisibilityContext);
  const navigate = useNavigate();

  if (visibility === SideNavVisibility.COLLAPSED) {
    return (
      <NavLink
        className={classnames(sideNavCss.menuItem, {
          [sideNavCss.active]: match,
        })}
        id={id}
        to={link}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <Flex
      align="flex-end"
      className={classnames(sideNavCss.button, {
        [sideNavCss.active]: match,
        [sideNavCss.settings]: link.includes("settings"),
      })}
      justify="flex-end"
      ml="2xl"
      onClick={() => {
        navigate(link);
      }}
    >
      <Flex grow={1} shrink={1} style={{ minWidth: 0 }}>
        <NavLink className={sideNavCss.subitemLabel} id={id} to={link}>
          <Text
            as="div"
            fontSize="xs"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {children}
          </Text>
        </NavLink>
      </Flex>
      <Flex align="center" gap="xs" grow={0}>
        {bubble && <div className={sideNavCss.actionButtons}>{bubble}</div>}
        {suffix && <div className={sideNavCss.actionButtons}>{suffix}</div>}
      </Flex>
    </Flex>
  );
});
