import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { WidgetClient, getShippingFee } from "@redotech/redo-api-client/widget";
import { Order } from "@redotech/redo-model/order";
import type { Return, Shipment } from "@redotech/redo-model/return";
import { Payer } from "@redotech/redo-model/return-flow";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import { ReturnTotalsCalculator } from "@redotech/redo-model/return-totals-calculator";
import type { Address } from "@redotech/redo-model/team";
import { Team } from "@redotech/redo-model/team";
import { Button, ButtonTheme, IconButton } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { Card, CardSize } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { RedoClientContext } from "@redotech/redo-web/client";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import ChevronDownIcon from "@redotech/redo-web/icon-old/chevron-down.svg";
import ChevronLeftIcon from "@redotech/redo-web/icon-old/chevron-left.svg";
import ChevronRightIcon from "@redotech/redo-web/icon-old/chevron-right.svg";
import DownloadLabelIcon from "@redotech/redo-web/icon-old/download.svg";
import InvoiceIcon from "@redotech/redo-web/icon-old/invoice.svg";
import LabelIcon from "@redotech/redo-web/icon-old/label.svg";
import MailIcon from "@redotech/redo-web/icon-old/mail.svg";
import QrCodeIcon from "@redotech/redo-web/icon-old/qr-code.svg";
import { ExternalLink } from "@redotech/redo-web/link";
import { Event, Timeline } from "@redotech/redo-web/timeline";
import { memo, useContext, useMemo, useState } from "react";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { getOrder } from "../../client/order";
import { CancelPickupModal } from "../return-modals/cancel-pickup-modal";
import { ResendModal } from "../return-modals/resend-modal";
import { SchedulePickupModal } from "../return-modals/schedule-pickup-modal";
import { shipmentStatusName } from "../util";
import * as shippingCss from "./shipping-card.module.css";

enum PickupStates {
  CANCELLED = "cancelled",
  PAID_FOR = "paid_for",
  NOT_PAID_FOR_CAN_DEDUCT = "not_paid_for_can_deduct",
  NOT_PAID_FOR_CANNOT_DEDUCT = "not_paid_for_cannot_deduct",
  INELIGIBLE = "ineligible",
}

export const ShippingCard = memo(function ShippingCard({
  reload,
  return: return_,
  order,
  isRepairCard,
}: {
  reload(): void;
  return?: Return;
  order?: Order;
  isRepairCard?: boolean;
}) {
  const [resendOpen, setResendOpen] = useState(false);
  const [schedulePickupModalOpen, setSchedulePickupModalOpen] = useState(false);
  const team = useContext(TeamContext);

  const handleResend = useHandler(() => {
    setResendOpen(true);
  });
  const handleSchedulePickup = useHandler(() => {
    setSchedulePickupModalOpen(true);
  });
  const client = useRequiredContext(RedoClientContext);
  const merchantClient = useRequiredContext(RedoMerchantClientContext);
  const widgetClient = return_
    ? new WidgetClient(client, return_.team.widget_slug)
    : undefined;

  const pickupInfo = useLoad(async () => {
    if (!team?.widget_slug || !return_) {
      return { pickupState: PickupStates.INELIGIBLE }; // we can't get the rate, so say ineligible
    }
    if (return_?.pickup) {
      if (return_.pickup.status === "pending_refund") {
        return { pickupState: PickupStates.CANCELLED };
      }
      return {
        pickupState: PickupStates.PAID_FOR,
        pickupFee: return_?.pickupMetrics?.rate,
      };
    }
    const { pickupFee } = widgetClient
      ? await getShippingFee(widgetClient, {
          products: return_.products.map((product) => ({
            id: product.id,
            quantity: product.quantity,
            line_item_id: +product.line_item_id,
          })),
          address: return_.shipping_address
            ? {
                city: return_.shipping_address.city,
                country: return_.shipping_address.country,
                street1: return_.shipping_address.address1,
                street2: return_.shipping_address.address2,
                zip: return_.shipping_address.zip,
                state: return_.shipping_address.province,
              }
            : null,
          payer: Payer.CUSTOMER,
          merchantAddress: return_.merchant_address || null,
        })
      : undefined;
    if (!pickupFee) {
      return { pickupState: PickupStates.INELIGIBLE, pickupFee };
    }
    const order = await getOrder(merchantClient, return_.orders[0].order);
    const returnTotalsCalculator = new ReturnTotalsCalculator({
      return_: {
        ...return_,
        totals: {
          ...return_.totals,
          fee: return_.totals.fee + pickupFee,
        },
      },
      team,
      order,
    });
    const canDeduct =
      returnTotalsCalculator.getTotalsForAllProducts().shippingFee === 0;
    if (canDeduct) {
      return { pickupState: PickupStates.NOT_PAID_FOR_CAN_DEDUCT, pickupFee };
    }
    return {
      pickupState: PickupStates.NOT_PAID_FOR_CANNOT_DEDUCT,
      pickupFee,
    };
  }, [return_]);

  const handleResendLabelModalClose = useHandler(() => setResendOpen(false));
  const handleSchedulePickupModalClose = useHandler(() => {
    setSchedulePickupModalOpen(false);
    reload();
  });

  const [cancelPickupModalOpen, setCancelPickupModalOpen] = useState(false);
  const handleCancelPickupModalClose = useHandler(() =>
    setCancelPickupModalOpen(false),
  );
  const handleCancelPickup = useHandler(async () => {
    setCancelPickupModalOpen(true);
    reload();
  });

  const [shipmentIndex, setShipmentIndex] = useState(0);
  const shipments = useMemo(() => {
    if (!return_) {
      return [];
    }
    if (isRepairCard) {
      if (return_.status === ReturnStatus.OPEN) {
        return [];
      } else if (return_.status === ReturnStatus.COMPLETE) {
        return return_.reShipments ?? [];
      }
    }
    let shipments: (Shipment | undefined)[] = [];
    if (return_.shipmentGroups?.length) {
      shipments = return_.shipmentGroups.map((group) =>
        return_.shipments?.find((s) => s.shipmentGroupID === group.id),
      );
    } else if (return_.shipment) {
      shipments = [
        {
          ...return_.shipment,
          form_label: return_.form_label,
          postage_label: return_.postage_label,
        },
      ];
    }

    return shipments;
  }, [return_]);

  if (!return_) {
    return null;
  }

  return (
    <>
      {widgetClient ? (
        <Card
          headerExtra={
            shipments.length > 1 ? (
              <Flex align="center" gap="xxs">
                <IconButton
                  disabled={shipmentIndex === 0}
                  onClick={() => setShipmentIndex(shipmentIndex - 1)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <span className={shippingCss.shipmentCounter}>
                  {shipmentIndex + 1} of {shipments.length}
                </span>
                <IconButton
                  disabled={shipmentIndex === shipments.length - 1}
                  onClick={() => setShipmentIndex(shipmentIndex + 1)}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Flex>
            ) : null
          }
          size={CardSize.MEDIUM}
          title={isRepairCard ? "Re-shipping info" : "Return shipping info"}
        >
          {shipments.length === 0 && isRepairCard ? (
            <div className={cardCss.body}>
              This return needs to be processed before a reverse label can be
              generated.
            </div>
          ) : (
            <ShipmentDetails
              handleCancelPickup={handleCancelPickup}
              handleResend={handleResend}
              handleSchedulePickup={handleSchedulePickup}
              happyReturnsData={return_.happyReturnsData}
              isRepairCard={isRepairCard}
              pickupState={
                pickupInfo.pending || pickupInfo.error || !pickupInfo.value
                  ? PickupStates.INELIGIBLE
                  : pickupInfo.value.pickupState
              }
              shipment={shipments[shipmentIndex]}
              zipCode={return_.shipping_address?.zip}
            />
          )}
        </Card>
      ) : undefined}
      {resendOpen && (
        <ResendModal
          onClose={handleResendLabelModalClose}
          open={resendOpen}
          reload={reload}
          return={return_}
        />
      )}
      {schedulePickupModalOpen &&
      !!team &&
      !!order &&
      !!pickupInfo.value &&
      !isRepairCard ? (
        <SchedulePickupModal
          canDeduct={canDeduct(
            team,
            return_,
            order,
            pickupInfo.value.pickupFee,
          )}
          isReschedule={pickupInfo.value?.pickupState === PickupStates.PAID_FOR}
          onClose={handleSchedulePickupModalClose}
          open={schedulePickupModalOpen}
          pickupFee={pickupInfo.value?.pickupFee}
          return={return_}
        />
      ) : undefined}
      {cancelPickupModalOpen && !!widgetClient && !isRepairCard ? (
        <CancelPickupModal
          onClose={handleCancelPickupModalClose}
          open={cancelPickupModalOpen}
          reload={reload}
          returnId={return_._id}
          widgetClient={widgetClient}
        />
      ) : undefined}
    </>
  );
});

const canDeduct = (
  team: Team,
  return_: Return,
  order: Order,
  pickupFee: number,
) => {
  const returnTotalsCalculator = new ReturnTotalsCalculator({
    return_,
    order,
    team,
  });
  const { totalStoreCredit, totalRefund } =
    returnTotalsCalculator.getTotalsForAllProducts();
  if (totalStoreCredit > pickupFee && team.settings.deductLabelFromCredit) {
    return true;
  }
  if (totalRefund > pickupFee && team.settings.deductLabelFromRefund) {
    return true;
  }
  return false;
};

const ShipmentDetails = memo(function ShipmentDetails({
  handleResend,
  handleSchedulePickup,
  handleCancelPickup,
  shipment,
  pickupState,
  isRepairCard,
  happyReturnsData,
  zipCode,
}: {
  handleResend: () => void;
  handleSchedulePickup: () => void;
  shipment?: Shipment;
  happyReturnsData?: Return["happyReturnsData"];
  handleCancelPickup: () => void;
  pickupState: PickupStates;
  isRepairCard?: boolean;
  zipCode?: string | null;
}) {
  const handleDownload = useHandler(
    (document: "label" | "raw_label" | "qr_code" | "commercial_invoice") => {
      if (
        document === "raw_label" &&
        shipment?._shipment?.postage_label.label_url
      ) {
        open(shipment._shipment.postage_label.label_url, "_blank");
      } else if (
        document === "commercial_invoice" &&
        shipment?._shipment?.forms?.find(
          (form: any) => form.form_type === "commercial_invoice",
        )?.form_url
      ) {
        open(
          shipment?._shipment?.forms?.find(
            (form: any) => form.form_type === "commercial_invoice",
          )?.form_url,
          "_blank",
        );
      } else {
        open(
          document === "qr_code"
            ? shipment!.form_label
            : shipment!.postage_label,
          "_blank",
        );
      }
    },
  );

  const events: Event[] = useMemo(
    () =>
      (shipment?._shipment.tracker?.tracking_details || []).map((detail) => ({
        at: new Date(detail.datetime).toTemporalInstant(),
        detail: detail.tracking_location.city,
        message: detail.message,
      })),
    [shipment],
  );

  const downloadOptionsDropdown = (
    <>
      {shipment?.postage_label && (
        <DropdownOption action={() => handleDownload("label")}>
          <div className={shippingCss.dropdownContainer}>
            <LabelIcon className={shippingCss.icon} />
            Label
          </div>
        </DropdownOption>
      )}
      {shipment?.form_label && pickupState !== PickupStates.PAID_FOR && (
        <DropdownOption action={() => handleDownload("qr_code")}>
          <div className={shippingCss.dropdownContainer}>
            <QrCodeIcon className={shippingCss.icon} />
            QR Code
          </div>
        </DropdownOption>
      )}
      {shipment?._shipment?.forms?.find(
        (form) => form.form_type === "commercial_invoice",
      )?.form_url && (
        <DropdownOption action={() => handleDownload("commercial_invoice")}>
          <div className={shippingCss.dropdownContainer}>
            <InvoiceIcon className={shippingCss.icon} />
            Invoice
          </div>
        </DropdownOption>
      )}
    </>
  );

  return (
    <>
      {happyReturnsData ? (
        <section className={shippingCss.section}>
          <h3 className={cardCss.title}>Happy Returns</h3>
          {happyReturnsData.qrCode ? (
            <div className={cardCss.body}>
              <ExternalLink url={happyReturnsData.qrCode}>QR Code</ExternalLink>
            </div>
          ) : undefined}
          {happyReturnsData.retailerId && zipCode ? (
            <div className={cardCss.body}>
              <ExternalLink
                url={`https://locations.happyreturns.com/?address=${zipCode}&has_qr_code=true&retailer=${happyReturnsData.retailerId}`}
              >
                Return Bar Locations
              </ExternalLink>
            </div>
          ) : undefined}
        </section>
      ) : (
        <>
          <section className={shippingCss.section}>
            <h3 className={cardCss.title}>Status</h3>
            <div className={cardCss.body}>
              {shipment &&
                shipmentStatusName(shipment._shipment.tracker?.status)}
            </div>
          </section>
          <section className={shippingCss.section}>
            <h3 className={cardCss.title}>Timeline</h3>
            <Timeline events={events} />
          </section>
          <section className={shippingCss.section}>
            <h3 className={cardCss.title}>Tracking number</h3>
            <div className={cardCss.body}>
              {shipment?._shipment && (
                <ExternalLink url={shipment._shipment.tracker?.public_url}>
                  {shipment._shipment.tracker?.carrier}{" "}
                  {shipment._shipment.tracking_code}
                </ExternalLink>
              )}
            </div>
          </section>
          <section className={shippingCss.section}>
            <h3 className={cardCss.title}>Shipping To</h3>
            <div className={cardCss.body}>
              {shipment?.isReturn
                ? shipment?.fromAddress && <Address {...shipment.fromAddress} />
                : shipment?.toAddress && <Address {...shipment.toAddress} />}
            </div>
          </section>
        </>
      )}
      <section className={shippingCss.section}>
        <h3 className={cardCss.title}>Label</h3>
        <Flex dir="row" justify="space-around">
          {(shipment?.form_label || shipment?.postage_label) && (
            <ButtonDropdown
              dropdown={downloadOptionsDropdown}
              theme={ButtonTheme.OUTLINED}
            >
              <div className={shippingCss.buttonContent}>
                <DownloadLabelIcon className={shippingCss.icon} />
                Download
                <ChevronDownIcon className={shippingCss.icon} />
              </div>
            </ButtonDropdown>
          )}
          {!isRepairCard && (
            <Button
              className={shippingCss.labelButton}
              icon={({ className }) => <MailIcon className={className} />}
              onClick={handleResend}
              theme={ButtonTheme.OUTLINED}
            >
              Resend
            </Button>
          )}
        </Flex>
      </section>
      {![PickupStates.INELIGIBLE, PickupStates.CANCELLED].includes(
        pickupState,
      ) && !isRepairCard ? (
        <section className={shippingCss.section}>
          <h3 className={cardCss.title}>Package Pickup</h3>
          <Button
            className={shippingCss.pickupButton}
            icon={({ className }) => <MailIcon className={className} />}
            onClick={handleSchedulePickup}
            theme={ButtonTheme.OUTLINED}
          >
            {pickupState === PickupStates.PAID_FOR ? "Reschedule" : "Schedule"}{" "}
            Pickup
          </Button>
        </section>
      ) : undefined}
      {pickupState === PickupStates.PAID_FOR ? (
        <Button
          className={shippingCss.pickupButton}
          icon={({ className }) => <MailIcon className={className} />}
          onClick={handleCancelPickup}
          theme={ButtonTheme.GHOST}
        >
          Cancel pickup
        </Button>
      ) : undefined}
    </>
  );
});

const Address = memo(function Address(
  //TODO: Investigate makeing a shared component for addresses
  address: Address,
) {
  return (
    <div>
      {address.name}
      <br />
      {address.street1}
      <br />
      {address.street2 && (
        <>
          {address.street2}
          <br />
        </>
      )}
      {address.city}, {address.state} {address.zip}
      <br />
      {address.country}
    </div>
  );
});
