import { Team } from "@redotech/redo-model/team";
import Quill from "quill";

export const getPdpUrl = (team: Team, productHandle: string) => {
  return `https://${team?.storeUrl}/products/${productHandle}`;
};

/* Edit history of Quill editor by find-replace on attributes. Used to clear the formatting of the macro variables. */
const findReplaceQuillInsertAttribute = (
  quill: Quill,
  attrName: string,
  attrValueToMatch: string | any,
  attrValueToSub: string | any,
) => {
  const content = quill.getContents();
  const ops = content.ops;

  for (let i = 0; i < ops.length; i++) {
    const op = ops[i];
    if (typeof op.insert === "string") {
      const attributes = op.attributes;

      if (attributes && attributes[attrName] === attrValueToMatch) {
        attributes[attrName] = attrValueToSub;
      }
    }
  }

  quill.setContents(quill.getContents());
};

export const clearFormattingFromMacroAutomationsText = (quill: Quill) => {
  findReplaceQuillInsertAttribute(quill, "color", "#5f45e2", "black");
  findReplaceQuillInsertAttribute(quill, "background", "#ebe9fd", "inherit");
};

export const clearColoringAtCursor = (
  quill: Quill,
  newCursorPosition: number,
) => {
  quill.setSelection(newCursorPosition, 0);
  quill.format("color", false);
  quill.format("background", false);
};

export const formatWithInserts = ({ quill }: { quill: Quill }) => {
  const text = quill.getText().toLowerCase();

  /**
   * https://quilljs.com/docs/formats
   *
   * Background Color - background
   * Bold - bold
   * Color - color
   * Font - font
   * Inline Code - code
   * Italic - italic
   * Link - link
   * Size - size
   * Strikethrough - strike
   * Superscript/Subscript - script
   * Underline - underline
   *
   */
  type QuillFormatArgs = {
    color?: string;
    background?: string;
    link?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strike?: boolean;
    code?: boolean;
    font?: string;
    size?: string;
    script?: string;
  };

  const defaultFormat: Omit<
    Required<QuillFormatArgs>,
    "script" | "font" | "size"
  > &
    QuillFormatArgs = {
    color: "black",
    background: "transparent",
    link: "",
    underline: false,
    bold: false,
    italic: false,
    strike: false,
    code: false,
  };

  // Formats the message for discount codes and products
  const handleFormat = ({
    searchCharacter,
    wordsToFormat,
    formatFn,
    ...quillFormatArgs
  }: QuillFormatArgs & {
    searchCharacter?: string;
    wordsToFormat: string[];
    formatFn?: (
      atIndex: number,
      searchString: string,
      quill: Quill,
      quillFormatArgs: QuillFormatArgs,
    ) => void;
  }) => {
    for (const word of wordsToFormat) {
      const searchString = `${searchCharacter ?? ""}${word.toLowerCase()}`;
      let atIndex = text.indexOf(searchString);
      while (atIndex !== -1) {
        // Clear all formatting for the search string
        quill.removeFormat(atIndex, searchString.length);

        // Run the given formatter, or use a default formatter of just formatting the entire length of the matched word
        const formatFnToUse =
          formatFn ??
          ((atIndex, searchString, quill, quillFormatArgs) =>
            quill.formatText(atIndex, searchString.length, quillFormatArgs));
        formatFnToUse(atIndex, searchString, quill, quillFormatArgs);

        // Get the format of the last character before the search string
        // If the search string is at the beginning of the message, use the default format
        const lastFormat =
          atIndex > 0 ? quill.getFormat(atIndex - 1) : defaultFormat;

        const resumedPreviousStylesAfterFormattedRegion = {
          ...lastFormat,
          /**
           * Defer to previous format, or default format, for each format property applied.
           */
          ...Object.fromEntries(
            Object.entries(quillFormatArgs)
              .filter(([_, value]) => value !== undefined)
              .map(([key]) => [
                key,
                (lastFormat as any)[key] ?? (defaultFormat as any)[key],
              ]),
          ),
        };

        const endIndex = atIndex + searchString.length;
        quill.formatText(
          endIndex,
          1,
          resumedPreviousStylesAfterFormattedRegion,
        );

        atIndex = text.indexOf(searchString, atIndex + 1);
      }
    }
  };

  // URLs
  text
    .match(
      /**
       * note the trailing space - this prevents mangling url when continuing typing.
       * Regex here: https://stackoverflow.com/a/3809435
       */
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*) /g,
    )
    ?.map((url) => url)
    .filter(Boolean)
    .forEach((url) =>
      handleFormat({
        link: url,
        wordsToFormat: [url],
        formatFn: (atIndex, searchString, quill, quillFormatArgs) => {
          /** Don't link the trailing space */
          quill.formatText(
            atIndex,
            searchString.length - 1,
            quillFormatArgs,
            Quill.sources.SILENT,
          );
        },
      }),
    );
};
