import { SchemaType } from "./advanced-flow/schemas/schemas";
import { Category } from "./advanced-flow/triggers";

export enum TemplateType {
  TRANSACTIONAL = "transactional",
  MARKETING = "marketing",
}

export function templateTypeLabel(templateType: TemplateType): string {
  switch (templateType) {
    case TemplateType.TRANSACTIONAL:
      return "Transactional";
    case TemplateType.MARKETING:
      return "Marketing";
  }
}

export interface SMSTemplate {
  templateType: TemplateType;
  team: any;
  name: string;
  content: string;
  schemaType: SchemaType;
  category: Category;
}

export interface SMSTemplateRow extends SMSTemplate {
  _id: any;
}
