import { Order } from "@redotech/redo-model/order";
import {
  MerchantAppReturn,
  NULL_ORDER_ID,
  Return,
} from "@redotech/redo-model/return";
import { Card, CardSize } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { ExternalLink, Link } from "@redotech/redo-web/link";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import * as sortBy from "lodash/sortBy";
import { memo, useContext } from "react";
import { TeamContext } from "../../app/team";
import { shopifyAdminOrderUrl, shopifyStoreName } from "../../shopify";
import { shopifyAddressString } from "../return";
import { returnStatusName } from "../util";
import * as customerHistoryCss from "./customer-history-card.module.css";

export const CustomerHistoryCard = memo(function CustomerHistoryCard({
  return: return_,
}: {
  return?: MerchantAppReturn;
}) {
  return (
    <Card size={CardSize.MEDIUM} title="Customer history">
      {return_?.customerOrders ? (
        <>
          {sortBy(
            return_.customerReturns!.filter(
              (return_) => return_.type === "claim",
            ),
            (return_) => return_.createdAt,
          ).map((return_, index) => (
            <CustomerHistoryReturn key={`claim-${index}`} return={return_} />
          ))}
          {sortBy(
            return_.customerReturns!.filter(
              (return_) => return_.type === "return",
            ),
            (return_) => return_.createdAt,
          ).map((return_, index) => (
            <CustomerHistoryReturn key={`return-${index}`} return={return_} />
          ))}
          {sortBy(
            return_.customerOrders,
            (order) => order.shopifyCreatedAt,
          ).map((order, index) => {
            if (order.shopify.name === NULL_ORDER_ID) {
              return;
            }
            return <CustomerHistoryOrder key={index} order={order} />;
          })}
        </>
      ) : (
        <SkeletonText length={30} />
      )}
    </Card>
  );
});

const CustomerHistoryOrder = memo(function CustomerHistoryOrder({
  order,
}: {
  order: Order;
}) {
  const team = useContext(TeamContext);
  if (!team) {
    return null;
  }
  return (
    <>
      <div className={cardCss.title}>
        {team.platform === "shopify" ? (
          <ExternalLink
            url={shopifyAdminOrderUrl(
              shopifyStoreName(team!.storeUrl),
              order.shopify_id,
            ).toString()}
          >
            Order {order.shopify.name}
          </ExternalLink>
        ) : (
          <span>Order {order.shopify.name}</span>
        )}
      </div>
      <div className={cardCss.body}>
        {CURRENCY_FORMAT(order.shopify.currency).format(
          +order.shopify.total_price,
        )}
        {order.shopify.shipping_address &&
          ` | ${shopifyAddressString(order.shopify.shipping_address)}`}
        <br />
        {getDateTimeString(new Date(order.shopifyCreatedAt))}
      </div>
    </>
  );
});

const CustomerHistoryReturn = memo(function CustomerHistoryReturn({
  return: return_,
}: {
  return: Return;
}) {
  const team = useContext(TeamContext);
  if (!team) {
    return null;
  }
  return (
    <>
      <div className={customerHistoryCss.historyReturnHeader}>
        <div className={cardCss.title}>
          <Link to={`/stores/${team._id}/returns/${return_._id}`}>
            {
              {
                claim: "Claim",
                return: "Return",
                warranty: "Warranty",
                managed_claim: "Claim",
              }[return_.type]
            }{" "}
            {return_.orders[0].name}
          </Link>
        </div>
        <Pill size={PillSize.SMALL}>{returnStatusName(return_.status)}</Pill>
      </div>

      <div className={cardCss.body}>
        {getDateTimeString(new Date(return_.createdAt))}
      </div>
    </>
  );
});
