import {
  CUSTOMER_EVENT_DISCRIMINATOR_KEY,
  CustomerEvent,
  CustomerEventType,
  MarketingSubscribeEvent,
} from "@redotech/redo-model/customer-event/customer-event-definition";
import { memo } from "react";
import { BaseCustomerActivityCard } from "./base-customer-activity-card";

export const MarketingCustomerEventCard = memo(
  function MarketingCustomerEventCard({ event }: { event: CustomerEvent }) {
    const eventType = event[CUSTOMER_EVENT_DISCRIMINATOR_KEY];
    const title =
      eventType === CustomerEventType.MARKETING_SUBSCRIBED
        ? `Subscribed to ${getSubscriptionType(event)}marketing`
        : eventType === CustomerEventType.MARKETING_UNSUBSCRIBED
          ? "Unsubscribed from marketing"
          : eventType === CustomerEventType.MARKETING_CONFIRMED
            ? "Confirmed marketing subscription"
            : "";

    if (!title) {
      return null;
    }

    return (
      <BaseCustomerActivityCard
        onClick={undefined}
        timestamp={event.timestamp}
        title={title}
        topLeftElement={undefined} // TODO: get designs for this
      />
    );
  },
);

function getSubscriptionType(event: MarketingSubscribeEvent): string {
  if (event.email && event.phoneNumber) {
    return "email and SMS ";
  }

  if (event.email) {
    return "email ";
  }

  if (event.phoneNumber) {
    return "SMS ";
  }

  return "";
}
