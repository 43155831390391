import { CustomReportSchema } from "@redotech/redo-model/custom-report";
import { z } from "zod";

export const getCustomReport = {
  input: z.object({
    _id: z.string(),
  }),
  output: CustomReportSchema.nullable(),
};

export const getCustomReports = {
  input: z.object({}),
  output: z.array(CustomReportSchema),
};

export const createCustomReport = {
  input: CustomReportSchema.omit({
    _id: true,
    team: true, // The team is set to the current team
    createdAt: true,
    updatedAt: true,
  }),
  output: CustomReportSchema,
};

export const updateCustomReport = {
  input: CustomReportSchema.omit({
    team: true, // The team is set to the current team
    createdAt: true,
    updatedAt: true,
  }),
  output: CustomReportSchema,
};

export const deleteCustomReport = {
  input: z.object({
    _id: z.string(),
  }),
  output: z.object({}),
};
