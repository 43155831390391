import { z, ZodObject } from "zod";
import { zExt } from "../../../common/zod-util";

const generateFormBuilderFormDbValidator = <T extends ZodObject<any>>(
  formDataValidator: T,
) =>
  z.object({
    teamId: zExt.objectId(),
    versionedFormData: z.array(formDataValidator),
  });

export const generateFormBuilderFormDbWriteValidator = <
  T extends ZodObject<any>,
>(
  formDataDbWriteValidator: T,
) => generateFormBuilderFormDbValidator(formDataDbWriteValidator);

export const generateFormBuilderFormDbParser = <T extends ZodObject<any>>(
  formDataDbParser: T,
) =>
  generateFormBuilderFormDbValidator(formDataDbParser).extend({
    _id: zExt.objectId(),
    createdAt: z.date(),
    updatedAt: z.date(),
  });
