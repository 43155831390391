import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { filtersToQueryString } from "@redotech/redo-model/conversation-filters/conversation-filter-query";
import {
  ConversationFiltersV3,
  CONVERSATIONS_FILTERS_VERSION,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  Attachment,
  CreateConversationBody,
} from "@redotech/redo-model/create-conversation-body";
import {
  EmailDraftRecipientInfo,
  EmailReplyType,
} from "@redotech/redo-model/support/conversations/email-info";
import {
  SortableConversationTableColumn,
  TableSort,
} from "@redotech/redo-model/table";
import { UpdateConversationBody } from "@redotech/redo-model/updateconversationbody";
import { AxiosHeaders } from "axios";
import { RedoMerchantClient } from ".";

/**
 * GET /conversations
 */
export async function getConversations(
  client: RedoMerchantClient,
  {
    pageContinue,
    pageStop,
    pageSize,
    filters,
    sort,
    signal,
  }: {
    pageContinue: string | undefined;
    pageStop?: string;
    pageSize: number;
    filters?: ConversationFiltersV3 | undefined;
    sort?: TableSort<SortableConversationTableColumn>;
    signal?: AbortSignal;
  },
): Promise<{ data: ExpandedConversation[]; pageNext: string | undefined }> {
  const headers = {
    ...client.authorization(),
    "x-api-version": CONVERSATIONS_FILTERS_VERSION,
    "X-Page-Continue": pageContinue,
    "X-Page-Size": pageSize,
    "X-Page-Stop": pageStop,
  };
  const v3Filters: ConversationFiltersV3 = Object.assign(
    { advancedFilters: [] },
    filters,
    sort,
  );

  const query = filtersToQueryString(v3Filters);

  const url = query
    ? `team/conversations?filters=${query}`
    : "team/conversations";
  const response = await client.client.get(url, {
    headers,
    signal,
  });
  return {
    data: response.data,
    pageNext:
      <string>(<AxiosHeaders>response.headers).get("X-Page-Next") || undefined,
  };
}

/**
 * GET /conversations/:id
 */
export async function getConversation(
  client: RedoMerchantClient,
  {
    conversationId,
    signal,
  }: {
    conversationId: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.get(`conversations/${conversationId}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /conversations/merge
 */
export async function mergeConversations(
  client: RedoMerchantClient,
  {
    selectedConversationIds,
    customer,
    platform,
    subject,
    assignee,
    signal,
  }: {
    selectedConversationIds: string[];
    customer: string;
    platform: Exclude<
      ConversationPlatform,
      | ConversationPlatform.INSTAGRAM_COMMENTS
      | ConversationPlatform.FACEBOOK_COMMENTS
    >;
    subject: string;
    assignee: string | null;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    "conversations/merge",
    {
      selectedConversationIds,
      customer,
      platform,
      subject,
      assignee,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/:id/archive
 */
export async function archiveConversation(
  client: RedoMerchantClient,
  {
    id,
    signal,
  }: {
    id: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    `conversations/${id}/archive`,
    {},
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/bulk-archive
 */
export async function bulkArchiveConversations(
  client: RedoMerchantClient,
  {
    conversationIds,
    signal,
  }: {
    conversationIds: string[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    "conversations/bulk-archive",
    {
      conversationIds,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/:id
 */
export async function updateConversation(
  client: RedoMerchantClient,
  conversation: ExpandedConversation,
  body: UpdateConversationBody,
  signal?: AbortSignal,
): Promise<{ data: ExpandedConversation }> {
  const response = await client.client.put(
    `conversations/${conversation._id}`,
    body,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return {
    data: response.data,
  };
}

/**
 * PUT /conversations/bulk-status-update
 */
export async function bulkUpdateConversationsStatus(
  client: RedoMerchantClient,
  {
    conversationIds,
    status,
    signal,
  }: {
    conversationIds: string[];
    status?: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-status-update",
    {
      conversationIds,
      status,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/remove-in-progress-status
 */
export async function removeInProgressStatus(
  client: RedoMerchantClient,
  {
    signal,
  }: {
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/remove-in-progress-status",
    {},
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-add-tags
 */
export async function bulkAddTags(
  client: RedoMerchantClient,
  {
    conversationIds,
    tags,
    signal,
  }: {
    conversationIds: string[];
    tags: { name: string }[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-add-tags",
    {
      conversationIds,
      tags,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-remove-tags
 */
export async function bulkRemoveTags(
  client: RedoMerchantClient,
  {
    conversationIds,
    tags,
    signal,
  }: {
    conversationIds: string[];
    tags: { name: string }[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-remove-tags",
    {
      conversationIds,
      tags,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-assign
 */
export async function bulkAssignConversations(
  client: RedoMerchantClient,
  {
    conversationIds,
    assignee,
    signal,
  }: {
    conversationIds: string[];
    assignee: string | null;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-assign",
    {
      conversationIds,
      assignee,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-update-viewed
 */
export async function bulkUpdateViewed(
  client: RedoMerchantClient,
  {
    conversationIds,
    markRead,
    signal,
  }: {
    conversationIds: string[];
    markRead: boolean;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-update-viewed",
    {
      conversationIds,
      markRead,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-snooze
 */
export async function bulkSnoozeConversations(
  client: RedoMerchantClient,
  {
    conversationIds,
    snoozedUntil,
    signal,
  }: {
    conversationIds: string[];
    snoozedUntil: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-snooze",
    {
      conversationIds,
      snoozedUntil,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations
 */
export async function createConversation(
  client: RedoMerchantClient,
  body: CreateConversationBody,
  signal?: AbortSignal | undefined,
) {
  const response = await client.client.post("conversations", body, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /conversations/draft
 */
export async function upsertMessageDraft(
  client: RedoMerchantClient,
  {
    conversationId,
    message,
    visibility,
    usersMentioned,
    htmlBody,
    attachments,
    emailEnvelopeInfo = undefined,
    signal,
    replyType = EmailReplyType.REPLY,
  }: {
    conversationId: string;
    message: string;
    visibility: MessageVisibility;
    usersMentioned: string[];
    htmlBody?: string;
    attachments?: Attachment[];
    emailEnvelopeInfo?: EmailDraftRecipientInfo | undefined;
    replyType?: EmailReplyType;
    signal?: AbortSignal;
  },
): Promise<{
  draftMessage: ExpandedConversationMessage;
  conversationUpdatedAt: string;
}> {
  const response = await client.client.post(
    "conversations/draft",
    {
      conversationId,
      message,
      visibility,
      usersMentioned,
      htmlBody,
      attachments,
      emailEnvelopeInfo,
      replyType,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/private-draft
 */
export async function upsertPrivateMessageDraft(
  client: RedoMerchantClient,
  {
    commentConversationId,
    commentId,
    message,
    signal,
  }: {
    commentConversationId: string;
    commentId: string;
    message: string;
    signal?: AbortSignal;
  },
): Promise<{
  draftMessage: ExpandedConversationMessage;
  dmConversationId: string;
  dmConversationUpdatedAt: string;
}> {
  const response = await client.client.post(
    "conversations/private-draft",
    {
      commentConversationId,
      commentId,
      message,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/send-draft
 */
export async function sendMessageDraft(
  client: RedoMerchantClient,
  {
    conversationId,
    redoDraftMessageId,
    signal,
  }: {
    conversationId: string;
    redoDraftMessageId: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    `conversations/send-draft/${conversationId}/${redoDraftMessageId}`,
    {},
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/resend/:conversationid/:messageId
 */
export async function resendMessage(
  client: RedoMerchantClient,
  {
    conversationId,
    redoMessageId,
  }: {
    conversationId: string;
    redoMessageId: string;
  },
  signal?: AbortSignal,
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    `conversations/resend/${conversationId}/${redoMessageId}`,
    {},
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/private-draft
 */
export async function sendPrivateMessageDraft(
  client: RedoMerchantClient,
  {
    draftConversationId,
    draftMessageId,
    signal,
  }: {
    draftConversationId: string;
    draftMessageId: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    `conversations/send-private-draft/${draftConversationId}/${draftMessageId}`,
    {},
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PATCH /conversations/draft/:conversationId/:messageId
 * Used for both message and private message drafts
 */
export async function changeDraftStatus(
  client: RedoMerchantClient,
  {
    conversationId,
    messageId,
    setAsDraft,
    signal,
  }: {
    conversationId: string;
    messageId: string;
    setAsDraft: boolean;
    signal?: AbortSignal;
  },
): Promise<{
  updatedMessage: ExpandedConversationMessage;
  conversationUpdatedAt: string;
}> {
  const response = await client.client.patch(
    `conversations/draft/${conversationId}/${messageId}`,
    {
      setAsDraft,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * DELETE /conversations/draft/:conversationId/:messageId
 * Used for both message and private message drafts
 */
export async function deleteMessageDraft(
  client: RedoMerchantClient,
  {
    conversationId,
    messageId,
    signal,
  }: {
    conversationId: string;
    messageId: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.delete(
    `conversations/draft/${conversationId}/${messageId}`,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * @deprecated
 * DELETE /conversations/:conversationId/file/:fileId
 */
export async function deleteConversationFile(
  client: RedoMerchantClient,
  {
    conversationId,
    fileId,
    signal,
  }: {
    conversationId: string;
    fileId: string;
    signal?: AbortSignal;
  },
): Promise<{ conversation: ExpandedConversation }> {
  const response = await client.client.delete(
    `conversations/${conversationId}/file/${fileId}`,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/:id/forward
 */
export async function forwardEmail(
  client: RedoMerchantClient,
  {
    emails,
    conversationId,
    subject,
    messagePrefix,
    ccEmails,
    bccEmails,
    fromEmail,
    fromName,
    integrationKind,
    signal,
  }: {
    emails: string[];
    conversationId: string;
    subject: string;
    messagePrefix?: string;
    ccEmails?: readonly string[];
    bccEmails?: readonly string[];
    fromEmail: string;
    fromName: string;
    integrationKind: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    `conversations/${conversationId}/forward`,
    {
      emails,
      subject,
      messagePrefix,
      ccEmails,
      bccEmails,
      fromEmail,
      fromName,
      integrationKind,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * GET /conversations/profile-pictures
 */
export async function getProfilePictures(
  client: RedoMerchantClient,
  {
    conversationId,
    signal,
  }: {
    conversationId: string;
    signal?: AbortSignal;
  },
): Promise<{ [key: string]: string }> {
  const response = await client.client.get("conversations/profile-pictures", {
    headers: client.authorization(),
    params: { conversationId },
    signal,
  });
  return response.data;
}

/**
 * GET /conversations/customer
 */
export async function getRecentCustomerConversations(
  client: RedoMerchantClient,
  {
    customerEmail,
    status,
    signal,
  }: {
    customerEmail: string;
    status: FiltersStatus | "all";
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation[]> {
  const response = await client.client.get(`conversations/customer`, {
    headers: client.authorization(),
    params: { customerEmail, status },
    signal,
  });
  return response.data;
}
