import { RouteObject } from "react-router-dom";

export const approvalRoutes: RouteObject[] = [
  {
    children: [
      {
        path: "return/:returnId",
        async lazy() {
          const { ApproveReturn } = await import("./approve-return");
          return { Component: ApproveReturn };
        },
      },
    ],
  },
];
