// extracted by mini-css-extract-plugin
export var bubble = "_2q-0";
export var container = "_2q-3";
export var contrastBorder = "_2q-4";
export var greyOut = "_2q-2";
export var initialsWrapper = "_2q-1";
export var large = "_2q-9";
export var medium = "_2q-8";
export var mediumSmall = "_2q-7";
export var micro = "_2q-5";
export var small = "_2q-6";
export var tiny = "_2q-b";
export var xTiny = "_2q-a";