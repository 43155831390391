import { z } from "zod";
import { buttonHierarchies, fontWeights } from "../brand-kit";
import { FONT_FAMILIES } from "../email-builder";
import { MarketingForgeElementType } from "../forge/marketing-signup-forge-types";
import { buttonActions, inputTypes } from "../signup-form";

export const RichTextElementSchema = z.object({
  id: z.number(),
  type: z.literal(MarketingForgeElementType.RICH_TEXT),
  html: z.string(),
  fontFamily: z.enum(FONT_FAMILIES),
  fontWeight: z.enum(fontWeights),
  fontSizePx: z.number(),
  textColor: z.string(),
  linkColor: z.string(),
});

export const ButtonElementSchema = z.object({
  type: z.literal(MarketingForgeElementType.BUTTON),
  hierarchy: z.enum(buttonHierarchies),
  text: z.string(),
  action: z.enum(buttonActions),
});

export const InputElementSchema = z.object({
  type: z.literal(MarketingForgeElementType.INPUT),
  inputType: z.enum(inputTypes),
  placeholderText: z.string(),
});

export const ImageElementSchema = z.object({
  type: z.literal(MarketingForgeElementType.IMAGE),
  url: z.string(),
});
