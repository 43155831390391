import { genericMemo } from "@redotech/react-util/component";
import { BooleanEvaluationExpression } from "@redotech/redo-model/advanced-flow/expressions/boolean-expression";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { DeepPartial } from "@redotech/util/type";
import { useEffect } from "react";

export const BooleanInspector = genericMemo(function BooleanInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: "Boolean";
  expression: DeepPartial<BooleanEvaluationExpression>;
  setExpression: (expression: DeepPartial<BooleanEvaluationExpression>) => void;
}) {
  useEffect(() => {
    if (!expression.type) {
      setExpression({ ...expression, type: "boolean_evaluation" });
    }
  }, [expression]);

  // May seem funny to return nothing. The useEffect above is the main point of this component.
  // But it is conceivable that this component could be expanded in the future, like with an "invert" toggle
  return null;
});
