import * as classNames from "classnames";
import { memo } from "react";
import HelpIcon from "../../arbiter-icon/help-circle.svg";
import { Flex } from "../../flex";
import { Tooltip } from "../../tooltip/tooltip";
import * as baseRedoInputCss from "./base-redo-input.module.css";
import {
  RedoInputSize,
  RedoInputState,
  sizeStyles,
} from "./base-redo-text-input";

export const BaseRedoInputInfoTooltip = memo(function BaseRedoInputInfoTooltip({
  infoTooltip,
  state,
  size,
}: {
  infoTooltip: string;
  state: RedoInputState;
  size: RedoInputSize;
}) {
  const error = state === RedoInputState.ERROR;

  return (
    <Tooltip title={infoTooltip}>
      <Flex
        className={classNames(
          sizeStyles[size],
          baseRedoInputCss.infoIconWrapper,
          error && baseRedoInputCss.error,
        )}
      >
        <HelpIcon />
      </Flex>
    </Tooltip>
  );
});
