import { faqArticleBodySchema } from "@redotech/redo-model/faq/faq-body-schema";
import { faqElementSchema } from "@redotech/redo-model/faq/faq-db-parser";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateArticleSchema = {
  input: z.object({
    elements: z.array(faqElementSchema),
    title: z.string(),
    faqCollection: z.string(),
    published: z.boolean(),
    id: z.string(),
  }),
  output: zExt.coercedResult(
    z.object({
      oldArticle: faqArticleBodySchema,
      newArticle: faqArticleBodySchema,
    }),
  ),
};
