/**
 * @file
 * String-serializable parameters
 */

import { useHandler } from "@redotech/react-util/hook";
import {
  Equal,
  identityEqual,
  stringEqual,
  symbolEqual,
} from "@redotech/util/equal";
import {
  StringFormat,
  stringStringFormat,
  symbolStringFormat,
} from "@redotech/util/string-format";
import { createContext, useContext, useRef } from "react";
import { useSearchParams } from "react-router-dom";

const ParamPrefix = createContext("");

export class ReadableParamStore {
  readonly #queryParams: URLSearchParams;
  readonly #prefix: string;

  constructor(prefix: string, queryParams: URLSearchParams) {
    this.#prefix = prefix;
    this.#queryParams = queryParams;
  }

  get(param: string): string[] {
    return this.#queryParams.getAll(`${this.#prefix}${param}`);
  }
}

export class WriteableParamStore {
  readonly #queryParams: URLSearchParams;
  readonly #prefix: string;

  constructor(prefix: string, queryParams: URLSearchParams) {
    this.#prefix = prefix;
    this.#queryParams = queryParams;
  }

  set(param: string, values: string[]) {
    param = `${this.#prefix}${param}`;
    this.#queryParams.delete(param);
    for (const value of values) {
      this.#queryParams.append(param, value);
    }
  }
}

export interface Param<T> {
  read(store: ReadableParamStore): T;
  write(store: WriteableParamStore, value: T): void;
}

export abstract class SingleParam<T> implements Param<T> {
  protected abstract readonly format: StringFormat<T>;
  protected abstract readonly equal: Equal<T>;

  constructor(
    private readonly name: string,
    private readonly default_: T,
  ) {}

  read(store: ReadableParamStore) {
    const values = store.get(this.name);
    if (!values.length) {
      return this.default_;
    }
    if (values.length) {
      try {
        return this.format.read(values[0]);
      } catch (e) {
        console.error(e);
      }
    }
    return this.default_;
  }

  write(store: WriteableParamStore, value: T) {
    let strings: string[] | undefined;
    if (!this.equal(value, this.default_)) {
      try {
        strings = [this.format.write(value)];
      } catch (e) {
        console.error(e);
      }
    }
    store.set(this.name, strings || []);
  }
}

export abstract class OptionalParam<T> implements Param<T | undefined> {
  protected abstract readonly format: StringFormat<T>;

  constructor(private readonly name: string) {}

  read(store: ReadableParamStore) {
    const values = store.get(this.name);
    return values.length ? this.format.read(values[0]) : undefined;
  }

  write(store: WriteableParamStore, value: T | undefined) {
    store.set(this.name, value !== undefined ? [this.format.write(value)] : []);
  }
}

export class ObjectParam<T> implements Param<T> {
  constructor(properties: { [K in keyof T]: Param<T[K]> }) {
    this.properties = Object.entries(properties);
  }

  private readonly properties: [string, Param<any>][];

  read(store: ReadableParamStore): T {
    const result: any = {};
    for (const [property, param] of this.properties) {
      result[property] = param.read(store);
    }
    return result;
  }

  write(store: WriteableParamStore, value: T) {
    for (const [property, param] of this.properties) {
      param.write(store, (<any>value)[property]);
    }
  }
}

export class SymbolParam<T extends symbol = symbol> extends SingleParam<T> {
  constructor(name: string, default_: T, symbols: Iterable<T>) {
    super(name, default_);
    this.format = symbolStringFormat(symbols);
  }
  protected readonly equal = symbolEqual;
  protected readonly format: StringFormat<T>;
}

export class StringParam<T extends string = string> extends SingleParam<T> {
  protected readonly equal = stringEqual;
  protected readonly format = <StringFormat<T>>stringStringFormat;
}

export class OptionalStringParam extends OptionalParam<string> {
  protected readonly format = stringStringFormat;
}

export function useParam<T>(
  param: Param<T>,
  equal: Equal<T> = identityEqual,
): [T, (value: T) => void] {
  const prefix = useContext(ParamPrefix);
  const [searchParams, setSearchParams] = useSearchParams();
  const store = new ReadableParamStore(prefix, searchParams);
  const set = useHandler((value: T) =>
    setSearchParams(
      (params) => {
        const store = new WriteableParamStore(prefix, params);
        param.write(store, value);
        return params;
      },
      { replace: true },
    ),
  );
  const read = param.read(store);
  const ref = useRef(read);
  if (!equal(read, ref.current)) {
    ref.current = read;
  }
  return [ref.current, set];
}
