import { Json } from "@redotech/json/json";
import { OutletFade } from "@redotech/react-animation/outlet-fade";
import {
  OptionalStringParam,
  useParam,
} from "@redotech/react-router-util/param";
import { useHandler } from "@redotech/react-util/hook";
import {
  FlowType,
  ReturnFlow as ModelReturnFlow,
  Step as ModelStep,
  StepFn,
  returnFlowJsonFormat,
  stepsBuild,
} from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { IconButton } from "@redotech/redo-web/button";
import { Card, CardTheme } from "@redotech/redo-web/card";
import {
  BlockConfig,
  EdgeConfig,
  Flowchart,
  SelectionMode,
  SelectionTheme,
} from "@redotech/redo-web/flowchart";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import CheckIcon from "@redotech/redo-web/icon-old/check-thin.svg";
import EditPencilIcon from "@redotech/redo-web/icon-old/edit-pencil.svg";
import InfoIcon from "@redotech/redo-web/icon-old/info.svg";
import CloseIcon from "@redotech/redo-web/icon-old/modal-close-button.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import {
  ActionPortalContext,
  useScrollableHeight,
  useScrollableWidth,
} from "@redotech/redo-web/page";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { TextInput } from "@redotech/redo-web/text-input";
import { sinkPromise } from "@redotech/util/promise";
import * as classnames from "classnames";
import {
  Dispatch,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { ASSIGN_TO_AGENT } from "../chat-flow/assign-to-agent";
import { CHAT_MULTIPLE_CHOICE } from "../chat-flow/chat-multiple-choice";
import { CLOSE_CHAT } from "../chat-flow/close-chat";
import { CUSTOMER_RESPONSE } from "../chat-flow/customer-response";
import { REENTRY } from "../chat-flow/reentry";
import { SEE_ORDERS } from "../chat-flow/see-orders";
import { SEND_MESSAGE } from "../chat-flow/send-message";
import { DISCOUNT } from "../discount-flow/discount";
import { AB_TEST } from "../return-flow/ab-test";
import { BLOCK } from "../return-flow/block";
import { CONDITION } from "../return-flow/condition";
import { DISCCOUNT_CODE_NAME } from "../return-flow/discount-code-name";
import { DO_NOTHING } from "../return-flow/do-nothing";
import { EMPTY } from "../return-flow/empty";
import { EXCHANGE_SHIPPING_NAME } from "../return-flow/exchange-shipping-name";
import { FINAL_SALE_RETURN } from "../return-flow/final-sale";
import { FLAG } from "../return-flow/flag";
import { INFORMATION } from "../return-flow/information";
import { INPUT } from "../return-flow/input";
import { MANUAL_REVIEW } from "../return-flow/manual-review";
import {
  MULTIPLE_CHOICE,
  getReturnReasonStep,
} from "../return-flow/multiple-choice";
import { OVERRIDE_ADJUSTMENT } from "../return-flow/override-adjustment";
import { GREEN_RETURN_OVERRIDE } from "../return-flow/override-green-return";
import { REJECT } from "../return-flow/reject";
import { RETURN } from "../return-flow/return";
import * as returnFlowCss from "../return-flow/return-flow.module.css";
import { SelectStep, StepSelect } from "../return-flow/select";
import * as selectCss from "../return-flow/select.module.css";
import { SEND_EMAIL } from "../return-flow/send-email";
import { SEND_SMS } from "../return-flow/send-sms";
import { getStartStep } from "../return-flow/start";
import {
  Step,
  StepId,
  StepSelectFn,
  StepType,
  TypedStep,
} from "../return-flow/step";
import { SUBMIT_CLAIM } from "../return-flow/submit-claim";
import { SUBMIT_REPAIR } from "../return-flow/submit-repair";
import { SUBMIT_WARRANTY } from "../return-flow/submit-warranty";
import { SUBMIT_WARRANTY_REGISTRATION } from "../return-flow/submit-warranty-registration";
import { TRIGGER } from "../return-flow/trigger";
import { WAIT } from "../return-flow/wait";
import { MULTIPLE_ACTIONS } from "../rules/multiple-actions";
import { MULTIPLE_CONDITIONS } from "../rules/multiple-conditions";
import { RuleDetailsDrawer } from "../rules/rule-details-drawer";
import { FlowEditorHeaderButtons } from "./flow-editor-header-buttons";

export const FLOW_TYPES = {
  RETURN: "return",
  FINALIZE: "finalize",
  CLAIM: "claim",
  CHAT: "chat",
};

function invalidStepType(s: never): never {
  throw new Error("Invalid step type");
}

export function modelToSteps(
  model: ModelReturnFlow,
  firstStepTitle?: string,
  returnReasonTitle?: string,
): Map<StepId, Step> {
  // start must come first, to ensure that stepsToModel places that item first as well
  const startState: { next: StepId | undefined } = {
    next: undefined,
  };
  const result = new Map<StepId, Step>(
    firstStepTitle
      ? [["start", new TypedStep(getStartStep(firstStepTitle), startState)]]
      : [],
  );
  for (const [index, step] of model.steps.entries()) {
    let type: StepType<any, any>;
    switch (step.type) {
      case ModelStep.CONDITION:
        type = CONDITION;
        break;
      case ModelStep.INPUT:
        type = INPUT;
        break;
      case ModelStep.INFORMATION:
        type = INFORMATION;
        break;
      case ModelStep.MANUAL_REVIEW:
        type = MANUAL_REVIEW;
        break;
      case ModelStep.FLAG:
        type = FLAG;
        break;
      case ModelStep.EXCHANGE_SHIPPING_NAME:
        type = EXCHANGE_SHIPPING_NAME;
        break;
      case ModelStep.DISCOUNT_CODE_NAME:
        type = DISCCOUNT_CODE_NAME;
        break;
      case ModelStep.MULTIPLE_CHOICE:
        type = step.reason
          ? getReturnReasonStep(returnReasonTitle!)
          : MULTIPLE_CHOICE;
        break;
      case ModelStep.REJECT:
        type = REJECT;
        break;
      case ModelStep.OVERRIDE_ADJUSTMENT:
        type = OVERRIDE_ADJUSTMENT;
        break;
      case ModelStep.OVERRIDE_GREEN_RETURN:
        type = GREEN_RETURN_OVERRIDE;
        break;
      case ModelStep.RETURN:
        type = RETURN;
        break;
      case ModelStep.DISCOUNT:
        type = DISCOUNT;
        break;
      case ModelStep.FINAL_SALE_RETURN:
        type = FINAL_SALE_RETURN;
        break;
      case ModelStep.SUBMIT_CLAIM:
        type = SUBMIT_CLAIM;
        break;
      case ModelStep.SUBMIT_WARRANTY:
        type = SUBMIT_WARRANTY;
        break;
      case ModelStep.SUBMIT_REPAIR:
        type = SUBMIT_REPAIR;
        break;
      case ModelStep.BLOCK:
        type = BLOCK;
        break;
      case ModelStep.CHAT_MULTIPLE_CHOICE:
        type = CHAT_MULTIPLE_CHOICE;
        break;
      case ModelStep.CLOSE_CHAT:
        type = CLOSE_CHAT;
        break;
      case ModelStep.CUSTOMER_RESPONSE:
        type = CUSTOMER_RESPONSE;
        break;
      case ModelStep.ASSIGN_TO_AGENT:
        type = ASSIGN_TO_AGENT;
        break;
      case ModelStep.SEND_MESSAGE:
        type = SEND_MESSAGE;
        break;
      case ModelStep.REENTRY:
        type = REENTRY;
        break;
      case ModelStep.TRIGGER:
        type = TRIGGER;
        break;
      case ModelStep.WAIT:
        type = WAIT;
        break;
      case ModelStep.SEND_EMAIL:
        type = SEND_EMAIL;
        break;
      case ModelStep.SEND_SMS:
        type = SEND_SMS;
        break;
      case ModelStep.SEE_ORDERS:
        type = SEE_ORDERS;
        break;
      case ModelStep.MULTIPLE_CONDITIONS:
        type = MULTIPLE_CONDITIONS;
        break;
      case ModelStep.MULTIPLE_ACTIONS:
      // fallthrough
      case ModelStep.ACTION:
        // Not a standalone step. Used in MULTIPLE_ACTIONS
        type = MULTIPLE_ACTIONS;
        break;
      case ModelStep.AB_TEST:
        type = AB_TEST;
        break;
      case ModelStep.DO_NOTHING:
        type = DO_NOTHING;
        break;
      case ModelStep.SUBMIT_WARRANTY_REGISTRATION:
        type = SUBMIT_WARRANTY_REGISTRATION;
        break;
      default:
        invalidStepType(step);
    }
    const id = String(index);
    if (startState.next === undefined) {
      startState.next = id;
    }
    result.set(id, new TypedStep(type, type.fromModel(step, String)));
  }
  return result;
}

function stepsToModel(steps: Map<StepId, Step>): ModelReturnFlow {
  const stepFns = new Map<StepId, StepFn>();
  for (const [id, step] of steps) {
    if (step.start && step.ignore()) {
      stepFns.set(step.start, undefined!); // ensure first step is first
      continue;
    }
    stepFns.set(id, (idFn) => step.toModel((id) => idFn(stepFns.get(id)!)));
  }
  return { steps: stepsBuild(stepFns.values()) };
}

let stepCounter = 0;

export const FlowEditor = memo(function FlowEditor({
  questionFlow,
  handleSave,
  saveLoad,
  publishLoad,
  defaultQuestionFlow,
  firstStepTitle,
  stepOptions,
  returnReasonTitle,
  flowType,
  handlePublish,
  handleUnpublish,
  ruleDetails,
  defaultButtonClicked,
  defaultButtonPending,
  defaultButtonText,
}: {
  // Comes from data stored in team.settings.{flowtype}
  questionFlow: Json;
  handleSave: (
    newFlow: Json,
    signal?: AbortSignal,
    ruleName?: string,
    ruleDescription?: string,
  ) => Promise<void>;
  saveLoad: { pending: boolean };
  publishLoad?: { pending: boolean };
  handlePublish?: () => Promise<void>;
  handleUnpublish?: () => Promise<void>;
  defaultQuestionFlow: ModelReturnFlow;
  firstStepTitle?: string;
  stepOptions: StepType<any, any>[];
  returnReasonTitle?: string;
  flowType: FlowType;
  ruleDetails?: { name: string | undefined; description: string | undefined };
  defaultButtonClicked?: () => void;
  defaultButtonPending?: boolean;
  defaultButtonText?: string;
}) {
  useScrollableWidth();
  useScrollableHeight();

  const team = useContext(TeamContext);
  const actionsElement = useContext(ActionPortalContext);

  const [selected, setSelected] = useParam(new OptionalStringParam("selected"));
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const [modified, setModified] = useState(false);
  const [published, setPublished] = useState<boolean | undefined>(undefined);

  const initialRuleName = ruleDetails?.name || "";
  const initialRuleDescription = ruleDetails?.description || "";

  const [ruleName, setRuleName] = useState<string>(initialRuleName);
  const [ruleDescription, setRuleDescription] = useState<string>(
    initialRuleDescription,
  );

  const [steps, setSteps] = useState(new Map<StepId, Step>());

  const reset = () => {
    if (!team || !questionFlow || !defaultQuestionFlow) {
      return;
    }
    let questionFlowReset = returnFlowJsonFormat.read(questionFlow);
    const hasExistingFlow = questionFlowReset.steps.length > 0;
    // There isn't a flow saved. Loading the default flow.
    if (!hasExistingFlow) {
      questionFlowReset = defaultQuestionFlow;
      setModified(true);
    }
    setSteps(
      modelToSteps(questionFlowReset, firstStepTitle, returnReasonTitle),
    );
    if (hasExistingFlow) {
      setPublished(!!questionFlowReset.publishedAt);
      setModified(false);
    }
  };

  useEffect(reset, [team, questionFlow, defaultQuestionFlow]);

  useEffect(() => {
    return () => {
      setSelected(undefined);
    };
  }, [flowType]);

  const [selectNode, setSelectNode] = useState<SelectStep | undefined>();

  const [hover, setHover] = useState<string[]>([]);

  useEffect(() => {
    if (
      initialRuleDescription !== ruleDescription ||
      initialRuleName !== ruleName
    ) {
      setModified(true);
    }
  }, [ruleName, ruleDescription]);

  const create = useHandler(() => {
    const id = `new.${stepCounter++}`;
    const step = new TypedStep(EMPTY, undefined);
    setSteps((steps) => {
      steps = new Map(steps);
      steps.set(id, step);
      return steps;
    });
    setSelected(id);
    setModified(true);
    return id;
  });

  const setStep = useHandler<Dispatch<SetStateAction<Step | undefined>>>(
    (step) => {
      setSteps((steps) => {
        if (step instanceof Function) {
          step = step(steps.get(selected!)!);
        }
        if (step) {
          steps = new Map(steps);
          steps.set(selected!, step);
        } else {
          steps = new Map(
            [...steps.entries()]
              .filter(([id]) => id !== selected)
              .map(([id, step]) => [id, step.stepDeleted(selected!)]),
          );
        }
        return steps;
      });
      setModified(true);
    },
  );

  const onSelect = useHandler((value: StepId | undefined) => {
    if (selectNode) {
      selectNode(value);
    } else {
      setShowDetailsDrawer(false);
      setSelected(value);
    }
  });

  const selectNodeHandler = useHandler((value: SelectStep) =>
    setSelectNode(() => value),
  );

  function getSaveDisabledMessage(): string | undefined {
    if (saveLoad.pending) {
      return "Saving your changes...";
    }
    if (flowType === FlowType.RULE && !ruleName) {
      return "You must name the rule before saving";
    }
    const hasInvalidStep = [...steps.values()].some(
      (step) => !step.valid(flowType),
    );
    if (hasInvalidStep) {
      return "One or more of your steps are invalid. Update the steps then try again.";
    }
    return undefined;
  }

  const detailsClicked = () => {
    setSelected(undefined);
    setShowDetailsDrawer((shouldShow) => !shouldShow);
  };

  const cancelClicked = () => {
    reset();
    setSelectNode(undefined);
  };

  const saveClicked = async () => {
    await handleSave(
      returnFlowJsonFormat.write(stepsToModel(steps)),
      undefined,
      ruleName,
      ruleDescription,
    );
    setModified(false);
  };

  const publishClicked = () => {
    handlePublish && sinkPromise(handlePublish());
  };

  const unpublishClicked = () => {
    handleUnpublish && sinkPromise(handleUnpublish());
  };

  const flowEditorHeaderButtons = (
    <FlowEditorHeaderButtons
      cancelClicked={cancelClicked}
      cancelDisabled={saveLoad.pending}
      defaultButtonClicked={defaultButtonClicked}
      defaultButtonPending={defaultButtonPending}
      defaultButtonText={defaultButtonText}
      detailsClicked={detailsClicked}
      flowType={flowType}
      publishClicked={publishClicked}
      publishPending={publishLoad?.pending ?? false}
      rightPanelOpen={!!selected || showDetailsDrawer}
      ruleName={ruleName}
      saveClicked={saveClicked}
      saveDisabled={getSaveDisabledMessage()}
      savePending={saveLoad.pending}
      setRuleName={setRuleName}
      showPublishButton={!modified && !!handlePublish && published === false}
      showSaveAndCancel={modified}
      showUnpublishButton={!modified && !!handleUnpublish && published === true}
      unpublishClicked={unpublishClicked}
    />
  );

  return (
    <>
      {actionsElement
        ? createPortal(flowEditorHeaderButtons, actionsElement)
        : null}

      <div
        className={classnames(
          returnFlowCss.container,
          gridCss.grid,
          gridCss.stretch,
        )}
      >
        <div className={classnames(returnFlowCss.column, gridCss.span12)}>
          <Diagram
            flowType={flowType}
            highlight={!!selectNode}
            hover={hover}
            onSelect={onSelect}
            selected={selected}
            steps={steps}
          />
        </div>
        <div
          className={classnames(returnFlowCss.sideDrawer, {
            [returnFlowCss.sideDrawerOpen]: selected !== undefined,
          })}
        >
          {selected !== undefined && (
            <Detail
              canClose={!selectNode}
              create={create}
              flowType={flowType}
              handleClose={() => setSelected(undefined)}
              id={selected}
              setHover={setHover}
              setSelectNodes={selectNodeHandler}
              setStep={setStep}
              step={steps.get(selected!)}
              stepOptions={stepOptions}
              steps={steps}
            />
          )}
        </div>
        <div
          className={classnames(returnFlowCss.sideDrawer, {
            [returnFlowCss.sideDrawerOpen]: showDetailsDrawer,
          })}
        >
          <OutletFade
            childClassName={returnFlowCss.detailsContent}
            key_="FlowDetail"
          >
            <header className={returnFlowCss.headerWithClose}>
              <div className={returnFlowCss.closeButton}>
                <IconButton onClick={() => setShowDetailsDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </header>
            <RuleDetailsDrawer
              description={ruleDescription}
              name={ruleName}
              setDescription={(val) => {
                setRuleDescription(val);
                setModified(true);
              }}
              setName={(val) => {
                setRuleName(val);
                setModified(true);
              }}
            />
          </OutletFade>
        </div>
      </div>
    </>
  );
});

const Diagram = memo(function Diagram({
  highlight,
  hover,
  selected,
  steps,
  onSelect,
  flowType,
}: {
  highlight: boolean;
  hover: string[];
  steps: Map<string, Step>;
  selected: string | undefined;
  onSelect(id: string): void;
  flowType: FlowType;
}) {
  const selectionMode: SelectionMode = useMemo(
    () => ({
      enabled: (id) =>
        !highlight || (steps.get(id)!.start === undefined && selected !== id),
      theme: highlight ? SelectionTheme.HIGHLIGHT : SelectionTheme.NORMAL,
    }),
    [highlight, steps],
  );

  const blocks: BlockConfig[] = [...steps.entries()].map(([id, step]) => ({
    error: !step.valid(flowType),
    layout: step.layout(),
    title: step.title(),
    description: step.description(),
    icon: <step.Icon className={returnFlowCss.icon} />,
    id,
  }));

  const edges: EdgeConfig[] = [...steps.entries()].flatMap(([id, step]) => {
    const downstreams = step
      .downstream()
      .filter((downstream) => !!downstream.id);
    return downstreams.map((downstream) => ({
      id: `${id}.${downstream.id}`,
      from: id,
      to: downstream.id,
      label: downstream.label,
    }));
  });

  return (
    <Card
      className={returnFlowCss.center}
      noPadding
      scrollable
      theme={CardTheme.DARK}
    >
      <Flowchart
        blocks={blocks}
        edges={edges}
        hover={hover}
        onSelect={onSelect}
        selected={selected}
        selectionMode={selectionMode}
      />
    </Card>
  );
});

const Detail = memo(function Detail({
  create,
  id,
  setHover,
  setSelectNodes,
  setStep,
  canClose,
  handleClose,
  step,
  steps,
  stepOptions,
  flowType,
}: {
  create(): StepId;
  id: string | undefined;
  setHover(id: StepId[]): void;
  setSelectNodes(fn: SelectStep | undefined): void;
  setStep: Dispatch<SetStateAction<Step | undefined>>;
  canClose: boolean;
  handleClose(): void;
  step: Step | undefined;
  steps: Map<string, Step>;
  stepOptions: StepType<any, any>[];
  flowType: FlowType;
}) {
  const user = useContext(UserContext);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState<string>();
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const typeChanged = useHandler((type: StepType<any, any> | undefined) => {
    type = type || EMPTY;
    setStep((step) => step && new TypedStep(type!, type!.empty));
  });

  const handleTitleChange = useHandler((newTitle: string) => {
    (step as TypedStep<any, any>).setCustomTitle(newTitle, setStep);
  });

  const stepSelect: StepSelectFn = ({ value, valueChange, type }) => {
    return (
      <StepSelect
        create={create}
        disabled={!canEditSettings}
        onHover={(hover) =>
          setHover(hover && value !== undefined ? [value] : [])
        }
        setSelectStep={setSelectNodes}
        step={steps.get(value!)}
        type={type}
        value={value}
        valueChange={valueChange}
      />
    );
  };

  useEffect(() => {
    setTitle(undefined);
    setIsEditingTitle(false);
  }, [step]);

  return (
    <div>
      <OutletFade
        childClassName={returnFlowCss.detailsContent}
        key_={`${id || ""}.${step ? step.title() : ""}`}
      >
        {step && (
          <>
            <header className={returnFlowCss.headerWithClose}>
              {canClose && (
                <div className={returnFlowCss.closeButton}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <div className={returnFlowCss.detailHeader}>
                <div className={returnFlowCss.detailTitle}>
                  {isEditingTitle ? (
                    <TextInput
                      button={
                        <div className={returnFlowCss.cancelConfirm}>
                          <IconButton onClick={() => setIsEditingTitle(false)}>
                            <XIcon />
                          </IconButton>
                          <IconButton
                            disabled={!title}
                            onClick={() => {
                              handleTitleChange(title || step.sidebarTitle());
                              setIsEditingTitle(false);
                            }}
                          >
                            <CheckIcon />
                          </IconButton>
                        </div>
                      }
                      onChange={(newTitle) => setTitle(newTitle)}
                      value={title ?? step.sidebarTitle()}
                    />
                  ) : (
                    <>
                      <h2>{step.sidebarTitle()}</h2>
                      {step.start === undefined &&
                        step.sidebarTitle() !== "New step" && (
                          <IconButton
                            onClick={() => {
                              setTitle(step.sidebarTitle());
                              setIsEditingTitle(true);
                            }}
                          >
                            <EditPencilIcon />
                          </IconButton>
                        )}
                    </>
                  )}
                </div>
                {step.start === undefined &&
                  canEditSettings &&
                  !isEditingTitle && (
                    <IconButton onClick={() => setStep(undefined)}>
                      <TrashIcon />
                    </IconButton>
                  )}
              </div>
            </header>
            {step.information() && (
              <div className={returnFlowCss.informationContainer}>
                <InfoIcon className={returnFlowCss.infoIcon} />
                <div>{step.information()}</div>
              </div>
            )}
            {step.start === undefined && (
              <LabeledInput label="Step type">
                <SelectDropdown
                  disabled={!canEditSettings}
                  options={stepOptions}
                  value={step.type}
                  valueChange={typeChanged}
                >
                  {(type) => (
                    <div className={selectCss.value}>
                      <type.Icon className={selectCss.icon} />
                      <div className={selectCss.title}>{type.title}</div>
                    </div>
                  )}
                </SelectDropdown>
              </LabeledInput>
            )}
            {step.details({ stepSelect, setStep, flowType })}
          </>
        )}
      </OutletFade>
    </div>
  );
});
