import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import {
  archiveConversation,
  bulkArchiveConversations,
} from "../../client/conversations";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import { getNextBatch } from "../utils";
import * as archiveTicketModalCss from "./archive-ticket-modal.module.css";

export const ArchiveTicketModal = memo(function ArchiveTicketModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  inDetailView,
  cleanupFn,
  selectAllInfo,
  nextConversationInList,
  prevConversationInList,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const { removeConversation } = useRequiredContext(
    UpdateConversationStateContext,
  );
  const [archivingSelectAll, setArchivingSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);

  const handleArchiveTickets = async () => {
    if (conversations.length === 1) {
      await archiveConversation(client, { id: conversations[0]._id });
      amplitude.logEvent("archive-conversation", {
        mode: "single",
        conversationIds: [conversations[0]._id],
        channel: [conversations[0].platform],
      });
      if (inDetailView) {
        setActiveConversation(nextConversationInList || prevConversationInList);
      }
      removeConversation(conversations[0]);
    } else if (conversations.length > 1) {
      const conversationIds = conversations.map(
        (conversation) => conversation._id,
      );
      const channels = [
        ...new Set(conversations.map((conversation) => conversation.platform)),
      ];
      await bulkArchiveConversations(client, { conversationIds });
      amplitude.logEvent("archive-conversation", {
        mode: "multiple",
        conversationIds,
        channels,
      });
    }
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const archiveBatch = async (): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkArchiveConversations(client, { conversationIds });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously archives each page of conversations.
  // If speed becomes an issue, we could asynchronously archive each page.
  const handleArchiveSelectAll = async () => {
    if (!selectAllInfo) return;
    selectAllInfo.setBlockRefresh(true);
    setArchivingSelectAll(true);
    let done = false;
    do {
      const result = await archiveBatch();
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("archive-conversation", { mode: "all" });
    setArchivingSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const plural = conversations.length > 1 || selectAllInfo;

  const footer = (
    <div className={archiveTicketModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={async () => {
          selectAllInfo
            ? await handleArchiveSelectAll()
            : await handleArchiveTickets();
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, delete ticket{plural ? "s" : ""}
      </Button>
    </div>
  );

  return (
    <Modal
      footer={archivingSelectAll ? undefined : footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        archivingSelectAll
          ? "Deleting tickets"
          : `Delete ${
              selectAllInfo
                ? "all selected "
                : plural
                  ? conversations.length + " "
                  : ""
            }ticket${plural ? "s" : ""}?`
      }
    >
      {archivingSelectAll ? (
        <ProgressBar
          value={(numDone / (selectAllInfo?.count || numDone)) * 100}
        />
      ) : (
        <p>
          Are you sure you want to delete {plural ? "these " : "this "}ticket
          {plural ? "s" : ""}?
        </p>
      )}
    </Modal>
  );
});
