import { useLayoutEffect } from "react";
import { Subject } from "rxjs";

export function useLayoutUpdateSubject<T>(
  subject: Subject<T>,
  value: T,
  exit: T,
) {
  useLayoutEffect(() => {
    subject.next(value);
    return () => subject.next(exit);
  }, [value, subject, exit]);
}
