import { z } from "zod";

export const getConciergeDashboardMetricsSchema = {
  input: z.null(),
  output: z.object({
    engagedSessionConversionRate: z.number(),
    sessionConversionRate: z.number(),
    ordersGenerated: z.number(),
    engagementRate: z.number(),
  }),
};
