import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { ConditionType } from "../condition";

export const ORDER_COUNT_GREATER_THAN: ConditionType<
  string,
  ModelCondition.OrderCountGreaterThan
> = {
  name: "Order count greater than",
  description(state) {
    return `More than ${+state} orders`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Minimum orders">
        <TextInput
          min={0}
          onChange={setState}
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: String(0),
  fromModel(model) {
    return model.orderCount;
  },
  toModel(state) {
    return { type: ModelCondition.ORDER_COUNT_GREATER_THAN, orderCount: state };
  },
  valid(state) {
    return state !== undefined;
  },
};
