import { Tuple } from "@redotech/util/type";
import { ConversationPlatform } from "../conversation";
import { SortableConversationTableColumn, TableSort } from "../table";

export const CONVERSATIONS_FILTERS_VERSION = "3";

export interface UniqueV3Filters {
  status?: FiltersStatus | null;
  search?: string;
  sort?: TableSort<SortableConversationTableColumn>;
  customerEmail?: string;
  drafts?: boolean;
}

export interface ConversationFiltersV3 extends UniqueV3Filters {
  advancedFilters: AdvancedFilter[];
}

interface AdvancedFilterI<VALUE, QUERY> {
  type: FilterGroupFilterOption;
  value: VALUE;
  query?: QUERY;
}

export enum FilterGroupFilterOption {
  ASSIGNEES = "assignees",
  CHANNELS = "channels",
  CONVERSATION_TAGS = "conversationTags",
  CLOSED_DATE = "closedDate",
  MENTIONS = "mentions",
  CREATED_DATE = "createdDate",
  CUSTOMER_TAGS = "customerTags",
  LAST_RESPONSE_AT = "lastResponseAt",
  READ = "read",
  WORDS = "words",
}

export type AdvancedFilter =
  | ChannelsFilter
  | AssigneesFilter
  | ReadStatusFilter
  | CreatedDateFilter
  | LastResponseAtFilter
  | ClosedDateFilter
  | ConversationTagsFilter
  | WordsFilter
  | CustomerTagsFilter
  | MentionsUsersFilter;

/**
 * In the frontend, filters can be in an unset (or pending) state
 * where the filter doesn't apply until the value is set.
 *
 * This type allows us to write filter components that can support either a set or unset state.
 */
export type PendingFilter<T> = T extends {
  type: infer U;
  query?: infer Q;
}
  ? { [K in keyof T]: K extends "value" ? null : T[K] }
  : never;

export type PendingAdvancedFilter = PendingFilter<AdvancedFilter>;

export enum FiltersStatus {
  OPEN = "open",
  CLOSED = "closed",
  SNOOZED = "snoozed",
  IN_PROGRESS = "in_progress",
}

export enum ChannelFilterType {
  INCLUDES = "includes",
  EXCLUDES = "excludes",
}

export interface ChannelsFilter {
  type: FilterGroupFilterOption.CHANNELS;
  value: ConversationPlatform[];
  query: ChannelFilterType;
}

export enum AssigneesFilterType {
  INCLUDES = "includes",
  EXCLUDES = "excludes",
}

export interface AssigneesFilter
  extends AdvancedFilterI<(string | null)[], AssigneesFilterType> {
  type: FilterGroupFilterOption.ASSIGNEES;
  value: (string | null)[];
  query: AssigneesFilterType;
}

export interface ReadStatusFilter extends AdvancedFilterI<boolean, undefined> {
  type: FilterGroupFilterOption.READ;
  value: boolean;
}

export enum DateFilterQueryType {
  WITHIN = "within",
  BEFORE = "before",
  AFTER = "after",
}

export enum KnownDateFilterTimeFrame {
  TODAY = "today",
  THIS_WEEK = "this week",
  LAST_WEEK = "last week",
  THIS_MONTH = "this month",
  LAST_MONTH = "last month",
  THIS_YEAR = "this year",
  LAST_YEAR = "last year",
  CUSTOM = "custom",
}

export interface DateFilter<T extends FilterGroupFilterOption>
  extends AdvancedFilterI<KnownDateFilterTimeFrame, DateFilterQueryType> {
  type: T;
  value: KnownDateFilterTimeFrame;
  query: DateFilterQueryType;
  customDate?: Date | Tuple<Date, 2>;
}

export interface CreatedDateFilter
  extends DateFilter<FilterGroupFilterOption.CREATED_DATE> {}

export interface LastResponseAtFilter
  extends DateFilter<FilterGroupFilterOption.LAST_RESPONSE_AT> {}

export interface ClosedDateFilter
  extends DateFilter<FilterGroupFilterOption.CLOSED_DATE> {}

export enum ConversationTagFilterType {
  ALL_OF = "all_of",
  ANY_OF = "any_of",
  NONE_OF = "none_of",
}

export interface ConversationTagsFilter
  extends AdvancedFilterI<string[], ConversationTagFilterType> {
  type: FilterGroupFilterOption.CONVERSATION_TAGS;
  value: string[];
  query: ConversationTagFilterType;
}

export interface WordsFilter extends AdvancedFilterI<string[], undefined> {
  type: FilterGroupFilterOption.WORDS;
  value: string[];
}

export enum CustomerTagsFilterType {
  ALL_OF = "all_of",
  ANY_OF = "any_of",
  NONE_OF = "none_of",
}

export interface CustomerTagsFilter
  extends AdvancedFilterI<string[], CustomerTagsFilterType> {
  type: FilterGroupFilterOption.CUSTOMER_TAGS;
  value: string[];
  query: CustomerTagsFilterType;
}

export interface MentionsUsersFilter
  extends AdvancedFilterI<string[], undefined> {
  type: FilterGroupFilterOption.MENTIONS;
  value: string[];
}
