import Gravatar from "@gravatar/js";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { useDevicePixelRatio } from "@redotech/react-util/screen";
import type { Return } from "@redotech/redo-model/return";
import { TimelineEvent } from "@redotech/redo-model/timeline";
import { GetUser } from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import { Card } from "@redotech/redo-web/card";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import SendIcon from "@redotech/redo-web/icon-old/send.svg.js";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { UserImage, UserImageSize } from "@redotech/redo-web/user-image";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { FormEvent, memo, useContext, useMemo, useRef, useState } from "react";
import { TeamContext } from "../../app/team";
import { returnCommentCreate, returnCommentDelete } from "../../client/comment";
import { RedoMerchantClientContext } from "../../client/context";
import * as commentsCss from "./comments-card.module.css";

export const CommentsCard = memo(function CommentsCard({
  return: return_,
  reload,
}: {
  return?: Return;
  reload(): void;
}) {
  const [key, setKey] = useState(0);
  const client = useRequiredContext(RedoMerchantClientContext);
  const newCommentRef = useRef<CommentData>();
  const [newCommentLoad, doNewComment] = useTriggerLoad(async (signal) => {
    await alertOnFailure("Could not create new comment")(() =>
      returnCommentCreate(client, {
        message: newCommentRef.current!.message,
        returnId: return_!.id,
        signal,
      }),
    );
    setKey((key) => key + 1);
    reload();
  });
  const handleCommentSubmit = useHandler((data: CommentData) => {
    newCommentRef.current = data;
    doNewComment();
  });

  return (
    <Card title="Comments">
      <Flex dir="column" gap="md">
        {return_?.timeline
          .filter((event) => !event.isShipment && !event.customer)
          .map((event) => (
            <Comment
              event={event}
              key={event._id}
              reload={reload}
              return={return_}
            />
          ))}
      </Flex>
      <NewComment
        key={key}
        onSubmit={handleCommentSubmit}
        pending={newCommentLoad.pending}
      />
    </Card>
  );
});

const Comment = memo(function Comment({
  return: return_,
  event,
  reload,
}: {
  reload(): void;
  return: Return;
  event: TimelineEvent;
}) {
  const team = useContext(TeamContext);
  const devicePixelRatio = useDevicePixelRatio();
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = event?.user as GetUser | null;
  const gravatarUrl = useMemo(() => {
    return Gravatar({
      email: event.isSystem
        ? "hello@getredo.com"
        : (event.customer ? event.customer.email : user ? user.email : "") ||
          "",
      defaultImage: "identicon",
      protocol: location.protocol === "https:" ? "https" : undefined,
      size: Math.ceil(devicePixelRatio * 32),
    });
  }, [devicePixelRatio, team?.email]);

  const profileImage = useMemo(() => {
    if (!user) {
      return null;
    }
    return Gravatar({
      email: user.email || "",
      defaultImage: "404",
      protocol: location.protocol === "https:" ? "https" : undefined,
      size: Math.ceil(devicePixelRatio * 24),
    });
  }, [user, devicePixelRatio]);

  const [_deleteLoad, doDelete] = useTriggerLoad(async (signal) => {
    await alertOnFailure("Could not delete comment")(() =>
      returnCommentDelete(client, {
        commentId: event._id!,
        returnId: return_._id,
        signal,
      }),
    );
    reload();
  });

  if (!team) {
    return null;
  }

  return (
    <Flex dir="row" gap="xs">
      {user ? (
        <UserImage
          alt="profile picture"
          imageUrl={profileImage}
          name={user?.name ?? ""}
          size={UserImageSize.SMALL}
        />
      ) : (
        <img className={commentsCss.commentProfile} src={gravatarUrl} />
      )}

      {event.link ? (
        <Flex dir="column" flex={1} gap="xs">
          <Flex
            bgColor="secondary"
            dir="row"
            key={event._id}
            p="md"
            radius="sm"
          >
            <Text fontSize="xs" textColor="primary">
              {event.link?.message}
            </Text>
            <Text
              className={commentsCss.commentLink}
              fontSize="xs"
              onClick={() => window.open(event.link?.url)}
            >
              &nbsp;{`order ${event.link?.orderNumber ?? "link"}`}
            </Text>
          </Flex>
          <Text fontSize="xxs" textColor="secondary">
            {`${getDateTimeString(new Date(event.updatedAt))} ${
              user?.name ?? ""
            }`}
          </Text>
        </Flex>
      ) : (
        <Flex dir="column" flex={1} gap="xs">
          <Flex bgColor="secondary" dir="row" p="md" radius="sm" w="full">
            <Text fontSize="xs" textColor="primary" w="full">
              {event.message}
            </Text>
            {!event.isSystem && !event.customer && (
              <RedoButton
                IconLeading={TrashIcon}
                onClick={() => doDelete()}
                pending={_deleteLoad.pending}
                size={RedoButtonSize.SMALL}
                theme={RedoButtonTheme.DESTRUCTIVE}
              />
            )}
          </Flex>
          <Text fontSize="xxs" textColor="secondary">
            {`${getDateTimeString(new Date(event.updatedAt))} ${
              user?.name ?? ""
            }`}
          </Text>
        </Flex>
      )}
    </Flex>
  );
});

const commentForm = groupInput({
  message: input<string>(),
});

type CommentData = InputProvider.Value<typeof commentForm>;

const defaultComment: CommentData = { message: "" };

const NewComment = memo(function NewComment({
  pending = false,
  onSubmit,
}: {
  pending?: boolean;
  onSubmit(data: { message: string }): void;
}) {
  const input = useInput(commentForm, defaultComment);
  const { message } = input.inputs;
  return (
    <form
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        return onSubmit({ message: message.value });
      }}
    >
      <FormTextInput
        button={
          <RedoButton
            disabled={message.value === ""}
            IconTrailing={SendIcon}
            onClick={() => onSubmit({ message: message.value })}
            pending={pending}
            size={RedoButtonSize.REGULAR}
          />
        }
        disabled={pending}
        fullwidth
        input={message}
        label=""
        placeholder="Type a comment and press Enter"
      />
    </form>
  );
});
