import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { filtersToQueryString } from "@redotech/redo-model/conversation-filters/conversation-filter-query";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  SortableConversationTableColumn,
  TableSort,
} from "@redotech/redo-model/table";
import { stringHash } from "@redotech/util/string";

export type ConversationFetcherResult = {
  data: ExpandedConversation[];
  pageNext: string | undefined;
};

function getCacheKey(
  pageContinue: string | undefined,
  pageSize: number,
  passThroughValues: ConversationFiltersV3 | undefined,
  sort: TableSort<SortableConversationTableColumn> | undefined,
) {
  // This is the same way we construct the query string for the request to the backend. Better than doing a JSON.stringify as it is not guarenteed to order the items the same way
  const v3Filters: ConversationFiltersV3 = Object.assign(
    { advancedFilters: [] },
    passThroughValues,
    sort,
  );
  const query = filtersToQueryString(v3Filters);
  return stringHash(query + pageContinue + pageSize).toString();
}

export class ConversationFetcherCache {
  private cache = new Map<string, ConversationFetcherResult>();

  public set(
    pageContinue: string | undefined,
    pageSize: number,
    passThroughValues: ConversationFiltersV3 | undefined,
    sort: TableSort<SortableConversationTableColumn> | undefined,
    result: ConversationFetcherResult,
  ): void {
    const hash = getCacheKey(pageContinue, pageSize, passThroughValues, sort);
    this.cache.set(hash, result);
  }

  public get(
    pageContinue: string | undefined,
    pageSize: number,
    passThroughValues: ConversationFiltersV3 | undefined,
    sort: TableSort<SortableConversationTableColumn> | undefined,
  ): ConversationFetcherResult | undefined {
    const hash = getCacheKey(pageContinue, pageSize, passThroughValues, sort);
    return this.cache.get(hash);
  }

  public invalidate() {
    this.cache.clear();
  }
}
