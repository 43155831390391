import { v3FiltersFormat } from "@redotech/redo-model/conversation-filters/conversation-filter-formats";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { SystemView } from "@redotech/redo-model/conversation-filters/system-view";
import { UpdateViewBody } from "@redotech/redo-model/update-view-body";
import { View } from "@redotech/redo-model/view";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCheckbox } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { Flex } from "@redotech/redo-web/flex";
import { DisabledTooltip } from "@redotech/redo-web/tooltip/disabled-tooltip";
import { sinkPromise } from "@redotech/util/promise";
import { memo, useEffect, useMemo, useState } from "react";
import * as filterEditorPageHeaderCss from "./filter-editor-page-header.module.css";

/**
 * Because this component is rendered in a parent, you cannot assume it can access any contexts
 */
export const FilterEditorPageHeader = memo(function FilterEditorPageHeader({
  userViews,
  activeFilters,
  cancel,
  save,
  activeView,
}: {
  activeView: View;
  activeFilters: ConversationFiltersV3;
  userViews: View[];
  cancel: () => void;
  save: (view: UpdateViewBody) => Promise<void>;
}) {
  const [viewName, setViewName] = useState<string>("");
  const [isViewPrivate, setIsViewPrivate] = useState<boolean>(false);

  useEffect(() => {
    if (activeView && activeView._id !== SystemView.CREATE) {
      setViewName(activeView.name);
    }
    setIsViewPrivate(!!activeView.user);
  }, [activeView]);

  const reservedViewNames = useMemo(() => {
    const filterOutActiveView = userViews.filter(
      (view) => view._id !== activeView._id,
    );

    return new Set([
      ...filterOutActiveView.map((view) => view.name.toLowerCase()),
      ...Object.values(SystemView).map((view) => view.toLowerCase()),
    ]);
  }, [userViews, activeView]);

  const sumitDisabledBecauseOfEmptyName =
    viewName.length === 0 ? "You must name this view" : false;

  const submitDisabledBecauseOfDuplicateName = reservedViewNames.has(
    viewName.toLowerCase(),
  )
    ? "A view with this name already exists"
    : false;

  const submitDisabled =
    sumitDisabledBecauseOfEmptyName || submitDisabledBecauseOfDuplicateName;

  const [pending, setPending] = useState(false);

  return (
    <Flex
      align="center"
      className={filterEditorPageHeaderCss.wrapper}
      justify="space-between"
      px="6xl"
      py="3xl"
    >
      <Flex align="center" gap="xl">
        <RedoTextInput
          placeholder="Enter a name for this view"
          setValue={setViewName}
          value={viewName}
        />
        <RedoCheckbox
          label="Make view private"
          setValue={(value) => setIsViewPrivate(!!value)}
          value={isViewPrivate}
        />
      </Flex>
      <Flex align="center">
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={cancel}
          text="Cancel"
        />
        <DisabledTooltip disabledMessage={submitDisabled}>
          <RedoButton
            disabled={!!submitDisabled}
            hierarchy={RedoButtonHierarchy.PRIMARY}
            onClick={() => {
              setPending(true);
              const view: UpdateViewBody = {
                isPrivate: isViewPrivate,
                name: viewName,
                filters: v3FiltersFormat.write(activeFilters),
              };
              sinkPromise(
                save(view).finally(() => {
                  setPending(false);
                }),
              );
            }}
            pending={pending}
            text="Save view"
          />
        </DisabledTooltip>
      </Flex>
    </Flex>
  );
});
