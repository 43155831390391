import { faqCollectionBodySchema } from "@redotech/redo-model/faq/faq-body-schema";
import { z } from "zod";

export const updateCollectionSchema = {
  input: z.object({
    _id: z.string(),
    title: z.string(),
    description: z.string(),
    published: z.boolean(),
  }),
  output: faqCollectionBodySchema,
};
