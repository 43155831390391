import * as amplitude from "@amplitude/analytics-browser";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { LineItem, Order } from "@redotech/redo-model/order";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useEffect, useState } from "react";
import { Address, AddressEditModal } from "./address-edit-modal";
import { Button, ButtonTheme } from "./button";
import { ContactInfo, ContactInfoModal } from "./contact-info-modal";
import * as editOrderModalCss from "./edit-order-modal.module.css";
import { LabeledInput } from "./labeled-input";
import { LoadingRedoAnimation } from "./loading-redo-animation";
import { Modal, ModalSize, PaddingAmount } from "./modal";
import { OrderLineItem } from "./order-line-item";
import { ShippingContactInfo } from "./shipping-contact-info";
import { TextInput } from "./text-input";

export interface lineItemEdit {
  lineItemTitle: string;
  lineItemVariantTitle: string;
  quantity: number;
}

const orderForm = groupInput({
  lineItems: input<LineItem[]>(),
  shippingAddress: groupInput({
    firstName: input<string>(),
    lastName: input<string>(),
    street1: input<string>(),
    street2: input<string>(),
    city: input<string>(),
    state: input<string>(),
    zip: input<string>(),
    country: input<string>(),
  }),
  contactInfo: groupInput({
    email: input<string>(),
    phone: input<string>(),
  }),
});

type OrderValue = InputProvider.Value<typeof orderForm>;

export const EditOrderModal = memo(function EditOrderModal({
  open,
  setOpen,
  order,
  onOrdersChange,
  updateOrder,
  disableEditLineItems = false,
  getLineItemInventory,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order;
  onOrdersChange: () => void;
  updateOrder: (data: any) => Promise<any>;
  disableEditLineItems?: boolean;
  getLineItemInventory?: (id: string) => Promise<number>;
}) {
  const [initialValue, setInitialValue] = useState<OrderValue>({
    lineItems: order.shopify.line_items,
    shippingAddress: {
      firstName: order.shopify.shipping_address?.first_name || "",
      lastName: order.shopify.shipping_address?.last_name || "",
      street1: order.shopify.shipping_address?.address1 || "",
      street2: order.shopify.shipping_address?.address2 || "",
      city: order.shopify.shipping_address?.city || "",
      state: order.shopify.shipping_address?.province_code || "",
      zip: order.shopify.shipping_address?.zip || "",
      country: order.shopify.shipping_address?.country_code || "",
    },
    contactInfo: {
      email: order.shopify.email || "",
      phone: order.shopify.phone || "",
    },
  });
  const [editShippingAddressModalOpen, setEditShippingAddressModalOpen] =
    useState(false);
  const [editContactInfoModalOpen, setEditContactInfoModalOpen] =
    useState(false);
  const [AdjustQuantityModalOpen, setAdjustQuantityModalOpen] = useState(false);
  const [lineItemToAdjust, setLineItemToAdjust] = useState<LineItem>();
  const [updating, setUpdating] = useState(false);

  const input = useInput(orderForm, initialValue);

  const setAddressWrapper = (address: Address) => {
    address.firstName &&
      input.inputs.shippingAddress.inputs.firstName.setValue(address.firstName);
    address.lastName &&
      input.inputs.shippingAddress.inputs.lastName.setValue(address.lastName);
    input.inputs.shippingAddress.inputs.street1.setValue(address.street1);
    input.inputs.shippingAddress.inputs.street2.setValue(address.street2);
    input.inputs.shippingAddress.inputs.city.setValue(address.city);
    input.inputs.shippingAddress.inputs.state.setValue(address.state);
    input.inputs.shippingAddress.inputs.zip.setValue(address.zip);
    input.inputs.shippingAddress.inputs.country.setValue(address.country);
  };

  const setContactInfoWrapper = (contactInfo: ContactInfo) => {
    input.inputs.contactInfo.inputs.email.setValue(contactInfo.email);
    input.inputs.contactInfo.inputs.phone.setValue(contactInfo.phone);
  };

  useEffect(() => {
    if (!open) {
      input.setValue(initialValue);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    input.setValue(initialValue);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (!order) {
      return;
    }
    setInitialValue({
      lineItems: order.shopify.line_items,
      shippingAddress: {
        firstName: order.shopify.shipping_address?.first_name || "",
        lastName: order.shopify.shipping_address?.last_name || "",
        street1: order.shopify.shipping_address?.address1 || "",
        street2: order.shopify.shipping_address?.address2 || "",
        city: order.shopify.shipping_address?.city || "",
        state: order.shopify.shipping_address?.province_code || "",
        zip: order.shopify.shipping_address?.zip || "",
        country: order.shopify.shipping_address?.country_code || "",
      },
      contactInfo: {
        email: order.shopify.email || "",
        phone: order.shopify.phone || "",
      },
    });
  }, [order]);

  const save = useHandler(async () => {
    setUpdating(true);
    const { value } = input;
    // update the shopify order
    const data = {
      shipping_address: {
        first_name: value.shippingAddress.firstName,
        last_name: value.shippingAddress.lastName,
        address1: value.shippingAddress.street1,
        address2: value.shippingAddress.street2,
        city: value.shippingAddress.city,
        province_code: value.shippingAddress.state,
        zip: value.shippingAddress.zip,
        country_code: value.shippingAddress.country,
      },
      email: value.contactInfo.email,
      phone: value.contactInfo.phone,
    };
    const lineItemEdits: lineItemEdit[] = [];
    value.lineItems.forEach((lineItem) => {
      const originalLineItem = order.shopify.line_items.find(
        (item) => item.id === lineItem.id,
      );
      if (originalLineItem && originalLineItem.quantity !== lineItem.quantity) {
        lineItemEdits.push({
          lineItemTitle: lineItem.title,
          lineItemVariantTitle: lineItem.variant_title,
          quantity: lineItem.quantity,
        });
      }
    });

    await updateOrder({
      shopifyOrderId: order.shopify.id,
      data,
      lineItemEdits,
    });
    amplitude.logEvent("update-order", { shopifyOrderId: order.shopify_id });

    onOrdersChange();
    setUpdating(false);
  });

  const handleLineItemRemoval = (lineItem: LineItem) => {
    const lineItems = input.inputs.lineItems.value;
    input.inputs.lineItems.setValue(
      lineItems.map((item) => {
        if (item.id === lineItem.id) {
          return { ...item, quantity: 0 };
        }
        return item;
      }),
    );
  };

  const handleLineItemQuantityChange = (
    lineItem: LineItem,
    quantity: number,
  ) => {
    if (quantity === 0) {
      handleLineItemRemoval(lineItem);
      return;
    }
    const lineItems = input.inputs.lineItems.value;
    input.inputs.lineItems.setValue(
      lineItems.map((item) => {
        if (item.id === lineItem.id) {
          return { ...item, quantity };
        }
        return item;
      }),
    );
  };

  const footer = (
    <div className={editOrderModalCss.footer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        disabled={!input.changed}
        onClick={async () => {
          await save();
          setOpen(false);
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Confirm changes
      </Button>
    </div>
  );

  return (
    <>
      <Modal
        footer={updating ? undefined : footer}
        onClose={() => setOpen(false)}
        open={open}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        title="Edit order"
      >
        {updating ? (
          <div className={editOrderModalCss.animationContainer}>
            <LoadingRedoAnimation />
          </div>
        ) : (
          <div className={editOrderModalCss.modalContent}>
            <div className={editOrderModalCss.orderName}>
              Order {order.shopify.name}
            </div>
            {!disableEditLineItems && (
              <div className={editOrderModalCss.lineItems}>
                {order.shopify.line_items
                  .filter((lineItem) => {
                    const inputLineItem = input.inputs.lineItems.value.find(
                      (item) => item.id === lineItem.id,
                    );
                    return inputLineItem && inputLineItem.quantity > 0;
                  })
                  .map((lineItem, index) => {
                    const inputLineItem = input.inputs.lineItems.value.find(
                      (item) => item.id === lineItem.id,
                    );
                    return (
                      <OrderLineItem
                        editedQuantity={
                          inputLineItem?.quantity !== lineItem.quantity
                            ? inputLineItem?.quantity
                            : undefined
                        }
                        handleLineItemRemoval={handleLineItemRemoval}
                        index={index}
                        key={lineItem.id}
                        lineItem={lineItem}
                        onEditClick={() => {
                          setLineItemToAdjust(inputLineItem);
                          setAdjustQuantityModalOpen(true);
                        }}
                        useFulfillableQuantity
                      />
                    );
                  })}
                {input.inputs.lineItems.value.length === 0 &&
                  initialValue.lineItems.length > 0 && (
                    <div className={editOrderModalCss.warning}>
                      All line items have been removed. Confirming changes will
                      cancel the order.
                    </div>
                  )}
              </div>
            )}
            <ShippingContactInfo
              onContactButtonClick={() => setEditContactInfoModalOpen(true)}
              onShippingButtonClick={() =>
                setEditShippingAddressModalOpen(true)
              }
              order={order}
            />
          </div>
        )}
      </Modal>
      <AddressEditModal
        includeNames
        initial={input.inputs.shippingAddress.value}
        open={editShippingAddressModalOpen}
        setAddress={setAddressWrapper}
        setOpen={setEditShippingAddressModalOpen}
        title="Edit shipping address"
      />
      <ContactInfoModal
        includeName={false}
        initial={input.inputs.contactInfo.value}
        open={editContactInfoModalOpen}
        requirePhone={false}
        setContactInfo={setContactInfoWrapper}
        setOpen={setEditContactInfoModalOpen}
      />
      {lineItemToAdjust && (
        <AdjustQuantityModal
          getLineItemInventory={getLineItemInventory}
          lineItemInfo={{
            ...lineItemToAdjust,
            currentSetQuantity: Math.min(
              lineItemToAdjust.quantity,
              lineItemToAdjust.fulfillable_quantity,
            ),
          }}
          onQuantityChange={handleLineItemQuantityChange}
          open={AdjustQuantityModalOpen}
          setOpen={setAdjustQuantityModalOpen}
        />
      )}
    </>
  );
});

const AdjustQuantityModal = memo(function AdjustQuantityModal({
  open,
  setOpen,
  lineItemInfo,
  onQuantityChange,
  getLineItemInventory,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  lineItemInfo: LineItem & { currentSetQuantity: number };
  onQuantityChange: (lineItem: LineItem, quantity: number) => void;
  getLineItemInventory?: (id: string) => Promise<number>;
}) {
  const [quantity, setQuantity] = useState(lineItemInfo.currentSetQuantity);

  const lineItemInventory = useLoad(async () => {
    if (getLineItemInventory && lineItemInfo.product_id) {
      return getLineItemInventory(lineItemInfo.product_id.toString());
    }
    return undefined;
  }, []);

  useEffect(() => {
    setQuantity(lineItemInfo.currentSetQuantity);
  }, [lineItemInfo]);

  const footer = (
    <div className={editOrderModalCss.footer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        disabled={quantity === lineItemInfo.currentSetQuantity}
        onClick={() => {
          onQuantityChange(lineItemInfo, quantity);
          setOpen(false);
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Done
      </Button>
    </div>
  );

  return (
    <Modal
      footer={footer}
      onClose={() => setOpen(false)}
      open={open}
      paddingAmount={PaddingAmount.NONE}
      showFooterBorder
      size={ModalSize.SMALL}
      title="Adjust quantity"
    >
      <div className={editOrderModalCss.modalContent}>
        <p>
          Adjust the quantity for
          <span className={editOrderModalCss.strong}>
            {lineItemInfo.title}
            {lineItemInfo.variant_title && ` - ${lineItemInfo.variant_title}`}
          </span>
        </p>
        <LabeledInput label="Quantity">
          <TextInput
            max={lineItemInventory.value ?? lineItemInfo.fulfillable_quantity}
            min={0}
            onChange={(val: string) => setQuantity(Number(val))}
            type="number"
            value={quantity.toString()}
          />
        </LabeledInput>
      </div>
    </Modal>
  );
});
