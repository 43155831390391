import { SMSTemplate, SMSTemplateRow } from "@redotech/redo-model/sms-template";
import { RedoMerchantClient } from ".";

/**
 * POST /sms-template
 */
export async function createSMSTemplate(
  client: RedoMerchantClient,
  {
    template,
    signal,
  }: {
    template: SMSTemplate;
    signal?: AbortSignal;
  },
): Promise<SMSTemplateRow> {
  const response = await client.client.post(
    `sms-template`,
    {
      template,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * DELETE /sms-template/:id
 */
export async function deleteSMSTemplate(
  client: RedoMerchantClient,
  {
    templateId,
    signal,
  }: {
    templateId: string;
    signal?: AbortSignal;
  },
): Promise<void> {
  await client.client.delete(`sms-template/${templateId}`, {
    headers: client.authorization(),
    signal,
  });
}

/**
 * GET /sms-template/:id
 */
export async function getSMSTemplate(
  client: RedoMerchantClient,
  {
    id,
    signal,
  }: {
    id: string;
    signal?: AbortSignal;
  },
): Promise<SMSTemplateRow> {
  const response = await client.client.get(`sms-template/${id}`, {
    headers: client.authorization(),
    signal,
  });

  return response.data;
}

/**
 * PUT /sms-template/:id
 */
export async function updateSMSTemplate(
  client: RedoMerchantClient,
  {
    id,
    template,
    signal,
  }: {
    id: string;
    template: SMSTemplate;
    signal?: AbortSignal;
  },
): Promise<SMSTemplateRow> {
  const response = await client.client.put(
    `sms-template/${id}`,
    {
      template,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * GET /team/sms-template
 */
export async function getTeamSMSTemplates(
  client: RedoMerchantClient,
  {
    signal,
    page,
    pageSize,
  }: {
    signal?: AbortSignal;
    page: number;
    pageSize: number;
  },
): Promise<{ data: SMSTemplateRow[] }> {
  const response = await client.client.get("team/sms-template", {
    headers: client.authorization(),
    params: {
      page,
      pageSize,
    },
    signal,
  });
  return {
    data: response.data,
  };
}
