// extracted by mini-css-extract-plugin
export var animationContainer = "_74-0";
export var conversationContainer = "_74-1";
export var detailsContent = "_74-2";
export var message = "_74-3";
export var modalContent = "_74-4";
export var modalFooter = "_74-6";
export var modalHeader = "_74-5";
export var name = "_74-7";
export var optionContainer = "_74-8";
export var optionTitle = "_74-9";
export var pillContainer = "_74-a";
export var selectedConversationsContainer = "_74-b";
export var selectedOptionContainer = "_74-c";