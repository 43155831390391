// extracted by mini-css-extract-plugin
export var acitonButtons = "_6u-0";
export var block = "_6u-1";
export var blockContent = "_6u-7";
export var blockDescription = "_6u-8";
export var blockIcon = "_6u-9";
export var blockTitle = "_6u-6";
export var border = "_6u-a";
export var center = "_6u-k";
export var edge = "_6u-b";
export var edgeLabel = "_6u-c";
export var edgePath = "_6u-d";
export var error = "_6u-e";
export var flowchart = "_6u-f";
export var flowchartContent = "_6u-g";
export var highlighted = "_6u-3";
export var hover = "_6u-4";
export var icon = "_6u-h";
export var left = "_6u-l";
export var navButton = "_6u-i";
export var navButtons = "_6u-j";
export var right = "_6u-m";
export var selected = "_6u-2";
export var small = "_6u-5";