export interface StringFormat<T> {
  read(string: string): T;
  write(value: T): string;
}

export const numberStringFormat: StringFormat<number> = {
  read: Number,
  write: String,
};

export function symbolStringFormat<T extends symbol>(
  symbols: Iterable<T>,
): StringFormat<T> {
  const map = new Map<string, T>();
  for (const symbol of symbols) {
    if (!symbol.description!) {
      throw new Error(`Symbol ${String(symbol)} is missing description`);
    }
    if (map.has(symbol.description)) {
      throw new Error(`Duplicate description ${symbol.description}`);
    }
    map.set(symbol.description, symbol);
  }
  return {
    read(string: string) {
      const result = map.get(string);
      if (!result) {
        throw new Error(`Invalid string ${string}`);
      }
      return result;
    },
    write(symbol: T) {
      if (map.get(symbol.description!) !== symbol) {
        throw new Error(`Invalid symbol ${String(symbol)}`);
      }
      return symbol.description!;
    },
  };
}

export const stringStringFormat: StringFormat<string> = {
  read(string: string) {
    return string;
  },
  write(value: string) {
    return value;
  },
};
