import Drawer from "@mui/material/Drawer";
import {
  CustomerActivityCategory,
  CustomerActivityFilterOptions,
} from "@redotech/redo-model/customer-activity/customer-activity-filters";
import XCloseIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { DateRangeInput, MaybeDate } from "@redotech/redo-web/date-range";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import * as customerActivityCss from "./customer-activity.module.css";

const CustomerActivityFilterDrawerHeader = memo(
  function CustomerActivityFilterDrawerHeader({
    onClose,
  }: {
    onClose: () => void;
  }) {
    return (
      <Flex dir="column" gap="none">
        <Flex align="flex-start" dir="column" gap="xs" p="3xl">
          <Text fontSize="xl" fontWeight="semibold">
            Filters
          </Text>
          <Text fontSize="sm" textColor="tertiary">
            Apply filters to this activity feed.
          </Text>
        </Flex>
        <IconButton
          className={customerActivityCss.drawerCloseButtonContainer}
          onClick={onClose}
        >
          <XCloseIcon />
        </IconButton>
      </Flex>
    );
  },
);

function updatecustomerActivityCategoriesEnabled(
  CustomerActivityCategoryEnabled: CustomerActivityCategoryEnabled,
  customerActivityCategory: CustomerActivityCategory,
  enabled: boolean,
): CustomerActivityCategoryEnabled {
  return {
    ...CustomerActivityCategoryEnabled,
    [customerActivityCategory]: enabled,
  };
}

const CustomerActivityFilterDrawerTypeFilterCheckRow = memo(
  function CustomerActivityFilterDrawerTypeFilterCheckRow({
    label,
    customerActivityCategory,
    customerActivityCategoriesEnabled,
    setcustomerActivityCategoriesEnabled,
  }: {
    label: string;
    customerActivityCategory: CustomerActivityCategory;
    customerActivityCategoriesEnabled: CustomerActivityCategoryEnabled;
    setcustomerActivityCategoriesEnabled: (
      value: CustomerActivityCategoryEnabled,
    ) => void;
  }) {
    return (
      <Flex pl="md">
        <Checkbox
          onChange={(value) =>
            setcustomerActivityCategoriesEnabled(
              updatecustomerActivityCategoriesEnabled(
                customerActivityCategoriesEnabled,
                customerActivityCategory,
                value,
              ),
            )
          }
          value={customerActivityCategoriesEnabled[customerActivityCategory]}
        >
          {label}
        </Checkbox>
      </Flex>
    );
  },
);

const CustomerActivityFilterDrawerTypeFilter = memo(
  function CustomerActivityFilterDrawerTypeFilter({
    customerActivityCategoriesEnabled,
    setcustomerActivityCategoriesEnabled,
  }: {
    customerActivityCategoriesEnabled: CustomerActivityCategoryEnabled;
    setcustomerActivityCategoriesEnabled: (
      value: CustomerActivityCategoryEnabled,
    ) => void;
  }) {
    return (
      <Flex dir="column" gap="none">
        <Text fontSize="sm" pb="lg" textColor="secondary">
          Type
        </Text>
        <Flex dir="column" gap="md">
          <CustomerActivityFilterDrawerTypeFilterCheckRow
            customerActivityCategoriesEnabled={
              customerActivityCategoriesEnabled
            }
            customerActivityCategory={CustomerActivityCategory.CLAIMS}
            label="Claims"
            setcustomerActivityCategoriesEnabled={
              setcustomerActivityCategoriesEnabled
            }
          />
          <CustomerActivityFilterDrawerTypeFilterCheckRow
            customerActivityCategoriesEnabled={
              customerActivityCategoriesEnabled
            }
            customerActivityCategory={CustomerActivityCategory.ORDERS}
            label="Orders"
            setcustomerActivityCategoriesEnabled={
              setcustomerActivityCategoriesEnabled
            }
          />
          <CustomerActivityFilterDrawerTypeFilterCheckRow
            customerActivityCategoriesEnabled={
              customerActivityCategoriesEnabled
            }
            customerActivityCategory={CustomerActivityCategory.RETURNS}
            label="Returns"
            setcustomerActivityCategoriesEnabled={
              setcustomerActivityCategoriesEnabled
            }
          />
          <CustomerActivityFilterDrawerTypeFilterCheckRow
            customerActivityCategoriesEnabled={
              customerActivityCategoriesEnabled
            }
            customerActivityCategory={CustomerActivityCategory.SHOPPING}
            label="Shopping activity"
            setcustomerActivityCategoriesEnabled={
              setcustomerActivityCategoriesEnabled
            }
          />
          <CustomerActivityFilterDrawerTypeFilterCheckRow
            customerActivityCategoriesEnabled={
              customerActivityCategoriesEnabled
            }
            customerActivityCategory={CustomerActivityCategory.SUPPORT}
            label="Support"
            setcustomerActivityCategoriesEnabled={
              setcustomerActivityCategoriesEnabled
            }
          />
        </Flex>
      </Flex>
    );
  },
);

const TODAY = new Date();
const CustomerActivityFilterDrawerTimestampFilter = memo(
  function CustomerActivityFilterDrawerTimestampFilter({
    timestampStart,
    setTimestampStart,
    timestampEnd,
    setTimestampEnd,
  }: {
    timestampStart: MaybeDate;
    setTimestampStart: (value: MaybeDate) => void;
    timestampEnd: MaybeDate;
    setTimestampEnd: (value: MaybeDate) => void;
  }) {
    const multiValueChange = (
      onStartChange: (value: MaybeDate) => void,
      onEndChange: (value: MaybeDate) => void,
    ) => {
      // Cannot use useHandler inside a function that is not a React component
      function handler(v: [MaybeDate, MaybeDate] | null): void {
        if (v && Array.isArray(v)) {
          const [newStart, newEnd] = v;

          onStartChange(newStart);
          onEndChange(newEnd);
        } else {
          onStartChange(null);
          onEndChange(null);
        }
      }
      return handler;
    };

    return (
      <Flex dir="column" gap="none">
        <Text fontSize="sm" pb="lg" textColor="secondary">
          Date range
        </Text>
        <DateRangeInput
          maxDate={TODAY}
          value={[timestampStart ?? null, timestampEnd ?? null]}
          valueChange={multiValueChange(setTimestampStart, setTimestampEnd)}
        />
      </Flex>
    );
  },
);

const CustomerActivityFilterDrawerContent = memo(
  function CustomerActivityFilterDrawerContent({
    customerActivityCategoriesEnabled,
    setcustomerActivityCategoriesEnabled,
    timestampStart,
    setTimestampStart,
    timestampEnd,
    setTimestampEnd,
  }: {
    customerActivityCategoriesEnabled: CustomerActivityCategoryEnabled;
    setcustomerActivityCategoriesEnabled: (
      value: CustomerActivityCategoryEnabled,
    ) => void;
    timestampStart: MaybeDate;
    setTimestampStart: (value: MaybeDate) => void;
    timestampEnd: MaybeDate;
    setTimestampEnd: (value: MaybeDate) => void;
  }) {
    return (
      <Flex dir="column" gap="none" pl="3xl" pr="3xl">
        <Flex dir="column" gap="3xl">
          <CustomerActivityFilterDrawerTypeFilter
            customerActivityCategoriesEnabled={
              customerActivityCategoriesEnabled
            }
            setcustomerActivityCategoriesEnabled={
              setcustomerActivityCategoriesEnabled
            }
          />
          <CustomerActivityFilterDrawerTimestampFilter
            setTimestampEnd={setTimestampEnd}
            setTimestampStart={setTimestampStart}
            timestampEnd={timestampEnd}
            timestampStart={timestampStart}
          />
        </Flex>
      </Flex>
    );
  },
);

type CustomerActivityCategoryEnabled = {
  [key in CustomerActivityCategory]: boolean;
};
const DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED: CustomerActivityCategoryEnabled =
  {
    [CustomerActivityCategory.CLAIMS]: true,
    [CustomerActivityCategory.ORDERS]: true,
    [CustomerActivityCategory.RETURNS]: true,
    [CustomerActivityCategory.SUPPORT]: true,
    [CustomerActivityCategory.SHOPPING]: true,
    [CustomerActivityCategory.MARKETING]: true,
  };
function customerActivityCategoriesToEnabledMap(
  customerActivityCategories: CustomerActivityCategory[] | undefined,
): CustomerActivityCategoryEnabled {
  if (customerActivityCategories) {
    return {
      [CustomerActivityCategory.CLAIMS]: customerActivityCategories.includes(
        CustomerActivityCategory.CLAIMS,
      ),
      [CustomerActivityCategory.ORDERS]: customerActivityCategories.includes(
        CustomerActivityCategory.ORDERS,
      ),
      [CustomerActivityCategory.RETURNS]: customerActivityCategories.includes(
        CustomerActivityCategory.RETURNS,
      ),
      [CustomerActivityCategory.SUPPORT]: customerActivityCategories.includes(
        CustomerActivityCategory.SUPPORT,
      ),
      [CustomerActivityCategory.SHOPPING]: customerActivityCategories.includes(
        CustomerActivityCategory.SHOPPING,
      ),
      [CustomerActivityCategory.MARKETING]: customerActivityCategories.includes(
        CustomerActivityCategory.MARKETING,
      ),
    };
  } else {
    return DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED;
  }
}

export const CustomerActivityFilterDrawer = memo(
  function CustomerActivityFilterDrawer({
    open,
    onClose,
    initialFilterOptions,
  }: {
    open: boolean;
    onClose: (
      customerActivityFilterOptions: CustomerActivityFilterOptions,
    ) => void;
    initialFilterOptions: CustomerActivityFilterOptions | undefined;
  }) {
    const [
      customerActivityCategoriesEnabled,
      setcustomerActivityCategoriesEnabled,
    ] = useState<CustomerActivityCategoryEnabled>(
      customerActivityCategoriesToEnabledMap(
        initialFilterOptions?.activityCategories,
      ),
    );
    const [timestampStart, setTimestampStart] = useState<MaybeDate>(
      initialFilterOptions?.timestampStart ?? null,
    );
    const [timestampEnd, setTimestampEnd] = useState<MaybeDate>(
      initialFilterOptions?.timestampEnd ?? null,
    );

    const getCustomerActivityFilterOptions =
      (): CustomerActivityFilterOptions => {
        const activityCategories = Object.entries(
          customerActivityCategoriesEnabled,
        ).reduce<CustomerActivityCategory[]>((acc, [key, value]) => {
          if (value) {
            acc.push(key as CustomerActivityCategory);
          }
          return acc;
        }, []);

        return {
          activityCategories:
            activityCategories.length > 0 ? activityCategories : undefined,
          timestampStart: timestampStart ?? undefined,
          timestampEnd: timestampEnd ?? undefined,
        };
      };

    const handleClose = () => {
      const customerEventFilterOptions = getCustomerActivityFilterOptions();
      if (customerEventFilterOptions.activityCategories === undefined) {
        // reset checkboxes to all be selected if none are selected when closing the drawer
        setcustomerActivityCategoriesEnabled(
          DEFAULT_CUSTOMER_ACTIVITY_CATEGORIES_ENABLED,
        );
      }
      onClose(customerEventFilterOptions);
    };

    return (
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          className: customerActivityCss.customerActivityFilterDrawer,
        }}
      >
        <CustomerActivityFilterDrawerHeader onClose={handleClose} />
        <CustomerActivityFilterDrawerContent
          customerActivityCategoriesEnabled={customerActivityCategoriesEnabled}
          setcustomerActivityCategoriesEnabled={
            setcustomerActivityCategoriesEnabled
          }
          setTimestampEnd={setTimestampEnd}
          setTimestampStart={setTimestampStart}
          timestampEnd={timestampEnd}
          timestampStart={timestampStart}
        />
      </Drawer>
    );
  },
);
