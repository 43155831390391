import { useMounted } from "@redotech/react-util/util";
import { Key, ReactNode, Ref, createContext, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition";
import { OverlapTransition, OverlapTransitionState } from "./transition";

export interface OutletInfo {
  key: Key;
  node: ReactNode;
  render: OutletRender;
}

export interface OutletRender {
  (ref: Ref<HTMLElement>, children: ReactNode): ReactNode | ReactNode[];
}

export function OutletCssTransition({
  classNames,
  timeout,
  outlet,
  key_,
  children,
}: {
  classNames?: string | CSSTransitionClassNames | undefined;
  outlet: ReactNode;
  key_: Key;
  timeout: number;
  children: OutletRender;
}) {
  return (
    <OverlapTransition key_={key_}>
      {(_, state, remove) => (
        <OutletCssTransitionItem
          classNames={classNames}
          outlet={outlet}
          remove={remove}
          state={state}
          timeout={timeout}
        >
          {children}
        </OutletCssTransitionItem>
      )}
    </OverlapTransition>
  );
}

export const OverlapTransitionStateContext =
  createContext<OverlapTransitionState>(OverlapTransitionState.APPEAR);

function OutletCssTransitionItem({
  classNames,
  state,
  timeout,
  remove,
  children,
  outlet,
}: {
  classNames?: string | CSSTransitionClassNames | undefined;
  state: OverlapTransitionState;
  timeout: number;
  remove: () => void;
  outlet: ReactNode;
  children: OutletRender;
}) {
  const mounted = useMounted();

  const outletRef = useRef<ReactNode>();
  if (state === OverlapTransitionState.EXIT) {
    outlet = outletRef.current!;
  } else {
    outletRef.current = outlet;
  }

  const nodeRef = useRef<any>();

  return (
    <CSSTransition
      classNames={classNames}
      in={
        state === OverlapTransitionState.APPEAR ||
        (mounted && state === OverlapTransitionState.ENTER)
      }
      nodeRef={nodeRef}
      onExited={remove}
      timeout={timeout}
      unmountOnExit
    >
      <OverlapTransitionStateContext.Provider value={state}>
        {children(nodeRef, outlet)}
      </OverlapTransitionStateContext.Provider>
    </CSSTransition>
  );
}
