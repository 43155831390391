import { createContext, memo, ReactNode, useState } from "react";

export const ConversationSendStateContext = createContext<
  Set<string> | undefined
>(undefined);

export interface SetConversationSendStateContext {
  (conversationId: string, sending: boolean): void;
}

export const SetConversationSendStateContext = createContext<
  SetConversationSendStateContext | undefined
>(undefined);

export const ConversationSendStateContextProvider = memo(
  function ConversationSendStateContextProvider({
    children,
  }: {
    children: ReactNode | ReactNode[];
  }) {
    const [conversationsSending, setConversationsSending] = useState<
      Set<string>
    >(new Set());

    const setConversationSendState = (
      conversationId: string,
      sending: boolean,
    ) => {
      setConversationsSending((prevConversationsSending) => {
        const updatedConversations = new Set(prevConversationsSending);
        if (sending) {
          updatedConversations.add(conversationId);
        } else {
          updatedConversations.delete(conversationId);
        }
        return updatedConversations;
      });
    };

    return (
      <ConversationSendStateContext.Provider value={conversationsSending}>
        <SetConversationSendStateContext.Provider
          value={setConversationSendState}
        >
          {children}
        </SetConversationSendStateContext.Provider>
      </ConversationSendStateContext.Provider>
    );
  },
);
