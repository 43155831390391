import { getRecurringPlanPricing } from "../../shopify-subscription/shopify-subscription";
import { Team } from "../../team";

export function shopifySubscriptionMatchesAiSubscription(team: Team): boolean {
  if (
    !team.settings.support?.ai?.enabled ||
    !team.settings.support.billing?.ai?.planName
  ) {
    return true;
  }

  const shopifySubscription = team.settings.shopifySubscription?.[0];

  const recurringComponent = getRecurringPlanPricing(shopifySubscription);

  const monthlyAiPlanPrice =
    (team.settings.support.billing.ai?.pricePerMonth || 0) / 100;
  const recurringPlanPrice = Number(recurringComponent?.price.amount || "0");

  if (monthlyAiPlanPrice === 0 && !recurringComponent) {
    return true;
  }

  return !!recurringComponent && monthlyAiPlanPrice === recurringPlanPrice;
}
