// extracted by mini-css-extract-plugin
export var container = "_5x-0";
export var content = "_5x-3";
export var customerTitle = "_5x-4";
export var empty = "_5x-5";
export var gray = "_5x-6";
export var header = "_5x-7";
export var info = "_5x-8";
export var loading = "_5x-2";
export var loadingCard = "_5x-9";
export var oneLine = "_5x-a";
export var personName = "_5x-e";
export var pillContainer = "_5x-b";
export var selected = "_5x-1";
export var text = "_5x-c";
export var twoLines = "_5x-d";
export var unread = "_5x-f";
export var unreadIndicator = "_5x-g";