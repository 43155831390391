import { z } from "zod";

export enum InventoryPolicy {
  CONTINUE = "continue",
  DENY = "deny",
}
export const ProductImage = z.object({
  url: z.string(),
  alt: z.string(),
});

export const VariantInfo = z.object({
  id: z.string(),
  price: z.string(),
  inventoryPolicy: z.nativeEnum(InventoryPolicy),
  inventoryQuantity: z.number(),
  inventoryTracked: z.boolean(),
  sku: z.string(),
  name: z.string(),
  quantityAvailable: z.number(),
  image: ProductImage,
  productId: z.string(),
  productName: z.string(),
});

export type VariantInfo = z.infer<typeof VariantInfo>;

export const ProductInfo = z.object({
  id: z.string(),
  name: z.string(),
  image: ProductImage,
  description: z.string(),
  tags: z.array(z.string()),
  variants: z.array(VariantInfo),
});

export type ProductInfo = z.infer<typeof ProductInfo>;
