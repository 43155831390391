// extracted by mini-css-extract-plugin
export var box = "_2h-0";
export var check = "_2h-1";
export var checkboxButton = "_2h-6";
export var checkboxGroup = "_2h-5";
export var disabled = "_2h-7";
export var ghost = "_2h-9";
export var input = "_2h-2";
export var label = "_2h-4";
export var normal = "_2h-8";
export var uncheck = "_2h-3";