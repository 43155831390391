import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { Dispatch, memo, SetStateAction, useEffect, useState } from "react";
import {
  EmailDraftState,
  InDraftingEmailState,
} from "../conversation-email-view/email-draft-state";
import {
  EmailListChipInput,
  recipientDescriptions,
} from "./email-list-chip-input";

export const EditRecipientsModal = memo(function EditRecipientsModal({
  conversation,
  isOpen,
  onClose,
  emailDraft,
  setEmailDraft,
}: {
  conversation: ExpandedConversation;
  isOpen: boolean;
  onClose(): void;
  emailDraft: InDraftingEmailState;
  setEmailDraft: Dispatch<SetStateAction<EmailDraftState>>;
}) {
  const initTo = () =>
    emailDraft.draft.recipientsInfo.to?.map((ei) => ei.email) || [];
  const initCC = () =>
    emailDraft.draft.recipientsInfo.cc?.map((ei) => ei.email) || [];
  const initBCC = () =>
    emailDraft.draft.recipientsInfo.bcc?.map((ei) => ei.email) || [];

  const [currentToEmails, setCurrentToEmails] =
    useState<readonly string[]>(initTo());
  const [currentCcEmails, setCurrentCcEmails] =
    useState<readonly string[]>(initCC());
  const [currentBccEmails, setCurrentBccEmails] =
    useState<readonly string[]>(initBCC());

  const initRecipients = () => {
    setCurrentToEmails(initTo());
    setCurrentCcEmails(initCC());
    setCurrentBccEmails(initBCC());
  };

  useEffect(initRecipients, [conversation._id, emailDraft]);

  async function submit() {
    onClose();
    if (emailDraft.status !== "drafting") {
      return;
    }
    setEmailDraft({
      ...emailDraft,
      draft: {
        ...emailDraft.draft,
        recipientsInfo: {
          ...emailDraft.draft.recipientsInfo,
          to: currentToEmails.map((email) => ({ email })),
          cc: currentCcEmails.map((email) => ({ email })),
          bcc: currentBccEmails.map((email) => ({ email })),
        },
      },
    });
  }

  const onCancel = () => {
    initRecipients();
    onClose();
  };

  const phrases = {
    modalTitle: "Edit Recipients",
    modalSubtitle: "These settings will be applied to this email.",
    cancel: "Cancel",
    submit: "Save changes",
  };

  return (
    <RedoModal
      isOpen={isOpen}
      onModalCloseRequested={onCancel}
      primaryButton={{ text: phrases.submit, onClick: submit }}
      secondaryButton={{ text: phrases.cancel, onClick: onCancel }}
      subtitle={phrases.modalSubtitle}
      title={phrases.modalTitle}
    >
      <Flex dir="column" gap="md">
        <EmailListChipInput
          emailsToShow={currentToEmails}
          infoText={recipientDescriptions.toInfo}
          setEmails={setCurrentToEmails}
          title={recipientDescriptions.toTitle}
        />
        <EmailListChipInput
          emailsToShow={currentCcEmails}
          infoText={recipientDescriptions.ccInfo}
          setEmails={setCurrentCcEmails}
          title={recipientDescriptions.ccTitle}
        />
        <EmailListChipInput
          emailsToShow={currentBccEmails}
          infoText={recipientDescriptions.bccInfo}
          setEmails={setCurrentBccEmails}
          title={recipientDescriptions.bccTitle}
        />
      </Flex>
    </RedoModal>
  );
});
