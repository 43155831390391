import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { ConditionType } from "../condition";

export const TOTAL_SPENT_GREATER_THAN: ConditionType<
  string,
  ModelCondition.TotalSpentGreaterThan
> = {
  name: "Total spent greater than",
  description(state) {
    return `More than $${+state} spent`;
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Minimum order total">
        <TextInput
          min={0}
          onChange={setState}
          prefix="$"
          readonly={disabled}
          theme={InputTheme.FORM}
          type="number"
          value={state}
        />
      </LabeledInput>
    );
  },
  empty: String(0),
  fromModel(model) {
    return model.totalSpent;
  },
  toModel(state) {
    return { type: ModelCondition.TOTAL_SPENT_GREATER_THAN, totalSpent: state };
  },
  valid(state) {
    return state !== undefined;
  },
};
