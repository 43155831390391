// extracted by mini-css-extract-plugin
export var bubbleContent = "_5i-0";
export var container = "_5i-e";
export var dark = "_5i-3";
export var dot = "_5i-8";
export var firstDot = "_5i-a";
export var left = "_5i-4";
export var light = "_5i-2";
export var lightCorrect = "_5i-1";
export var loadingBubble = "_5i-6";
export var loadingDot = "_5i-d";
export var loadingDotContainer = "_5i-7";
export var moveUpDown = "_5i-9";
export var right = "_5i-5";
export var secondDot = "_5i-b";
export var thirdDot = "_5i-c";