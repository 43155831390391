// extracted by mini-css-extract-plugin
export var defaultWrapper = "_7g-3";
export var downloadButton = "_7g-4";
export var fileContainer = "_7g-7";
export var fileInfo = "_7g-5";
export var fileInfoWidthRestricted = "_7g-6";
export var fileType = "_7g-1";
export var fileTypeName = "_7g-2";
export var image = "_7g-0";
export var link = "_7g-9";
export var overflowEllipsis = "_7g-8";