import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";

const orderBreadcrumb = breadcrumbSlot();

export const orderRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Orders" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { element: <Page /> };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Orders } = await import("./orders");
          return {
            element: (
              <Orders
                filterParams={(filters) => ({
                  dateRange: filters.dateRange,
                  shipmentStatus: filters.shipmentStatus,
                })}
              />
            ),
          };
        },
      },
      {
        path: "labels",
        async lazy() {
          const { LabelsLoad } = await import("./outbound-labels");
          return {
            element: (
              <LabelsLoad
                filterParams={(filters) => ({
                  printedLabels: filters.printedLabels,
                })}
              />
            ),
          };
        },
      },
      {
        path: ":orderId",
        handle: { breadcrumb: orderBreadcrumb },
        async lazy() {
          const { Order } = await import("./order");
          return { element: <Order breadcrumb={orderBreadcrumb} /> };
        },
      },
    ],
  },
];
