import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import {
  ReturnOptionMethod,
  StoreCreditType,
} from "@redotech/redo-model/return-flow/return-option";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import RefreshIcon from "@redotech/redo-web/icon-old/refresh.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Switch } from "@redotech/redo-web/switch";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import { getModelReturnOptionMethod, returnOptionMethods } from "./return";
import { StepType, StepTypeDetailsProps } from "./step";

function returnOptionLabels(
  value: ReturnOptionMethod["type"] | undefined,
): string | undefined {
  switch (value) {
    case ReturnOptionMethod.STORE_CREDIT:
      return "Store credit";
    case ReturnOptionMethod.EXCHANGE:
      return "Same Item Exchange";
    case ReturnOptionMethod.REFUND:
      return "Refund";
  }
  return undefined;
}

export interface SubmitClaimOption {
  method: ReturnOptionMethod["type"];
  creditType: StoreCreditType;
  requireSendBack: boolean;
  chargeCustomerForNewOrderShipping?: boolean;
}

export interface State {
  option: SubmitClaimOption;
  customTitle?: string;
}

function valid(state: State) {
  return !!state.option.method;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handleActionChange = useHandler((method: ReturnOptionMethod["type"]) =>
    setState((state) =>
      produce(state, (state) => {
        state.option.method = method;
      }),
    ),
  );
  const handleRequireSendBack = useHandler((value: boolean) =>
    setState((state) =>
      produce(state, (state) => {
        state.option.requireSendBack = value;
      }),
    ),
  );
  const handleExchangeShipping = useHandler((value: boolean) =>
    setState((state) =>
      produce(state, (state) => {
        state.option.chargeCustomerForNewOrderShipping = value;
      }),
    ),
  );
  return (
    <>
      <LabeledInput label="Compensation Method">
        <SelectDropdown
          disabled={!canEditSettings}
          options={returnOptionMethods}
          value={state.option.method}
          valueChange={handleActionChange}
        >
          {returnOptionLabels}
        </SelectDropdown>
      </LabeledInput>
      <LabeledInput label="Require customer to send back item?">
        <Switch
          disabled={!canEditSettings}
          onChange={handleRequireSendBack}
          value={state.option.requireSendBack || false}
        />
      </LabeledInput>
      {state.option.method === ReturnOptionMethod.EXCHANGE && (
        <LabeledInput
          description="If enabled, customer will be charged for outgoing shipping of exchange orders."
          label="Charge customer for new order shipping"
        >
          <Switch
            disabled={!canEditSettings}
            onChange={handleExchangeShipping}
            value={state.option.chargeCustomerForNewOrderShipping || false}
          />
        </LabeledInput>
      )}
    </>
  );
});

export const SUBMIT_CLAIM: StepType<State, ModelStep.SubmitClaim> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Submit Claim",
  valid,
  Icon: RefreshIcon,
  description(state: State) {
    return returnOptionLabels(state.option.method) || "";
  },
  downstream() {
    return [];
  },
  empty: {
    option: {
      method: ReturnOptionMethod.EXCHANGE,
      creditType: StoreCreditType.GIFT_CARD,
      requireSendBack: false,
      chargeCustomerForNewOrderShipping: false,
    },
  },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  toModel(state: State): ModelStep.SubmitClaim {
    return {
      customTitle: state.customTitle,
      type: ModelStep.SUBMIT_CLAIM,
      option: {
        method: getModelReturnOptionMethod(
          state.option.method,
          state.option.creditType,
        ),
        requireSendBack: state.option.requireSendBack,
        chargeCustomerForNewOrderShipping:
          state.option.chargeCustomerForNewOrderShipping || false,
      },
    };
  },
  fromModel(model: ModelStep.SubmitClaim) {
    return {
      customTitle: model.customTitle,
      option: {
        method: model.option.method.type,
        creditType:
          "creditType" in model.option.method
            ? model.option.method.creditType
            : StoreCreditType.GIFT_CARD,
        requireSendBack: model.option.requireSendBack,
        chargeCustomerForNewOrderShipping:
          model.option.chargeCustomerForNewOrderShipping || false,
      },
    };
  },
};
