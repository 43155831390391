import { object, string, z } from "zod";

export const polishMessageSchema = {
  input: object({
    draftId: string(),
    conversationId: string(),
  }),
  output: object({
    polishedResponse: string(),
  }),
};

export type PolishMessageInput = z.infer<typeof polishMessageSchema.input>;
export type PolishMessageOutput = z.infer<typeof polishMessageSchema.output>;
