// extracted by mini-css-extract-plugin
export var alignCenter = "_4y-b";
export var buttonContent = "_4y-0";
export var cardList = "_4y-1";
export var centerContent = "_4y-2";
export var chevron = "_4y-3";
export var closePanelButton = "_4y-i";
export var conversationDetailContainer = "_4y-4";
export var detailWrapper = "_4y-5";
export var dropdownButton = "_4y-c";
export var dropdownTitle = "_4y-7";
export var hidden = "_4y-8";
export var icon = "_4y-9";
export var panel = "_4y-g";
export var portalButtonsWrapper = "_4y-a";
export var right = "_4y-h";
export var tableSummaryContainer = "_4y-6";
export var tableSummaryHeader = "_4y-e";
export var threeDotsButton = "_4y-d";
export var wide = "_4y-f";