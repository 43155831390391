import { CompleteOrderFulfillmentStatus } from "@redotech/redo-model/shopify-order";
import { memo } from "react";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "./arbiter-components/badge/redo-badge";

export const OrderStatusPill = memo(function OrderStatusPill({
  orderStatus,
}: {
  orderStatus: CompleteOrderFulfillmentStatus;
}) {
  const getBadgeColor = (): RedoBadgeColor => {
    if (orderStatus === CompleteOrderFulfillmentStatus.CANCELED) {
      return RedoBadgeColor.ERROR;
    } else if (orderStatus === CompleteOrderFulfillmentStatus.FULFILLED) {
      return RedoBadgeColor.SUCCESS;
    } else if (orderStatus === CompleteOrderFulfillmentStatus.UNFULFILLED) {
      return RedoBadgeColor.WARNING;
    } else if (orderStatus === CompleteOrderFulfillmentStatus.PARTIAL) {
      return RedoBadgeColor.WARNING;
    } else {
      return RedoBadgeColor.GRAY;
    }
  };

  const getBadgeText = (): string => {
    return orderStatus.displayValue;
  };

  return (
    <RedoBadge
      color={getBadgeColor()}
      size={RedoBadgeSize.SMALL}
      text={getBadgeText()}
    />
  );
});
