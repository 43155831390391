export { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

/**
 * The only notable difference between Mongo and JSON is that mongoose supports dates.
 * This helper function makes it possible to support both sources in one schema.
 */
export const dateOrStringAsDate = z
  .union([z.date(), z.string()])
  .transform((value) => (typeof value === "string" ? new Date(value) : value));
