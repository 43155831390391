import {
  FrontendTeamNoticeEvent,
  TeamNoticeEventType,
} from "@redotech/redo-model/team-notifications/team-notifications";
import { assertNever } from "@redotech/util/type";

/*
 * The way our long polling works, we expect to get a CONNECTION_MADE right after a CONNECTION_ABORTED event
 * We do not need to refresh in both cases. Let's just refresh in the CONNECTION_MADE case.
 */
export function maybeRunConversationsRefresh(
  message: FrontendTeamNoticeEvent | null,
  refreshCallback: () => void,
) {
  if (!message) {
    refreshCallback();
    return;
  }

  try {
    switch (message.type) {
      case TeamNoticeEventType.ERROR_EVENT:
      case TeamNoticeEventType.CONNECTION_ABORTED:
      case TeamNoticeEventType.CALL_CONNECTED:
      case TeamNoticeEventType.USER_AVAILABILITY_UPDATED:
      case TeamNoticeEventType.END_RINGING:
        break;
      case TeamNoticeEventType.CONNECTION_MADE:
      case TeamNoticeEventType.RELOAD_ALL_CONVERSATIONS:
      case TeamNoticeEventType.SOME_CONVERSATIONS_UPDATED:
        refreshCallback();
        break;
      default:
        assertNever(message);
    }
  } catch (err) {
    console.error(err);
    refreshCallback();
  }
}
