import { scrolled as scrolled_ } from "@redotech/web-util/scroll";
import { useLayoutEffect, useState } from "react";
import { useEventListener } from "./event";
import { useHandler } from "./hook";

export function useScrolled(
  element: HTMLElement | null,
  allowance: number = 1,
): { top: boolean; bottom: boolean } {
  const [scrolled, setScrolled] = useState({ top: true, bottom: true });

  const refreshScrolled = useHandler(() => {
    const newScrolled = element
      ? scrolled_(element, allowance)
      : { top: true, bottom: true };
    setScrolled((scrolled) =>
      scrolled.bottom === newScrolled.bottom && scrolled.top === newScrolled.top
        ? scrolled
        : newScrolled,
    );
  });

  useLayoutEffect(() => {
    if (!element) {
      return;
    }
    const observer = new ResizeObserver(refreshScrolled);
    observer.observe(element);
    return () => observer.disconnect();
  }, [element, refreshScrolled]);

  useEventListener(
    element === document.documentElement ? window : element,
    "scroll",
    refreshScrolled,
  );
  useLayoutEffect(refreshScrolled);

  return scrolled;
}
