import { array, nativeEnum, object, string, z } from "zod";
import { ResponseLength } from "../concierge-conversation/concierge-conversation-message-definition";

export const ConciergeBehaviorSettingsParser = object({
  avatarUrl: string().max(500).optional(),
  name: string().max(100).optional(),
  toneOfVoice: string().max(500).optional(),
  writingExamples: array(string().max(1000)).optional(),
  brandDescription: string().max(1000).optional(),
  customerDescription: string().max(1000).optional(),
  outputLength: nativeEnum(ResponseLength).optional(),
});

export type ConciergeBehaviorSettings = z.infer<
  typeof ConciergeBehaviorSettingsParser
>;
