import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import MailIcon from "@redotech/redo-web/icon-old/mail.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { produce } from "immer";
import Quill from "quill";
import { memo, useContext, useRef } from "react";
import { UserContext } from "../../app/user";
import { ClientUploader } from "../../client/upload";
import {
  StepDownstream,
  StepId,
  StepType,
  StepTypeDetailsProps,
} from "../return-flow/step";
import { containsImg, StepRichTextEditor } from "./step-rich-text";

export interface State {
  next: StepId | undefined;
  message: string;
  messageHtml?: string;
  attempted?: boolean;
  customTitle?: string;
}

function valid(state: State) {
  return (
    state.next !== undefined &&
    !!state.message &&
    !containsImg(state.messageHtml)
  );
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

  const quillRef = useRef<Quill | undefined>(undefined);

  const handleMessage = useHandler(() => {
    if (!quillRef.current) {
      return;
    }
    let message = quillRef.current.getText();
    let messageHtml: string | undefined = quillRef.current.getSemanticHTML();
    if (message === "\n") {
      message = "";
    }
    if (messageHtml === "<p></p>") {
      messageHtml = undefined;
    }

    if (messageHtml !== state.messageHtml || message !== state.message) {
      setState((state) => ({ ...state, message, messageHtml }));
    }
  });

  return (
    <ClientUploader>
      <LabeledInput
        errors={containsImg(state.messageHtml) ? ["Cannot contain images"] : []}
        label="Message to customer"
      >
        <StepRichTextEditor
          htmlValue={state.messageHtml}
          onTextChange={handleMessage}
          quillRef={quillRef}
          readOnly={!canEditSettings}
          textValue={state.message}
        />
      </LabeledInput>
      <LabeledInput errors={!state.next ? ["Required"] : []} label="Next">
        {stepSelect({
          value: state.next,
          valueChange: (next) => setState({ ...state, next }),
        })}
      </LabeledInput>
    </ClientUploader>
  );
});

export const SEND_MESSAGE: StepType<State, ModelStep.SendMessage> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Send message",
  valid,
  Icon: MailIcon,
  description(state: State) {
    return state.message;
  },
  downstream(state: State) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  layout() {
    return BlockLayout.FULL;
  },
  empty: {
    message: "",
    next: undefined,
    messageHtml: undefined,
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  toModel(state: State, id: (id: StepId) => number): ModelStep.SendMessage {
    return {
      customTitle: state.customTitle,
      type: ModelStep.SEND_MESSAGE,
      next: id(state.next!),
      message: state.message,
      messageHtml: state.messageHtml,
    };
  },
  fromModel(model: ModelStep.SendMessage, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      next: id(model.next),
      message: model.message,
      messageHtml: model.messageHtml,
    };
  },
};
