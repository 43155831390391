import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { GetUser } from "@redotech/redo-model/user";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as activeUsersTypingTextCss from "./active-users-typing-text.module.css";
export const formatTypingNames = (
  typing: Record<string, Date>,
  user: GetUser | undefined,
  conversation: ExpandedConversation,
) => {
  const peopleTyping = Object.keys(typing).filter(
    (name) => name !== user?.name,
  );
  if (peopleTyping.length > 1) {
    return "Multiple people are typing...";
  } else if (peopleTyping.length === 1) {
    const name = peopleTyping[0];
    if (name === "Customer") {
      const customerName = conversation?.customer?.name || "Customer";
      return `${customerName} is typing...`;
    } else {
      return `${name} is typing...`;
    }
  } else {
    return "";
  }
};

export const ActiveUsersTypingText = ({
  typing,
  user,
  activeConversation,
}: {
  typing: Record<string, Date>;
  user: GetUser | undefined;
  activeConversation: ExpandedConversation;
}) => {
  return (
    <Flex className={activeUsersTypingTextCss.typingIndicator}>
      <Text fontSize="xs" fontWeight="regular" textColor="tertiary">
        {formatTypingNames(typing, user, activeConversation)}
      </Text>
    </Flex>
  );
};
