/**
 * @see {@link https://www.abeautifulsite.net/posts/opening-a-new-window-after-an-async-operation/}
 */
export function newWindow(fn: () => Promise<string>) {
  const window = open()!;
  (async () => {
    try {
      window.location.href = await fn();
    } catch {
      window.close();
    }
  })();
}
