import { genericMemo } from "@redotech/react-util/component";
import {
  TextMatchExpression,
  textMatchOperators,
} from "@redotech/redo-model/advanced-flow/expressions/text-expression";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { DeepPartial } from "@redotech/util/type";
import { useEffect } from "react";
import { camelToTitleCase } from ".";

export const TextInspector = genericMemo(function TextInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: "Text" | "Url";
  expression: DeepPartial<TextMatchExpression>;
  setExpression: (expression: DeepPartial<TextMatchExpression>) => void;
}) {
  useEffect(() => {
    if (!expression.type) {
      setExpression({ ...expression, type: "text_match" });
    }
    if (!expression.operator) {
      setExpression({ ...expression, operator: "equals" });
    }
  }, [expression]);

  return (
    <>
      <LabeledInput label="Text match type">
        <SelectDropdown
          options={textMatchOperators}
          value={expression.operator}
          valueChange={(operator) =>
            operator ? setExpression({ ...expression, operator }) : undefined
          }
        >
          {(value) => camelToTitleCase(value)}
        </SelectDropdown>
      </LabeledInput>

      <LabeledInput label="Values">
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          trimWhitespace
          value={(expression.matchValues?.filter(Boolean) ?? []) as string[]}
          valueChange={(matchValues) =>
            matchValues
              ? setExpression({
                  ...expression,
                  matchValues: matchValues.filter(Boolean),
                })
              : undefined
          }
        />
      </LabeledInput>
    </>
  );
});
