import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { textClasses } from "../../theme/typography";
import { RedoBadge } from "../badge/redo-badge";
import * as redoTextInputCss from "./base-redo-input.module.css";
import {
  RedoInputSize,
  RedoInputState,
  sizeStyles,
  sizeToDescriptorTextProps,
  sizeToInputTextProps,
  sizeToPx,
} from "./base-redo-text-input";
import { BaseRedoInputInfoTooltip } from "./base-redo-text-input-info-tooltip";
import * as redoTagInputFieldCss from "./redo-tag-input-field.module.css";
import { RedoTextInputProps } from "./redo-text-input";

/**
 * @param inputTypeHint -- if you have one of these types of input attributes,
 * browser autofill and other helpful features will work better if you define it.
 *
 * @param required -- when used in a form, will prevent the form from submitting if the input is empty.
 *
 * @param dangerousStyleThatShouldOnlyBeUsedForMerchantBranding -- this prop should only be defined
 * when we want the input to follow a merchant's branding. Do not use it to style this component for a Redo use case.
 */

export const RedoTagInputField = memo(
  forwardRef(function RedoTagInputField(
    {
      size = RedoInputSize.SMALL,
      value,
      placeholder,
      setValue,
      tags,
      infoTooltip,
      inputTypeHint,
      label,
      description,
      state = RedoInputState.DEFAULT,
      className,
      required,
      maxLength,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
      name,
      autoCompleteDisabled,
    }: Omit<RedoTextInputProps, "IconLeading"> & {
      tags: ReturnType<typeof RedoBadge>[];
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    function handleInputContentChange(
      event: React.ChangeEvent<HTMLInputElement>,
    ) {
      setValue(event.target.value);
    }

    const descriptorFontSize = sizeToDescriptorTextProps[size];

    const error = state === RedoInputState.ERROR;
    const disabled = state === RedoInputState.DISABLED;
    const readonly = state === RedoInputState.READONLY;

    // Needed for readonly styles
    const [measureBox, setMeasureBox] = useState<HTMLDivElement | null>(null);
    const [textWidth, setTextWidth] = useState<number>(0);

    function recalculateInputWidth() {
      requestAnimationFrame(() => {
        if (!measureBox) {
          return;
        }
        const width = measureBox.offsetWidth;
        setTextWidth(width);
      });
    }

    useEffect(() => {
      if (readonly) {
        recalculateInputWidth();
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeholder, value, measureBox, readonly]);

    // create a unique html ID for the input element
    const inputId = useMemo(
      () => `redo-tag-input-field-${Math.random().toString(36).substr(2, 9)}`,
      [],
    );

    return (
      <>
        <Flex dir="column" gap="sm" grow={1} wrap="wrap">
          {label && <Text fontSize={descriptorFontSize}>{label}</Text>}
          <Flex
            align="center"
            as="label"
            bgColor="primary"
            className={classNames(
              redoTagInputFieldCss.growableInput,
              disabled && redoTextInputCss.disabled,
              redoTextInputCss.inputWrapper,
              error && redoTextInputCss.error,
              sizeStyles[size],
              readonly && redoTextInputCss.readonly,
              className,
            )}
            dir="row"
            grow="1"
            justify="flex-start"
            labelProps={{ htmlFor: inputId }}
            px={readonly ? undefined : sizeToPx[size]}
            style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
          >
            <Flex dir="row" gap="xs" grow={1} p="xs" wrap="wrap">
              <>{tags}</>
              <input
                autoComplete={autoCompleteDisabled ? "off" : undefined}
                className={classNames(
                  textClasses(sizeToInputTextProps[size]),
                  redoTextInputCss.input,
                )}
                disabled={disabled || readonly}
                id={inputId}
                maxLength={maxLength}
                name={name}
                onChange={handleInputContentChange}
                placeholder={placeholder}
                ref={ref}
                required={required}
                style={readonly ? { width: textWidth + "px" } : {}}
                type={inputTypeHint}
                value={value}
              />
            </Flex>
            {infoTooltip && (
              <BaseRedoInputInfoTooltip
                infoTooltip={infoTooltip}
                size={size}
                state={state}
              />
            )}
          </Flex>

          {description && (
            <Text
              fontSize={descriptorFontSize}
              textColor={error ? "error" : "tertiary"}
            >
              {description}
            </Text>
          )}
        </Flex>
        <Flex className={redoTextInputCss.measureBox}>
          <Text
            className={classNames(textClasses(sizeToInputTextProps[size]))}
            ref={setMeasureBox}
          >
            {value || placeholder}
          </Text>
        </Flex>
      </>
    );
  }),
);
