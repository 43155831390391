import { SpacingValue } from "./box";

// These multipliers should exactly match the ones in spacing-source.scss
const multipliers: { [key in SpacingValue]: number } = {
  none: 0,
  xxs: 0.5,
  xs: 1,
  sm: 1.5,
  md: 2,
  lg: 3,
  xl: 4,
  "2xl": 5,
  "3xl": 6,
  "4xl": 8,
  "5xl": 10,
  "6xl": 12,
  "7xl": 16,
  "8xl": 20,
  "9xl": 24,
  "10xl": 32,
  "11xl": 40,
};

const spacingUnitPx = 4; // Taken from arbiter
/** Map from SpacingValue to pixel distance. */
export const spacingPx: (spacing: SpacingValue) => number = (spacing) =>
  spacingUnitPx * multipliers[spacing];

const spacingUnitRem = 0.25; // Taken from arbiter
/** Map from SpacingValue to rem distance */
export const spacingRem: (spacing: SpacingValue) => number = (spacing) =>
  spacingUnitRem * multipliers[spacing];
