// extracted by mini-css-extract-plugin
export var alternate = "_2r-h";
export var black = "_2r-3";
export var blue = "_2r-d";
export var blueLight = "_2r-c";
export var brand = "_2r-7";
export var error = "_2r-9";
export var gray = "_2r-6";
export var grayBlue = "_2r-b";
export var iconWrapper = "_2r-4";
export var indigo = "_2r-e";
export var orange = "_2r-g";
export var pink = "_2r-f";
export var primary = "_2r-0";
export var purple = "_2r-8";
export var success = "_2r-5";
export var warning = "_2r-a";
export var white = "_2r-1";
export var whiteSuccess = "_2r-2";