import { discriminatedUnion, literal, nativeEnum, object, z } from "zod";
import { zodToJsonSchema } from "zod-to-json-schema";

import {
  ActionType,
  CONCIERGE_CONVERSATION_CONSTANTS as C,
  ContentType,
  IntentType,
} from "./concierge-conversation-message-definition";

export const CartActionableContentSchema = object({
  [C.CONTENT_TYPE_KEY]: literal(ContentType.CART_ITEMS).describe(
    "This represents what entity the user can perform an action on.",
  ),
  [C.ACTION_KEY]: literal(ActionType.CREATE).describe(
    "This represents the CRUD operation that the user can perform on the entity.",
  ),
  [C.INTENT_KEY]: nativeEnum(IntentType)
    .optional()
    .describe(
      "This represents the intent of action/entity pair that is being presented to the user.",
    ),
  [C.CONTENT_KEY]: object({
    [C.PRODUCT_ID_KEY]: z.coerce
      .number()
      .describe("The product that will be created in the cart."),
    [C.VARIANT_ID_KEY]: z.coerce
      .number()
      .describe("The variant of the product that will be created in the cart."),
    [C.QUANTITY_KEY]: z.coerce
      .number()
      .describe("The quantity of the product to create in the cart."),
  }).describe(
    "This represents that specific parameters that will be passed when the user performs the action.",
  ),
});

export const ActionableContentSchema = discriminatedUnion(C.CONTENT_TYPE_KEY, [
  CartActionableContentSchema,
]);

export const llmActionableContentJSONSchema = zodToJsonSchema(
  ActionableContentSchema,
  { name: "ActionableContent" },
);
export type LlmActionableContent = z.infer<typeof ActionableContentSchema>;
