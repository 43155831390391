import { LegacyRef, MutableRefObject, RefCallback, useRef } from "react";
import { useHandler } from "./hook";

export function refMerged<T>(
  refs: (MutableRefObject<T> | LegacyRef<T>)[],
): RefCallback<T> {
  return (value) => {
    for (const ref of refs) {
      if (typeof ref === "function") {
        ref(value);
      } else {
        (ref as MutableRefObject<T | null>).current = value;
      }
    }
  };
}

export function useRefMerged<T>(
  refs: (MutableRefObject<T> | LegacyRef<T>)[],
): RefCallback<T> {
  return useHandler(refMerged(refs));
}

export function useKeyedRef<T, V>(): [
  (key: T) => V,
  (key: T) => (value: V | null) => void,
] {
  const ref = useRef(new Map<T, V>());

  return [
    (key) => ref.current.get(key)!,
    (key) => (value) => {
      if (value === null) {
        ref.current.delete(key);
      } else {
        ref.current.set(key, value);
      }
    },
  ];
}
