import { memo } from "react";
import * as requiredMessage from "./required-message.module.css";

export const RequiredMessage = memo(function RequiredMessage({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={requiredMessage.required}>{children}</div>;
});
