import { EmailAddressInfo } from "@redotech/redo-model/support/conversations/email-info";
import { memo } from "react";
import * as emailAddressTooltipCss from "./email-address-tooltip.module.css";

export const EmailAddressesTooltip = memo(function EmailAddressesTooltip({
  from,
  to,
  date,
  subject,
  cc,
  bcc,
}: {
  from: EmailAddressInfo;
  to: EmailAddressInfo[];
  date: string;
  subject: string;
  cc?: EmailAddressInfo[];
  bcc?: EmailAddressInfo[];
}) {
  return (
    <>
      {from && <AddressSection addresses={[from]} title="From" />}
      {to?.length ? <AddressSection addresses={to} title="To" /> : ""}
      {cc?.length ? <AddressSection addresses={cc} title="CC" /> : ""}
      {bcc?.length ? <AddressSection addresses={bcc} title="BCC" /> : ""}
      {date && <InfoSection title="Date" value={date} />}
      {subject && <InfoSection title="Subject" value={subject} />}
    </>
  );
});

const AddressSection = memo(function AddressSection({
  title,
  addresses,
}: {
  title: string;
  addresses: EmailAddressInfo[];
}) {
  const addressesString = addresses
    .map((address: EmailAddressInfo) => {
      if (address.name) {
        return `${address.name} <${address.email}>`;
      }
      return address.email;
    })
    .join(", ");
  return <InfoSection title={title} value={addressesString} />;
});

const InfoSection = memo(function InfoSection({
  title,
  value,
}: {
  title: string;
  value: string;
}) {
  return (
    <div className={emailAddressTooltipCss.infoItem}>
      <p className={emailAddressTooltipCss.title}>{title}</p>
      <p className={emailAddressTooltipCss.value}>{value}</p>
    </div>
  );
});
