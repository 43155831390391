import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { CartData, CartLine } from "@redotech/shopify-storefront";
import { memo } from "react";
import * as cartDetailsCss from "./cart-details.module.css";
import { Divider } from "./divider";

const isRedoProduct = (vendor: string) => {
  return !["redo", "re:do"].includes(vendor.toLowerCase());
};

const countRedoItems = (cart: CartData) => {
  return cart.lines.edges.reduce((accumulator: number, line: CartLine) => {
    if (!isRedoProduct(line.node.merchandise.product.vendor)) {
      return accumulator + line.node.quantity;
    } else {
      return accumulator;
    }
  }, 0);
};

export const CartDetails = memo(function CartDetails({
  carts,
}: {
  carts: CartData[];
}) {
  const filteredCarts = carts.filter((cart) => cart.totalQuantity > 0);
  return (
    <>
      <Divider />
      {filteredCarts.length ? (
        filteredCarts.map((cart) => (
          <>
            <h2 className={cartDetailsCss.strong}>
              {`Cart (${cart.totalQuantity - countRedoItems(cart)})`}
            </h2>
            <div className={cartDetailsCss.cartContainer}>
              <p>{`Subtotal: ${CURRENCY_FORMAT(
                cart.cost.totalAmount.currencyCode,
              ).format(Number(cart.cost.totalAmount.amount))}`}</p>
              {cart.lines.edges
                .filter((line: CartLine) =>
                  isRedoProduct(line.node.merchandise.product.vendor),
                )
                .map((line: CartLine) => (
                  <div
                    className={cartDetailsCss.itemContainer}
                    key={line.node.id}
                  >
                    {line.node.merchandise.image && (
                      <img src={line.node.merchandise.image.originalSrc} />
                    )}
                    <div className={cartDetailsCss.itemDetails}>
                      <p>{`${line.node.merchandise.product.title} (${line.node.quantity})`}</p>
                      <p className={cartDetailsCss.gray}>
                        {line.node.merchandise.title}
                      </p>
                      <p className={cartDetailsCss.gray}>
                        {CURRENCY_FORMAT(
                          line.node.merchandise.price.currencyCode,
                        ).format(Number(line.node.merchandise.price.amount))}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ))
      ) : (
        <>
          <h2 className={cartDetailsCss.strong}>Cart (0)</h2>
          <p>The cart is empty</p>
        </>
      )}
    </>
  );
});
