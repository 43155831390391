import {
  ReturnStatus,
  returnStatusToDisplayText,
} from "@redotech/redo-model/return-status";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { memo } from "react";

/**
 * Returns and claims are have the same set of statuses. Feel free to split this out if that changes.
 */
export const ReturnClaimStatusBadge = memo(function ReturnClaimStatusBadge({
  returnClaimStatus,
}: {
  returnClaimStatus: ReturnStatus;
}) {
  const getBadgeColor = (): RedoBadgeColor => {
    if (returnClaimStatus === ReturnStatus.OPEN) {
      return RedoBadgeColor.SUCCESS;
    } else {
      return RedoBadgeColor.GRAY;
    }
  };

  const getBadgeText = (): string => {
    return returnStatusToDisplayText(returnClaimStatus);
  };

  return (
    <RedoBadge
      color={getBadgeColor()}
      size={RedoBadgeSize.SMALL}
      text={getBadgeText()}
    />
  );
});
