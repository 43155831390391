import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { ConditionType } from "../condition";

export const VENDOR: ConditionType<readonly string[], ModelCondition.Vendor> = {
  name: "Vendors",
  description(state) {
    return `Vendors: ${state.join(", ")}`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description="Vendors to limit the condition to. If no vendors are selected, the condition will apply to all vendors."
        label="Vendors"
      >
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          disabled={disabled}
          trimWhitespace
          value={state}
          valueChange={setState}
        />
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model: ModelCondition.Vendor) {
    return model.vendors;
  },
  toModel(state) {
    return { type: ModelCondition.VENDOR, vendors: state };
  },
  valid() {
    return true;
  },
};
