// extracted by mini-css-extract-plugin
export var alignBaseline = "_1-8";
export var alignCenter = "_1-4";
export var alignFlexEnd = "_1-6";
export var alignFlexStart = "_1-5";
export var alignSelfBaseline = "_1-d";
export var alignSelfCenter = "_1-9";
export var alignSelfFlexEnd = "_1-b";
export var alignSelfFlexStart = "_1-a";
export var alignSelfStretch = "_1-c";
export var alignStretch = "_1-7";
export var column = "_1-3";
export var flex = "_1-0";
export var justifyCenter = "_1-e";
export var justifyFlexEnd = "_1-g";
export var justifyFlexStart = "_1-f";
export var justifySpaceAround = "_1-i";
export var justifySpaceBetween = "_1-h";
export var justifySpaceEvenly = "_1-j";
export var reverse = "_1-2";
export var row = "_1-1";
export var wrapNowrap = "_1-l";
export var wrapWrap = "_1-k";
export var wrapWrapReverse = "_1-m";