import { z } from "zod";
import { zExt } from "../../../common/zod-util";
import { ReviewForgeElementType } from "../../../forge/review-forge-types";

const reviewElementDbWriteValidatorBase = z.object({
  type: z.nativeEnum(ReviewForgeElementType),
});

const semanticTagDbWriteValidatorBase = z.object({
  tagId: zExt.objectId(),
  name: z.string(),
  type: z.nativeEnum(ReviewForgeElementType),
  metadata: z.any(),
});

const submittableElementDbWriteValidatorBase =
  reviewElementDbWriteValidatorBase.extend({
    required: z.boolean(),
    tag: semanticTagDbWriteValidatorBase,
  });
const reviewShortAnswerElementDbWriteValidator =
  submittableElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.SHORT_ANSWER),
    title: z.string(),
    placeholderText: z.string(),
  });

const reviewRichTextElementDbWriteValidator =
  reviewElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.RICH_TEXT),
    id: z.number(),
    html: z.string(),
    fontFamily: z.string(),
    fontWeight: z.string(),
    fontSizePx: z.number(),
    textColor: z.string(),
    linkColor: z.string(),
  });

const reviewLogoElementDbWriteValidator =
  reviewElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.LOGO),
    widthPercentage: z.number(),
  });

const reviewProductDetailsElementDbWriteValidator =
  reviewElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.PRODUCT_DETAILS),
    imageWidthPercentage: z.number(),
  });

const reviewStarsElementDbWriteValidator =
  submittableElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.STARS),
    title: z.string(),
  });

const reviewMediaUploadElementDbWriteValidator =
  submittableElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.MEDIA_UPLOAD),
    widthPercentage: z.number(),
  });

const reviewSingleSelectElementDbWriteValidator =
  submittableElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.SINGLE_SELECT),
    options: z.array(z.string()),
  });

const reviewParagraphElementDbWriteValidator =
  submittableElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.PARAGRAPH),
    title: z.string(),
    placeholderText: z.string(),
    required: z.boolean(),
  });

const reviewDiscountElementDbWriteValidator =
  reviewElementDbWriteValidatorBase.extend({
    type: z.literal(ReviewForgeElementType.DISCOUNT),
  });

const reviewStaticElementDbWriteValidator = z.discriminatedUnion("type", [
  reviewRichTextElementDbWriteValidator,
  reviewLogoElementDbWriteValidator,
  reviewProductDetailsElementDbWriteValidator,
]);

const reviewSubmittableElementDbWriteValidator = z.discriminatedUnion("type", [
  reviewShortAnswerElementDbWriteValidator,
  reviewStarsElementDbWriteValidator,
  reviewMediaUploadElementDbWriteValidator,
  reviewSingleSelectElementDbWriteValidator,
  reviewParagraphElementDbWriteValidator,
]);

export const reviewElementDbWriteValidator = z.discriminatedUnion("type", [
  ...reviewStaticElementDbWriteValidator.options,
  ...reviewSubmittableElementDbWriteValidator.options,
]);

export const thankYouPageElementDbWriteValidator = z.discriminatedUnion(
  "type",
  [
    ...reviewStaticElementDbWriteValidator.options,
    reviewDiscountElementDbWriteValidator,
  ],
);
