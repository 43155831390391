import { Provider } from "@redotech/redo-model/order";
import {
  canRestockItemsManually,
  isDamaged,
  Product,
  RESTOCK_STRATEGY,
  Return,
} from "@redotech/redo-model/return";
import {
  ProductTotals,
  ReturnTotals,
} from "@redotech/redo-model/return-totals-calculator";
import { Settings, Team } from "@redotech/redo-model/team";

export enum ItemRestockState {
  ABLE_TO_RESTOCK = "ABLE_TO_RESTOCK",
  ALREADY_RESTOCKED = "ALREADY_RESTOCKED",
  NOT_RESTOCKABLE = "NOT_RESTOCKABLE",
}

export type ItemRestockUpdateParams =
  | {
      itemRestockState: ItemRestockState.ABLE_TO_RESTOCK;
      value: boolean;
    }
  | {
      itemRestockState: ItemRestockState.ALREADY_RESTOCKED;
    }
  | {
      itemRestockState: ItemRestockState.NOT_RESTOCKABLE;
    };

export function getPriceDetailsForProduct(
  product: Product,
  returnTotals: ReturnTotals,
): {
  price: number;
  priceAdjustment: number;
  priceBeforeRestockingFee: number;
} {
  const productTotals = (returnTotals?.productTotals || []).find(
    (productTotals: ProductTotals) =>
      productTotals.product?._id === product._id,
  );
  const price = productTotals?.finalPrice || 0;
  const priceAdjustment = productTotals?.priceAdjustment || 0;
  const priceBeforeRestockingFee =
    priceAdjustment < 0 ? price - priceAdjustment : price;

  return {
    price,
    priceAdjustment,
    priceBeforeRestockingFee,
  };
}

export function getDefaultRestockForProduct(
  returnReason: string | null | undefined,
  teamSettings: Settings,
): boolean {
  return isDamaged(returnReason)
    ? teamSettings.inventory.restockDamaged
    : teamSettings.inventory.restock;
}

function isItemAlreadyRestocked(item: Product): boolean {
  return !!item.hasBeenRestockedOnPlatform;
}

export function getDefaultItemsToRestock(
  return_: Return,
  team: Team,
  orderProvider: Provider,
) {
  return return_.products.reduce<Record<string, ItemRestockUpdateParams>>(
    (acc, item) => {
      if (isItemAlreadyRestocked(item)) {
        acc[item._id] = {
          itemRestockState: ItemRestockState.ALREADY_RESTOCKED,
        };
      } else if (
        !RESTOCK_STRATEGY[item.strategy] ||
        !canRestockItemsManually(orderProvider)
      ) {
        // Shopify is currently the only supported provider where we can
        // manually restock items (feel free to expand functionality to other
        // providers).
        acc[item._id] = {
          itemRestockState: ItemRestockState.NOT_RESTOCKABLE,
        };
      } else {
        const defaultRestockValue = getDefaultRestockForProduct(
          item.reason,
          team.settings,
        );
        acc[item._id] = {
          itemRestockState: ItemRestockState.ABLE_TO_RESTOCK,
          value: defaultRestockValue,
        };
      }
      return acc;
    },
    {},
  );
}

export function setItemRestockUpdated(
  prevItemsToRestock: Record<string, ItemRestockUpdateParams>,
  itemId: string,
  value: boolean,
): Record<string, ItemRestockUpdateParams> {
  if (
    prevItemsToRestock[itemId].itemRestockState ===
    ItemRestockState.ABLE_TO_RESTOCK
  ) {
    return {
      ...prevItemsToRestock,
      [itemId]: {
        ...prevItemsToRestock[itemId],
        value,
      },
    };
  } else {
    throw new Error(
      "Attempted to update restock state for item that is not able to be restocked",
    );
  }
}
