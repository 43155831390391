import { filterArrayFormat } from "@redotech/redo-model/conversation-filters/conversation-filter-formats";
import {
  filtersToQueryString,
  queryStringToFilters,
} from "@redotech/redo-model/conversation-filters/conversation-filter-query";
import {
  AdvancedFilter,
  AssigneesFilter,
  AssigneesFilterType,
  ConversationFiltersV3,
  FilterGroupFilterOption,
  FiltersStatus,
  PendingAdvancedFilter,
  UniqueV3Filters,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { conversationFiltersEquivalent } from "@redotech/redo-model/conversation-filters/conversation-filters-equal";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ActiveViewContext } from "./active-view-context";

export const UniqueFiltersContext = createContext<UniqueV3Filters>({});

export const SetUniqueFiltersContext = createContext<
  (filters: UniqueV3Filters) => void
>(() => {});

export const AdvancedFiltersContext = createContext<
  (AdvancedFilter | PendingAdvancedFilter)[]
>([]);

export const SetAdvancedFiltersContext = createContext<
  React.Dispatch<
    React.SetStateAction<(AdvancedFilter | PendingAdvancedFilter)[]>
  >
>(() => {});

export const FinalizedFiltersContext = createContext<ConversationFiltersV3>({
  advancedFilters: [],
});

export const FiltersProvider = memo(function FiltersProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const activeView = useContext(ActiveViewContext);

  const [uniqueFilters, setUniqueFilters] = useState<UniqueV3Filters>(
    activeView.filters,
  );

  const [advancedFilters, setAdvancedFilters] = useState<
    (AdvancedFilter | PendingAdvancedFilter)[]
  >(activeView.filters.advancedFilters);

  const nonPendingAdvancedFilters = useMemo(
    () => advancedFilters.filter((filter) => filter.value !== null),
    [advancedFilters],
  );

  const nonPendingAdvancedFiltersString = useMemo(
    () => JSON.stringify(filterArrayFormat.write(nonPendingAdvancedFilters)),
    [nonPendingAdvancedFilters],
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setUniqueFilters(activeView.filters);
    setAdvancedFilters(activeView.filters.advancedFilters);
  }, [activeView]);

  useEffect(() => {
    const filters = queryParamsToFilters(location.search);
    if (!filters) {
      return;
    }
    setUniqueFilters(filters);
    setAdvancedFilters(filters.advancedFilters);
  }, []);

  const finalizedFilters = useMemo(() => {
    return {
      ...activeView.filters,
      ...uniqueFilters,
      advancedFilters: nonPendingAdvancedFilters,
    };
  }, [activeView, uniqueFilters, nonPendingAdvancedFiltersString]);

  useEffect(() => {
    _updateFilters(finalizedFilters, activeView.filters, navigate);
  }, [activeView, finalizedFilters]);

  useEffect(() => {
    const urlFilters = queryParamsToFilters(location.search);
    if (urlFilters && urlFilters.status === undefined) {
      setUniqueFilters((old) => {
        return { ...old, status: FiltersStatus.OPEN };
      });
    }
  }, [activeView]);

  return (
    <AdvancedFiltersContext.Provider value={advancedFilters}>
      <SetAdvancedFiltersContext.Provider value={setAdvancedFilters}>
        <UniqueFiltersContext.Provider value={uniqueFilters}>
          <SetUniqueFiltersContext.Provider value={setUniqueFilters}>
            <FinalizedFiltersContext.Provider value={finalizedFilters}>
              {children}
            </FinalizedFiltersContext.Provider>
          </SetUniqueFiltersContext.Provider>
        </UniqueFiltersContext.Provider>
      </SetAdvancedFiltersContext.Provider>
    </AdvancedFiltersContext.Provider>
  );
});

function _updateFilters(
  newFilters: ConversationFiltersV3,
  baseFilters: ConversationFiltersV3,
  navigate: NavigateFunction,
) {
  const searchParams = new URLSearchParams(location.search);

  if (conversationFiltersEquivalent(newFilters, baseFilters)) {
    searchParams.delete("filters");
  } else {
    const filterParam = filtersToQueryString(newFilters);
    if (filterParam) {
      searchParams.set("filters", filterParam);
    } else {
      searchParams.delete("filters");
    }
  }

  const newSearch = searchParams.toString();
  if (newSearch === location.search) {
    return;
  }

  navigate({
    search: searchParams.toString(),
  });
}

const queryParamsToFilters = (
  rawSearchString: string,
): ConversationFiltersV3 | undefined => {
  const queryParams = new URLSearchParams(rawSearchString);
  const filters = queryParams.get("filters");
  if (!filters) {
    return undefined;
  }
  return queryStringToFilters(filters);
};

export const navigateToTicketsAssignedToUser = (
  teamId: string,
  userId: string,
) => {
  const assigneeFilter: AssigneesFilter = {
    type: FilterGroupFilterOption.ASSIGNEES,
    value: [userId],
    query: AssigneesFilterType.INCLUDES,
  };
  const filters: ConversationFiltersV3 = {
    advancedFilters: [assigneeFilter],
  };
  window.location.href = `/stores/${teamId}/support/table/all?filters=${filtersToQueryString(filters)}`;
};
