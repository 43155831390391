import {
  JsonFormat,
  objectJsonFormat,
  stringJsonFormat,
} from "@redotech/json/format";
import { v3FiltersFormat } from "./conversation-filters/conversation-filter-formats";
import { ConversationFiltersV3 } from "./conversation-filters/conversation-filters";

export interface View {
  _id: string;
  team: string;
  name: string;
  filters: ConversationFiltersV3;
  user?: string;
}

export const viewJsonFormat: JsonFormat<View> = objectJsonFormat(
  {
    _id: stringJsonFormat,
    team: stringJsonFormat,
    name: stringJsonFormat,
    filters: v3FiltersFormat,
  },
  { user: stringJsonFormat },
);
