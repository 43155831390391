import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { CardListRef } from "@redotech/redo-web/card-list";
import { TableRef } from "@redotech/redo-web/table";
import { createContext } from "react";

export interface UpdateConversationStateContext {
  tableRef: React.MutableRefObject<TableRef<ExpandedConversation>>;
  cardListRef: React.MutableRefObject<CardListRef<ExpandedConversation>>;
  invalidateCache: () => void;
  waitForRefresh: () => Promise<void>;
  refreshIgnoreResult: () => void;
  removeConversation: (conversation: ExpandedConversation) => void;
  optimisticUpdate: (conversation: ExpandedConversation) => void;
}

export const UpdateConversationStateContext = createContext<
  UpdateConversationStateContext | undefined
>(undefined);
