// extracted by mini-css-extract-plugin
export var actionButton = "_96-1";
export var actions = "_96-0";
export var copyableField = "_96-2";
export var icon = "_96-3";
export var overflowVisible = "_96-4";
export var pillWrapper = "_96-5";
export var productDetails = "_96-7";
export var productPillsContainer = "_96-8";
export var productTopDetails = "_96-9";
export var products = "_96-6";
export var returnDetails = "_96-a";
export var strong = "_96-b";
export var variantQuantityRow = "_96-c";