import { z } from "zod";
import { brandKitValidator } from "../../../brand-kit";
import { generateFormBuilderFormDbParser } from "../common/form-builder-form-db-validator";
import {
  reviewElementDbWriteValidator,
  thankYouPageElementDbWriteValidator,
} from "./review-element-db-validator";

const reviewFormDataSettingsDbWriteValidator = z.object({
  title: z.string(),
  description: z.string(),
  categories: z.array(z.string()),
});

const reviewFormDataStylesDbWriteValidator = z.object({
  brandKitEnabled: z.boolean(),
  brandKitOverride: brandKitValidator,
});

const reviewFormDataPageDbWriteValidator = z.object({
  elements: z.array(reviewElementDbWriteValidator),
});

const reviewFormDataThankYouPageDbWriteValidator = z.object({
  elements: z.array(thankYouPageElementDbWriteValidator),
});

export const reviewFormDataDbWriteValidator = z.object({
  settings: reviewFormDataSettingsDbWriteValidator,
  styles: reviewFormDataStylesDbWriteValidator,
  pages: z.array(reviewFormDataPageDbWriteValidator),
  thankYouPage: reviewFormDataThankYouPageDbWriteValidator,
});
export const reviewFormDataDbParser = reviewFormDataDbWriteValidator.extend({
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const reviewFormBuilderFormDbParser = generateFormBuilderFormDbParser(
  reviewFormDataDbParser,
);
export type ReviewFormBuilderForm = z.infer<
  typeof reviewFormBuilderFormDbParser
>;
