import { Provider } from "@redotech/redo-model/order";
import { ProductInfo } from "@redotech/redo-model/product";
import { z } from "zod";

export const searchProductsSchema = {
  input: z.object({
    searchString: z.string(),
    provider: z.nativeEnum(Provider),
  }),
  output: z.object({
    products: z.array(ProductInfo),
  }),
};
