import { memo } from "react";
import * as dividerCss from "./divider.module.css";

export const Divider = memo(function Divider() {
  return (
    <div className={dividerCss.dividerContainer}>
      <hr className={dividerCss.line} />
    </div>
  );
});
