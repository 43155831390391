import { FlowType } from "@redotech/redo-model/return-flow";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import { TextInput } from "@redotech/redo-web/text-input";
import { DisabledTooltip } from "@redotech/redo-web/tooltip/disabled-tooltip";
import * as classnames from "classnames";
import { memo } from "react";
import * as flowEditorHeaderCss from "./flow-editor-header-buttons.module.css";

export interface FlowEditorHeaderProps {
  flowType: FlowType;

  showSaveAndCancel: boolean;
  cancelDisabled: boolean;
  saveDisabled: string | undefined;
  savePending: boolean;

  showPublishButton: boolean;
  publishClicked: () => void;
  showUnpublishButton: boolean;
  unpublishClicked: () => void;
  publishPending: boolean;

  rightPanelOpen: boolean;

  detailsClicked: () => void;
  cancelClicked: () => void;
  saveClicked: () => void;

  ruleName: string;
  setRuleName: (name: string) => void;

  defaultButtonClicked?: () => void; // button that is visible when all others are not
  defaultButtonPending?: boolean;
  defaultButtonText?: string;
}

export const FlowEditorHeaderButtons = memo(function FlowEditorHeaderButtons({
  flowType,
  showSaveAndCancel,
  cancelDisabled,
  saveDisabled,
  savePending,
  publishPending,
  showPublishButton,
  showUnpublishButton,
  rightPanelOpen,
  ruleName,
  setRuleName,
  detailsClicked,
  cancelClicked,
  saveClicked,
  publishClicked,
  unpublishClicked,
  defaultButtonClicked,
  defaultButtonPending,
  defaultButtonText,
}: FlowEditorHeaderProps) {
  const ruleSpecificItems = flowType === FlowType.RULE && (
    <>
      <TextInput
        onChange={setRuleName}
        placeholder="Give this rule a name"
        value={ruleName}
      />
      <RedoButton
        hierarchy={RedoButtonHierarchy.TERTIARY}
        onClick={detailsClicked}
        size={RedoButtonSize.LARGE}
        text="Details"
      />
    </>
  );

  const showDefaultButton =
    defaultButtonClicked &&
    defaultButtonText &&
    !showSaveAndCancel &&
    !showPublishButton;

  return (
    <Flex
      className={classnames({
        [flowEditorHeaderCss.rightPanelOpen]: rightPanelOpen,
      })}
    >
      {ruleSpecificItems}
      {showSaveAndCancel && (
        <>
          <RedoButton
            disabled={cancelDisabled}
            hierarchy={RedoButtonHierarchy.SECONDARY}
            onClick={cancelClicked}
            size={RedoButtonSize.LARGE}
            text="Cancel"
          />
          <DisabledTooltip disabledMessage={saveDisabled}>
            <RedoButton
              disabled={!!saveDisabled}
              hierarchy={RedoButtonHierarchy.PRIMARY}
              onClick={saveClicked}
              pending={savePending}
              size={RedoButtonSize.LARGE}
              text="Save"
            />
          </DisabledTooltip>
        </>
      )}
      {showUnpublishButton && (
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={unpublishClicked}
          pending={publishPending}
          size={RedoButtonSize.LARGE}
          text="Unpublish"
        />
      )}
      {showPublishButton && (
        <RedoButton
          hierarchy={RedoButtonHierarchy.PRIMARY}
          onClick={publishClicked}
          pending={publishPending}
          size={RedoButtonSize.LARGE}
          text="Publish"
        />
      )}
      {showDefaultButton && (
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={defaultButtonClicked}
          pending={defaultButtonPending ?? false}
          text={defaultButtonText}
        />
      )}
    </Flex>
  );
});
