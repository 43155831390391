import { useMemo, useState } from "react";

export function useFocus(): [
  boolean,
  { onBlur: () => void; onFocus: () => void },
] {
  const [focus, setFocus] = useState(false);
  const props = useMemo(
    () => ({
      onBlur() {
        setFocus(false);
      },
      onFocus() {
        setFocus(true);
      },
    }),
    [setFocus],
  );
  return [focus, props];
}
