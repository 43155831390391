// extracted by mini-css-extract-plugin
export var brand = "_2g-9";
export var button = "_2g-0";
export var chevron = "_2g-v";
export var chevronButton = "_2g-w";
export var content = "_2g-n";
export var danger = "_2g-g";
export var dangerOutlined = "_2g-h";
export var dark = "_2g-l";
export var darkTheme = "_2g-c";
export var extraSmall = "_2g-4";
export var fullWidth = "_2g-8";
export var ghost = "_2g-d";
export var gray = "_2g-t";
export var icon = "_2g-p";
export var iconButton = "_2g-s";
export var iconButtonBorder = "_2g-x";
export var iconButtonBorderDark = "_2g-y";
export var iconButtonBorderLight = "_2g-z";
export var iconLeft = "_2g-q";
export var iconRight = "_2g-r";
export var large = "_2g-1";
export var light = "_2g-m";
export var medium = "_2g-2";
export var micro = "_2g-5";
export var nano = "_2g-7";
export var outlined = "_2g-k";
export var pending = "_2g-a";
export var primary = "_2g-i";
export var shadow = "_2g-o";
export var small = "_2g-3";
export var solidLight = "_2g-e";
export var solidLightBrand = "_2g-f";
export var spinner = "_2g-u";
export var transparent = "_2g-j";
export var warning = "_2g-b";
export var wideNano = "_2g-6";