import { z } from "zod";

export const recreateDiscountsSchema = {
  input: z.object({
    discountCodes: z.array(z.string()),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};
