import { RedoClient } from "@redotech/redo-api-client";
import { createAuth, WidgetClient } from "@redotech/redo-api-client/widget";
import { Order } from "@redotech/redo-model/order";
import { Team } from "@redotech/redo-model/team";
import { REDO_RETURNS_APP_URL_LOCAL } from "../../config";

export async function getCustomerPortalLink(
  client: RedoClient,
  team: Team,
  order: Order,
  fulfillmentIdx?: number,
): Promise<string> {
  const widgetClient = new WidgetClient(client, team.widget_slug);
  const email = order.shopify.email;
  const orderName = order.shopify.name;

  const auth = await createAuth(widgetClient, {
    orderNumber: orderName,
    email,
    type: "order",
  });

  const domain = team.portal.domain || team.storeUrl;
  const path = team.portal.pathPrefix || "/apps/redo";

  const url =
    process.env.NODE_ENV === "production"
      ? new URL(`https://${domain}${path}/returns-portal`)
      : new URL(
          `${REDO_RETURNS_APP_URL_LOCAL}/widget_id/${team.widget_slug}/returns-portal`,
        );
  const address = {
    street1: order.shopify.shipping_address?.address1,
    street2: order.shopify.shipping_address?.address2 || "",
    city: order.shopify.shipping_address?.city,
    state: order.shopify.shipping_address?.province,
    country: order.shopify.shipping_address?.country_code,
    zip: order.shopify.shipping_address?.zip,
  };
  url.searchParams.set(
    "customer",
    JSON.stringify({
      ...auth.customer,
      address: JSON.stringify(address),
    }),
  );
  url.searchParams.set("token", auth.token);

  let finalDestination = `/returns-portal/orders/${order._id}`;
  if (fulfillmentIdx !== undefined) {
    finalDestination += `/track/${fulfillmentIdx}`;
  }

  url.searchParams.set("next", finalDestination);

  return String(url);
}
