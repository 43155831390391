import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import UserIcon from "./icon-old/user.svg";
import * as userImageCss from "./user-image.module.css";

const colors = [
  "#A894ED",
  "#3E91DD",
  "#E4A3DD",
  "#61C0C6",
  "#4ECC82",
  "#E86B4B",
  "#BFAC82",
  "#C0E861",
  "#ED9494",
  "#EDCF94",
  "#94E8ED",
  "#FF9447",
];

const getBubbleColor = (name: string) => {
  const numericValue = name
    .split("")
    .reduce((accumulator: number, letter: string) => {
      return accumulator + letter.charCodeAt(0);
    }, 0);
  const colorIndex = numericValue % colors.length;
  return colors[colorIndex];
};

export enum UserImageSize {
  X_TINY = -1,
  TINY = 0,
  MICRO = 1,
  SMALL = 2,
  MEDIUM_SMALL = 3,
  MEDIUM = 4,
  LARGE = 5,
}

export const userImageSizeClasses: Record<UserImageSize, string> = {
  [UserImageSize.X_TINY]: userImageCss.xTiny,
  [UserImageSize.TINY]: userImageCss.tiny,
  [UserImageSize.MICRO]: userImageCss.micro,
  [UserImageSize.SMALL]: userImageCss.small,
  [UserImageSize.MEDIUM_SMALL]: userImageCss.mediumSmall,
  [UserImageSize.MEDIUM]: userImageCss.medium,
  [UserImageSize.LARGE]: userImageCss.large,
};

export interface UserImageProps {
  alt: string;
  imageUrl: string | null;
  name?: string;
  size?: UserImageSize;
  contrastBorder?: boolean;
  greyOut?: boolean;
}

/**
 * User image tries to use its main image (as given with the url), but has
 * 2 fallbacks: first to use initials of the name, and second to a generic user icon.
 *
 * @param imageUrl MUST BE TRUTHY i.e. NON-EMPTY or else double-icon bug will occur.
 */
export const UserImage = memo(function UserImage({
  alt,
  imageUrl,
  name,
  size = UserImageSize.SMALL,
  contrastBorder = false,
  greyOut = false,
}: UserImageProps) {
  const [displayDefault, setDisplayDefault] = useState(false);
  const allInitials = name
    ?.split(" ")
    ?.filter((namePart) => /^[A-Za-z]/.test(namePart)) // Ensure the part starts with a letter
    ?.map((namePart) => namePart[0].toUpperCase());
  const initials =
    allInitials && allInitials.length > 0
      ? allInitials.length > 1
        ? allInitials[0] + allInitials[allInitials.length - 1]
        : allInitials[0]
      : undefined;

  useEffect(() => {
    if (imageUrl) {
      setDisplayDefault(false);
    }
  }, [imageUrl]);

  return (
    <div className={userImageCss.container}>
      {imageUrl && !displayDefault && (
        <div
          className={classNames(
            ...(contrastBorder ? [userImageCss.contrastBorder] : []),
            userImageSizeClasses[size],
            ...(greyOut ? [userImageCss.greyOut] : []),
          )}
        >
          <img
            alt={alt}
            className={classNames(
              userImageCss.bubble,
              userImageSizeClasses[size],
            )}
            onError={() => setDisplayDefault(true)}
            src={imageUrl}
          />
        </div>
      )}
      {displayDefault && name?.trim() && (
        <div
          className={classNames(
            userImageCss.bubble,
            userImageSizeClasses[size],
            ...(greyOut ? [userImageCss.greyOut] : []),
          )}
          style={{ backgroundColor: getBubbleColor(name) }}
        >
          <div className={userImageCss.initialsWrapper}>
            <p>{initials}</p>
          </div>
        </div>
      )}
      {(!imageUrl || (!name?.trim() && displayDefault)) && (
        <UserIcon
          className={classNames(
            userImageSizeClasses[size],
            ...(greyOut ? [userImageCss.greyOut] : []),
          )}
        />
      )}
    </div>
  );
});
