import { genericMemo, IterableMap } from "@redotech/react-util/component";
import { ArrowDown, ArrowUp, EnterKey } from "@redotech/web-util/key";
import * as React from "react";
import { ReactNode, useEffect } from "react";
import { Flex } from "../../flex";
import { SpacingValue } from "../../theme/box";
import {
  RedoListItem,
  RedoListItemMenu,
  RedoListItemSize,
  RedoListItemVariant,
} from "./redo-list-item";
import * as redoListCss from "./redo-list.module.css";

export interface RedoListItem<T> {
  value: T;
  menu?: RedoListItemMenu;
}

export enum RedoListItemSelectedSource {
  Keyboard = "keyboard",
  Mouse = "mouse",
}

/**
 * @param refToListenTo -- this can be an item like a button or an input.
 * After the button is clicked or the input receives focus,
 * keyboard navigation will be enabled for the list.
 */
export interface RedoListProps<T> {
  items: RedoListItem<T>[];
  itemSelected(item: RedoListItem<T>, source: RedoListItemSelectedSource): void;
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex(index: number | undefined): void;
  selectionVariant?: RedoListItemVariant;
  children(item: RedoListItem<T>): ReactNode;
  isItemSelected?(item: RedoListItem<T>): boolean;
  keyFn?: (item: RedoListItem<T>, index: number) => string | number;
  size?: RedoListItemSize;
  containerClassName?: string;
  gap?: SpacingValue;
  itemPadding?: SpacingValue;
  isItemDisabled?(item: RedoListItem<T>): boolean;
}

export const RedoList = genericMemo(function RedoList<T>({
  size = RedoListItemSize.MEDIUM,
  items,
  itemSelected,
  isItemSelected = () => false,
  focusedIndex,
  setFocusedIndex,
  refToListenTo,
  children,
  selectionVariant = RedoListItemVariant.CHECKMARK,
  keyFn = (_, idx) => idx,
  containerClassName,
  gap,
  itemPadding,
  isItemDisabled = () => false,
}: RedoListProps<T>) {
  // FIXME
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleKeyPress(event: KeyboardEvent | React.KeyboardEvent) {
    if (event.key === EnterKey) {
      event.preventDefault();
      if (focusedIndex === undefined) {
        return;
      }
      const selectedItem = items[focusedIndex];
      if (!isItemDisabled(selectedItem)) {
        itemSelected(selectedItem, RedoListItemSelectedSource.Keyboard);
      }
    } else if (event.key === ArrowDown) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? -1;
      let newIndex = oldIndex + 1;
      while (newIndex < items.length && isItemDisabled(items[newIndex])) {
        newIndex++;
      }
      if (newIndex < items.length) {
        setFocusedIndex(newIndex);
      }
    } else if (event.key === ArrowUp) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? items.length;
      let newIndex = oldIndex - 1;
      while (newIndex >= 0 && isItemDisabled(items[newIndex])) {
        newIndex--;
      }
      if (newIndex >= 0) {
        setFocusedIndex(newIndex);
      } else {
        setFocusedIndex(undefined);
      }
    }
  }

  useEffect(() => {
    refToListenTo?.addEventListener("keydown", handleKeyPress);
    return () => {
      refToListenTo?.removeEventListener("keydown", handleKeyPress);
    };
  }, [refToListenTo, handleKeyPress]);

  return (
    <div className={containerClassName}>
      <Flex
        className={redoListCss.childrenContainer}
        dir="column"
        gap={gap}
        onKeyDown={handleKeyPress}
        p={itemPadding}
        tabIndex={0}
      >
        <IterableMap items={items} keyFn={keyFn}>
          {(item, idx) => {
            const node = children(item);
            const disabled = isItemDisabled(item);
            return (
              <RedoListItem
                disabled={disabled}
                focused={idx === focusedIndex}
                itemClicked={() => {
                  if (!disabled) {
                    itemSelected(item, RedoListItemSelectedSource.Mouse);
                  }
                }}
                menu={item.menu}
                onItemHovered={(hovered) => {
                  if (hovered && !disabled) {
                    setFocusedIndex(idx);
                  } else if (focusedIndex === idx) {
                    setFocusedIndex(undefined);
                  }
                }}
                selected={isItemSelected(item)}
                selectionVariant={selectionVariant}
                size={size}
              >
                {node}
              </RedoListItem>
            );
          }}
        </IterableMap>
      </Flex>
    </div>
  );
});
