import { Schema } from "../type-system/schema";

export const textMatchOperators = [
  "startsWith",
  "endsWith",
  "includes",
  "equals",
] as const;

export type StringMatchOperator = (typeof textMatchOperators)[number];

export interface TextMatchExpression {
  type: "text_match";
  field: keyof Schema["fields"];
  operator: StringMatchOperator;
  matchValues: string[];
}
