import { RouteObject } from "react-router-dom";

export const upsellPageSettingsRoutes: RouteObject = {
  handle: { breadcrumb: "One-Click Upsell", breadcrumbNavigate: false },
  path: "one-click-upsell",
  children: [
    {
      handle: { breadcrumb: "Upsell Page" },
      path: "upsell-page",
      children: [
        {
          index: true,
          lazy: async () => {
            const { UpsellPageEntry } = await import(
              "./one-click-upsell/upsell-page-entry"
            );
            return { Component: UpsellPageEntry };
          },
        },
        {
          handle: { breadcrumb: "Upsell Builder" },
          path: "upsell-page-builder",
          lazy: async () => {
            const { UpsellPageBuilder } = await import(
              "./one-click-upsell/upsell-page-builder"
            );
            return { element: <UpsellPageBuilder /> };
          },
        },
      ],
    },
  ],
};
