import * as amplitude from "@amplitude/analytics-browser";
import Gravatar from "@gravatar/js";
import { styled, TooltipProps } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Avatar } from "@redotech/redo-web/arbiter-components/avatars/avatar";
import { AvatarLabelGroup } from "@redotech/redo-web/arbiter-components/avatars/avatar-label-group";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
  RedoBadgeType,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import DotsIconSvg from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import InfoSvg from "@redotech/redo-web/arbiter-icon/info-circle.svg";
import PaperclipSvg from "@redotech/redo-web/arbiter-icon/paperclip_filled.svg";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MessageInput } from "../message-input/message-input";
import { EmailAddressesTooltip } from "./email-address-tooltip";
import { EmailDraftState } from "./email-draft-state";
import {
  convertPlainTextToHtml,
  cutOutRepliesFromMessageContent,
  updateLinksToOpenInNewTab,
} from "./format-email-message-content";

import { useRequiredContext } from "@redotech/react-util/context";
import {
  EmailAddressInfo,
  EmailAddressInfoWithValidEmail,
  EmailReplyType,
  filterAndPreprocessEmailInfo,
  hasExactlyOneRecipientEmail,
  stringifyEmailRecipientsInfo,
} from "@redotech/redo-model/support/conversations/email-info";
import { Permission, permitted } from "@redotech/redo-model/user";
import CornerUpLeftDoubleSvg from "@redotech/redo-web/arbiter-icon/corner-up-left-double.svg";
import CornerUpLeftSvg from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import CornerUpRightSvg from "@redotech/redo-web/arbiter-icon/corner-up-right_filled.svg";
import { UserContext } from "../../app/user";
import { ConversationSendStateContext } from "../conversation-send-states";
import { getPublicDraftReplyingToMessage } from "../message-input-utils";
import * as emailMessageCss from "./email-message.module.css";
const CustomTooltip = styled(function CustomTooltip({
  className,
  ...props
}: TooltipProps) {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0F0F0F",
    color: "#FFFFFF",
    fontSize: "var(--redo-body-xsmall-text-size) !important",
    font: "var(--redo-body-font-family)",
    padding: "8px 12px",
    borderRadius: "var(--redo-corner-small-radius)",
    lineHeight: "18px",
    maxWidth: 400,
  },
}));

export type MessageContent =
  | { type: "messageText"; messageText: string }
  | { type: "messageHtml"; messageHtml: string };

export type MessageInputProps = {
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setShowErrorMessage: Dispatch<SetStateAction<boolean>>;
  showFullCommentThread: boolean;
  typing: Record<string, Date>;
  setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
  setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
};

const adoptSheet = (atRoot: ShadowRoot, styles: string) => {
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(styles);
  atRoot.adoptedStyleSheets.push(sheet);
};

export const EmailMessage = memo(function EmailMessage({
  startCollapsed,
  username,
  userAvatarImageUrl,
  messageContent,
  from,
  to,
  cc,
  bcc,
  date,
  subject,
  files,
  visibility,
  isUser,
  message,
  conversation,
  profilePictures,
  whichDraftModeToStartWith,
  setActiveConversation,
  setErrorMessage,
  setShowErrorMessage,
  showFullCommentThread,
  typing,
  setTyping,
  setShowFullCommentThread,
  nextConversationInList,
  prevConversationInList,
}: {
  startCollapsed: boolean;
  username: string;
  userAvatarImageUrl: string;
  messageContent: MessageContent;
  from: EmailAddressInfoWithValidEmail | undefined;
  to: EmailAddressInfoWithValidEmail[];
  cc: EmailAddressInfoWithValidEmail[];
  bcc: EmailAddressInfoWithValidEmail[];
  date: string;
  subject: string;
  files: any[];
  visibility: MessageVisibility;
  isUser?: boolean;
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  profilePictures: Record<string, string> | undefined;
  whichDraftModeToStartWith: EmailReplyType | undefined;
} & MessageInputProps) {
  const [
    shouldPopupRecipientsModalBecauseForwardButtonClicked,
    setShouldPopupRecipientsModalBecauseForwardButtonClicked,
  ] = useState(false);
  useEffect(() => {
    setShouldPopupRecipientsModalBecauseForwardButtonClicked(false);
  }, [conversation._id]);

  const user = useContext(UserContext);
  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  const shouldShowReplyButton = !!to.length;

  const conversationSendStates = useRequiredContext(
    ConversationSendStateContext,
  );

  const shouldShowReplyAllButton =
    !hasExactlyOneRecipientEmail({
      to,
      cc,
      bcc,
    }) || !to.length;

  /** Don't actually "reply" to your own messages lol. Reply to the original recipients when applicable */
  const shouldReuseThisToRecipientsForNewToRecipients =
    from?.email === conversation.email?.email;

  /**
   * compare emails regardless of aliases (sender addr to the right of the "+" sign infront of "@")
   */
  const compareEmailsRegardlessOfAliases = (
    email1: EmailAddressInfo | undefined,
    email2: EmailAddressInfo | undefined,
  ): boolean => {
    if (!email1?.email || !email2?.email) {
      return false;
    }
    const getEmailWithoutAlias = (email: string): string => {
      return email.split("@")[0].split("+")[0] + "@" + email.split("@")[1];
    };

    try {
      return (
        getEmailWithoutAlias(email1.email) ===
        getEmailWithoutAlias(email2.email)
      );
    } catch (error) {
      console.error("Error comparing emails:", error);
      return false;
    }
  };

  /**
   * Helper function to filter a list of EmailAddressInfo that
   * if the current "from" email is in the CC or BCC list, remove it.
   */
  const filterOutSelfFromCcOrBcc = (
    emailAddresses: EmailAddressInfo[],
  ): EmailAddressInfo[] => {
    return emailAddresses.filter(
      (emailAddress) => !compareEmailsRegardlessOfAliases(emailAddress, from),
    );
  };

  const initialDraftToAssignment = (mode: EmailReplyType) =>
    /** Reply uses only the "to" email addresses */
    mode === "Reply"
      ? shouldReuseThisToRecipientsForNewToRecipients
        ? to ?? []
        : from
          ? [from]
          : []
      : /** (when replying all, include new people who were looped in from your reply)*/
        mode === "Reply All"
        ? shouldReuseThisToRecipientsForNewToRecipients
          ? to ?? []
          : [
              ...(from ? [from] : []),
              ...to.filter((ei) => ei.email !== conversation.email?.email),
            ] ?? []
        : /** if the mode is forward, don't have any "to" addresses to start */
          [];

  const getInitialDraft = (mode: EmailReplyType): EmailDraftState => ({
    status: "drafting",
    mode: mode,
    draft: {
      recipientsInfo: Object.assign(
        {},
        ...Object.entries(
          mode === "Forward"
            ? {
                to: [],
                cc: [],
                bcc: [],
              }
            : {
                to: initialDraftToAssignment(mode),

                cc: filterOutSelfFromCcOrBcc(mode === "Reply All" ? cc : []),
                bcc: filterOutSelfFromCcOrBcc(mode === "Reply All" ? bcc : []),
              },
        ).map(([emailType, unvalidatedEmails]) => ({
          [emailType]: filterAndPreprocessEmailInfo(unvalidatedEmails),
        })),
        {
          inReplyTo: message?.email?.messageId,
          inReplyToMongoId: message?._id,
        },
      ),
      subject: `Re: ${subject}`,
      content: "",
    },
  });
  const initializeDraft = (): EmailDraftState => {
    const messageDraft = getPublicDraftReplyingToMessage(conversation, message);
    if (messageDraft) {
      const emailDraftState: EmailDraftState = {
        status: "drafting",
        mode:
          messageDraft.draftInfo?.emailDraftInfo?.replyType ||
          EmailReplyType.REPLY,
        draft: {
          recipientsInfo: {
            to: messageDraft.email?.to,
            cc: filterAndPreprocessEmailInfo(messageDraft.email?.cc || []),
            bcc: filterAndPreprocessEmailInfo(messageDraft.email?.bcc || []),
            inReplyTo: message?.email?.messageId,
            inReplyToMongoId: message?._id,
          },
          subject: `Re: ${conversation.subject}`,
          content: messageDraft.content,
        },
      };
      return emailDraftState;
    } else if (whichDraftModeToStartWith) {
      return getInitialDraft(whichDraftModeToStartWith);
    }
    return { status: "noDraft" };
  };

  const [replyDraft, setReplyDraft] =
    useState<EmailDraftState>(initializeDraft);

  const [triggerReinitalizeDraft, setTriggerReinitalizeDraft] = useState(false);

  useEffect(() => {
    if (triggerReinitalizeDraft) {
      setReplyDraft(initializeDraft());
      setTriggerReinitalizeDraft(false);
    }
  }, [triggerReinitalizeDraft]);

  /**
   * If the draft mode has changed, we need to reset the draft.
   * Otherwise, if it's been edited, update the stored draft.
   */
  const handleSetReplyDraft: Dispatch<SetStateAction<EmailDraftState>> = (
    newDraft: SetStateAction<EmailDraftState>,
  ) => {
    setReplyDraft((prevDraft: EmailDraftState) => {
      const updatedDraft =
        typeof newDraft === "function" ? newDraft(prevDraft) : newDraft;

      let updatedDraftOrSwitchedTypeDraft = updatedDraft;

      const shouldResetRecipientInfo =
        prevDraft.status === "drafting" &&
        updatedDraft.status === "drafting" &&
        updatedDraft.mode !== prevDraft.mode;
      if (shouldResetRecipientInfo) {
        updatedDraftOrSwitchedTypeDraft = getInitialDraft(updatedDraft.mode);
      }
      return updatedDraftOrSwitchedTypeDraft;
    });
  };

  const [collapsed, setCollapsed] = useState(startCollapsed);

  useLayoutEffect(() => {
    setCollapsed(startCollapsed);
  }, [conversation._id, startCollapsed]);

  const [mouseDownLoc, setMouseDownLoc] = useState<
    { x: number; y: number } | undefined
  >({ x: 0, y: 0 });
  const [hideReplies, setHideReplies] = useState(true);

  const messageHTML =
    messageContent.type === "messageHtml"
      ? messageContent.messageHtml
      : convertPlainTextToHtml(messageContent.messageText);

  let showToggleViewReplyButton = false;
  let shortenedMessage = messageHTML;
  if (hideReplies) {
    const { processedContent, showToggleViewReplyButton: showToggleViewReply } =
      cutOutRepliesFromMessageContent(shortenedMessage);
    showToggleViewReplyButton = showToggleViewReply;
    shortenedMessage = processedContent;
  } else {
    showToggleViewReplyButton = true;
  }

  // Our emails need to be in a shadow dom to prevent style pollution.
  // This shadow dom needs to be imperitave because <template shadowrootmode="open"> doesn't seem to work in react.
  // We also take extra care to clear / refill the shadow dom when the contents change.
  const emailShadowDomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let shadowDom;
    if (emailShadowDomRef.current) {
      if (emailShadowDomRef.current.shadowRoot) {
        shadowDom = emailShadowDomRef.current.shadowRoot;
      } else {
        shadowDom = emailShadowDomRef.current.attachShadow({
          mode: "open",
        });
        adoptSheet(
          shadowDom,
          `
          p {
            margin-block-start: 0;
            margin-block-end: 0;
          }
          img {
            max-width: 100%;

            /**
             * When we send/receive images via email, often their height is controlled using inline styles.
             * However, sometimes their width is too large, so above we set a max-width of 100%.
             * So, we need to set a height so that the image doesn't squish.
             */
            height: auto !important;

          }
        `,
        );
      }

      emailShadowDomRef.current.classList.add(emailMessageCss.shadowDomWrapper);

      const rootElement = document.createElement("div");
      rootElement.innerHTML = shortenedMessage;
      updateLinksToOpenInNewTab(rootElement);
      shadowDom.appendChild(rootElement);
    }
    return () => {
      if (emailShadowDomRef?.current?.shadowRoot) {
        emailShadowDomRef.current.shadowRoot.innerHTML = "";
      }
    };
  }, [shortenedMessage, collapsed]);

  type VaryingByMessageTypeProps = {
    avatarGroup: {
      headerColor: string;
      subtlerColor: string;
    };
  };

  /* Most things are covered by css selectors of these classNames, but some things need to be passed as props to arbiter components. */
  const varyingByMessageTypePropsMap = {
    [emailMessageCss.internal]: {
      avatarGroup: {
        headerColor: "var(--redo-colors-text-text-primary-900) !important",
        subtlerColor: "var(--redo-colors-text-text-tertiary-600) !important",
      },
    },
    [emailMessageCss.merchant]: {
      avatarGroup: {
        headerColor: "var(--redo-colors-text-text-primary-900) !important",
        subtlerColor: "var(--redo-colors-text-text-tertiary-600) !important",
      },
    },
    [emailMessageCss.normal]: {
      avatarGroup: {
        headerColor: "var(--redo-colors-text-text-primary-900) !important",
        subtlerColor: "var(--redo-colors-text-text-primary-900) !important",
      },
    },
  };
  const messageVariantClassname =
    visibility === MessageVisibility.INTERNAL
      ? emailMessageCss.internal
      : isUser
        ? emailMessageCss.merchant
        : emailMessageCss.normal;

  const messageVariantProps = varyingByMessageTypePropsMap[
    messageVariantClassname as keyof typeof varyingByMessageTypePropsMap
  ] as VaryingByMessageTypeProps;

  // TODO: Do more than just use gravatar. We may want a way for support reps to upload profile photos,
  // and we may want something to get better photos for customers as well.
  let senderImage = useMemo(() => {
    const senderEmail =
      message.type === "merchant" || message.type === "system"
        ? message.user?.email
        : message.customer.email;
    if (senderEmail) {
      return Gravatar({
        email: senderEmail,
        defaultImage: "404",
        protocol: location.protocol === "https:" ? "https" : undefined,
        size: Math.ceil(devicePixelRatio * 32),
      });
    } else return null;
  }, [devicePixelRatio, message]);
  if (message.type === "customer") {
    if (message.customer === conversation.customer.customer) {
      if (conversation.customer?.image?.url) {
        senderImage = conversation.customer.image.url;
      }

      if (
        [
          ConversationPlatform.INSTAGRAM,
          ConversationPlatform.INSTAGRAM_COMMENTS,
        ].includes(conversation.platform) &&
        conversation.customer?.instagram?.profilePic
      ) {
        senderImage = conversation.customer.instagram.profilePic;
      } else if (
        conversation.platform === ConversationPlatform.FACEBOOK &&
        conversation.customer?.facebook?.profilePic
      ) {
        senderImage = conversation.customer.facebook.profilePic;
      }
    } else if (profilePictures && profilePictures[message.customer]) {
      senderImage = profilePictures[message.customer];
    }
  }
  /*
   Show & enable reply buttons if this message is public, we're not already replying, and we have an email message id
   Having an email message id is super important so that drafts know what message they're replying to
   We could have probably just used the redo message id, which would have proably simplified some logic
   Also don't show if we are currently in the five second period between "send" click and actually sending
   This is important because a user could try to reply to the same message again (before we've actually sent it) which would override the draft message and break things
  */
  const shouldShowReplyButtons =
    visibility !== MessageVisibility.INTERNAL &&
    replyDraft.status === "noDraft" &&
    canReply;

  // Disable the buttons if we are sending a message in this conversation already
  const shouldDisableReplyButtons = conversationSendStates.has(
    conversation._id,
  );

  const commonButtonProps = {
    hierarchy: RedoButtonHierarchy.SECONDARY,
    size: RedoButtonSize.REGULAR,
    disabled: shouldDisableReplyButtons,
  };

  const replyButtons = (
    <Flex className={emailMessageCss.emailContentPaddedContainer} gap="xl">
      {shouldShowReplyButton && (
        <RedoButton
          IconLeading={CornerUpLeftSvg}
          onClick={() => {
            amplitude.logEvent("select-supportTicketEmailReply", {
              conversationId: conversation._id,
              channel: conversation.platform,
            });
            setReplyDraft(getInitialDraft(EmailReplyType.REPLY));
          }}
          text="Reply"
          {...commonButtonProps}
        />
      )}

      {shouldShowReplyAllButton && (
        <RedoButton
          IconLeading={CornerUpLeftDoubleSvg}
          onClick={() => {
            amplitude.logEvent("select-supportTicketEmailReplyAll", {
              conversationId: conversation._id,
              channel: conversation.platform,
            });
            setReplyDraft(getInitialDraft(EmailReplyType.REPLY_ALL));
          }}
          text="Reply All"
          {...commonButtonProps}
        />
      )}

      <RedoButton
        IconLeading={CornerUpRightSvg}
        onClick={() => {
          amplitude.logEvent("select-supportTicketEmailForward", {
            conversationId: conversation._id,
            channel: conversation.platform,
          });
          setShouldPopupRecipientsModalBecauseForwardButtonClicked(true);
          setReplyDraft(getInitialDraft(EmailReplyType.FORWARD));
        }}
        text="Forward"
        {...commonButtonProps}
      />
    </Flex>
  );

  return (
    <Flex
      dir="column"
      {...(!collapsed ? { pb: "xl" } : {})}
      className={classNames(
        emailMessageCss.emailContainer,
        messageVariantClassname,
        {
          [emailMessageCss.collapsed]: collapsed,
        },
      )}
    >
      <Flex
        align="center"
        className={classNames(
          emailMessageCss.emailHeader,
          messageVariantClassname,
        )}
        gap="4xl"
        justify="space-between"
        onMouseDown={(e) => {
          setMouseDownLoc({ x: e.clientX, y: e.clientY });
        }}
        onMouseUp={(e) => {
          if (
            mouseDownLoc &&
            Math.abs(e.clientX - mouseDownLoc?.x) < 5 &&
            Math.abs(e.clientY - mouseDownLoc?.y) < 5
          ) {
            setCollapsed(!collapsed);
          }
        }}
        p="xl"
        shrink={0}
      >
        <AvatarLabelGroup
          avatar={(p) => (
            <Avatar
              alt="avatar image"
              imageUrl={senderImage || "404"}
              name={username}
            />
          )}
          infoRow={
            <>
              {collapsed ? (
                <div className={emailMessageCss.infoRowBoundedContent}>
                  <Text
                    className={emailMessageCss.emailShortenedSummary}
                    color={messageVariantProps.avatarGroup.subtlerColor}
                    fontSize="xs"
                    pb="xxs"
                    pt="xxs"
                  >
                    {message.content}
                  </Text>
                </div>
              ) : visibility === MessageVisibility.PUBLIC ? (
                <>
                  <CustomTooltip
                    placement="bottom-end"
                    title={
                      <EmailAddressesTooltip
                        bcc={bcc}
                        cc={cc}
                        date={date}
                        from={from ?? { email: "", name: "" }}
                        subject={subject}
                        to={to}
                      />
                    }
                  >
                    <Flex
                      align="center"
                      className={classNames(
                        emailMessageCss.emailInfoIcon,
                        messageVariantClassname,
                      )}
                      justify="center"
                      onMouseUp={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <InfoSvg />
                    </Flex>
                  </CustomTooltip>
                  <Text
                    color={messageVariantProps.avatarGroup.subtlerColor}
                    fontSize="xs"
                    pb="xxs"
                    pt="xxs"
                  >
                    {stringifyEmailRecipientsInfo({
                      to: to,
                      cc: cc,
                      bcc: bcc,
                    })}
                  </Text>
                </>
              ) : null}
            </>
          }
          usernameRow={
            <Flex align="center">
              <Text
                color={messageVariantProps.avatarGroup.headerColor}
                fontSize="sm"
                fontWeight="semibold"
              >
                {username}
              </Text>
              {message.type === "merchant" &&
                message.visibility !== MessageVisibility.INTERNAL && (
                  <RedoBadge
                    size={RedoBadgeSize.X_SMALL}
                    text="Merchant reply"
                  />
                )}
              {getPublicDraftReplyingToMessage(conversation, message) && (
                <RedoBadge
                  color={RedoBadgeColor.GRAY}
                  size={RedoBadgeSize.X_SMALL}
                  text="Draft"
                />
              )}
              {visibility == MessageVisibility.INTERNAL && (
                <RedoBadge
                  color={RedoBadgeColor.WARNING}
                  size={RedoBadgeSize.X_SMALL}
                  text="Internal note"
                  type={RedoBadgeType.ALTERNATE}
                />
              )}
            </Flex>
          }
        />
        <Flex align="center" justify="flex-end" shrink="0">
          {!!files.length && (
            <RedoBadge
              segmentLeading={{ type: "icon", Icon: PaperclipSvg }}
              size={RedoBadgeSize.SMALL}
              text={`${files.length} attachment${files.length > 1 ? "s" : ""}`}
            />
          )}
          <Text
            color={messageVariantProps.avatarGroup.subtlerColor}
            fontSize="xs"
          >
            {date}
          </Text>
        </Flex>
      </Flex>
      {!collapsed && (
        <>
          <Flex className={emailMessageCss.emailContentPaddedContainer}>
            <div ref={emailShadowDomRef} />
          </Flex>
          {!!files.length && (
            <Flex
              className={emailMessageCss.emailContentPaddedContainer}
              dir="row"
              gap="xl"
              wrap="wrap"
            >
              {files.map((file) => (
                <Flex
                  className={emailMessageCss.attachment}
                  key={`keyforemailattachments${file._id}`}
                  radius="xs"
                >
                  <AttachmentThumbnail
                    description={file.description}
                    mimeType={file.mimeType}
                    url={file.url}
                  />
                </Flex>
              ))}
            </Flex>
          )}
          {showToggleViewReplyButton && (
            <Flex
              className={emailMessageCss.emailContentPaddedContainer}
              justify="flex-start"
            >
              <RedoButton
                hierarchy={RedoButtonHierarchy.TERTIARY}
                IconLeading={() => <DotsIconSvg />}
                onClick={() => setHideReplies(!hideReplies)}
                size={RedoButtonSize.SMALL}
              />
            </Flex>
          )}
          {shouldShowReplyButtons && replyButtons}
          {replyDraft.status === "drafting" && canReply && (
            <Flex
              align="flex-start"
              className={emailMessageCss.messageInputContainer}
              justify="flex-start"
            >
              <Flex
                className={classNames(
                  emailMessageCss.draftEditor,
                  emailMessageCss.wrappingText,
                )}
                grow={1}
                shrink={0}
              >
                <MessageInput
                  conversation={conversation}
                  emailDraftProps={{
                    draftInfo: replyDraft,
                    handleSetReplyDraft: handleSetReplyDraft,
                    setTriggerReinitalizeDraft: setTriggerReinitalizeDraft,
                    shouldPopupRecipientsModalBecauseForwardButtonClicked,
                    setShouldPopupRecipientsModalBecauseForwardButtonClicked,
                  }}
                  messageIdForKey={message._id}
                  nextConversationInList={nextConversationInList}
                  prevConversationInList={prevConversationInList}
                  setActiveConversation={setActiveConversation}
                  setErrorMessage={setErrorMessage}
                  setShowErrorMessage={setShowErrorMessage}
                  setShowFullCommentThread={setShowFullCommentThread}
                  setTyping={setTyping}
                  showFullCommentThread={showFullCommentThread}
                  typing={typing}
                />
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
});
