import { useHandler } from "@redotech/react-util/hook";
import {
  Step as ModelStep,
  Payer,
  UncoveredPayer,
} from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import ToolIcon from "@redotech/redo-web/arbiter-icon/tool-02.svg";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Switch } from "@redotech/redo-web/switch";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import { StepType, StepTypeDetailsProps } from "./step";

type SubmitWarrantyOption = {
  labelPayer: UncoveredPayer;
};

export interface State {
  customTitle?: string;
  option: SubmitWarrantyOption;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handleSafetyNet = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.option.labelPayer = value ? Payer.MERCHANT : Payer.CUSTOMER;
      }),
    );
  });
  return (
    <LabeledInput
      description="Merchant pays for labels not covered by Redo."
      label="Safety net"
    >
      <Switch
        disabled={!canEditSettings}
        onChange={handleSafetyNet}
        value={state.option.labelPayer === Payer.MERCHANT}
      />
    </LabeledInput>
  );
});

export const SUBMIT_REPAIR: StepType<State, ModelStep.SubmitRepair> = {
  Details,
  customTitle: (state) => state.customTitle,
  title: "Submit Repair",
  valid: () => true,
  Icon: ToolIcon,
  description(state: State) {
    return "Submit a repair request";
  },
  downstream() {
    return [];
  },
  empty: {
    option: {
      labelPayer: Payer.CUSTOMER,
    },
  },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  toModel(state: State): ModelStep.SubmitRepair {
    return {
      customTitle: state.customTitle,
      type: ModelStep.SUBMIT_REPAIR,
      option: {
        labelPayer: state.option.labelPayer,
      },
    };
  },
  fromModel(model: ModelStep.SubmitRepair): State {
    return {
      customTitle: model.customTitle,
      option: {
        labelPayer: model.option?.labelPayer ?? Payer.CUSTOMER,
      },
    };
  },
};
