/**
 * Convert {@link ReadableStream} to {@link AsyncIterableIterator}
 */
export async function* streamAsyncIterator<T>(
  stream: ReadableStream<T>,
  abort?: AbortSignal,
): AsyncIterableIterator<T> {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}
