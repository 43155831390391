import { memo } from "react";
import * as systemMessageCss from "./system-message.module.css";
export const SystemMessage = memo(function SystemMessage({
  message,
}: {
  message: string;
}) {
  return (
    <div className={systemMessageCss.systemMessageWrapper}>
      <hr className={systemMessageCss.divider} />
      <div className={systemMessageCss.systemMessage}>
        <p>{message}</p>
      </div>
      <hr className={systemMessageCss.divider} />
    </div>
  );
});
