// extracted by mini-css-extract-plugin
export var box = "_7i-1";
export var checked = "_7i-5";
export var descriptionSpacer = "_7i-9";
export var disabled = "_7i-4";
export var icon = "_7i-6";
export var input = "_7i-0";
export var labelSpacer = "_7i-8";
export var medium = "_7i-3";
export var small = "_7i-2";
export var text = "_7i-7";