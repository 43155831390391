import type { Product, Return } from "@redotech/redo-model/return";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import {
  LabeledInput,
  LabelPosition,
  LabelSize,
} from "@redotech/redo-web/labeled-input";
import { Switch, SwitchSize } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useMemo } from "react";
import { returnKindNameProduct } from "../../util";
import * as productItemSummaryRowCss from "./product-item-summary-row.module.css";
import { ItemRestockState } from "./util";

export type ProductItemSummaryRowRestockSwitch = {
  hideSwitch?: boolean;
} & (
  | {
      itemRestockState: ItemRestockState.ABLE_TO_RESTOCK;
      value: boolean;
      setValue: (value: boolean) => void;
    }
  | {
      itemRestockState: ItemRestockState.ALREADY_RESTOCKED;
    }
  | {
      itemRestockState: ItemRestockState.NOT_RESTOCKABLE;
    }
);

export const ProductItemSummaryRow = memo(function ProductItemSummaryRow({
  item,
  return_,
  restockSwitch,
  refundAmount,
}: {
  item: Product;
  return_: Return;
  restockSwitch: ProductItemSummaryRowRestockSwitch;
  refundAmount: string | number;
}) {
  const { formatCurrency } = useContext(CurrencyContext);

  const itemSubtitle = useMemo(() => {
    const _refundAmount = return_ ? formatCurrency(refundAmount) : undefined;
    return _refundAmount
      ? `${item.variant_title} / ${_refundAmount}`
      : item.variant_title;
  }, [formatCurrency, return_, refundAmount, item]);

  return (
    <Flex flex={1} gap="lg" wrap="wrap">
      <img
        className={productItemSummaryRowCss.itemImage}
        src={item.images[0]}
      />
      <Flex
        className={productItemSummaryRowCss.itemDetailsTitle}
        dir="column"
        flex={1}
        gap="none"
      >
        <Text fontSize="sm" fontWeight="medium" textColor="primary">
          {item.product_title}
        </Text>
        <Flex wrap="wrap">
          <Text fontSize="xs" fontWeight="regular" textColor="secondary">
            {itemSubtitle}
          </Text>
          <RedoBadge
            color={RedoBadgeColor.GRAY}
            size={RedoBadgeSize.X_SMALL}
            text={returnKindNameProduct(item, return_.provision, return_.type)}
          />
        </Flex>
      </Flex>
      {restockSwitch.itemRestockState !== ItemRestockState.NOT_RESTOCKABLE &&
        !restockSwitch.hideSwitch && (
          <Flex
            align="center"
            className={productItemSummaryRowCss.restockItemSwitchContainer}
          >
            <LabeledInput
              label="Restock item"
              position={LabelPosition.RIGHT}
              size={LabelSize.EXTRA_SMALL}
            >
              <Switch
                disabled={
                  restockSwitch.itemRestockState ===
                  ItemRestockState.ALREADY_RESTOCKED
                }
                disabledTooltip={
                  restockSwitch.itemRestockState ===
                  ItemRestockState.ALREADY_RESTOCKED
                    ? "Item already restocked"
                    : undefined
                }
                onChange={(value: boolean) => {
                  if (
                    restockSwitch.itemRestockState ===
                    ItemRestockState.ABLE_TO_RESTOCK
                  ) {
                    restockSwitch.setValue(value);
                  }
                }}
                position={LabelPosition.RIGHT}
                size={SwitchSize.EXTRA_SMALL}
                value={
                  restockSwitch.itemRestockState ===
                  ItemRestockState.ALREADY_RESTOCKED
                    ? true
                    : restockSwitch.value
                }
              />
            </LabeledInput>
          </Flex>
        )}
    </Flex>
  );
});
