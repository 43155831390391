// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_90-0";
export var footer = "_90-2";
export var infoSection = "_90-3";
export var infoSectionContainer = "_90-4";
export var infoSectionTitleRow = "_90-5";
export var itemDetails = "_90-6";
export var line = "_90-7";
export var lineItemImage = "_90-1";
export var lineItems = "_90-8";
export var modalContent = "_90-9";
export var multiLineInfo = "_90-a";
export var orderName = "_90-b";
export var strong = "_90-c";