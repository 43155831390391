import { optionalEqual } from "@redotech/util/equal";
import { SortableConversationTableColumn, TableSort } from "../table";
import { filterArrayFormat } from "./conversation-filter-formats";
import { ConversationFiltersV3 } from "./conversation-filters";

const tableSortsEqual = optionalEqual<
  TableSort<SortableConversationTableColumn>
>((a, b) => a.direction === b.direction && a.key === b.key);

export function conversationFiltersEquivalent(
  filters1: ConversationFiltersV3 | undefined,
  filters2: ConversationFiltersV3 | undefined,
): boolean {
  if (!filters1 && !filters2) {
    return true;
  }
  if (!filters1 || !filters2) {
    return false;
  }

  if (filters1.status !== filters2.status) {
    return false;
  }
  if ((filters1.search || "") !== (filters2.search || "")) {
    return false;
  }
  if (!tableSortsEqual(filters1.sort, filters2.sort)) {
    return false;
  }
  if (filters1.customerEmail !== filters2.customerEmail) {
    return false;
  }

  if (filters1.advancedFilters.length !== filters2.advancedFilters.length) {
    return false;
  }

  const filters1Sort = [...filters1.advancedFilters].sort((a, b) =>
    a.type.localeCompare(b.type),
  );
  const filters2Sort = [...filters2.advancedFilters].sort((a, b) =>
    a.type.localeCompare(b.type),
  );

  const filters1JsonString = JSON.stringify(
    filterArrayFormat.write(filters1Sort),
  );
  const filters2JsonString = JSON.stringify(
    filterArrayFormat.write(filters2Sort),
  );

  return filters1JsonString === filters2JsonString;
}
