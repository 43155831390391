import Modal from "@mui/material/Modal";
import { memo, useState } from "react";
import * as imageCss from "./image.module.css";

/**
 * Focusable image
 */
export const PreviewImage = memo(function PreviewImage({
  src,
}: {
  src: string;
}) {
  const [open, setOpen] = useState(false);

  // Check if src is a video
  const isVideo = /\.(mp4|mov|avi|mkv)$/i.test(src);

  return (
    <>
      <Modal onClose={() => setOpen(false)} open={open}>
        {isVideo ? (
          <video className={imageCss.image} controls src={src} />
        ) : (
          <img className={imageCss.image} src={src} />
        )}
      </Modal>

      <button
        className={imageCss.button}
        onClick={() => setOpen(true)}
        type="button"
      >
        {isVideo ? (
          <video className={imageCss.preview} src={src} />
        ) : (
          <img className={imageCss.preview} src={src} />
        )}
      </button>
    </>
  );
});
