import { z } from "zod";
import { upsellPageDataSchema } from "./upsell-page-data-schema";

export const getUpsellPageSchema = {
  input: z.object({
    upsellPageId: z.string(),
  }),
  output: z.object({
    upsellPageId: z.string(),
    upsellPageData: upsellPageDataSchema,
  }),
};
