import { useRequiredContext } from "@redotech/react-util/context";
import { createContext, ReactNode } from "react";
import { RedoMerchantClientContext } from "../client/context";
import { MerchantAppEventServer } from "./merchant-app-event-server";

export const MerchantAppEventServerContext = createContext<
  MerchantAppEventServer | undefined
>(undefined);

export function MerchantAppEventServerProvider({
  children,
}: {
  children?: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const eventServer = new MerchantAppEventServer(client);

  return (
    <MerchantAppEventServerContext.Provider value={eventServer}>
      {children}
    </MerchantAppEventServerContext.Provider>
  );
}
