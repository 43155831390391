// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_7h-e";
export var baseCustomerActivityCardArrowRightContainer = "_7h-d";
export var baseCustomerActivityCardContainer = "_7h-b";
export var baseCustomerActivityCardContainerHovered = "_7h-c";
export var customerActivityDataClaimIcon = "_7h-7";
export var customerActivityDataClaimIconContainer = "_7h-6";
export var customerActivityDataConversationIcon = "_7h-5";
export var customerActivityDataConversationIconContainer = "_7h-4";
export var customerActivityDataOrderIcon = "_7h-1";
export var customerActivityDataOrderIconContainer = "_7h-0";
export var customerActivityDataReturnIcon = "_7h-3";
export var customerActivityDataReturnIconContainer = "_7h-2";
export var customerActivityShoppingIconContainer = "_7h-8";
export var messageBubble = "_7h-9";
export var messageBubbleText = "_7h-a";