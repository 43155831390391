import { BlockLayout } from "@redotech/redo-web/flowchart";
import { StepType } from "./step";

export const EMPTY: StepType<void, never> = {
  description() {
    return "";
  },
  Details() {
    return null;
  },
  customTitle() {
    return undefined;
  },
  downstream() {
    return [];
  },
  empty: undefined,
  fromModel() {
    throw new Error("Unsupported");
  },
  layout() {
    return BlockLayout.COMPACT;
  },
  toModel() {
    throw new Error("Unsupported");
  },
  Icon() {
    return null;
  },
  stepDeleted(state) {
    return state;
  },
  title: "New step",
  valid() {
    return false;
  },
};
