import { IterableMap } from "@redotech/react-util/component";
import { useInput } from "@redotech/react-util/form";
import { Product, Return, ReturnAddress } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import {
  ButtonPlacement,
  RedoModal,
  RedoModalSize,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import {
  ButtonSize,
  RedoButtonCloseX,
} from "@redotech/redo-web/arbiter-components/redo-button-close-x";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabelTheme } from "@redotech/redo-web/labeled-input";
import { RadioGroup } from "@redotech/redo-web/radio";
import {
  FormMultiSelectDropdown,
  FormSelectDropdown,
  SelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import { Text } from "@redotech/redo-web/text";
import {
  FormTextInput,
  InputLines,
  InputTheme,
} from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  InputProvider,
  listInput,
  nonEmptyValidator,
} from "@redotech/ui/form";
import { memo, useState } from "react";
import * as returnCss from "../return.module.css";
import { ApprovalProductInfo } from "./approve-modal";

const shippingLabelInput = groupInput({
  id: input<symbol>({ equal: () => true }),
  items: input<Product[]>({ validator: nonEmptyValidator }),
  weight: groupInput({
    amount: input<string>({ validator: nonEmptyValidator }),
    unit: input<string>({ validator: nonEmptyValidator }),
  }),
  returnToAddress: input<ReturnAddress | undefined>(),
  labelNote: input<string>(),
});

const shippingLabelDefault = (
  id: symbol,
  returnAddress?: ReturnAddress,
): InputProvider.Value<typeof shippingLabelInput> => ({
  id,
  items: [],
  weight: { amount: "1.1", unit: "lbs" },
  returnToAddress: returnAddress,
  labelNote: "",
});

const allLabelsInput = listInput(
  () => shippingLabelInput,
  shippingLabelDefault,
  (i: shippingLabelValue) => i.id,
);

export type shippingLabelForm = InputProvider.Form<typeof shippingLabelInput>;
export type shippingLabelValue = InputProvider.Value<typeof shippingLabelInput>;

export type AllLabelsForm = InputProvider.Form<typeof allLabelsInput>;
export type AllLabelsValue = InputProvider.Value<typeof allLabelsInput>;

type ApproveInfo = {
  customerNotes: string;
  products: ApprovalProductInfo[];
  orderTags: readonly string[];
};

export const CreateLabelsModal = memo(function CreateLabelsModal({
  return_,
  team,
  onBack,
  onSubmit,
  approveInfo,
  onModalClose,
  address,
  setAddress,
  addressOptions,
  showAddressSelector,
}: {
  return_: Return;
  team: Team;
  onBack: () => void;
  onSubmit: (multipleLabels: AllLabelsValue | undefined) => void;
  onModalClose: () => void;
  approveInfo: ApproveInfo;
  address: ReturnAddress | undefined;
  setAddress: (address: ReturnAddress | undefined) => void;
  addressOptions: ReturnAddress[];
  showAddressSelector: boolean;
}) {
  const input = useInput(allLabelsInput, [
    shippingLabelDefault(Symbol(0), return_.merchant_address),
  ]);

  const productsToShip = return_.products.filter((p) =>
    approveInfo.products.some((product) => product._id === p._id),
  );

  const unusedProducts = productFilter(productsToShip, input.value, undefined);

  const [loading, setLoading] = useState<boolean>(false);

  const [labelOption, setLabelOption] = useState<
    "One label" | "Multiple labels"
  >("One label");
  return (
    <RedoModal
      buttonPlacement={ButtonPlacement.TIGHT}
      footerBorder
      headerBorder
      isOpen
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={onModalClose}
      primaryButton={{
        text: loading ? "processing..." : "Approve return",
        onClick: () => {
          setLoading(true);
          onSubmit(labelOption === "One label" ? undefined : input.value);
        },
        disabled:
          loading ||
          (labelOption === "Multiple labels" &&
            (!!input.allErrors.length ||
              unusedProducts.length > 0 ||
              input.value.length < 2)),
      }}
      secondaryButton={{ text: "Back", onClick: onBack }}
      theme={RedoModalTheme.NONE}
      title="Create labels"
      TitleIcon={undefined}
    >
      <Flex dir="column" py="xl">
        <RadioGroup
          alignDescription
          centerBubble={false}
          disabled={loading}
          optionDescription={(value) => {
            switch (value) {
              case "One label":
                return "Create one label for all items in this return";
              case "Multiple labels":
                return "Create multiple labels for this return";
              default:
                return "";
            }
          }}
          optionLabel={(value: any) =>
            value === "One label" ? (
              <Text fontWeight="bold">One label</Text>
            ) : (
              <Text fontWeight="bold">Multiple labels</Text>
            )
          }
          options={["One label", "Multiple labels"]}
          value={labelOption}
          valueChange={setLabelOption}
        />
        {labelOption === "Multiple labels" && (
          <ShippingLabels
            input={input}
            loading={loading}
            productsToShip={productsToShip}
            return_={return_}
            team={team}
          />
        )}
        {labelOption == "One label" && showAddressSelector && (
          <div className={returnCss.addressSpace}>
            <div className={returnCss.greenReturnSelectTitle}>
              Return Location:
            </div>
            <SelectDropdown
              className=""
              options={addressOptions}
              value={address}
              valueChange={(value) => {
                setAddress(value);
              }}
            >
              {(option) => (
                <div className="analyticsCss.dropdownOption">{option.name}</div>
              )}
            </SelectDropdown>
          </div>
        )}
      </Flex>
    </RedoModal>
  );
});

function productFilter(
  products: Product[],
  input: AllLabelsValue,
  labelInput: shippingLabelValue | undefined,
) {
  const unusedProducts = products.filter((p) => {
    const otherLabels = input.filter((label) => label !== labelInput);
    for (const label of otherLabels) {
      for (const item of label.items) {
        if (p._id === item._id) {
          return false;
        }
      }
    }
    return true;
  });
  return unusedProducts;
}

function timesProductUsed(product: Product, allLabelsForm: AllLabelsForm) {
  let times = 0;
  for (const labelInfo of allLabelsForm.value) {
    for (const item of labelInfo.items) {
      if (product === item) {
        times += 1;
      }
    }
  }
  return times;
}

const ShippingLabels = memo(function ShippingLabels({
  return_,
  team,
  input,
  productsToShip,
  loading,
}: {
  return_: Return;
  team: Team;
  input: AllLabelsForm;
  productsToShip: Product[];
  loading: boolean;
}) {
  const weightUnits = ["oz", "lbs", "g"];
  const addressOptions: ReturnAddress[] = [];

  for (const location of team.settings.locations) {
    if (location.address && location.address.street1) {
      addressOptions.push({
        ...location.address,
        address1: location.address.street1,
        address2: location.address.street2,
        phone: location.address.phone || "",
        latitude: undefined,
        longitude: undefined,
      });
    }
  }

  return (
    <Flex dir="column">
      <IterableMap items={input.inputs} keyFn={(_, i) => i}>
        {(shippingLabelInput: shippingLabelForm, i: number) => {
          return (
            <Flex dir="column" gap="3xl" py="xl">
              <Divider />
              <Flex justify="space-between">
                <Text fontWeight="bold">{`Shipping label ${i + 1}`}</Text>{" "}
                {i > 0 && (
                  <div
                    className={returnCss.trash}
                    onClick={() =>
                      input.setValue([
                        ...input.value.filter(
                          (label) => label !== shippingLabelInput.value,
                        ),
                      ])
                    }
                  >
                    <TrashIcon />
                  </div>
                )}
              </Flex>

              <FormMultiSelectDropdown
                disabled={loading}
                display={(value) => {
                  const names = value.map((product) => product.product_title);
                  return names.join(", ");
                }}
                input={shippingLabelInput.inputs.items}
                label="Items"
                labelTheme={LabelTheme.THIN}
                options={productsToShip}
              >
                {(option) => (
                  <Flex>
                    <Text fontSize="xs">{`${option.product_title}`}</Text>{" "}
                    <Text fontSize="xs">
                      {timesProductUsed(option, input)
                        ? ` ${timesProductUsed(option, input)} labels created`
                        : ""}
                    </Text>{" "}
                  </Flex>
                )}
              </FormMultiSelectDropdown>

              {!!(shippingLabelInput.value.items.length > 0) && (
                <ItemCard labelOptionsInput={shippingLabelInput} />
              )}

              <Flex align="flex-end">
                <Flex flex={12}>
                  <FormTextInput
                    disabled={loading}
                    fullwidth
                    input={shippingLabelInput.inputs.weight.inputs.amount}
                    label="Weight"
                    labeledInputClassName={returnCss.fullWidth}
                    labelTheme={LabelTheme.THIN}
                    type="number"
                  />
                </Flex>
                <Flex flex={2}>
                  <FormSelectDropdown
                    disabled={loading}
                    input={shippingLabelInput.inputs.weight.inputs.unit}
                    label=""
                    options={weightUnits}
                  >
                    {(option) => option}
                  </FormSelectDropdown>
                </Flex>
              </Flex>

              <FormSelectDropdown
                disabled={loading}
                input={shippingLabelInput.inputs.returnToAddress}
                label="Return Location"
                labelTheme={LabelTheme.THIN}
                options={[...addressOptions]}
                valueChange={(value) => {
                  shippingLabelInput.inputs.returnToAddress.setValue(value);
                }}
              >
                {(option) => (
                  <div className="analyticsCss.dropdownOption">
                    {option?.name}
                  </div>
                )}
              </FormSelectDropdown>

              <FormTextInput
                disabled={loading}
                input={shippingLabelInput.inputs.labelNote}
                label="Label instructions"
                labelTheme={LabelTheme.THIN}
                lines={InputLines.MULTI}
                theme={InputTheme.FORM}
              />
            </Flex>
          );
        }}
      </IterableMap>
      <Button
        fullWidth={false}
        icon={PlusIcon}
        iconAlign="left"
        onClick={() =>
          input.setValue([
            ...input.value,
            shippingLabelDefault(
              Symbol(input.value.length),
              return_.merchant_address,
            ),
          ])
        }
        theme={ButtonTheme.GHOST}
      >
        Create new label
      </Button>
    </Flex>
  );
});

const ItemCard = memo(function ItemCard({
  labelOptionsInput,
}: {
  labelOptionsInput: shippingLabelForm;
}) {
  return (
    <Flex wrap="wrap">
      {labelOptionsInput.value.items.map((product, i) => {
        return (
          <Flex
            align="center"
            className={returnCss.itemCard}
            key={i}
            p="sm"
            radius="md"
          >
            <img
              className={returnCss.createLabelsImage}
              src={product.images[0]}
            />
            <Text
              className={returnCss.noTextWrap}
              overflow="hidden"
              textOverflow="ellipsis"
            >{`${product.product_title}`}</Text>

            <RedoButtonCloseX
              onclick={() => {
                labelOptionsInput.inputs.items.setValue(
                  labelOptionsInput.value.items.filter((i) => i !== product),
                );
              }}
              onDarkBackground={false}
              size={ButtonSize.SMALL}
            />
          </Flex>
        );
      })}
    </Flex>
  );
});
