import * as classNames from "classnames";
import { memo } from "react";
import * as dividerCss from "./divider.module.css";
import { styleClasses, StyleProps } from "./styles";

export const Divider = memo(function Divider() {
  return <hr className={dividerCss.divider} />;
});

interface DividerWithTextProps extends StyleProps {
  text: string;
}

export const DividerText = memo(function DividerText(
  props: DividerWithTextProps,
) {
  return (
    <div
      className={classNames(
        styleClasses({
          ...props,
        }),
        dividerCss.dividerText,
      )}
    >
      {props.text}
    </div>
  );
});
