import { TeamCount } from "@redotech/redo-model/connected-team";
import { Notification } from "@redotech/redo-model/notification";
import { FrontendTeamNoticeEvent } from "@redotech/redo-model/team-notifications/team-notifications";
import { sinkPromise } from "@redotech/util/promise";
import { sleep } from "@redotech/util/schedule";
import { assertNever } from "@redotech/util/type";
import { RedoMerchantClient } from "../client";
import {
  getConnectedTeamsCountsStream,
  getTeamNotificationsStream,
  getUserNotificationsStream,
  listen,
} from "../support/utils";

type Callback<T> = (value: T) => any | ((value: T) => Promise<any>);
type UnlistenCallback = () => any | (() => Promise<any>);

export enum MerchantAppTopic {
  TEAM = "conversations",
  CONNECTED_TEAMS_COUNTS = "connectedTeamsCounts",
  USER_NOTIFICATIONS = "userNotifications",
}

type TeamSubscription<T = FrontendTeamNoticeEvent> = {
  topic: MerchantAppTopic.TEAM;
  callback: Callback<FrontendTeamNoticeEvent | T>;
};

type UserSubscription<T = Notification> = {
  topic: MerchantAppTopic.USER_NOTIFICATIONS;
  callback: Callback<Notification | T>;
};

type ConnectedTeamsCountsSubscription<T = TeamCount> = {
  topic: MerchantAppTopic.CONNECTED_TEAMS_COUNTS;
  callback: Callback<TeamCount | T>;
};

type SubscribeAndCallOnceCallback =
  | TeamSubscription<null>
  | UserSubscription<null>
  | ConnectedTeamsCountsSubscription<null>;

type EventServerCallback =
  | TeamSubscription
  | UserSubscription
  | ConnectedTeamsCountsSubscription;

export class MerchantAppEventServer {
  private readonly abortController = new AbortController();
  private haveSetupConnectedTeamsCountsListener = false;
  private haveSetupTeamListener = false;
  private userNotificationsSetup = false;

  constructor(private readonly client: RedoMerchantClient) {}

  private async setupTeamListener() {
    this.haveSetupTeamListener = true;
    try {
      for await (const value of listen({
        query: () =>
          getTeamNotificationsStream({
            authorization: this.client.authorization(),
            signal: this.abortController.signal,
          }),
        loopCondition: true,
      })) {
        Array.from(this.teamTopicListeners.values()).forEach((callback) =>
          callback(value as unknown as FrontendTeamNoticeEvent),
        );
      }
    } catch (e: unknown) {
      // Retry
      await sleep(Temporal.Duration.from({ seconds: 10 })).then(() => {
        sinkPromise(this.setupTeamListener());
      });
    }
  }

  private async setupUserNotificationsListener() {
    this.userNotificationsSetup = true;
    try {
      for await (const value of listen({
        query: () =>
          getUserNotificationsStream({
            authorization: this.client.authorization(),
            signal: this.abortController.signal,
          }),
        loopCondition: true,
      })) {
        Array.from(this.userNotificationsListeners.values()).forEach(
          (callback) => callback(value as unknown as Notification),
        );
      }
    } catch (e: unknown) {
      // Retry
      await sleep(Temporal.Duration.from({ seconds: 10 })).then(() => {
        sinkPromise(this.setupUserNotificationsListener());
      });
    }
  }

  private async setupConnectedTeamsCountsListener() {
    this.haveSetupConnectedTeamsCountsListener = true;
    try {
      for await (const value of listen({
        query: () =>
          getConnectedTeamsCountsStream({
            authorization: this.client.authorization(),
            signal: this.abortController.signal,
          }),
        loopCondition: true,
      })) {
        Array.from(this.connectedTeamsCountsTopicListeners.values()).forEach(
          (callback) => callback(value as unknown as TeamCount),
        );
      }
    } catch (e: unknown) {
      // Retry
      await sleep(Temporal.Duration.from({ seconds: 10 })).then(() => {
        sinkPromise(this.setupConnectedTeamsCountsListener());
      });
    }
  }

  private readonly userNotificationsListeners = new Set<
    Callback<Notification>
  >();
  private readonly teamTopicListeners = new Set<
    Callback<FrontendTeamNoticeEvent>
  >();
  private readonly connectedTeamsCountsTopicListeners = new Set<
    Callback<TeamCount>
  >();

  /**
   * Publish-subscribe pattern for getting notified when a value is written to a stream. Pass the callback (e.g. a fetch, or setState)
   * that will happen once the write happens, and the callback will be run on writes to the stream.
   * The callback function will immediately be run at the beginning as well, so you can set-and-forget.
   */
  public subscribeAndCallOnce(
    subscription: SubscribeAndCallOnceCallback,
  ): UnlistenCallback {
    subscription.callback(null);
    return this.subscribe(subscription);
  }

  public subscribe({ topic, callback }: EventServerCallback): UnlistenCallback {
    this.runSetup(topic);
    if (topic === MerchantAppTopic.TEAM) {
      this.teamTopicListeners.add(callback);
      const unlistenCallback = () => this.teamTopicListeners.delete(callback);
      return unlistenCallback;
    } else if (topic === MerchantAppTopic.CONNECTED_TEAMS_COUNTS) {
      this.connectedTeamsCountsTopicListeners.add(callback);
      const unlistenCallback = () =>
        this.connectedTeamsCountsTopicListeners.delete(callback);
      return unlistenCallback;
    } else if (topic === MerchantAppTopic.USER_NOTIFICATIONS) {
      this.userNotificationsListeners.add(callback);
      const unlistenCallback = () =>
        this.userNotificationsListeners.delete(callback);
      return unlistenCallback;
    } else {
      assertNever(topic);
    }
  }

  private runSetup(topic: MerchantAppTopic) {
    if (topic === MerchantAppTopic.TEAM) {
      if (!this.haveSetupTeamListener) {
        sinkPromise(this.setupTeamListener());
      }
    } else if (topic === MerchantAppTopic.CONNECTED_TEAMS_COUNTS) {
      if (!this.haveSetupConnectedTeamsCountsListener) {
        sinkPromise(this.setupConnectedTeamsCountsListener());
      }
    } else if (topic === MerchantAppTopic.USER_NOTIFICATIONS) {
      if (!this.userNotificationsSetup) {
        sinkPromise(this.setupUserNotificationsListener());
      }
    } else {
      assertNever(topic);
    }
  }
}
