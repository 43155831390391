import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import * as linkCss from "@redotech/redo-web/link.module.css";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { Spinner, SpinnerStyle } from "@redotech/redo-web/spinner";
import * as classNames from "classnames";
import { ReactNode, memo, useState } from "react";
import { RedoMerchantClientContext } from "../client/context";
import { getCurrentOnboarding, onboardingTc } from "../client/onboarding";
import * as indexCss from "./index.module.css";

const tcUrl = new URL("https://www.getredo.com/terms-conditions");
const setUpOnboardingUrl = new URL("https://calendly.com/d/3ph-3zm-27n");

export const Onboarding = memo(function Onboarding() {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [showModal, setShowModal] = useState(false);

  const [acceptTermsLoad, doAcceptTerms] = useTriggerLoad(async (signal) => {
    return alertOnFailure("Failed to onboard")(async () => {
      await onboardingTc(client, { signal });
      return true;
    });
  });

  const onboardingLoad = useLoad(async (signal) => {
    const data = await getCurrentOnboarding(client, { signal });
    setShowModal(!data.tcConfirmed);
  }, []);

  let title: ReactNode;
  let content: ReactNode;
  let footer: ReactNode;
  if (acceptTermsLoad.pending) {
    title = <>Setting up Redo</>;
    content = (
      <div className={indexCss.spinner}>
        <div className={indexCss.spinnerContainer}>
          <Spinner style={SpinnerStyle.AUTO} />
        </div>
      </div>
    );
    footer = <div />;
  } else if (acceptTermsLoad.value) {
    title = <>Welcome to Redo</>;
    content = (
      <>
        <div className={indexCss.text}>
          To finish setting up Redo, please schedule an appointment with our
          onboarding specialists.
        </div>
        <a
          className={classNames(linkCss.link, indexCss.featuredLink)}
          href={setUpOnboardingUrl.toString()}
          rel="noreferrer"
          target="_blank"
        >
          Schedule an appointment
        </a>
      </>
    );
    footer = (
      <Button
        className={indexCss.button}
        onClick={() => setShowModal(false)}
        theme={ButtonTheme.PRIMARY}
      >
        Continue to Redo
      </Button>
    );
  } else {
    title = <>Welcome to Redo</>;
    content = (
      <>
        <div className={indexCss.text}>
          To continue with installation, please review and accept the terms and
          conditions.
        </div>
        <a
          className={classNames(linkCss.link, indexCss.featuredLink)}
          href={tcUrl.toString()}
          rel="noreferrer"
          target="_blank"
        >
          Read them here
        </a>
      </>
    );
    footer = (
      <Button
        className={indexCss.button}
        onClick={() => doAcceptTerms()}
        theme={ButtonTheme.PRIMARY}
      >
        Accept
      </Button>
    );
  }

  return (
    <Modal
      footer={footer}
      hideCloseButton
      open={showModal}
      size={ModalSize.SMALL}
      title={title}
    >
      {acceptTermsLoad.error || onboardingLoad.error ? (
        <div>There was an error during onboarding.</div>
      ) : (
        content
      )}
    </Modal>
  );
});
