import { RouteObject } from "react-router-dom";

const hidePadding = (url: string): boolean => {
  return !/reviews\/dashboard/.test(url);
};

export const reviewsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Reviews", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: <Page hidePadding={hidePadding} />,
      };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Dashboard } = await import("./dashboard/dashboard");
          return {
            Component: Dashboard,
          };
        },
      },
      {
        path: "dashboard",
        handle: { breadcrumb: "Dashboard" },
        async lazy() {
          const { Dashboard } = await import("./dashboard/dashboard");
          return {
            Component: Dashboard,
          };
        },
      },
      {
        path: "all-reviews",
        handle: { breadcrumb: "All reviews" },
        async lazy() {
          const { AllReviews } = await import("./all-reviews/all-reviews");
          return {
            Component: AllReviews,
          };
        },
      },
      {
        path: "products",
        handle: { breadcrumb: "Products" },
        async lazy() {
          const { Products } = await import("./products/products");
          return {
            Component: Products,
          };
        },
      },
    ],
  },
];
