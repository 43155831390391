import { Currency } from "@redotech/money/currencies";
import { RegisterInit } from "@shopify/web-pixels-extension";
import {
  MoneyV2,
  PixelEventsProductAddedToCartData,
  PixelEventsProductViewedData,
} from "@shopify/web-pixels-extension/build/ts/types/PixelEvents";
import { ConversationStatus } from "../conversation";
import { MarketingSubscriptionType } from "../marketing";
import { ReturnStatus } from "../return-status";
import { CompleteOrderFulfillmentStatus } from "../shopify-order";
import { SignupForm } from "../signup-form";

export enum CustomerEventType {
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED",
  ORDER_UPDATED = "ORDER_UPDATED",

  RETURN_CREATED = "RETURN_CREATED",
  RETURN_STATUS_UPDATED = "RETURN_STATUS_UPDATED",
  RETURN_COMMENT_CREATED = "RETURN_COMMENT_CREATED",

  CLAIM_CREATED = "CLAIM_CREATED",
  CLAIM_STATUS_UPDATED = "CLAIM_STATUS_UPDATED",
  CLAIM_COMMENT_CREATED = "CLAIM_COMMENT_CREATED",

  CONVERSATION_CREATED = "CONVERSATION_CREATED",
  CONVERSATION_STATUS_UPDATED = "CONVERSATION_STATUS_UPDATED",
  CONVERSATION_MESSAGE_CREATED = "CONVERSATION_MESSAGE_CREATED",
  EXTERNAL_PLATFORM_CUSTOMER_CREATED = "EXTERNAL_PLATFORM_CUSTOMER_CREATED",

  STOREFRONT_LOGIN = "STOREFRONT_LOGIN",

  MARKETING_SUBSCRIBED = "MARKETING_SUBSCRIBED",
  MARKETING_CONFIRMED = "MARKETING_CONFIRMED",
  MARKETING_UNSUBSCRIBED = "MARKETING_UNSUBSCRIBED",

  PRODUCT_VIEWED = "PRODUCT_VIEWED",
  PRODUCT_ADDED_TO_CART = "PRODUCT_ADDED_TO_CART",
}

export const CUSTOMER_EVENT_DISCRIMINATOR_KEY = "eventType" as const;

export enum ExternalCustomerPlatform {
  SHOPIFY = "shopify",
}

export interface BaseCustomerEvent {
  _id: string;
  customer?: string; // TODO: make required (a customer will always exist for an event after the customer merging algorithm finishes)
  team: string;
  email?: string;
  // TODO: the potential identifiers on an event need to exactly match the potential identifiers on a customer
  // except it should be `redoId` instead of `_id`
  // This is different from the `customer` field,
  // because redoId is the id at the time of event creation (and will not change),
  // while customer is the current id (which may change if the customer is merged)
  timestamp: Date;
  [CUSTOMER_EVENT_DISCRIMINATOR_KEY]: CustomerEventType;
}

// ###
// OrderCustomerEvents
// ###
export interface IOrderCustomerEvent extends BaseCustomerEvent {
  orderId: string; // id of the Redo Order document
  shopifyId: string; // id of the Shopify order
}

export interface OrderCreatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_CREATED;
  itemCount: number;
  orderName: string;
  totalPrice: string;
  currency?: Currency;
}

export interface OrderUpdatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_UPDATED;
}

export interface OrderStatusUpdatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_STATUS_UPDATED;
  newStatus: CompleteOrderFulfillmentStatus;
}

// ###
// ReturnCustomerEvents
// ###
export interface IReturnCustomerEvent extends BaseCustomerEvent {
  returnId: string;
}

export interface ReturnCreatedCustomerEvent extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_CREATED;
}

export interface ReturnStatusUpdatedCustomerEvent extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_STATUS_UPDATED;
  newStatus: ReturnStatus;
}

export interface ReturnCommentCreatedCustomerEvent
  extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_COMMENT_CREATED;
  messageId: string;
}

// ###
// ClaimCustomerEvents
// ###
export interface IClaimCustomerEvent extends BaseCustomerEvent {
  claimId: string;
}

export interface ClaimCreatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_CREATED;
}

export interface ClaimStatusUpdatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_STATUS_UPDATED;
  newStatus: ReturnStatus;
}

export interface ClaimCommentCreatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_COMMENT_CREATED;
  messageId: string;
}

// ###
// ConversationCustomerEvents
// ###
export interface IConversationCustomerEvent extends BaseCustomerEvent {
  conversationId: string;
}

export interface ConversationCreatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_CREATED;
}

export interface ConversationStatusUpdatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_STATUS_UPDATED;
  newStatus: ConversationStatus;
}

export interface ConversationMessageCreatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_MESSAGE_CREATED;
  messageId: string;
}

// ###
// ExternalPlatformCustomerCreatedEvent
// ###
export interface ExternalPlatformCustomerCreatedEvent
  extends BaseCustomerEvent {
  eventType: CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED;
  externalPlatform: ExternalCustomerPlatform;
  externalPlatformCustomerId: string;
}

// ###
// StorefrontLoginEvent
// ###
export interface StorefrontLoginEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.STOREFRONT_LOGIN;
  multipassLogin: boolean;
}

// ###
// MarketingSubscribeEvent
// ###
export interface MarketingSubscriptionMetadata {
  entryPoint: string;
  formId?: string;
  /**
   * Only needed for auditing purposes.
   * Don't sweat it too much.
   */
  formSnapshot: FormSnapshot;
  customerIpAddress?: string;
}
export type FormSnapshot = SignupForm | string;
export interface MarketingSubscribeEvent
  extends BaseCustomerEvent,
    MarketingSubscriptionMetadata {
  eventType: CustomerEventType.MARKETING_SUBSCRIBED;
  // not a discriminated union because people can sign up for both email and SMS at the same time
  email?: string;
  phoneNumber?: string;
}

// ###
// MarketingConfirmEvent
// ###
export interface MarketingConfirmEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.MARKETING_CONFIRMED;
  subscriptionType: MarketingSubscriptionType;
}

// ###
// MarketingUnsubscribeEvent
// ###
export interface MarketingUnsubscribeEvent extends BaseCustomerEvent {
  eventType: CustomerEventType.MARKETING_UNSUBSCRIBED;
  subscriptionType: MarketingSubscriptionType;
}

const marketingEventTypes = [
  CustomerEventType.MARKETING_SUBSCRIBED,
  CustomerEventType.MARKETING_CONFIRMED,
  CustomerEventType.MARKETING_UNSUBSCRIBED,
] as const;
export type MarketingEventType = (typeof marketingEventTypes)[number];

export interface ProductInfo {
  productId: string;
  productTitle: string;
  variantId?: string;
  variantTitle?: string;
  url?: string;
  price: MoneyV2;
  priceRange?: {
    minVariantPrice: MoneyV2;
    maxVariantPrice: MoneyV2;
  };
  imageUrl?: string;
  handle?: string;
}

export interface BaseShoppingEvent extends BaseCustomerEvent {
  /**
   * The shopify event id of the shopping event
   */
  externalEventId: string;
  externalCustomer: RegisterInit["data"]["customer"];
}

export interface ProductViewedShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.PRODUCT_VIEWED;
  productVariant: PixelEventsProductViewedData["productVariant"];
  cart: RegisterInit["data"]["cart"];
}

export interface ProductAddedToCartShoppingEvent extends BaseShoppingEvent {
  eventType: CustomerEventType.PRODUCT_ADDED_TO_CART;
  cartLine: PixelEventsProductAddedToCartData["cartLine"];
}

export type CustomerEvent =
  | OrderCreatedCustomerEvent
  | OrderUpdatedCustomerEvent
  | OrderStatusUpdatedCustomerEvent
  | ReturnCreatedCustomerEvent
  | ReturnStatusUpdatedCustomerEvent
  | ReturnCommentCreatedCustomerEvent
  | ClaimCreatedCustomerEvent
  | ClaimStatusUpdatedCustomerEvent
  | ClaimCommentCreatedCustomerEvent
  | ConversationCreatedCustomerEvent
  | ConversationStatusUpdatedCustomerEvent
  | ConversationMessageCreatedCustomerEvent
  | ExternalPlatformCustomerCreatedEvent
  | StorefrontLoginEvent
  | MarketingSubscribeEvent
  | MarketingConfirmEvent
  | MarketingUnsubscribeEvent
  | ProductViewedShoppingEvent
  | ProductAddedToCartShoppingEvent;

export type Create<T> = T extends OrderStatusUpdatedCustomerEvent
  ? Omit<T, "_id" | "timestamp" | "newStatus"> & {
      newStatus: string;
    }
  : Omit<T, "_id" | "timestamp"> & {
      _id?: string;
      timestamp?: Date;
    };
