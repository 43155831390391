// extracted by mini-css-extract-plugin
export var createdDate = "_7z-1";
export var divider = "_7z-2";
export var headerDivider = "_7z-3";
export var messagesCard = "_7z-0";
export var messagesContainer = "_7z-7";
export var messagesContainerWrapper = "_7z-4";
export var pointerCapturingButtonBoxes = "_7z-a";
export var showLeftPanelButton = "_7z-9";
export var showMoreButton = "_7z-c";
export var showMoreButtonContainer = "_7z-b";
export var summaryTitle = "_7z-6";
export var systemMessageCardWrapper = "_7z-d";
export var topButtons = "_7z-8";
export var withPadding = "_7z-5";