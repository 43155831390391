import { useHandler } from "@redotech/react-util/hook";
import { Order, YofiRiskLevels } from "@redotech/redo-model/order";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import type { Return } from "@redotech/redo-model/return";
import { Channel, SalesChannels } from "@redotech/redo-model/sales-channels";
import { Card, CardSize } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import NeutralFaceIcon from "@redotech/redo-web/icon-old/face-neutral.svg";
import SadFaceIcon from "@redotech/redo-web/icon-old/face-sad.svg";
import SmileFaceIcon from "@redotech/redo-web/icon-old/face-smile.svg";
import { PreviewImage } from "@redotech/redo-web/image";
import { ExternalLink } from "@redotech/redo-web/link";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import * as classNames from "classnames";
import { memo } from "react";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";
import * as customerResponseCss from "./customer-response-card.module.css";

export const CustomerResponseCard = memo(function CustomerResponseCard({
  return: return_,
  order,
  setYofiModalOpen,
}: {
  return?: Return;
  order?: Order;
  setYofiModalOpen: (open: boolean) => void;
}) {
  const shopifySourceName =
    return_?.products[0]?.order?.shopify?.source_name || null;
  const salesChannelName =
    SalesChannels.find((channel: Channel) => {
      let found = false;
      channel.sourceNames.forEach((source: string) => {
        // Using starts with because some shopify source names include an order # that we want to leave out.
        if (shopifySourceName?.startsWith(source)) {
          found = true;
        }
      });
      return found;
    })?.displayName || null;

  const customerComments =
    return_?.timeline.filter((event) => event.customer && event.message) || [];
  const customerImages =
    return_?.timeline.filter((event) => event.customer && event.image) || [];

  const onRiskReportClick = useHandler(() => {
    setYofiModalOpen(true);
  });

  const yofiFraudScore = getFraudScore(order?.yofiScores);
  const { yofiPillTheme, yofiIcon } = getPillThemeAndIcon(yofiFraudScore);

  return (
    <Card size={CardSize.MEDIUM} title="Additional information">
      {salesChannelName && (
        <div className={customerResponseCss.salesChannelWrapper}>
          <h3 className={cardCss.title}>Sales channel</h3>
          <Pill size={PillSize.SMALL}>{salesChannelName}</Pill>
        </div>
      )}
      <section className={customerResponseCss.section}>
        <h3 className={cardCss.title}>Customer images and videos</h3>
        <div className={customerResponseCss.images}>
          {customerImages.map((event, index) => (
            <PreviewImage key={index} src={event.image!} />
          ))}
          {!customerImages.length && <>-</>}
        </div>
      </section>
      <section className={customerResponseCss.section}>
        <h3 className={cardCss.title}>Customer comments</h3>
        {customerComments.map((event) => (
          <div className={returnCss.comment} key={event._id}>
            {event.message}
          </div>
        ))}
        {!customerComments.length && <>-</>}
      </section>
      {yofiFraudScore && (
        <section className={customerResponseCss.section}>
          <div className={customerResponseCss.salesChannelWrapper}>
            <h3 className={cardCss.title}>
              {returnTypeName(return_?.type, true)} fraud risk
            </h3>
            <Pill size={PillSize.XSMALL} theme={yofiPillTheme}>
              <div className={customerResponseCss.pillContent}>
                {yofiIcon}
                {yofiFraudScore.charAt(0).toUpperCase() +
                  yofiFraudScore.slice(1)}
              </div>
            </Pill>
          </div>
          <div className={classNames(cardCss.body)}>
            <ExternalLink onClick={onRiskReportClick} showIcon={false}>
              View full report
            </ExternalLink>
          </div>
        </section>
      )}
    </Card>
  );
});

export function getFraudScore(
  yofiScores:
    | {
        return_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
        bot_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
        discount_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
        resell_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
      }
    | undefined,
) {
  const scores: (YofiRiskLevels | null)[] = [
    yofiScores?.return_abuse_score?.prediction_value || null,
    yofiScores?.resell_abuse_score?.prediction_value || null,
    yofiScores?.bot_abuse_score?.prediction_value || null,
    yofiScores?.discount_abuse_score?.prediction_value || null,
  ];
  const yofiFraudScore = scores.reduce((acc, score) => {
    if (score === YofiRiskLevels.HIGH) {
      return YofiRiskLevels.HIGH;
    }
    if (acc === YofiRiskLevels.HIGH || acc === YofiRiskLevels.MEDIUM) {
      return acc;
    }
    if (score === YofiRiskLevels.MEDIUM) {
      return YofiRiskLevels.MEDIUM;
    }
    if (score === YofiRiskLevels.LOW) {
      return YofiRiskLevels.LOW;
    }
    return acc;
  }, null);
  return yofiFraudScore;
}

export function getPillThemeAndIcon(yofiFraudScore: YofiRiskLevels | null) {
  let yofiPillTheme: PillTheme | undefined = undefined;
  let yofiIcon = undefined;
  switch (yofiFraudScore) {
    case YofiRiskLevels.LOW:
      yofiPillTheme = PillTheme.SUCCESS;
      yofiIcon = <SmileFaceIcon className={customerResponseCss.pillIcon} />;
      break;
    case YofiRiskLevels.MEDIUM:
      yofiPillTheme = PillTheme.NEUTRAL_YELLOW;
      yofiIcon = <NeutralFaceIcon className={customerResponseCss.pillIcon} />;
      break;
    case YofiRiskLevels.HIGH:
      yofiPillTheme = PillTheme.DANGER;
      yofiIcon = <SadFaceIcon className={customerResponseCss.pillIcon} />;
      break;
  }
  return { yofiPillTheme, yofiIcon };
}
