import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { SenderEmail } from "@redotech/redo-model/team";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { MultiSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { useContext, useEffect, useState } from "react";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { getGmailIntegrations } from "../../client/gmail";
import { getOutlookIntegrations } from "../../client/outlook";
import { ConditionType } from "../return-flow/condition";
import * as selectCss from "../return-flow/select.module.css";
import { GmailIntegration, OutlookIntegration } from "../support-email-info";

export const RECEIVED_AT_EMAIL: ConditionType<
  string[],
  ModelCondition.ReceivedAtEmail
> = {
  name: "Email Received At",
  description() {
    return "Which address the email arrived at";
  },
  Details({ state, setState, disabled }) {
    const [availableEmails, setAvailableEmails] = useState<string[]>([]);
    const team = useContext(TeamContext);
    const client = useRequiredContext(RedoMerchantClientContext);

    useEffect(() => {
      if (team?.settings?.support?.senderEmails?.length) {
        appendEmails(
          team.settings.support.senderEmails.map(
            (senderEmail: SenderEmail) => senderEmail.email,
          ),
        );
      }
    }, [team]);

    useLoad(
      async (signal) => {
        if (team) {
          const response = await getGmailIntegrations(client, {
            signal,
          });

          appendEmails(
            response.integrations.map(
              (integration: GmailIntegration) => integration.email,
            ),
          );
        }
      },
      [team],
    );

    useLoad(
      async (signal) => {
        if (team) {
          const response = await getOutlookIntegrations(client, {
            signal,
          });

          appendEmails(
            response.integrations.map(
              (integration: OutlookIntegration) => integration.email,
            ),
          );
        }
      },
      [team],
    );

    const appendEmails = (emails: string[]) => {
      setAvailableEmails((oldAvailableEmails: string[]) => {
        const updatedAvailableEmails = oldAvailableEmails;
        emails.forEach((email: string) => {
          if (!updatedAvailableEmails.includes(email)) {
            updatedAvailableEmails.push(email);
          }
        });
        return updatedAvailableEmails;
      });
    };

    return (
      <LabeledInput label="Email Address">
        <MultiSelectDropdown
          disabled={disabled}
          options={
            availableEmails.length
              ? availableEmails
              : ["No email addresses configured"]
          }
          value={state}
          valueChange={(e) => {
            if (e) {
              setState([...e]);
            }
          }}
        >
          {(email: string) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>{email}</div>
            </div>
          )}
        </MultiSelectDropdown>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model) {
    return model.emails;
  },
  toModel(state) {
    return { type: ModelCondition.RECEIVED_AT_EMAIL, emails: state };
  },
  valid(state) {
    return !state.includes("No email addresses configured");
  },
};
