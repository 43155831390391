export function expiresRefresher<T>(
  f: () => Promise<{ expiration: Temporal.Instant; value: T }>,
): () => Promise<T> {
  const refresh = refresher(
    f,
    (result) =>
      Temporal.Instant.compare(result.expiration, Temporal.Now.instant()) > 0,
  );
  return async () => {
    const { value } = await refresh();
    return value;
  };
}

export function lazy<T>(f: () => T): () => T {
  let result: T;
  return () => {
    if (f) {
      result = f();
      f = undefined!;
    }
    return result;
  };
}

export function lazyAsyncFunction<F extends (...args: any[]) => any>(
  f?: () => Promise<F>,
): F {
  let result: F;
  return async function (this: ThisType<F>, ...args: Parameters<F>) {
    if (f) {
      result = await f();
      f = undefined;
    }
    return result.apply(this, args);
  } as F;
}

/**
 * Refreshes value as needed, on demand
 */
export function refresher<T>(
  f: () => Promise<T>,
  valid: (value: T) => boolean,
): () => Promise<T> {
  let pending: Promise<void> | undefined;
  let hasValue = false;
  let value: T;
  return async () => {
    if (pending) {
      await pending;
      return value;
    }
    if (hasValue && valid(value)) {
      return value;
    }
    pending = (async () => {
      value = await f();
      hasValue = true;
    })();
    try {
      await pending;
    } finally {
      pending = undefined;
    }
    return value;
  };
}
