import { ConversationStatus } from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { assertNever } from "@redotech/util/type";
import { memo } from "react";
import { BadgePillThemeToColorMapping } from "../support/conversation-tags/conversation-tag-pill";

export const ConversationStatusPill = memo(function ConversationStatusPill({
  conversationStatus,
}: {
  conversationStatus: ConversationStatus;
}) {
  const getPillTheme = (): PillTheme => {
    switch (conversationStatus) {
      case ConversationStatus.CLOSED:
        return PillTheme.NORMAL;
      case ConversationStatus.SNOOZED:
        return PillTheme.NEUTRAL_YELLOW;
      case ConversationStatus.OPEN:
        return PillTheme.PRIMARY_LIGHT;
      case ConversationStatus.IN_PROGRESS:
        return PillTheme.NEUTRAL_BLUE;
      default:
        assertNever(conversationStatus);
        return PillTheme.NORMAL;
    }
  };

  const getPillText = (): string => {
    switch (conversationStatus) {
      case ConversationStatus.CLOSED:
        return "Closed";
      case ConversationStatus.SNOOZED:
        return "Snoozed";
      case ConversationStatus.OPEN:
        return "Open";
      case ConversationStatus.IN_PROGRESS:
        return "In progress";
      default:
        assertNever(conversationStatus);
        return "";
    }
  };

  return (
    <RedoBadge
      color={BadgePillThemeToColorMapping[getPillTheme()]}
      text={getPillText()}
    />
  );
});
