import { genericMemo } from "@redotech/react-util/component";
import { TextMatchExpression } from "@redotech/redo-model/advanced-flow/expressions/text-expression";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { DeepPartial } from "@redotech/util/type";
import { useEffect } from "react";

export const EnumInspector = genericMemo(function EnumInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: "Enum";
  expression: DeepPartial<TextMatchExpression>;
  setExpression: (expression: DeepPartial<TextMatchExpression>) => void;
}) {
  useEffect(() => {
    if (!expression.type) {
      setExpression({
        ...expression,
        type: "text_match",
        operator: "equals",
      });
    }
  }, [expression]);

  return (
    <LabeledInput label="Value">
      <Autocomplete
        getLabel={(value) => value ?? ""}
        keyFn={(value) => value ?? ""}
        multiple
        options={
          expression.field ? schema.fields[expression.field].values ?? [] : []
        }
        value={expression.matchValues}
        valueChange={(value) =>
          value
            ? setExpression({ ...expression, matchValues: value })
            : undefined
        }
      >
        {(value) => value ?? "<unknown>"}
      </Autocomplete>
    </LabeledInput>
  );
});
