import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { memo, useState } from "react";

import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { postConversationTag } from "../../client/conversation-tags";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { ConversationTagInputFields } from "./conversation-tag-fields";

export const CreateConversationTagModal = memo(
  function CreateConversationTagModal({
    cancelClicked,
    tagCreated,
  }: {
    cancelClicked(): void;
    tagCreated(newTag: ConversationTagWithId): void;
  }) {
    const team = useRequiredContext(TeamContext);
    const client = useRequiredContext(RedoMerchantClientContext);

    const [conversationTagsLoad, reloadConversationTags] = useLazyContext(
      ConversationTagsContext,
    );

    const existingTagNames = new Set(
      conversationTagsLoad.value?.map((tag) => tag.name.toLowerCase()),
    );

    const [pillTheme, setPillTheme] = useState<PillTheme>(
      PillTheme.PRIMARY_LIGHT,
    );
    const [name, setName] = useState("");

    async function handleSubmit() {
      const newTag = await postConversationTag(
        client,
        {
          name: name,
          pillTheme: pillTheme,
          teamId: team._id,
        },
        reloadConversationTags,
      );
      tagCreated({
        name: newTag.name,
        pillTheme: newTag.pillTheme,
        tagId: newTag._id,
        source: newTag.source,
      });
    }

    const nameConflict = existingTagNames.has(name.toLowerCase());

    return (
      <RedoModal
        isOpen
        onModalCloseRequested={cancelClicked}
        primaryButton={{
          text: "Create",
          onClick: handleSubmit,
          disabled: !name || nameConflict,
        }}
        secondaryButton={{ text: "Cancel", onClick: cancelClicked }}
        title="Create tag"
      >
        <ConversationTagInputFields
          name={name}
          pillTheme={pillTheme}
          setName={setName}
          setPillTheme={setPillTheme}
          showNameError={nameConflict}
        />
      </RedoModal>
    );
  },
);
