import { isDef } from "@redotech/util/checks";
import { PaymentIntentItem } from "../payment-intent";
import { ProvisionType } from "../return-flow";

export const canRefundInstantExchangeRecoveryCharge = ({
  paymentIntents,
  provision,
}: {
  paymentIntents: PaymentIntentItem[];
  provision: ProvisionType;
}): boolean => {
  return isDef(
    getRefundableInstantExchangeRecoveryPaymentIntent({
      paymentIntents,
      provision,
    }),
  );
};

export const getRefundableInstantExchangeRecoveryPaymentIntent = ({
  paymentIntents,
  provision,
}: {
  paymentIntents: PaymentIntentItem[];
  provision: ProvisionType;
}): PaymentIntentItem | undefined => {
  if (provision === ProvisionType.INSTANT) {
    return paymentIntents.find(
      (paymentIntent: PaymentIntentItem) =>
        paymentIntent.type === "recovery" && !paymentIntent.refund,
    );
  }

  return undefined;
};
