import { RouteObject } from "react-router-dom";

export const gorgiasRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Gorgias" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        path: "connect",
        async lazy() {
          const { Connect } = await import("./connect");
          return { Component: Connect };
        },
      },
      {
        path: "callback",
        async lazy() {
          const { Callback } = await import("./callback");
          return { Component: Callback };
        },
      },
    ],
  },
];
