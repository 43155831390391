import {
  GetOrdersRequest,
  Pickup,
  ReturnRequest,
} from "@redotech/redo-model/return-flow";

import { ProductInfo } from "@redotech/redo-model/customer-event/customer-event-definition";
import { Order } from "@redotech/redo-model/order";
import { AxiosResponse } from "axios";
import { RedoClient } from ".";

export class WidgetClient {
  constructor(
    private readonly redoClient: RedoClient,
    readonly widgetId: string,
  ) {}

  get client() {
    return this.redoClient.client;
  }
}

export type CreateAuthRequest =
  | { type: "order"; email: string; orderNumber: string }
  | { type: "email"; token: string }
  | { type: "customer_widget"; customerWidgetAuthToken: string };

export interface CreateAuthResponse {
  token: string;
  customer: { email: string; name: string };
}
export async function createAuth(
  client: WidgetClient,
  request: CreateAuthRequest,
  { signal }: { signal?: AbortSignal } = {},
): Promise<CreateAuthResponse> {
  let body;
  switch (request.type) {
    case "email":
      body = { token: request.token };
      break;
    case "order":
      body = { email: request.email, orderNumber: request.orderNumber };
      break;
    case "customer_widget":
      body = { customerWidgetAuthToken: request.customerWidgetAuthToken };
      break;
  }
  const response = await client.client.post(
    `widgets/${encodeURIComponent(client.widgetId)}/customer-auth`,
    body,
    {
      params: { type: request.type },
      signal,
    },
  );
  return response.data;
}

/**
 * POST /widgets/:widget_id/location-discrepancy
 */
export async function postLocationDiscrepancy(
  client: WidgetClient,
  { signal }: { signal?: AbortSignal } = {},
  ipifyCountry: string,
  predictedCountry: string,
): Promise<void> {
  await client.client.post(
    `widgets/${encodeURIComponent(client.widgetId)}/location-discrepancy`,
    { ipifyCountry, predictedCountry },
    { signal },
  );
}

/*
 * GET /widgets/:widgetId/products/:id
 */
export async function getProduct(
  client: WidgetClient,
  { signal, productId }: { signal?: AbortSignal; productId: string | null },
): Promise<{
  variants: {
    id: number;
    inventory_quantity: number;
    [key: string]: any;
  }[];
  id: number;
  images?: unknown[];
  title?: string;
  tags?: string;
}> {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/products/${productId}`,
    { signal, params: {} },
  );
  return response.data;
}

/**
 * PUT /widgets/:widget_id/labels
 */
export async function resendLabel(
  client: WidgetClient,
  {
    returnId,
    pickup,
    signal,
    emailOverride,
  }: {
    returnId: string;
    pickup?: Pickup;
    emailOverride?: string;
    signal?: AbortSignal;
  },
): Promise<void> {
  await client.client.put(
    `widgets/${encodeURIComponent(client.widgetId)}/labels`,
    { pickup, emailOverride },
    { signal, params: { return_id: returnId } },
  );
}

/**
 * PUT /widgets/:widget_id/returns/:return_id
 */
export async function cancelPickup(
  client: WidgetClient,
  { returnId, signal }: { returnId: string; signal?: AbortSignal },
): Promise<void> {
  await client.client.delete(
    `widgets/${encodeURIComponent(client.widgetId)}/returns/${returnId}/cancelPickup`,
    { signal },
  );
}

/**
 * POST /widgets/:widget_id/returns/submit
 *
 */
export async function submitReturn(
  client: WidgetClient,
  request: ReturnRequest,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.post(
    `widgets/${encodeURIComponent(client.widgetId)}/returns/submit`,
    { params: request },
    { signal },
  );
  return response.data;
}

/**
 * GET /widgets/:widget_id/orders
 */
export async function getOrders(
  client: WidgetClient,
  request: GetOrdersRequest,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/orders`,
    { signal, params: request },
  );
  return response.data;
}

/**
 * GET /widgets/:widget_id/orders/order_id/discount
 */
export async function getOrderDiscount(
  client: WidgetClient,
  orderId: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/orders/${encodeURIComponent(
      orderId,
    )}/discount`,
    { signal },
  );
  return response.data;
}

/**
 * POST /widgets/:widget_id/orders/order_id/discount
 */
export async function createOrderDiscount(
  client: WidgetClient,
  orderId: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.post(
    `widgets/${encodeURIComponent(client.widgetId)}/orders/${encodeURIComponent(
      orderId,
    )}/discount`,
    { signal },
  );
  return response.data;
}

/**
 * Get /widgets/:widget_id/orders/:orderId
 */
export async function getOrder(
  client: WidgetClient,
  orderId: string,
  {
    signal,
    includeUnfulfilled,
  }: { signal?: AbortSignal; includeUnfulfilled?: boolean } = {},
): Promise<{ order: Order }> {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/orders/${orderId}${includeUnfulfilled ? "?includeUnfulfilled=true" : ""}`,
    { signal },
  );
  return response.data;
}

export async function uploadImages(
  client: WidgetClient,
  params: any,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.post(
    `widgets/${encodeURIComponent(client.widgetId)}/upload`,
    params,
    { signal },
  );
  return response.data;
}

export async function getShippingFee(
  client: WidgetClient,
  params: any,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.put(
    `widgets/${encodeURIComponent(client.widgetId)}/returns/fee`,
    { params },
    { signal },
  );
  return response.data;
}

export async function getShopifyLocations(
  client: WidgetClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/shopify/locations`,
    { signal },
  );
  return response;
}

/**
 * GET /widget/:id/customers/top-products-of-interest
 */
export async function getTopProductsOfInterest(
  client: WidgetClient,
  {
    token,
    signal,
    limit,
  }: {
    token: string;
    signal?: AbortSignal;
    limit?: number;
  },
) {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/customers/top-products-of-interest`,
    {
      params: {
        token,
        limit,
      },
      signal,
    },
  );
  return response.data;
}

/**
 * GET /widget/:id/customers/viewed-products
 */
export async function getViewedProducts(
  client: WidgetClient,
  {
    token,
    signal,
    limit,
  }: {
    token: string;
    signal?: AbortSignal;
    limit?: number;
  },
): Promise<ProductInfo[]> {
  const response = await client.client.get(
    `widgets/${encodeURIComponent(client.widgetId)}/customers/viewed-products`,
    {
      params: {
        token,
        limit,
      },
      signal,
    },
  );
  return response.data;
}

/**
 * POST /customers/favorite-product
 */
async function setFavoriteProduct(
  client: WidgetClient,
  shouldSetAsFavoriteProduct: boolean,
  productHandle: string,
  token: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<AxiosResponse> {
  const body = {
    productHandle,
    variantId,
    shouldSetAsFavorite: shouldSetAsFavoriteProduct,
  };
  const response = await client.client.post(
    `widgets/${encodeURIComponent(client.widgetId)}/customers/favorite-product`,
    body,
    {
      params: {
        token,
      },
      signal,
    },
  );

  return response;
}

export async function addFavoriteProduct(
  client: WidgetClient,
  productHandle: string,
  token: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<boolean> {
  const response = await setFavoriteProduct(
    client,
    true,
    productHandle,
    token,
    variantId,
    {
      signal,
    },
  );
  return response.status === 200;
}

export async function removeFavoriteProduct(
  client: WidgetClient,
  productHandle: string,
  token: string,
  variantId?: string,
  { signal }: { signal?: AbortSignal } = {},
): Promise<boolean> {
  const response = await setFavoriteProduct(
    client,
    false,
    productHandle,
    token,
    variantId,
    {
      signal,
    },
  );
  return response.status === 200;
}
