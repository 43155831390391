import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useState } from "react";
import { ErrorMessageSection } from "../app/error-page";
import { ConversationEmailMessagesView } from "./conversation-email-view/conversation-email-messages-view";
import { ConversationHeader } from "./conversation-header";
import { ConversationMessages } from "./conversation-messages";
import { MessageInput } from "./message-input/message-input";

export const ConversationContent = memo(function ConversationContent({
  conversation,
  setActiveConversation,
  actionButtons,
  leftPanelOpen,
  rightPanelOpen,
  handleSetLeftPanelOpen,
  handleSetRightPanelOpen,
  nextConversationInList,
  prevConversationInList,
}: {
  conversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  actionButtons: React.ReactNode;
  leftPanelOpen: boolean;
  rightPanelOpen: boolean;
  handleSetLeftPanelOpen: (value: boolean) => void;
  handleSetRightPanelOpen: (value: boolean) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [typing, setTyping] = useState<Record<string, Date>>({});
  const [showFullCommentThread, setShowFullCommentThread] = useState(true);

  return (
    <>
      <ConversationHeader
        actionButtons={actionButtons}
        conversation={conversation}
        handleSetLeftPanelOpen={handleSetLeftPanelOpen}
        handleSetRightPanelOpen={handleSetRightPanelOpen}
        leftPanelOpen={leftPanelOpen}
        rightPanelOpen={rightPanelOpen}
        setActiveConversation={setActiveConversation}
      />
      {conversation.messages.length === 0 ? (
        <Flex align="center" height="full" justify="center" px="lg">
          <ErrorMessageSection
            details={`This conversation (id ${conversation._id}) has no messages. Please contact Redo support.`}
          />
        </Flex>
      ) : (
        <>
          {conversation.platform === ConversationPlatform.EMAIL ? (
            <ConversationEmailMessagesView
              conversation={conversation}
              nextConversationInList={nextConversationInList}
              prevConversationInList={prevConversationInList}
              setActiveConversation={setActiveConversation}
              setErrorMessage={setErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              setShowFullCommentThread={setShowFullCommentThread}
              setTyping={setTyping}
              showFullCommentThread={showFullCommentThread}
              typing={typing}
            />
          ) : (
            <ConversationMessages
              conversation={conversation}
              setActiveConversation={setActiveConversation}
              setErrorMessage={setErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              showFullCommentThread={showFullCommentThread}
              typing={typing}
            />
          )}
          <MessageInput
            conversation={conversation}
            key={conversation._id}
            nextConversationInList={nextConversationInList}
            prevConversationInList={prevConversationInList}
            rightPanelOpen={rightPanelOpen}
            setActiveConversation={setActiveConversation}
            setErrorMessage={setErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            setShowFullCommentThread={setShowFullCommentThread}
            setTyping={setTyping}
            showFullCommentThread={showFullCommentThread}
            typing={typing}
          />
        </>
      )}
      {showErrorMessage && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setShowErrorMessage(false)}
          open={showErrorMessage}
        >
          <Alert onClose={() => setShowErrorMessage(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
});
