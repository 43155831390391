export enum SlideInPanelAnchorLocation {
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

export const getSlideInPanelAnchorLocationLabel = (
  anchorLocation: SlideInPanelAnchorLocation,
) => {
  switch (anchorLocation) {
    case SlideInPanelAnchorLocation.BOTTOM_LEFT:
      return "Bottom left";
    case SlideInPanelAnchorLocation.BOTTOM_RIGHT:
      return "Bottom right";
  }
};

export const DEFAULT_PANEL_ANCHOR_LOCATION =
  SlideInPanelAnchorLocation.BOTTOM_LEFT;
