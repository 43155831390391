import { PillTheme } from "@redotech/redo-model/pill-theme";
import * as classnames from "classnames";
import { ReactNode, memo } from "react";
import * as pillCss from "./pill.module.css";
import * as wrapCss from "./wrap.module.css";

export enum PillSize {
  MEDIUM = 0,
  SMALL = 1,
  XSMALL = 2,
  NEAR_SQUARE = 3,
}

export { PillTheme };

const pillThemeClass: Record<PillTheme, string> = {
  [PillTheme.PRIMARY]: pillCss.primary,
  [PillTheme.NORMAL]: pillCss.normal,
  [PillTheme.PRIMARY_LIGHT]: pillCss.primaryLight,
  [PillTheme.SUCCESS]: pillCss.success,
  [PillTheme.DANGER]: pillCss.danger,
  [PillTheme.NEUTRAL_YELLOW]: pillCss.neutralYellow,
  [PillTheme.NEUTRAL_BLUE]: pillCss.neutralBlue,
  [PillTheme.LIGHT_PINK]: pillCss.lightPink,
  [PillTheme.SOLID_WHITE]: pillCss.solidWhite,
  [PillTheme.SOLID_RED]: pillCss.solidRed,
  [PillTheme.SOLID_YELLOW]: pillCss.solidYellow,
  [PillTheme.SOLID_GREEN]: pillCss.solidGreen,
  [PillTheme.SOLID_NAVY]: pillCss.solidNavy,
  [PillTheme.SOLID_LIGHT_BLUE]: pillCss.solidLightBlue,
  [PillTheme.SOLID_PINK]: pillCss.solidPink,
  [PillTheme.SOLID_ORANGE]: pillCss.solidOrange,
  [PillTheme.SOLID_BLACK]: pillCss.solidBlack,
};

const pillSizeClass = new Map<PillSize, string>();
pillSizeClass.set(PillSize.MEDIUM, pillCss.medium);
pillSizeClass.set(PillSize.SMALL, pillCss.small);
pillSizeClass.set(PillSize.XSMALL, pillCss.xsmall);
pillSizeClass.set(PillSize.NEAR_SQUARE, pillCss.nearSquare);

export const Pill = memo(function Pill({
  children,
  truncate = false,
  size,
  theme = PillTheme.NORMAL,
  preventCapitalize = false,
  shadow = false,
}: {
  children: ReactNode | ReactNode[];
  size?: PillSize;
  theme?: PillTheme;
  truncate?: boolean;
  preventCapitalize?: boolean;
  shadow?: boolean;
}) {
  return (
    <div
      className={classnames(
        pillCss.pill,
        size !== undefined && pillSizeClass.get(size),
        pillThemeClass[theme],
        { [wrapCss.truncate]: truncate },
        { [pillCss.shadow]: shadow },
        preventCapitalize && pillCss.noCapitalize,
      )}
    >
      {children}
    </div>
  );
});
