import { CallDirection } from "@redotech/redo-model/support/voice/voice-types";
import { assertNever } from "@redotech/util/type";

export enum UserCallAvailability {
  AVAILABLE = "available",
  IN_CALL = "in-call",
  UNAVAILABLE = "unavailable",
}

export enum CallState {
  RINGING = "ringing",
  ACTIVE = "active",
  CONNECTING = "connecting",
  HANGUP = "hangup",
}

export const displayUserCallAvailability = (
  availability: UserCallAvailability,
) => {
  switch (availability) {
    case UserCallAvailability.AVAILABLE:
      return "Available";
    case UserCallAvailability.IN_CALL:
      return "In call";
    case UserCallAvailability.UNAVAILABLE:
      return "Unavailable";
    default:
      assertNever(availability);
  }
};

export type UserAvailabilities = {
  userId: string;
  availability: UserCallAvailability;
}[];

export interface ActiveCall {
  state: CallState.ACTIVE;
  direction: CallDirection;
  remoteCallerNumber: string;
  startTime: Date;
  customerName?: string;
}

export interface RingingCall {
  state: CallState.RINGING;
  direction: CallDirection;
  remoteCallerNumber: string;
  customerName?: string;
}

export interface ConnectingCall {
  state: CallState.CONNECTING;
  direction: CallDirection;
  remoteCallerNumber: string;
  customerName?: string;
}

export interface HangingUpCall {
  state: CallState.HANGUP;
  direction: CallDirection;
  remoteCallerNumber: string;
  customerName?: string;
}

// WIP
export type Call = RingingCall | ActiveCall | ConnectingCall | HangingUpCall;

export type NonActiveCall = Exclude<Call, ActiveCall>;
