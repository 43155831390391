import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { Step } from "@redotech/redo-model/return-flow";
import {
  TemplateType,
  templateTypeLabel,
} from "@redotech/redo-model/sms-template";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import SmsIcon from "@redotech/redo-web/icon-old/send-sms.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Link } from "@redotech/redo-web/link";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Spinner, SpinnerStyle } from "@redotech/redo-web/spinner";
import { produce } from "immer";
import { memo } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { getTeamSMSTemplates } from "../../client/sms-template";
import * as selectCss from "./select.module.css";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  next?: StepId | undefined;
  customTitle?: string;
  smsId: string;
  smsName?: string;
  smsType?: string;
}

function valid(state: State) {
  return !!state.smsId;
}

const Details = memo(function Details({
  state,
  stepSelect,
  setState,
}: StepTypeDetailsProps<State>) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const smsLoad = useLoad(async (signal) => {
    const templates = await getTeamSMSTemplates(client, {
      signal,
      page: 0,
      pageSize: 1000,
    });
    return templates;
  }, []);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });

  const label = (
    <>
      SMS template - <Link to="../../sms-templates">Create new</Link>
    </>
  );

  return (
    <>
      <LabeledInput label={label}>
        {smsLoad.value ? (
          <SelectDropdown
            disabled={!smsLoad.value}
            options={smsLoad.value.data || []}
            value={smsLoad.value.data?.find(
              (template: any) => template._id === state.smsId,
            )}
            valueChange={(e: any) =>
              setState((state) => ({
                ...state,
                smsId: e._id,
                smsName: e.name,
                smsType: e.templateType,
              }))
            }
          >
            {(type) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{`${type.name}${
                  type.templateType
                    ? ` - ${templateTypeLabel(type.templateType)}`
                    : ""
                }`}</div>
              </div>
            )}
          </SelectDropdown>
        ) : (
          <div
            style={{ width: "21px", marginLeft: "auto", marginRight: "auto" }}
          >
            <Spinner style={SpinnerStyle.AUTO} />
          </div>
        )}
      </LabeledInput>
      <LabeledInput label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const SEND_SMS: StepType<State, Step.SendSms> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Send SMS Message",
  valid,
  Icon: SmsIcon,
  description(state: State) {
    return state?.smsName && state?.smsType
      ? `${state.smsName} - ${templateTypeLabel(state.smsType as TemplateType)}`
      : "";
  },
  empty: { next: undefined, smsId: "" },
  downstream(state) {
    // TODO(devin): double check
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): Step.SendSms {
    return {
      customTitle: state.customTitle,
      smsId: state.smsId,
      type: Step.SEND_SMS,
      next: state.next ? id(state.next) : undefined,
    };
  },
  fromModel(model: Step.SendSms, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      smsId: model.smsId,
      next: model.next ? id(model.next) : undefined,
    };
  },
};
