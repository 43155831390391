import { Currency } from "@redotech/money/currencies";
import {
  Cart,
  CartLine,
  ProductVariant,
  Customer as ShopifyCustomer,
} from "@shopify/web-pixels-extension/build/ts/types/PixelEvents";
import { z } from "zod";
import { dateOrStringAsDate, zExt } from "../common/zod-util";
import { ConversationStatus } from "../conversation";
import { MarketingSubscriptionType } from "../marketing";
import { returnStatusJsonFormat } from "../return-new";
import { CompleteOrderFulfillmentStatus } from "../shopify-order";
import { signupFormSchema } from "../signup-form-schema";
import {
  CUSTOMER_EVENT_DISCRIMINATOR_KEY,
  CustomerEventType,
  ExternalCustomerPlatform,
} from "./customer-event-definition";

const _CustomerEventSchema = z.object({
  _id: zExt.objectId().transform((id) => id.toString()),
  team: zExt.objectId().transform((id) => id.toString()),
  email: z.string().optional(),
  customer: zExt
    .objectId()
    .optional()
    .transform((id) => id?.toString()),
  timestamp: dateOrStringAsDate,
  eventType: z.nativeEnum(CustomerEventType),
});

const OrderCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.ORDER_CREATED),
  orderId: zExt.objectId(),
  shopifyId: z.string(),
  itemCount: z.number(),
  totalPrice: z.string(),
  orderName: z.string(),
  currency: z.nativeEnum(Currency).optional(),
});

const OrderUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.ORDER_UPDATED),
  orderId: zExt.objectId(),
  shopifyId: z.string(),
});

const OrderStatusUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.ORDER_STATUS_UPDATED),
  orderId: zExt.objectId(),
  shopifyId: z.string(),
  newStatus: z
    .string()
    .nullable()
    .transform(
      (str: string | null) => CompleteOrderFulfillmentStatus.fromDbValue(str)!,
    ),
});

const ReturnCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_CREATED),
  returnId: zExt.objectId(),
});

const ReturnStatusUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_STATUS_UPDATED),
  returnId: zExt.objectId(),
  newStatus: z
    .string()
    .nullable()
    .transform((str) => {
      return returnStatusJsonFormat.read(str);
    }),
});

const ReturnCommentCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_COMMENT_CREATED),
  returnId: zExt.objectId(),
  messageId: zExt.objectId(),
});

const ClaimCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CLAIM_CREATED),
  claimId: zExt.objectId(),
});

const ClaimStatusUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CLAIM_STATUS_UPDATED),
  claimId: zExt.objectId(),
  newStatus: z
    .string()
    .nullable()
    .transform((str) => {
      return returnStatusJsonFormat.read(str);
    }),
});

const ClaimCommentCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CLAIM_COMMENT_CREATED),
  claimId: zExt.objectId(),
  messageId: zExt.objectId(),
});

const ConversationCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CONVERSATION_CREATED),
  conversationId: zExt.objectId(),
});

const ConversationStatusUpdatedCustomerEventSchema =
  _CustomerEventSchema.extend({
    eventType: z.literal(CustomerEventType.CONVERSATION_STATUS_UPDATED),
    conversationId: zExt.objectId(),
    newStatus: z.nativeEnum(ConversationStatus),
  });

const ConversationMessageCreatedCustomerEventSchema =
  _CustomerEventSchema.extend({
    eventType: z.literal(CustomerEventType.CONVERSATION_MESSAGE_CREATED),
    conversationId: zExt.objectId(),
    messageId: zExt.objectId(),
  });

const ExternalPlatformCustomerCreatedCustomerEventSchema =
  _CustomerEventSchema.extend({
    eventType: z.literal(CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED),
    externalPlatformCustomerId: z.string(),
    externalPlatform: z.nativeEnum(ExternalCustomerPlatform),
  });

const StorefrontLoginCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.STOREFRONT_LOGIN),
  multipassLogin: z.boolean(),
});

const MarketingSubscribeEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.MARKETING_SUBSCRIBED),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  entryPoint: z.string(),
  formId: z
    .string()
    .or(zExt.objectId())
    .optional()
    .transform((id) => id?.toString()),
  formSnapshot: z.string().or(signupFormSchema),
  customerIpAddress: z.string().optional(),
});

const MarketingUnsubscribeEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.MARKETING_UNSUBSCRIBED),
  subscriptionType: z.nativeEnum(MarketingSubscriptionType),
});

const MarketingConfirmEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.MARKETING_CONFIRMED),
  subscriptionType: z.nativeEnum(MarketingSubscriptionType),
});

type PartialShopifyCustomer = Partial<ShopifyCustomer> | null;
type PartialCart = Partial<Cart> | null;

const ProductViewedShoppingEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.PRODUCT_VIEWED),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  productVariant: z.any().transform((val: any) => val as ProductVariant),
  cart: z.any().transform((val: any) => val as PartialCart),
  externalEventId: z.string(),
  externalCustomer: z
    .any()
    .transform((val: any) => val as PartialShopifyCustomer),
});

const ProductAddedToCartShoppingEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.PRODUCT_ADDED_TO_CART),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  cartLine: z.any().transform((val: any) => val as CartLine | null),
  externalEventId: z.string(),
  externalCustomer: z
    .any()
    .transform((val: any) => val as PartialShopifyCustomer),
});

export const CustomerEventSchema = z.discriminatedUnion(
  CUSTOMER_EVENT_DISCRIMINATOR_KEY,
  [
    OrderCreatedCustomerEventSchema,
    OrderUpdatedCustomerEventSchema,
    OrderStatusUpdatedCustomerEventSchema,
    ReturnCreatedCustomerEventSchema,
    ReturnStatusUpdatedCustomerEventSchema,
    ReturnCommentCreatedCustomerEventSchema,
    ClaimCreatedCustomerEventSchema,
    ClaimStatusUpdatedCustomerEventSchema,
    ClaimCommentCreatedCustomerEventSchema,
    ConversationCreatedCustomerEventSchema,
    ConversationStatusUpdatedCustomerEventSchema,
    ConversationMessageCreatedCustomerEventSchema,
    ExternalPlatformCustomerCreatedCustomerEventSchema,
    StorefrontLoginCustomerEventSchema,
    MarketingSubscribeEventSchema,
    MarketingUnsubscribeEventSchema,
    MarketingConfirmEventSchema,
    ProductViewedShoppingEventSchema,
    ProductAddedToCartShoppingEventSchema,
  ],
);

// Make sure the discriminated union is exhaustive
// An error here means that a new event type was added but not to the CustomerEventSchema
const _allEventTypes = null as unknown as CustomerEventType;
export const _supportedEventTypes: z.infer<
  typeof CustomerEventSchema
>["eventType"] = _allEventTypes;
