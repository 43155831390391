import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { uploadFile } from "@redotech/redo-api-client/conversations";
import { Attachment } from "@redotech/redo-model/create-conversation-body";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { toast } from "@redotech/redo-web/alert";
import FaceSmile from "@redotech/redo-web/arbiter-icon/face-smile.svg";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Button, ButtonTheme, IconButton } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import PaperclipIcon from "@redotech/redo-web/icon-old/paperclip.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill/quill-editor.module.css";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { nativeRandom, randomHexString } from "@redotech/util/random";
import Quill from "quill";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import * as stepRichTextCss from "./step-rich-text.module.css";

export const StepRichTextEditor = memo(function StepRichTextEditor({
  quillRef,
  htmlValue,
  textValue,
  onTextChange,
  readOnly,
  enableAttachments,
  attachments,
  onAttachmentUpload,
  onAttachmentRemoval,
}: {
  quillRef: React.MutableRefObject<Quill | undefined>;
  htmlValue?: string;
  textValue: string;
  onTextChange: () => void;
  readOnly?: boolean;
  enableAttachments?: boolean;
  attachments?: Attachment[];
  onAttachmentUpload?: (attachment: Attachment) => void;
  onAttachmentRemoval?: (url: string) => void;
}) {
  const apiClient = useRequiredContext(RedoClientContext);
  const [localAttachments, setLocalAttachments] = useState<Attachment[]>(
    attachments || [],
  );

  const toolbarId = useMemo<string>(
    () => `toolbar-${randomHexString(nativeRandom, 8)}`,
    [],
  );

  const emojiPickerAnchorRef = useRef<HTMLDivElement | null>(null);

  const setQuillRef = useHandler((ref: Quill | null) => {
    quillRef.current = ref || undefined;
  });

  useEffect(() => {
    if (!quillRef.current) {
      return;
    }

    if (htmlValue && htmlValue !== quillRef.current.getSemanticHTML()) {
      quillRef.current.clipboard.dangerouslyPasteHTML(htmlValue);
    } else if (textValue !== quillRef.current.getText()) {
      quillRef.current.setText(textValue);
    }
  }, [htmlValue, textValue]);

  async function handleUpload({ event, file }: { event?: any; file?: File }) {
    const fileToUpload = file || event.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      const body = response.body;
      const newAttachment = {
        url: body.url,
        description: body.description,
        mimeType: body.mimeType,
      };
      if (onAttachmentUpload) {
        onAttachmentUpload(newAttachment);
      }
      setLocalAttachments((oldAttachments) => {
        return [newAttachment, ...oldAttachments];
      });
    } else {
      toast(
        conversationFileUploadErrorMessages?.[response.error] ??
          "Unknown error uploading file.",
        {
          variant: "error",
        },
      );
    }
  }

  function clearInput(e: any) {
    // This makes the onChange always get triggered.
    e.target.value = "";
  }

  function removeFileFromAttachments(url: string) {
    if (onAttachmentRemoval) {
      onAttachmentRemoval(url);
    }
    setLocalAttachments((oldAttachments) => {
      return oldAttachments.filter((attachment) => attachment.url !== url);
    });
  }

  const isMac = isUserOnMac();

  return (
    <div className={stepRichTextCss.richTextInput} ref={emojiPickerAnchorRef}>
      <div className={quillEditorCss.quillToolbar} id={toolbarId}>
        <div className={quillEditorCss.quillFormatButtons}>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-link" />
          <button className="ql-strike" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <select className="ql-size" defaultValue="16px">
            <option value="12px">Small</option>
            <option value="16px">Normal</option>
            <option value="24px">Large</option>
            <option value="36px">Huge</option>
          </select>
          <select className="ql-color" />
          {enableAttachments && (
            <>
              {/* TODO: re-enable when we can figure out how to get the file picker to show */}
              {/* <button className="ql-image" /> */}
              <label htmlFor="file-upload" id="file-upload-label">
                <Button
                  onClick={() => {
                    (
                      document.querySelector(
                        `#${toolbarId} #file-upload`,
                      ) as HTMLElement | null
                    )?.click();
                  }}
                  theme={ButtonTheme.GHOST}
                >
                  <div className={stepRichTextCss.actionButton}>
                    <PaperclipIcon />
                  </div>
                </Button>
              </label>
              <input
                id="file-upload"
                onChange={(e) => handleUpload({ event: e })}
                onClick={(e) => clearInput(e)}
                type="file"
              />
            </>
          )}
          <Tooltip title={`Insert emoji (${isMac ? "⌘" : "Ctrl"}+3)`}>
            <IconButton className="rql-emoji">
              <FaceSmile />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <QuillEditor
        defaultValue={htmlValue || textValue}
        editorClassName={quillEditorCss.quillEditorSmall}
        emojiPickerAnchorOverride={emojiPickerAnchorRef.current}
        onTextChange={onTextChange}
        readOnly={readOnly}
        ref={setQuillRef}
        toolbar={toolbarId}
      />
      {!!localAttachments.length && (
        <div className={stepRichTextCss.fileList}>
          {localAttachments.map((attachment, index) => (
            <div
              className={stepRichTextCss.attachmentContainer}
              key={attachment.url}
            >
              <AttachmentThumbnail
                description={attachment.description}
                mimeType={attachment.mimeType}
                restrictWidth
                url={attachment.url}
              />
              <div className={stepRichTextCss.removeFileButton}>
                <IconButton
                  onClick={() => removeFileFromAttachments(attachment.url)}
                >
                  <XIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export const containsImg = (html: string | undefined) => {
  if (!html) {
    return false;
  }

  const div = document.createElement("div");
  div.innerHTML = html;
  return !!div.querySelector("img");
};
