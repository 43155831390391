// extracted by mini-css-extract-plugin
export var accent = "_2k-3";
export var add = "_2k-h";
export var addIcon = "_2k-i";
export var deleteIcon = "_2k-g";
export var disabled = "_2k-8";
export var ellipsis = "_2k-d";
export var error = "_2k-2";
export var form = "_2k-4";
export var hideFocus = "_2k-1";
export var icon = "_2k-6";
export var input = "_2k-c";
export var listItem = "_2k-f";
export var mediumHeight = "_2k-a";
export var noBorder = "_2k-7";
export var shortHeight = "_2k-9";
export var tallHeight = "_2k-b";
export var textInput = "_2k-0";
export var vertical = "_2k-e";
export var widget = "_2k-5";