import { useLayoutEffect, useRef, useState } from "react";
import { useHtmlEventListener } from "./event";

export function useHover(element: HTMLElement | null): boolean {
  const [hover, setHover] = useState(false);

  useOnHover(element, setHover);

  return hover;
}

export function useTransitioning(
  element: HTMLElement | null,
  property: (value: string) => boolean,
): boolean {
  const [transitioning, setTransitioning] = useState<HTMLElement | null>(
    element,
  );
  useHtmlEventListener(element, "transitionstart", (e) => {
    if (property(e.propertyName)) {
      setTransitioning(<HTMLElement>e.target);
    }
  });
  useHtmlEventListener(element, "transitioncancel", (e) => {
    if (property(e.propertyName)) {
      setTransitioning(null);
    }
  });
  useHtmlEventListener(element, "transitionend", (e) => {
    if (property(e.propertyName)) {
      setTransitioning(null);
    }
  });
  useLayoutEffect(() => {
    setTransitioning(null);
  }, [element]);
  return transitioning === element;
}

export function useOnHover(
  element: HTMLElement | null,
  onHover: (value: boolean) => void,
) {
  const ref = useRef<(value: boolean) => void>(onHover);
  ref.current = onHover;

  useHtmlEventListener(element, "mouseenter", () => ref.current(true));
  useHtmlEventListener(element, "mouseleave", () => ref.current(false));

  useLayoutEffect(() => () => ref.current!(false), [element]);
}
