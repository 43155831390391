import { IndexRouteObject } from "react-router-dom";

export const aiSettingsRoutes: IndexRouteObject = {
  handle: { breadcrumb: "AI" },
  index: true,
  lazy: async () => {
    const { AiSettings } = await import("./ai-settings");
    return { Component: AiSettings };
  },
  path: "ai",
};
