import { LoadState } from "@redotech/react-util/load";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { CartData } from "@redotech/shopify-storefront";
import * as classNames from "classnames";
import { memo } from "react";
import { ConversationDetailsCustomer } from "./conversation-details-customer";
import { ConversationDetailsOrders } from "./conversation-details-orders";
import { ConversationDetailsOverview } from "./conversation-details-overview";
import * as conversationDetailsPanelCss from "./conversation-details-panel.module.css";

export const ConversationDetailsPanel = memo(function ConversationDetailsPanel({
  conversation,
  setConversationAssignee,
  setActiveConversation,
  inTab,
  ordersLoad,
  doOrdersLoad,
  returnsLoad,
  doReturnsLoad,
  cartsLoad,
  doCartsLoad,
}: {
  conversation: ExpandedConversation;
  setConversationAssignee: (assignee: any) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
  inTab: boolean;
  ordersLoad: LoadState<any>;
  doOrdersLoad: () => void;
  returnsLoad: LoadState<any>;
  doReturnsLoad: () => void;
  cartsLoad: LoadState<CartData[] | undefined>;
  doCartsLoad: () => void;
}) {
  return (
    <Flex
      className={classNames(
        inTab ? undefined : conversationDetailsPanelCss.rightContent,
        conversationDetailsPanelCss.panelWrapper,
      )}
      dir="column"
      w="full"
    >
      {!inTab && (
        <Flex
          align="center"
          className={conversationDetailsPanelCss.header}
          shrink={0}
        >
          <Text fontSize="sm" fontWeight="semibold" textColor="primary">
            Details
          </Text>
        </Flex>
      )}
      <ConversationDetailsOverview
        conversation={conversation}
        setConversationAssignee={setConversationAssignee}
      />
      <ConversationDetailsCustomer
        conversation={conversation}
        doCartsLoad={doCartsLoad}
        doOrdersLoad={doOrdersLoad}
        doReturnsLoad={doReturnsLoad}
        orders={ordersLoad.value}
        returns={returnsLoad.value}
        setActiveConversation={setActiveConversation}
      />
      <ConversationDetailsOrders
        cartsLoad={cartsLoad}
        doOrdersLoad={doOrdersLoad}
        doReturnsLoad={doReturnsLoad}
        ordersLoad={ordersLoad}
        returnsLoad={returnsLoad}
      />
    </Flex>
  );
});
