import { SubjectLineMatchType } from "@redotech/redo-model/return-flow/condition";

export const N_CHARACTERS_OF_QUERYSTRINGS_TO_SHOW = 15;

export const displayStringForMatchCondition = (
  nameOfCondition: string,
  matchMode: SubjectLineMatchType,
  queryStrings?: string[],
): string =>
  `${nameOfCondition} ${matchMode === SubjectLineMatchType.CONTAINS ? "includes" : "is exactly"}: ${
    !queryStrings
      ? ""
      : `"${queryStrings.join(", ").substring(0, N_CHARACTERS_OF_QUERYSTRINGS_TO_SHOW)}${queryStrings.join(", ").length > N_CHARACTERS_OF_QUERYSTRINGS_TO_SHOW ? "..." : ""}"`
  }`;
