import { useRequiredContext } from "@redotech/react-util/context";
import { Activity } from "@redotech/redo-model/returns/activity";
import { GetUser } from "@redotech/redo-model/user";
import { AvatarStack, UserInfo } from "@redotech/redo-web/avatar-stack";
import { memo, useContext, useEffect, useState } from "react";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { UserCacheContext } from "../app/user-cache";
import { RedoMerchantClientContext } from "../client/context";
import {
  activitiesStream,
  getActivitiesForReturn,
  updateReturnActivity,
} from "../client/return";
import { getUserById } from "../client/user";
import { listen } from "../support/utils";
interface ReturnActivitiesProps {
  returnId: string;
}
export const ReturnActivities = memo(function ReturnActivities(
  props: ReturnActivitiesProps,
) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const [returnActivities, setReturnActivities] = useState<Activity[]>([]);

  const [userInfos, setUserInfos] = useState<UserInfo[] | null>(null);
  const user = useContext(UserContext);
  const team = useContext(TeamContext);
  const userCache = useContext(UserCacheContext);

  async function updateActivitiesList(activities: Activity[]): Promise<void> {
    setReturnActivities(activities);
    setUserInfos(
      await Promise.all(
        activities.map(async (activity) => {
          const userDetails = getSubtitleMessage(activity);
          return {
            user: await getUserFromActivity(activity),
            subtitle: userDetails.message,
            greyOut: userDetails.greyOut,
          };
        }),
      ),
    );
  }
  async function getAllReturnActivities(): Promise<void> {
    try {
      const activities = await getActivitiesForReturn(client, props.returnId);
      await updateActivitiesList([...activities]);
    } catch (e) {
      console.error(
        `Unable to retrieve activities for return ${props.returnId}`,
      );
    }
  }
  //For initially loading return activities
  useEffect(() => {
    void getAllReturnActivities();
  }, [props.returnId]);

  //For listening to changes in this return's activities
  useEffect(() => {
    const abortController = new AbortController();
    async function startStream(): Promise<void> {
      try {
        for await (const data of listen({
          query: async () =>
            await activitiesStream(
              client,
              props.returnId,
              abortController.signal,
            ),
          loopCondition: true,
        })) {
          const returnActivity: Activity = data as any;
          const activity = returnActivities.find(
            (a) => a.id == returnActivity.id,
          );
          if (!activity) {
            await updateActivitiesList([...returnActivities, returnActivity]);
            continue;
          }

          activity.lastViewedTime = returnActivity.lastViewedTime;
          await updateActivitiesList([...returnActivities]);
        }
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    }

    void startStream();
    return () => {
      abortController.abort();
    };
  }, [props.returnId, returnActivities]);

  //For broadcasting your activity
  useEffect(() => {
    //Only broadcast activity if you're on the team.
    if (
      !team ||
      !user ||
      !team.users.some((u) => {
        if (typeof u.user === "string") {
          return u.user == user._id.toString();
        }
        return u.user._id.toString() == user._id.toString();
      })
    ) {
      return;
    }
    const broadcastIntervalTime = 1000 * 5;
    //We need to send an update immediately so call updateReturnActivity before starting the 5 second interval
    void updateReturnActivity(client, props.returnId);
    const broadcastInterval = setInterval(async () => {
      await updateReturnActivity(client, props.returnId);
    }, broadcastIntervalTime);

    return () => clearInterval(broadcastInterval);
  }, [props.returnId, team, user]);

  async function getUserFromActivity(activity: Activity): Promise<GetUser> {
    const userId = activity.user;
    if (!userCache.has(userId)) {
      userCache.set(userId, await getUserById(client, { id: userId }));
    }
    return userCache.get(userId)!;
  }

  function getSubtitleMessage(activity: Activity): {
    message: string;
    greyOut: boolean;
  } {
    const nowTime = Date.now();
    //Sadly at this point the lastViewed is not technically a Date object
    const lastViewed = new Date(activity.lastViewedTime);
    const secondsPassed = (nowTime - lastViewed.getTime()) / 1000;

    const isGreyedOut = secondsPassed > 60 * 30;
    if (secondsPassed < 15) {
      return { message: "Viewing now", greyOut: isGreyedOut };
    }

    if (secondsPassed < 60) {
      return {
        message: "Last viewed less than a minute ago",
        greyOut: isGreyedOut,
      };
    }

    const hourInSeconds = 60 * 60;

    if (secondsPassed < hourInSeconds) {
      const wholeMinutes = Math.floor(secondsPassed / 60);
      return {
        message: `Last viewed ${wholeMinutes} minutes ago`,
        greyOut: isGreyedOut,
      };
    }

    const dayInSeconds = hourInSeconds * 24;

    if (secondsPassed < dayInSeconds) {
      const wholeHours = Math.floor(secondsPassed / hourInSeconds);
      return {
        message: `Last viewed ${wholeHours} hours ago`,
        greyOut: isGreyedOut,
      };
    }

    const wholeDays = Math.floor(secondsPassed / dayInSeconds);
    return {
      message: `Last viewed ${wholeDays} days ago`,
      greyOut: isGreyedOut,
    };
  }

  return <AvatarStack maxAvatars={5} users={userInfos || []} />;
});
