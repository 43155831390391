import { z } from "zod";

export const setReturnItemDispositionSchema = {
  input: z.object({
    returnId: z.string(),
    itemsWithDisposition: z.record(
      z.object({
        grade: z.optional(z.string()),
        outcome: z.optional(z.string()),
        notes: z.optional(z.string()),
      }),
    ),
    itemsToRestock: z.optional(z.record(z.boolean())),
  }),
  output: z.literal("SUCCESS"),
};

export type SetReturnItemDispositionInput = z.infer<
  typeof setReturnItemDispositionSchema.input
>;
