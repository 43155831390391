import { Currency } from "@redotech/money/currencies";
import {
  any,
  array,
  boolean,
  nativeEnum,
  number,
  object,
  record,
  string,
  union,
  infer as zInfer,
  null as zNull,
} from "zod";

const SeoParser = object({
  title: string().optional(),
  description: string().optional(),
});

const ProductVariantParser = object({
  id: string(),
  gid: string(),
  availableForSale: boolean(),
  price: object({
    amount: string(),
    currencyCode: nativeEnum(Currency),
  }),
  title: string(),
});

export const ProductDetailsParser = object({
  id: string(),
  userSelectedVariantId: string(),
  title: string(),
  description: string(),
  seo: SeoParser.optional(),
  variants: object({
    nodes: array(ProductVariantParser),
  }),
  url: string().optional(),
  metafields: array(
    object({
      key: string(),
      description: string().optional(),
      jsonValue: union([
        string(),
        zNull(),
        array(any()),
        number(),
        boolean(),
        record(string(), any()),
      ]),
    }),
  ).optional(),
});

export type ProductVariant = zInfer<typeof ProductVariantParser>;
export type ProductDetails = zInfer<typeof ProductDetailsParser>;
