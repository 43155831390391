import { FlowType } from "@redotech/redo-model/return-flow";
import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { ConditionType } from "../condition";

export const PRODUCT_TAG: ConditionType<
  readonly string[],
  ModelCondition.ProductTag
> = {
  name: "Product tags",
  description(state) {
    return `Product tags: ${state.join(", ")}`;
  },
  Details({ state, setState, disabled, flowType }) {
    return (
      <LabeledInput
        description={
          (flowType === FlowType.FINALIZE_RETURN ||
            flowType === FlowType.FINALIZE_CLAIM) &&
          "Tags to search for in all selected items"
        }
        label="Product tags"
      >
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          disabled={disabled}
          trimWhitespace
          value={state}
          valueChange={setState}
        />
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model: ModelCondition.ProductTag) {
    return model.tags;
  },
  toModel(state) {
    return { type: ModelCondition.PRODUCT_TAG, tags: state };
  },
  valid() {
    return true;
  },
};
