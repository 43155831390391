import { z } from "zod";
import { salesChannelSchema } from "./sales-channels-schema";

export const getSalesChannelOptionsSchema = {
  input: z.object({}),
  output: z
    .object({
      salesChannelOptions: z.array(salesChannelSchema),
    })
    .nullable(),
};

export type SalesChannel = z.infer<typeof salesChannelSchema>;
