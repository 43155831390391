import { ConversationStatus } from "@redotech/redo-model/conversation";
import { memo, ReactElement } from "react";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
  RedoBadgeType,
} from "../arbiter-components/badge/redo-badge";
import Asterisk02Svg from "../arbiter-icon/asterisk-02_filled.svg";
import ClockSnoozeSvg from "../arbiter-icon/clock-snooze_filled.svg";
import FolderCheckSvg from "../arbiter-icon/folder-check_filled.svg";
import StatusSvg from "../arbiter-icon/status.svg";
import { formatTimeUntil } from "../date-utils";

const StatusMappings: Record<
  ConversationStatus,
  [RedoBadgeColor, (p: any) => ReactElement]
> = {
  [ConversationStatus.OPEN]: [
    RedoBadgeColor.SUCCESS,
    (p: any) => <Asterisk02Svg />,
  ],
  [ConversationStatus.CLOSED]: [
    RedoBadgeColor.GRAY,
    (p: any) => <FolderCheckSvg />,
  ],
  [ConversationStatus.SNOOZED]: [
    RedoBadgeColor.WARNING,
    (p: any) => <ClockSnoozeSvg />,
  ],
  [ConversationStatus.IN_PROGRESS]: [
    RedoBadgeColor.BLUE,
    (p: any) => <StatusSvg />,
  ],
};

/**
 * Arbiter status badge:
 * https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=7852-62667&t=TcciBRu2ObN9VJRA-4
 */
export const RedoSupportStatusBadge = memo(function RedoSupportStatusBadge({
  status,
  size = RedoBadgeSize.SMALL,
  snoozedUntil,
}: {
  status: ConversationStatus;
  size?: RedoBadgeSize;
  snoozedUntil?: string;
}) {
  const [color, iconSvg] = StatusMappings[status];

  /** Put the badge name, and if snoozed, the time until it's over */
  let text = status
    .split("")
    .map((k, i) => (i === 0 ? k.toUpperCase() : k.toLowerCase()))
    .join("");

  if (status === ConversationStatus.SNOOZED && snoozedUntil) {
    text += ` - ${formatTimeUntil(snoozedUntil)}`;
  }

  return (
    <RedoBadge
      color={color}
      segmentLeading={{ type: "icon", Icon: iconSvg }}
      size={size}
      text={text}
      type={RedoBadgeType.PRIMARY}
    />
  );
});
