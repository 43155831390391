import { useRequiredContext } from "@redotech/react-util/context";
import { ConversationPlatform } from "@redotech/redo-model/conversation";
import {
  MacroAutomation,
  MacroAutomationsData,
} from "@redotech/redo-model/macro";
import { Flex } from "@redotech/redo-web/flex";
import { Fragment, memo, ReactElement } from "react";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import {
  EmailSubjectMacroAutomationPill,
  StatusMacroAutomationPill,
  TagMacroAutomationPill,
} from "./macro-automation-pill";
import { AddInternalNoteAutomation } from "./macro-automations/add-internal-note";
import { ForwardMessagePendingOnMessageTag } from "./macro-automations/forward-message";

export const MacroAutomationsList = memo(function MacroAutomationsList({
  macroAutomations,
  setMacroAutomations,
  platform,
}: {
  macroAutomations: MacroAutomationsData;
  setMacroAutomations: (
    setStateCallback: (prev: MacroAutomationsData) => MacroAutomationsData,
  ) => void;
  platform: ConversationPlatform;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);

  const TemplatesForViewingPendingMacroAutomations: Record<
    MacroAutomation,
    ReactElement
  > = {
    [MacroAutomation.SET_STATUS]: (
      <>
        {macroAutomations.statusToSet && (
          <StatusMacroAutomationPill
            snoozeDuration={macroAutomations.snoozeDuration}
            statusToSet={macroAutomations.statusToSet}
            xClicked={() => {
              setMacroAutomations((old) => {
                return {
                  ...old,
                  statusToSet: undefined,
                  snoozeDuration: undefined,
                };
              });
            }}
          />
        )}
      </>
    ),
    [MacroAutomation.ADD_TAGS]: (
      <>
        {macroAutomations.tagsToAdd?.map((tag) => {
          return (
            <TagMacroAutomationPill
              key={tag}
              tag={tag}
              xClicked={() => {
                setMacroAutomations((old) => {
                  return {
                    ...old,
                    tagsToAdd: old.tagsToAdd?.filter(
                      (oldTag) => oldTag !== tag,
                    ),
                  };
                });
              }}
            />
          );
        })}
      </>
    ),
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: (
      <>
        {macroAutomations.emailSubjectToSet &&
          platform === ConversationPlatform.EMAIL && (
            <EmailSubjectMacroAutomationPill
              emailSubject={macroAutomations.emailSubjectToSet}
              xClicked={() => {
                setMacroAutomations((old) => {
                  return {
                    ...old,
                    emailSubjectToSet: undefined,
                  };
                });
              }}
            />
          )}
      </>
    ),
    [MacroAutomation.ADD_INTERNAL_NOTE]: (
      <AddInternalNoteAutomation.PendingOnMessageTag
        client={client}
        noteToAddContent={macroAutomations.noteToAddContent ?? ""}
        noteToAddHtmlContent={macroAutomations.noteToAddHtmlContent ?? ""}
        noteToAddUsersMentioned={macroAutomations.noteToAddUsersMentioned ?? []}
        setNoteToAddContent={(content) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              noteToAddContent: content,
            };
          });
        }}
        setNoteToAddHtmlContent={(htmlContent) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              noteToAddHtmlContent: htmlContent,
            };
          });
        }}
        setNoteToAddUsersMentioned={(usersMentioned) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              noteToAddUsersMentioned: usersMentioned,
            };
          });
        }}
        setShouldAddNote={(value) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              shouldAddNote: value,
            };
          });
        }}
        shouldAddNote={macroAutomations.shouldAddNote ?? false}
        team={team}
      />
    ),
    [MacroAutomation.FORWARD_MESSAGE]: (
      <ForwardMessagePendingOnMessageTag
        content={macroAutomations.messageToForwardExtraContent ?? ""}
        email={macroAutomations.messageToForwardEmailAddress ?? ""}
        setContent={(content) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              messageToForwardExtraContent: content,
            };
          });
        }}
        setEmail={(email) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              messageToForwardEmailAddress: email,
            };
          });
        }}
        setShouldForwardMessage={(value) => {
          setMacroAutomations((old) => {
            return {
              ...old,
              shouldForwardMessage: value,
            };
          });
        }}
        shouldForwardMessage={macroAutomations.shouldForwardMessage ?? false}
      />
    ),
  };
  return (
    <Flex wrap="wrap">
      {Object.entries(TemplatesForViewingPendingMacroAutomations).map(
        ([automation, element]) => (
          <Fragment key={automation}>{element}</Fragment>
        ),
      )}
    </Flex>
  );
});
