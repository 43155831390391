export const ouncesToGrams = (ounces: number): number => {
  return ounces / 0.035274;
};

export const poundsToGrams = (pounds: number): number => {
  return pounds / 0.0022046;
};

export const kilogramsToGrams = (kilograms: number): number => {
  return kilograms * 1000;
};

export namespace WeightUnit {
  export const GRAMS = "g";
  export const KILOGRAMS = "kg";
  export const OUNCES = "oz";
  export const POUNDS = "lb";
}

export type WeightUnit =
  | typeof WeightUnit.GRAMS
  | typeof WeightUnit.KILOGRAMS
  | typeof WeightUnit.OUNCES
  | typeof WeightUnit.POUNDS;
