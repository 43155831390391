import { useRequiredContext } from "@redotech/react-util/context";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { memo, useEffect, useRef } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { getYofiJwt } from "../../client/return";

export const YofiDetailsModal = memo(function YofiDetailsModal({
  open,
  onClose,
  customerId,
}: {
  open: boolean;
  onClose(): void;
  customerId: string;
}) {
  const urlOrigin = "https://embed.botnot.io/";
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const client = useRequiredContext(RedoMerchantClientContext);

  const postMessageToIframe = (message: { type: string; data: any }) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      // Post the message to the iframe
      iframeRef.current.contentWindow.postMessage(message, urlOrigin);
    }
  };

  const sendToken = async () => {
    const token = await getYofiJwt(client, {});
    postMessageToIframe({
      type: "SET_TOKEN",
      data: token,
    });
  };

  const showCustomerDetail = (id: string) => {
    postMessageToIframe({
      type: "SHOW_CUSTOMER_DETAIL",
      data: { id },
    });
  };

  const handleIframeMessage = async (event: any) => {
    // Ensure the message is coming from Yofi origin
    if (event.origin !== urlOrigin) {
      return;
    }
    if (event.data.type === "TOKEN_REFRESH") {
      await sendToken();
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  return (
    <Modal
      onClose={onClose}
      open={open}
      paddingAmount={PaddingAmount.NONE}
      showHeader={false}
      size={ModalSize.FLEXIBLE}
      title="Return Details"
    >
      <iframe
        onLoad={async () => {
          await sendToken();
          showCustomerDetail(customerId);
        }}
        ref={iframeRef}
        src={urlOrigin}
        style={{
          border: "none",
          width: "90vw",
          height: "80vh",
          background: "transparent",
          borderRadius: 10,
        }}
        title="yofi"
      />
    </Modal>
  );
});
