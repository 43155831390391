import { useHandler } from "@redotech/react-util/hook";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { produce } from "immer";
import { memo } from "react";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

interface State {
  next: StepId | undefined;
}

function valid(state: State) {
  return state.next !== undefined;
}

const Details = memo(function Details({
  state,
  stepSelect,
  setState,
}: StepTypeDetailsProps<State>) {
  const valueChange = useHandler((value: StepId | undefined) => {
    setState((state) => ({ ...state, next: value || undefined }));
  });

  return (
    <LabeledInput label="Next step">
      {stepSelect({ value: state.next, valueChange })}
    </LabeledInput>
  );
});

export function getStartStep(firstStepTitle: string): StepType<State, any> {
  return {
    description() {
      return "";
    },
    Details,
    customTitle() {
      return undefined;
    },
    empty: { next: undefined },
    start(state) {
      return state.next;
    },
    ignore: true,
    Icon: () => null,
    title: firstStepTitle,
    valid,
    stepDeleted(state, stepId) {
      return produce(state, (state) => {
        if (state.next === stepId) {
          state.next = undefined;
        }
      });
    },
    layout() {
      return BlockLayout.COMPACT;
    },
    fromModel() {
      throw new Error("Unsupported");
    },
    toModel() {
      throw new Error("Unsupported");
    },
    downstream(state) {
      const result: StepDownstream[] = [];
      if (state.next) {
        result.push({ id: state.next });
      }
      return result;
    },
  };
}
