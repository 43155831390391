import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { WidgetClient, getProduct } from "@redotech/redo-api-client/widget";
import type { Return } from "@redotech/redo-model/return";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { sleep } from "@redotech/util/schedule";
import { memo, useEffect, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { changeProductReturnType } from "../../client/return";
import * as returnCss from "../return.module.css";
import { isVariantExchange, returnTypeName } from "../util";

export const ChangeReturnTypeModal = memo(function ChangeReturnTypeModal({
  open,
  onClose,
  return: return_,
  product,
  newType,
  reload,
}: {
  open: boolean;
  onClose(refresh?: boolean): void;
  return: Return;
  product: Return["products"][number];
  newType: string;
  reload(): void;
}) {
  const apiClient = useRequiredContext(RedoClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const [changeTypeLoad, doChangeType] = useTriggerLoad((signal) =>
    alertOnFailure("Changing product return type failed")(async () => {
      const newItemVariantIds = newItems.map((item) => item.id);
      await changeProductReturnType(client, {
        returnId: return_.id,
        signal,
        productId: product._id,
        newType: newType,
        variantId: variantSelected,
        newItemVariantIds: newItemVariantIds.length
          ? newItemVariantIds
          : undefined,
      });
      return_.products = [return_.products[0]];
      await sleep(Temporal.Duration.from({ seconds: 2 }));
      reload();
      return true;
    }),
  );
  const handleSubmit = useHandler(() => doChangeType());
  const previousType = isVariantExchange(product)
    ? "exchange"
    : product.strategy === "store_credit"
      ? "store_credit"
      : "refund";

  const widgetClient = new WidgetClient(apiClient, return_.team.widget_slug);
  const [_, setVariantExchangeAvailable] = useState(false);
  const [setShopifyProduct] = useState<any>(null);
  const [hideButtons, setHideButtons] = useState(false);
  const [showNewItems, setShowNewItems] = useState(false);
  const [variantSelected] = useState<string | undefined>();
  const [newItems] = useState<any[]>([]);

  useEffect(() => {
    void getProduct(widgetClient, { productId: product.product_id }).then(
      (productResponse: any) => {
        let available = false;
        const quantity = 1;
        setShopifyProduct(productResponse);
        if (productResponse.variants.length !== 1) {
          available = productResponse.variants.find(
            (variant: any) =>
              variant.inventory_management === null ||
              (variant.inventory_policy === "continue" &&
                return_.team.settings.inventory.followShopifyInventoryPolicy) ||
              variant.inventory_quantity >= quantity,
          );
        }

        setVariantExchangeAvailable(available);
      },
    );
  }, []);

  const footer = changeTypeLoad.value ? (
    <Button
      className={returnCss.modalButton}
      onClick={() => {
        onClose(true);
      }}
      theme={ButtonTheme.OUTLINED}
    >
      Ok
    </Button>
  ) : !hideButtons ? (
    <>
      <Button
        className={returnCss.modalButton}
        onClick={() => {
          onClose();
        }}
        theme={ButtonTheme.OUTLINED}
      >
        Cancel
      </Button>
      <Button
        className={returnCss.modalButton}
        disabled={changeTypeLoad.value}
        onClick={handleSubmit}
        pending={changeTypeLoad.pending}
        theme={ButtonTheme.PRIMARY}
      >
        Confirm
      </Button>
    </>
  ) : (
    showNewItems &&
    newItems.length > 0 && (
      <div className={returnCss.modalFooterRight}>
        <Button
          className={returnCss.modalButton}
          disabled={changeTypeLoad.value}
          onClick={() => {
            setShowNewItems(false);
            setHideButtons(false);
          }}
          pending={changeTypeLoad.pending}
          theme={ButtonTheme.PRIMARY}
        >
          Continue
        </Button>
      </div>
    )
  );

  const variantExchangeItem = product.exchange_for
    ? `${product.exchange_for.product_title} (${product.exchange_for.variant_title})`
    : product.exchangeGroupItem
      ? `${product.exchangeGroupItem.title} (${product.exchangeGroupItem.variantTitle})`
      : "";

  const FinalizeScreen = () => (
    <p>
      Are you sure you want to change the {returnTypeName(return_.type)} type of{" "}
      {product.product_title} from {previousType.replace(/_/g, " ")} to{" "}
      {newType.replace(/_/g, " ")}?{" "}
      {isVariantExchange(product) && (
        <b>This will remove {variantExchangeItem} from the exchange order.</b>
      )}
    </p>
  );

  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      size={ModalSize.SMALL}
      title={`Change ${returnTypeName(return_.type)} type`}
    >
      <div className={returnCss.modalContent}>
        {changeTypeLoad.value ? (
          <p>The {returnTypeName(return_.type)} type has been updated.</p>
        ) : (
          <FinalizeScreen />
        )}
      </div>
    </Modal>
  );
});
