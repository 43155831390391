import { Resource } from "@redotech/util/resource";

export function withObjectUrl(blob: Blob | MediaSource): Resource<string> {
  return async (fn) => {
    const url = URL.createObjectURL(blob);
    try {
      return await fn(url);
    } finally {
      URL.revokeObjectURL(url);
    }
  };
}
