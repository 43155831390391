// extracted by mini-css-extract-plugin
export var container = "_2i-0";
export var error = "_2i-9";
export var label = "_2i-5";
export var light = "_2i-6";
export var md = "_2i-3";
export var sm = "_2i-2";
export var thin = "_2i-8";
export var thinBold = "_2i-7";
export var vertical = "_2i-4";
export var xs = "_2i-1";