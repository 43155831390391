import { GetUser } from "@redotech/redo-model/user";
import { createContext } from "react";

export const UserContext = createContext<GetUser | undefined>(undefined);
UserContext.displayName = "UserContext";

export const ReloadUserContext = createContext<(() => void) | undefined>(
  undefined,
);
ReloadUserContext.displayName = "ReloadUserContext";
