/**
 * Backspace
 */
export const BackspaceKey = "Backspace";

/**
 * Enter
 */
export const EnterKey = "Enter";

export const ArrowRight = "ArrowRight";
export const ArrowLeft = "ArrowLeft";
export const ArrowUp = "ArrowUp";
export const ArrowDown = "ArrowDown";
