import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

// Mirror all changes to this file in redo/server/src/models/CustomReport.ts

const CustomReportScheduleSchema = z.object({
  enabled: z.boolean(),
  emailAddresses: z.array(z.string()),
  cron: z.string(),
  nextReport: z.date().optional(),
});

export const CustomReportSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  name: z.string(),
  queryParams: z.map(z.string(), z.string()),
  schedule: CustomReportScheduleSchema.optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

/** Front-end type */
export type CustomReport = z.infer<typeof CustomReportSchema>;
