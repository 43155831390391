// extracted by mini-css-extract-plugin
export var border = "_4e-5";
export var button = "_4e-g";
export var center = "_4e-7";
export var centered = "_4e-3";
export var closeButton = "_4e-9";
export var disabledWrapper = "_4e-h";
export var error = "_4e-k";
export var fill = "_4e-d";
export var footer = "_4e-c";
export var footerAction = "_4e-e";
export var footerButtons = "_4e-f";
export var header = "_4e-4";
export var icon = "_4e-j";
export var info = "_4e-n";
export var inline = "_4e-6";
export var large = "_4e-2";
export var main = "_4e-a";
export var modal = "_4e-0";
export var none = "_4e-o";
export var scrollable = "_4e-b";
export var small = "_4e-1";
export var success = "_4e-m";
export var tight = "_4e-i";
export var titleIcon = "_4e-8";
export var warn = "_4e-l";