import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { FiltersStatus } from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Card, CardSize } from "@redotech/redo-web/card";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import ExternalLinkIcon from "@redotech/redo-web/icon-old/external-link.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { Fragment, memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { getRecentCustomerConversations } from "../../client/conversations";
import { ConversationInfoCard } from "../../support/conversation-info-card";
import { CreateConversationModal } from "../../support/create-conversation-modal";
import * as customerConversationsCss from "./customer-conversations-card.module.css";

export const CustomerConversationsCard = memo(
  function CustomerConversationsCard({
    returnType,
    customerEmail,
  }: {
    returnType?: "return" | "claim" | "warranty" | "managed_claim";
    customerEmail?: string;
  }) {
    const team = useRequiredContext(TeamContext);
    const navigate = useNavigate();
    const client = useRequiredContext(RedoMerchantClientContext);

    const [statusFilter, setStatusFilter] = useState<FiltersStatus | "all">(
      FiltersStatus.OPEN,
    );

    const [createConversationModalOpen, setCreateConversationModalOpen] =
      useState(false);

    const [statusFilterDropdownRef, setStatusFilterDropdownRef] =
      useState<HTMLButtonElement | null>(null);

    const handleNavigateAfterCreate = async ({
      conversationId,
      stayOnPage = true,
    }: {
      conversationId: string;
      stayOnPage?: boolean;
    }) => {
      amplitude.logEvent(`create-conversation-${returnType}-detail-page`);
      if (!stayOnPage) {
        navigate(
          `/stores/${team?._id}/support/table/all?activeConversationId=${conversationId}`,
        );
      }
    };

    const conversationsLoad = useLoad(
      async (signal) => {
        if (!customerEmail) {
          return;
        }

        const conversations = await getRecentCustomerConversations(client, {
          customerEmail,
          status: statusFilter,
          signal,
        });

        return conversations;
      },
      [customerEmail, team, statusFilter],
    );

    const handleSearchSupport = () => {
      if (!conversationsLoad.value?.[0]?.customer.email || !team?._id) {
        return;
      }

      const searchString = conversationsLoad.value[0].customer.email;
      amplitude.logEvent(`search-conversation-from-${returnType}`, {
        mode: "all",
        searchString,
      });
      navigate({
        pathname: `/stores/${team?._id}/support/table/all`,
        search: `?initialSearch=${encodeURIComponent(searchString || "")}`,
      });
    };

    const statusOptions: RedoListItem<FiltersStatus | "all">[] = [
      { value: FiltersStatus.OPEN },
      { value: FiltersStatus.CLOSED },
      ...(team.settings.support?.useInProgressStatus
        ? [{ value: FiltersStatus.IN_PROGRESS }]
        : []),
      { value: FiltersStatus.SNOOZED },
      { value: "all" },
    ];

    function getItemDisplay(status: FiltersStatus | "all"): string {
      if (status === "all") {
        return `All`;
      } else if (status === FiltersStatus.OPEN) {
        return `Open`;
      } else if (status === FiltersStatus.CLOSED) {
        return `Closed`;
      } else if (status === FiltersStatus.SNOOZED) {
        return `Snoozed`;
      } else if (status === FiltersStatus.IN_PROGRESS) {
        return `In progress`;
      } else {
        return assertNever(status);
      }
    }

    return (
      <>
        <Card noPadding size={CardSize.MEDIUM}>
          <div className={customerConversationsCss.conversationsCardHeader}>
            <Flex align="center" justify="space-between" w="full">
              <Text fontSize="md" fontWeight="semibold">
                Support tickets
              </Text>
              <RedoButton
                hierarchy={RedoButtonHierarchy.SECONDARY}
                IconLeading={PlusIcon}
                onClick={() => setCreateConversationModalOpen(true)}
                size={RedoButtonSize.SMALL}
                text="Send email"
              />
            </Flex>
          </div>
          <Divider />
          <RedoSingleSelectDropdown
            dropdownButtonRef={statusFilterDropdownRef}
            options={statusOptions}
            optionSelected={(status: RedoListItem<FiltersStatus | "all">) => {
              setStatusFilter(status.value);
            }}
            selectedItem={statusOptions.find(
              (option) => option.value === statusFilter,
            )}
            size={RedoListItemSize.SMALL}
          >
            {(option: RedoListItem<FiltersStatus | "all">) => {
              return <Text fontSize="xs">{getItemDisplay(option.value)}</Text>;
            }}
          </RedoSingleSelectDropdown>
          <Flex px="3xl" py="xl">
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconTrailing={ChevronDownIcon}
              onClick={() => {}}
              ref={setStatusFilterDropdownRef}
              text={getItemDisplay(statusFilter)}
            />
          </Flex>
          <Divider />
          {conversationsLoad.value ? (
            conversationsLoad.value.length ? (
              <>
                {conversationsLoad.value.map(
                  (conversation: ExpandedConversation, index: number) => (
                    <Fragment key={`${index}`}>
                      {index > 0 && <Divider />}
                      <ConversationInfoCard
                        item={conversation}
                        key={`${index}`}
                        onClick={() => {
                          navigate(
                            `/stores/${team?._id}/support/table/all?activeConversationId=${conversation._id}`,
                          );
                        }}
                      />
                    </Fragment>
                  ),
                )}
                {conversationsLoad.value?.[0]?.customer.email && (
                  <>
                    <Divider />
                    <div
                      className={
                        customerConversationsCss.conversationsCardFooter
                      }
                    >
                      <Button
                        onClick={handleSearchSupport}
                        size={ButtonSize.EXTRA_SMALL}
                        theme={ButtonTheme.OUTLINED}
                      >
                        <div className={customerConversationsCss.buttonContent}>
                          See all support tickets
                          <ExternalLinkIcon
                            className={customerConversationsCss.icon}
                          />
                        </div>
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <ConversationInfoCard empty />
            )
          ) : (
            <div className={customerConversationsCss.conversationsCardFooter}>
              <SkeletonText length={30} />
            </div>
          )}
        </Card>
        <CreateConversationModal
          customerInfo={{
            email:
              conversationsLoad.value?.[0]?.customer.email || customerEmail,
          }}
          handleNavigate={handleNavigateAfterCreate}
          open={createConversationModalOpen}
          setOpen={setCreateConversationModalOpen}
          showStayOption
        />
      </>
    );
  },
);
