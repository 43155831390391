import { z } from "zod";
import {
  ReviewFormBuilderForm,
  reviewFormBuilderFormDbParser,
  reviewFormDataDbParser,
} from "./review-form-builder-form-db-validator";

export const reviewFormValidator = reviewFormBuilderFormDbParser
  .omit({ versionedFormData: true })
  .extend({
    formData: reviewFormDataDbParser,
  });

/**
 * When a form is updated, the latest version is appended to the form's
 * `versionedFormData`. This method gets the latest version of the form.
 */
export function getLatestReviewForm(
  reviewForm: ReviewFormBuilderForm,
): z.infer<typeof reviewFormValidator> {
  const { versionedFormData, ...rest } = reviewForm;
  return {
    ...rest,
    formData:
      reviewForm.versionedFormData[reviewForm.versionedFormData.length - 1],
  };
}
