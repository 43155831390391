import { AutocompleteValue, PopperProps } from "@mui/base";
import {
  AutocompleteChangeReason,
  AutocompleteFreeSoloValueMapping,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  Theme,
  outlinedInputClasses,
} from "@mui/material";
import { IterableMap, genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { HTMLAttributes, SyntheticEvent, forwardRef } from "react";
import * as autocompleteCSS from "./autocomplete.module.css";
import { Chip } from "./chip-input";
import { Dropdown, DropdownOption } from "./dropdown";
import CheckIcon from "./icon-old/check.svg";
import ChevronDownIcon from "./icon-old/chevron-down.svg";
import * as selectDropdownCss from "./select-dropdown.module.css";

export const AutocompleteThemeOverrides: {
  defaultProps?: ComponentsProps["MuiAutocomplete"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAutocomplete"];
  variants?: ComponentsVariants["MuiAutocomplete"];
} = {
  styleOverrides: {
    inputRoot: {
      lineHeight: "21px",
      padding: "10px 65px 10px 10px !important",
      borderRadius: "10px",
      fontSize: "var(--redo-body-medium-text-size)",
      transition: "background 250ms, border 250ms",
      gap: "var(--redo-spacing)",
      rowGap: "var(--redo-spacing)",
      ":hover": {
        borderColor: "#ddd",
      },
      ":focus-within": {
        borderColor: "var(--redo-primary-color)",
        background: "white",
      },
      [`.${outlinedInputClasses.notchedOutline}`]: {
        display: "none",
      },
    },
    input: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      height: "auto",
    },
    endAdornment: {
      right: "12px !important",
      button: {
        height: "16px",
        width: "16px",
        padding: 0,
        background: "none !important",
      },
      svg: {
        height: "16px",
        width: "16px",
        stroke: "black",
      },
    },
    clearIndicator: {
      marginRight: "16px",
    },
    popper: {
      top: "8px",
    },
    paper: {},
  },
};

type BaseProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean | undefined,
> = Pick<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  | "options"
  | "multiple"
  | "value"
  | "disableCloseOnSelect"
  | "disableClearable"
  | "onInputChange"
  | "loading"
  | "filterOptions"
  | "isOptionEqualToValue"
  | "noOptionsText"
  | "freeSolo"
>;

export enum AutocompleteBorderColor {
  NORMAL = "var(--redo-colors-border-border-secondary)",
  HIGH_CONTRAST = "var(--redo-contrast-high-border-color)",
}

export interface AutocompleteProps<
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
> extends BaseProps<T, Multiple, DisableClearable, FreeSolo> {
  keyFn?: (item: T, index: number) => React.Key;
  children?: (item: T) => React.ReactNode;
  getLabel: (item: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
  valueChange: (
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
  ) => void;
  disableOption?: (item: any) => boolean;
  blurOnSelect?: boolean;
  disabled?: boolean;
  freeSolo?: FreeSolo;
  tagLimit?: number;
  borderColor?: AutocompleteBorderColor;
  backgroundColor?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  id?: string;
}

// If you don't want autocomplete to filter out its options pass
// filterOptions: (x) => x
export const Autocomplete = genericMemo(function Autocomplete<
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>({
  valueChange,
  getLabel,
  children,
  keyFn = (item: T, index: number) => index,
  disableOption,
  blurOnSelect,
  disabled,
  freeSolo,
  borderColor,
  backgroundColor,
  tagLimit,
  placeholder,
  value,
  style,
  id,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const onChange = useHandler(
    (
      event: SyntheticEvent<Element, Event>,
      newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
    ) => {
      valueChange(newValue);
    },
  );

  return (
    <MuiAutocomplete
      {...props}
      autoHighlight
      blurOnSelect={blurOnSelect}
      disabled={disabled}
      freeSolo={freeSolo}
      getOptionLabel={getLabel}
      id={id}
      onChange={onChange}
      PaperComponent={NoopElement}
      PopperComponent={CustomPopper}
      popupIcon={<ChevronDownIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            !value || (Array.isArray(value) && value.length === 0)
              ? placeholder
              : undefined
          }
          variant="outlined"
        />
      )}
      renderOption={({ className, ...props }, option, { selected, index }) => {
        if (disableOption?.(option)) {
          delete props.onClick;
        }

        return (
          <li
            {...props}
            className={autocompleteCSS.dropdownItemWrapper}
            key={keyFn(option, index)}
          >
            <DropdownOption disabled={disableOption?.(option)}>
              <div>{(children || getLabel)(option)}</div>
              {selected && <CheckIcon className={selectDropdownCss.check} />}
            </DropdownOption>
          </li>
        );
      }}
      renderTags={(value, getTagProps, ownerState) => {
        if (!ownerState.focused && tagLimit && value.length > tagLimit) {
          value = value.slice(value.length - tagLimit, value.length);
        }
        return (
          <IterableMap items={value} keyFn={keyFn}>
            {(chipValue, index) => {
              const props = getTagProps({ index });

              return <Chip remove={props.onDelete}>{getLabel(chipValue)}</Chip>;
            }}
          </IterableMap>
        );
      }}
      slotProps={{
        clearIndicator: {
          disableRipple: true,
        },
        popupIndicator: {
          disableRipple: true,
        },
      }}
      style={style}
      sx={{
        border: `var(--redo-border-width) solid ${
          borderColor || AutocompleteBorderColor.NORMAL
        }`,
        borderRadius: "10px",
        backgroundColor: backgroundColor || "var(--redo-surface-medium-color)",
      }}
      value={value}
    />
  );
});

const CustomPopper = ({ children, anchorEl, open }: PopperProps) => (
  <Dropdown anchor={anchorEl as any} open={open}>
    {children as any}
  </Dropdown>
);

const NoopElement = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
  function NoopElement({ children }, ref) {
    return <>{children}</>;
  },
);
