import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { createContext, RefObject } from "react";

export interface TicketActions {
  doCloseTicketAction: () => Promise<void>;
  doReopenTicketAction: () => void;
  doSnoozeTicketAction: () => void;
  doArchiveTicketAction: () => void;
  doMarkUnreadTicketAction: () => Promise<void>;
  doMergeTicketAction: () => void;
  doMarkSpamTicketAction: () => void;
}

export const ActiveConversationContext = createContext<{
  div: RefObject<HTMLDivElement | null> | undefined;
  ticketActions: TicketActions | undefined;
  conversationClosing: boolean | undefined;
  setConversationClosing: (closing: boolean) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
  activeConversation: ExpandedConversation | undefined;
}>({
  div: undefined,
  ticketActions: undefined,
  conversationClosing: undefined,
  setConversationClosing: (closing) => {},
  setActiveConversation: (conversation) => {},
  activeConversation: undefined,
});
