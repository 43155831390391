import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { sinkPromise } from "@redotech/util/promise";
import { ForwardedRef, forwardRef, memo } from "react";
import { copyToClipboard } from "./clipboard";
import { CopyableTooltip } from "./copyable-tooltip";
import * as copyableTooltipCss from "./copyable-tooltip.module.css";
/**
 * Backed by regular redo-web Text component, but gives a helper to copy text on click.
 * Adds an extra constraint that the children must be a string.
 */
export const CopyableText = memo(
  forwardRef(function CopyableText(
    {
      children,
      placement,
      /** if you want to copy a different string than you see */
      altTextToCopy,
      ...otherTextProps
    }: {
      children: string;
      placement?: Parameters<typeof Tooltip>[0]["placement"];
      altTextToCopy?: string;
    } & Parameters<typeof Text>[0],
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const textToCopy = altTextToCopy ?? children;
    return (
      <CopyableTooltip textToCopy={textToCopy}>
        <Text
          {...otherTextProps}
          className={copyableTooltipCss.underlined}
          onClick={() => sinkPromise(copyToClipboard(textToCopy))}
          ref={ref}
        >
          {children}
        </Text>
      </CopyableTooltip>
    );
  }),
);
