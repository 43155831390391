import { ensureProtocolPresent, isValidLink } from "@redotech/util/link";
export function cutOutRepliesFromMessageContent(htmlContent: string) {
  let showToggleViewReplyButton = false;
  const shortenedMessage = htmlContent

    // Just going to add the gmail_quote class (temporary front end only) to anything we want to hide to standardize it.
    // replace any number of x_*gmail_quote classes with just gmail_quote -> outlook
    .replaceAll('gmail_quote"', 'gmail_quote" class="gmail_quote"')

    // Yahoo mail
    .replaceAll(
      // regex to match class= anything then yahoo_quoted
      /class=".*yahoo_quoted"/g,
      'class="gmail_quote"',
    )

    // Thunderbird
    .replaceAll('class="moz-cite-prefix"', 'class="gmail_quote"')

    // Gorgias
    .replaceAll('class="gorgias_quote"', 'class="gmail_quote"')

    // Adds the class "gmail_quote" to blockquotes with type="cite" to have them be collapsible.
    .replaceAll('type="cite"', 'type="cite" class="gmail_quote"');
  // Hide any reply threads in the email
  const htmlObject = document.createElement("div");
  htmlObject.innerHTML = shortenedMessage;

  // Remove outlook reply threads
  const firstThreadOutlook = htmlObject.querySelector("[id$='divRplyFwdMsg']");
  if (firstThreadOutlook) {
    let currentElement: Element | null = firstThreadOutlook;
    let nextElement: Element | null = firstThreadOutlook.nextElementSibling;
    while (currentElement) {
      currentElement.remove();
      currentElement = nextElement;
      nextElement = nextElement?.nextElementSibling || null;
    }
    showToggleViewReplyButton = true;
  }

  const elementsToRemove = htmlObject.querySelectorAll(
    "[class$='gmail_quote']",
  );

  if (elementsToRemove.length > 0) {
    showToggleViewReplyButton = true;
  }
  for (let i = 0; i < elementsToRemove.length; i++) {
    elementsToRemove[i].remove();
  }

  return { processedContent: htmlObject.innerHTML, showToggleViewReplyButton };
}

export function convertPlainTextToHtml(plainText: string) {
  return plainText.replace(/\n/g, "<br>");
}

export function updateLinksToOpenInNewTab(parent: HTMLElement): void {
  const links = Array.from(parent.getElementsByTagName("a"));

  for (const link of links) {
    link.setAttribute("target", "_blank");
  }

  for (const link of links) {
    const currentHref = link.href;
    const newLink = isValidLink(currentHref)
      ? ensureProtocolPresent(currentHref)
      : currentHref;
    link.href = newLink;
  }
}
