import { faqCollectionBodySchema } from "@redotech/redo-model/faq/faq-body-schema";
import { z } from "zod";
export const createCollectionSchema = {
  input: z.object({
    title: z.string(),
  }),
  output: z.union([
    z.object({
      success: z.literal(true),
      data: faqCollectionBodySchema,
    }),
    z.object({
      success: z.literal(false),
      error: z.string(),
    }),
  ]),
};
