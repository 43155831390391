// extracted by mini-css-extract-plugin
export var actionButton = "_95-1";
export var actions = "_95-0";
export var copyableField = "_95-2";
export var detailColumn = "_95-3";
export var detailRow = "_95-4";
export var dropdownTitle = "_95-5";
export var fulfillmentDetails = "_95-7";
export var fulfillments = "_95-6";
export var icon = "_95-8";
export var imgContainer = "_95-9";
export var lineItems = "_95-a";
export var link = "_95-p";
export var orderCard = "_95-k";
export var orderCollapseButton = "_95-l";
export var orderCollapseButtonIcon = "_95-m";
export var orderDetails = "_95-b";
export var orderPillsContainer = "_95-c";
export var pillWrapper = "_95-d";
export var product = "_95-e";
export var productDetails = "_95-g";
export var productTopDetails = "_95-h";
export var products = "_95-f";
export var strong = "_95-i";
export var tightText = "_95-o";
export var tooltipHidden = "_95-q";
export var variantQuantityRow = "_95-j";
export var wrappingText = "_95-n";