import { z } from "zod";
import {
  BaseCustomerEvent,
  BaseShoppingEvent,
  ClaimCommentCreatedCustomerEvent,
  ClaimCreatedCustomerEvent,
  ClaimStatusUpdatedCustomerEvent,
  ConversationCreatedCustomerEvent,
  ConversationMessageCreatedCustomerEvent,
  ConversationStatusUpdatedCustomerEvent,
  CustomerEvent,
  CustomerEventType,
  ExternalPlatformCustomerCreatedEvent,
  IClaimCustomerEvent,
  IConversationCustomerEvent,
  IOrderCustomerEvent,
  IReturnCustomerEvent,
  OrderCreatedCustomerEvent,
  OrderStatusUpdatedCustomerEvent,
  OrderUpdatedCustomerEvent,
  ProductAddedToCartShoppingEvent,
  ProductViewedShoppingEvent,
  ReturnCommentCreatedCustomerEvent,
  ReturnCreatedCustomerEvent,
  ReturnStatusUpdatedCustomerEvent,
  StorefrontLoginEvent,
} from "../customer-event/customer-event-definition";
import { CustomerEventSchema } from "../customer-event/customer-event-zod-schema";

export interface ICustomerActivity {
  customerEvents: CustomerEvent[];
}

export const CustomerActivitySchema = z.object({
  customerEvents: z.array(CustomerEventSchema),
});

// TODO: once Zod is implemented, delete all the functions below
// except for the ones that include multiple event types.
// For those ones, replace them with a map: Record<CustomerEventType, CustomerEventCategory[]>

export function isOrderCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is OrderCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.ORDER_CREATED;
}

export function isOrderUpdatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is OrderUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.ORDER_UPDATED;
}

export function isOrderStatusUpdatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is OrderStatusUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.ORDER_STATUS_UPDATED;
}

export function isOrderCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is IOrderCustomerEvent {
  return (
    isOrderCreatedCustomerEvent(customerActivity) ||
    isOrderStatusUpdatedCustomerEvent(customerActivity) ||
    isOrderUpdatedCustomerEvent(customerActivity)
  );
}

export function isReturnCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ReturnCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.RETURN_CREATED;
}

export function isReturnStatusUpdatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ReturnStatusUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.RETURN_STATUS_UPDATED;
}

export function isReturnCommentCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ReturnCommentCreatedCustomerEvent {
  return (
    customerActivity.eventType === CustomerEventType.RETURN_COMMENT_CREATED
  );
}

export function isReturnCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is IReturnCustomerEvent {
  return (
    isReturnCreatedCustomerEvent(customerActivity) ||
    isReturnStatusUpdatedCustomerEvent(customerActivity) ||
    isReturnCommentCreatedCustomerEvent(customerActivity)
  );
}

export function isClaimCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ClaimCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CLAIM_CREATED;
}

export function isClaimStatusUpdatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ClaimStatusUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CLAIM_STATUS_UPDATED;
}

export function isClaimCommentCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ClaimCommentCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CLAIM_COMMENT_CREATED;
}

export function isClaimCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is IClaimCustomerEvent {
  return (
    isClaimCreatedCustomerEvent(customerActivity) ||
    isClaimStatusUpdatedCustomerEvent(customerActivity) ||
    isClaimCommentCreatedCustomerEvent(customerActivity)
  );
}

export function isConversationCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ConversationCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CONVERSATION_CREATED;
}

export function isConversationStatusUpdatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ConversationStatusUpdatedCustomerEvent {
  return (
    customerActivity.eventType === CustomerEventType.CONVERSATION_STATUS_UPDATED
  );
}

export function isConversationMessageCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ConversationMessageCreatedCustomerEvent {
  return (
    customerActivity.eventType ===
    CustomerEventType.CONVERSATION_MESSAGE_CREATED
  );
}

export function isConversationCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is IConversationCustomerEvent {
  return (
    isConversationCreatedCustomerEvent(customerActivity) ||
    isConversationMessageCreatedCustomerEvent(customerActivity) ||
    isConversationStatusUpdatedCustomerEvent(customerActivity)
  );
}

export function isExternalPlatformCustomerCreatedCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ExternalPlatformCustomerCreatedEvent {
  return (
    customerActivity.eventType ===
    CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED
  );
}

export function isStorefrontLoginCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is StorefrontLoginEvent {
  return customerActivity.eventType === CustomerEventType.STOREFRONT_LOGIN;
}

export function isCustomerEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is CustomerEvent {
  return (
    isOrderCustomerEvent(customerActivity) ||
    isReturnCustomerEvent(customerActivity) ||
    isClaimCustomerEvent(customerActivity) ||
    isConversationCustomerEvent(customerActivity)
  );
}

export function isProductViewedEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ProductViewedShoppingEvent {
  return customerActivity.eventType === CustomerEventType.PRODUCT_VIEWED;
}

export function isProductAddedToCartEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is ProductAddedToCartShoppingEvent {
  return customerActivity.eventType === CustomerEventType.PRODUCT_ADDED_TO_CART;
}

export function isShoppingEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is BaseShoppingEvent {
  return (
    isProductViewedEvent(customerActivity) ||
    isProductAddedToCartEvent(customerActivity)
  );
}

export function isMarketingEvent(
  customerActivity: BaseCustomerEvent,
): customerActivity is BaseCustomerEvent {
  return [
    CustomerEventType.MARKETING_SUBSCRIBED,
    CustomerEventType.MARKETING_CONFIRMED,
    CustomerEventType.MARKETING_UNSUBSCRIBED,
  ].includes(customerActivity.eventType as CustomerEventType); // assertion can be removed after merging events
}
