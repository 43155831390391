// extracted by mini-css-extract-plugin
export var centered = "_2j-6";
export var flexible = "_2j-5";
export var footer = "_2j-k";
export var footerBorder = "_2j-l";
export var header = "_2j-c";
export var headerBorder = "_2j-d";
export var headerDefault = "_2j-g";
export var headerMedium = "_2j-h";
export var headerPadding = "_2j-e";
export var iconAndTitle = "_2j-f";
export var large = "_2j-4";
export var largePadding = "_2j-a";
export var medium = "_2j-3";
export var mediumPadding = "_2j-9";
export var modal = "_2j-0";
export var modalContainer = "_2j-b";
export var noPadding = "_2j-7";
export var skinny = "_2j-1";
export var small = "_2j-2";
export var smallPadding = "_2j-8";
export var subtitle = "_2j-j";
export var title = "_2j-i";