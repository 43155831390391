import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";

export const CreateTagOption = memo(function CreateTagOption() {
  return (
    <Flex
      align="center"
      className={conversationTagInputCss.optionButton}
      gap="xxs"
      pl="sm"
      radius="md"
    >
      <PlusIcon className={conversationTagInputCss.icon} />
      <Text fontSize="xs">New tag</Text>
    </Flex>
  );
});
