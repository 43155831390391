import {
  getBuyer,
  getReturner,
  type Return,
} from "@redotech/redo-model/return";
import { Card, CardSize } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { memo } from "react";
import { shopifyAddressString } from "../return";

export const CustomerCard = memo(function CustomerCard({
  return: return_,
}: {
  return?: Return;
}) {
  if (!return_) {
    return (
      <Card size={CardSize.MEDIUM} title="Original customer info">
        <div className={cardCss.title}>
          <SkeletonText length={30} />
        </div>
        <div className={cardCss.body}>
          <SkeletonText length={14} />
        </div>
        <div className={cardCss.body}>
          <SkeletonText length={28} />
        </div>
      </Card>
    );
  }
  const returner = getReturner(return_);
  const buyer = getBuyer(return_);

  // Hide this card if it is identical to returner/contact info card.
  if (
    return_ &&
    (!return_.shipping_address || // There should always be a shipping address for anything created after aug 2023, but when there isn't it is identical.
      (returner.name === buyer.name &&
        buyer.email === returner.email &&
        shopifyAddressString(
          return_.products[0].order.shopify.shipping_address,
        ) === shopifyAddressString(return_.shipping_address)))
  ) {
    return null;
  }
  return (
    <Card size={CardSize.MEDIUM} title="Original customer info">
      <div className={cardCss.title}>{buyer.name}</div>
      <a className={cardCss.body} href={return_ && `mailto:${buyer.email}`}>
        {buyer.email}
      </a>
      <address className={cardCss.body}>
        {shopifyAddressString(
          return_.products[0].order.shopify.shipping_address,
        )}
      </address>
    </Card>
  );
});
