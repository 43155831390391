import {
  isConversationCreatedCustomerEvent,
  isConversationMessageCreatedCustomerEvent,
  isConversationStatusUpdatedCustomerEvent,
} from "@redotech/redo-model/customer-activity/customer-activity-definition";
import { IConversationCustomerEvent } from "@redotech/redo-model/customer-event/customer-event-definition";
import MessageTextSquareIcon from "@redotech/redo-web/arbiter-icon/message-text-square-02.svg";
import { Flex } from "@redotech/redo-web/flex";
import { memo, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationStatusPill } from "../../../../common/conversation-status-pill";
import { BaseCustomerActivityCard } from "./base-customer-activity-card";
import * as customerActivityCardCss from "./customer-activity-card.module.css";
import { MessageBubble } from "./message-bubble";

export const ConversationCustomerActivityCard = memo(
  function ConversationCustomerActivityCard({
    customerEvent,
  }: {
    customerEvent: IConversationCustomerEvent;
  }) {
    const navigate = useNavigate();

    const iconElement = (
      <Flex
        align="center"
        className={
          customerActivityCardCss.customerActivityDataConversationIconContainer
        }
        justify="center"
      >
        <MessageTextSquareIcon
          className={
            customerActivityCardCss.customerActivityDataConversationIcon
          }
        />
      </Flex>
    );

    const getTitle = (): string => {
      if (isConversationMessageCreatedCustomerEvent(customerEvent)) {
        return "New support reply";
      } else if (isConversationCreatedCustomerEvent(customerEvent)) {
        return "New support ticket created";
      } else if (isConversationStatusUpdatedCustomerEvent(customerEvent)) {
        return "Support ticket status updated";
      } else {
        return "";
      }
    };

    const getExtraDetailsElement = (): ReactNode | undefined => {
      if (isConversationStatusUpdatedCustomerEvent(customerEvent)) {
        return (
          <Flex mt="lg">
            <ConversationStatusPill
              conversationStatus={customerEvent.newStatus}
            />
          </Flex>
        );
      } else if (isConversationMessageCreatedCustomerEvent(customerEvent)) {
        return (
          <Flex flex={1} mt="lg">
            <MessageBubble text={customerEvent.messageId} />
          </Flex>
        );
      } else {
        return undefined;
      }
    };

    const onCardClick = () => {
      navigate(
        `/stores/${customerEvent.team.toString()}/support/table/all?activeConversationId=${customerEvent.conversationId}`,
      );
    };

    return (
      <BaseCustomerActivityCard
        extraDetailsElement={getExtraDetailsElement()}
        onClick={onCardClick}
        timestamp={customerEvent.timestamp}
        title={getTitle()}
        topLeftElement={iconElement}
      />
    );
  },
);
