import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import type { Return } from "@redotech/redo-model/return";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { memo } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { closeReturn } from "../../client/return";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";

export const CloseModal = memo(function CloseModal({
  open,
  return: return_,
  onClose,
  reload,
}: {
  open: boolean;
  onClose(): void;
  return: Return;
  reload(): void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [reopenLoad, doReopen] = useTriggerLoad((signal) =>
    alertOnFailure("Closing return failed")(async () => {
      await closeReturn(client, {
        returnId: return_.id,
        signal,
      });
      reload();
      return true;
    }),
  );
  const handleSubmit = useHandler(() => doReopen());

  const footer = reopenLoad.value ? (
    <Button
      className={returnCss.modalButton}
      onClick={onClose}
      theme={ButtonTheme.OUTLINED}
    >
      Ok
    </Button>
  ) : (
    <>
      <Button
        className={returnCss.modalButton}
        onClick={onClose}
        theme={ButtonTheme.OUTLINED}
      >
        No, go back
      </Button>
      <Button
        className={returnCss.modalButton}
        disabled={reopenLoad.value}
        onClick={handleSubmit}
        pending={reopenLoad.pending}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, close
      </Button>
    </>
  );
  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      size={ModalSize.SMALL}
      title={`Close ${returnTypeName(return_.type)}`}
    >
      <div className={returnCss.modalContent}>
        {reopenLoad.value ? (
          <p>The {returnTypeName(return_.type)} has been closed.</p>
        ) : (
          <>
            <p>
              Closing a {returnTypeName(return_.type)} is for when you have
              handled the {returnTypeName(return_.type)} outside of Redo and you
              do not want the customer to use the Redo shipping label.
            </p>
            <p>
              Are you sure you want to close this {returnTypeName(return_.type)}
              ? Closing the {returnTypeName(return_.type)} will move the{" "}
              {returnTypeName(return_.type)} to completed and void the shipping
              label.
            </p>
          </>
        )}
      </div>
    </Modal>
  );
});
