import { RouteObject } from "react-router-dom";

export const conciergeRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Concierge" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { element: <Page /> };
    },
    children: [
      {
        path: "conversations",
        handle: { breadcrumb: "Conversations" },
        async lazy() {
          const { ConversationsPage } = await import("./conversations-page");
          return { element: <ConversationsPage /> };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutConcierge } = await import("./about");
          return { element: <AboutConcierge /> };
        },
      },
    ],
  },
];
