import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  shippingName: string;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State) {
  return state.shippingName !== undefined;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const handleShippingNameChange = useHandler((shippingName: string) => {
    setState((state) => ({ ...state, shippingName: shippingName }));
  });

  return (
    <>
      <LabeledInput
        description="Name of the shipping line for outgoing exchange orders"
        label="Outgoing shipping line name"
      >
        <TextInput
          onChange={handleShippingNameChange}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.shippingName}
        />
      </LabeledInput>
      <LabeledInput errors={[]} label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const EXCHANGE_SHIPPING_NAME: StepType<
  State,
  ModelStep.ExchangeShippingName
> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Shipping name",
  valid,
  Icon: TagIcon,
  description(state) {
    return state.shippingName;
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  empty: { next: undefined, shippingName: "" },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): ModelStep.ExchangeShippingName {
    return {
      customTitle: state.customTitle,
      type: ModelStep.EXCHANGE_SHIPPING_NAME,
      next: state.next ? id(state.next) : undefined,
      shippingName: state.shippingName,
    };
  },
  fromModel(model, id) {
    return {
      customTitle: model.customTitle,
      next: model.next ? id(model.next) : undefined,
      shippingName: model.shippingName || "",
    };
  },
};
