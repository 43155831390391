import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Permission, permitted } from "@redotech/redo-model/user";
import AlignLeft01Svg from "@redotech/redo-web/arbiter-icon/align-left-01_filled.svg";
import AlignRight01Svg from "@redotech/redo-web/arbiter-icon/align-right-01_filled.svg";
import XCloseSVG from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { InputSize } from "@redotech/redo-web/input";
import { Text } from "@redotech/redo-web/text";
import { TextInput } from "@redotech/redo-web/text-input";
import { sinkPromise } from "@redotech/util/promise";
import * as classNames from "classnames";
import { memo, ReactNode, useContext, useEffect, useState } from "react";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { updateConversation } from "../client/conversations";
import { ActiveUsers } from "./active-users";
import * as conversationHeaderCss from "./conversation-header.module.css";
import { TeamConversationActivityContext } from "./support";
import { getCustomerTitle } from "./utils";

export const ConversationHeader = memo(function ConversationHeader({
  conversation,
  leftPanelOpen,
  handleSetLeftPanelOpen,
  rightPanelOpen,
  handleSetRightPanelOpen,
  actionButtons,
  setActiveConversation,
}: {
  conversation: ExpandedConversation;
  leftPanelOpen: boolean;
  handleSetLeftPanelOpen: (value: boolean) => void;
  rightPanelOpen: boolean;
  handleSetRightPanelOpen: (value: boolean) => void;
  actionButtons: ReactNode;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
}) {
  const handleBackClick = () => {
    setActiveConversation(undefined);
  };

  useEffect(() => {
    setConversationSubject(conversation.subject);
  }, [conversation]);

  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useRequiredContext(UserContext);
  const canEditSubject =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  const [conversationSubject, setConversationSubject] = useState(
    conversation.subject,
  );

  async function handleSetSubject() {
    if (!conversationSubject) {
      setConversationSubject(conversation.subject);
    } else {
      await updateConversation(client, conversation, {
        subject: conversationSubject,
      });
    }
  }

  const teamConversationActivity = useContext(TeamConversationActivityContext);

  const shouldShowSubheaderBecauseThereAreOtherUsersViewing =
    teamConversationActivity?.[conversation._id]?.viewing?.length > 1;

  const shouldShowSubheader =
    shouldShowSubheaderBecauseThereAreOtherUsersViewing;

  return (
    <Flex
      className={classNames(
        conversationHeaderCss.headerBar,
        ...(!shouldShowSubheader ? [conversationHeaderCss.collapsed] : []),
      )}
      dir="column"
      gap="md"
      pb="md"
    >
      <Flex
        align="center"
        gap="3xl"
        justify="space-between"
        pl="2xl"
        pr="2xl"
        pt="md"
      >
        <Flex
          align="center"
          className={conversationHeaderCss.leftHeaderContent}
          gap="sm"
        >
          <Button
            className={conversationHeaderCss.headerButtons}
            onClick={() => handleSetLeftPanelOpen(!leftPanelOpen)}
            size={ButtonSize.NANO}
            theme={ButtonTheme.OUTLINED}
          >
            <Flex
              align="center"
              className={conversationHeaderCss.buttonIconWrapper}
              justify="center"
            >
              {leftPanelOpen ? <AlignLeft01Svg /> : <AlignRight01Svg />}
            </Flex>
          </Button>

          <Button
            className={classNames(
              conversationHeaderCss.headerButtons,
              conversationHeaderCss.justIconButtons,
            )}
            onClick={handleBackClick}
            size={ButtonSize.NANO}
            theme={ButtonTheme.SOLID_LIGHT}
          >
            <Flex
              align="center"
              className={classNames(conversationHeaderCss.buttonIconWrapper)}
              justify="center"
            >
              <XCloseSVG />
            </Flex>
          </Button>
          <Text fontSize="sm" fontWeight="bold" textColor="primary">
            {getCustomerTitle(conversation)}
          </Text>

          {conversation.platform === "email" && (
            <>
              <Text
                color="var(--redo-colors-text-text-placeholder_subtle)"
                fontSize="sm"
                fontWeight="regular"
              >
                |
              </Text>
              <Flex className={conversationHeaderCss.subjectContainer}>
                <TextInput
                  color="tertiary"
                  ellipsis
                  fullwidth
                  hideFocus
                  onChange={setConversationSubject}
                  onFocus={(focused) => {
                    if (!focused) {
                      sinkPromise(handleSetSubject());
                    }
                  }}
                  readonly={!canEditSubject}
                  showBorder={false}
                  size={InputSize.SMALL}
                  value={conversationSubject}
                />
              </Flex>
            </>
          )}
        </Flex>
        <Flex gap="md" justify="flex-end">
          {actionButtons}

          <Button
            className={conversationHeaderCss.headerButtons}
            onClick={() => {
              handleSetRightPanelOpen(!rightPanelOpen);
            }}
            size={ButtonSize.NANO}
            theme={ButtonTheme.OUTLINED}
          >
            <Flex
              align="center"
              className={conversationHeaderCss.buttonIconWrapper}
              justify="center"
            >
              {rightPanelOpen ? <AlignRight01Svg /> : <AlignLeft01Svg />}
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {shouldShowSubheader && (
        <Flex pl="2xl" pr="2xl">
          <ActiveUsers conversation={conversation} maxAvatars={4} />
        </Flex>
      )}
    </Flex>
  );
});
