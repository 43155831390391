import { memo } from "react";
import { Flex } from "../../flex";
import { UserImage, UserImageSize } from "../../user-image";
import * as avatarCss from "./avatar.module.css";

/** Arbiter-compliant Avatar. TODO: doesn't support all variants, sizes, etc. Add as you need. */
export const Avatar = memo(function Avatar({
  name,
  imageUrl,
  alt,
}: {
  name?: string;
  imageUrl: string | null;
  alt: string;
}) {
  return (
    <Flex
      align="center"
      className={avatarCss.avatarContainer}
      justify="center"
      pt="xs"
    >
      <UserImage
        alt={alt}
        imageUrl={imageUrl}
        name={name}
        size={UserImageSize.SMALL}
      />
    </Flex>
  );
});
