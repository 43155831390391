import { useRef } from "react";

// The idea is that when we call this function we wait for it to complete before executing it again
// If we try to call it again before it completes, we wait until the first call completes and then execute the callback again immediately after
// If no calls are made while we are waiting for the callback to complete do nothing when it finishes
// If you can think of a better name for this functionality go ahead and change the name
export function useWaitForPrevious<T>(fn: () => Promise<T>) {
  const pending = useRef<Promise<T> | undefined>(undefined);
  const queued = useRef(false);

  return async () => {
    // If there's already a call pending, queue this one up
    if (pending.current) {
      queued.current = true;
      await pending.current;

      if (!queued.current) {
        return;
      }
    }

    queued.current = false;
    pending.current = fn();

    try {
      await pending.current;
    } finally {
      pending.current = undefined;
    }
  };
}
