import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import type { Return } from "@redotech/redo-model/return";
import { ProvisionType } from "@redotech/redo-model/return-flow";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input } from "@redotech/ui/form";
import { memo, useContext } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { cancelReturn } from "../../client/return";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";

export const CancelModal = memo(function CancelModal({
  return: return_,
  open,
  onClose,
  reload,
}: {
  open: boolean;
  onClose(refresh?: boolean): void;
  return: Return;
  reload(): void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const { formatCurrency } = useContext(CurrencyContext);

  const input_ = useInput(
    groupInput({
      recoveryCharge: input<string>({
        validator: (value) => {
          const errors: string[] = [];
          if (!value || isNaN(+value)) {
            errors.push("Must be a number");
          } else if (+value < 0) {
            // Allowed to be $0 for when no correction needs to be made
            errors.push("Must be greater than $0");
          } else if (
            return_.totals.returnCollectionHoldAmount &&
            Math.round(return_.totals.returnCollectionHoldAmount * 100) / 100 <
              +value
          ) {
            errors.push(
              `Must not be greater than ${formatCurrency(
                return_.totals.returnCollectionHoldAmount,
              )}`,
            );
          }
          return errors;
        },
      }),
    }),
    {
      recoveryCharge: "0",
    },
  );
  const { recoveryCharge } = input_.inputs;
  const [resendLoad, doResend] = useTriggerLoad((signal) =>
    alertOnFailure(`Canceling ${returnTypeName(return_.type)} failed`)(
      async () => {
        await cancelReturn(client, {
          returnId: return_.id,
          customerRecoveryAmount: parseFloat(recoveryCharge.value),
          signal,
        });
        reload();
        return true;
      },
    ),
  );
  const handleSend = useHandler(() => doResend());

  const footer = resendLoad.value ? (
    <Button
      className={returnCss.modalButton}
      onClick={() => {
        onClose(true);
      }}
      theme={ButtonTheme.OUTLINED}
    >
      Ok
    </Button>
  ) : (
    <>
      <Button
        className={returnCss.modalButton}
        onClick={() => {
          onClose();
        }}
        theme={ButtonTheme.OUTLINED}
      >
        No, go back
      </Button>
      <Button
        className={returnCss.modalButton}
        disabled={!!input_.allErrors.length || resendLoad.value}
        onClick={handleSend}
        pending={resendLoad.pending}
        theme={ButtonTheme.DANGER}
      >
        Yes, proceed
      </Button>
    </>
  );
  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      size={ModalSize.SMALL}
      title={`Cancel ${returnTypeName(return_.type)}`}
    >
      <div className={returnCss.modalContent}>
        {resendLoad.value ? (
          <p>The return has been canceled.</p>
        ) : (
          <>
            <p>
              Are you sure you want to cancel the {returnTypeName(return_.type)}
              ? This will permanently reject the {returnTypeName(return_.type)}.
            </p>
            {return_.provision === ProvisionType.INSTANT && return_.stripe && (
              <FormTextInput
                description={`Amount to recover from customer if there was an issue with the exchange.${
                  return_.totals.returnCollectionHoldAmount
                    ? ` Maximum for whole order is ${formatCurrency(
                        return_.totals.returnCollectionHoldAmount,
                      )}.`
                    : ""
                }`}
                input={recoveryCharge}
                label="Recovery charge"
                prefix="$"
                type="number"
              />
            )}
          </>
        )}
      </div>
    </Modal>
  );
});
