import {
  isClaimCustomerEvent,
  isConversationCustomerEvent,
  isMarketingEvent,
  isOrderCustomerEvent,
  isReturnCustomerEvent,
  isShoppingEvent,
} from "@redotech/redo-model/customer-activity/customer-activity-definition";
import { CustomerEvent } from "@redotech/redo-model/customer-event/customer-event-definition";
import { memo } from "react";
import { ClaimCustomerActivityCard } from "./claim-customer-activity-card";
import { ConversationCustomerActivityCard } from "./conversation-customer-activity-card";
import { MarketingCustomerEventCard } from "./marketing-customer-activity-card";
import { OrderCustomerActivityCard } from "./order-customer-activity-card";
import { ReturnCustomerActivityCard } from "./return-customer-activity-card";
import { ShoppingCustomerActivityCard } from "./shopping-customer-activity-card";

export const CustomerActivityCard = memo(function CustomerActivityCard({
  customerActivity,
}: {
  customerActivity: CustomerEvent;
}) {
  if (isOrderCustomerEvent(customerActivity)) {
    return <OrderCustomerActivityCard customerEvent={customerActivity} />;
  } else if (isReturnCustomerEvent(customerActivity)) {
    return <ReturnCustomerActivityCard customerEvent={customerActivity} />;
  } else if (isClaimCustomerEvent(customerActivity)) {
    return <ClaimCustomerActivityCard customerEvent={customerActivity} />;
  } else if (isConversationCustomerEvent(customerActivity)) {
    return (
      <ConversationCustomerActivityCard customerEvent={customerActivity} />
    );
  } else if (isShoppingEvent(customerActivity)) {
    return <ShoppingCustomerActivityCard shoppingEvent={customerActivity} />;
  } else if (isMarketingEvent(customerActivity)) {
    return <MarketingCustomerEventCard event={customerActivity} />;
  } else {
    return null;
  }
});
