import * as lzString from "lz-string";
import { SortDirection } from "../table";
import { v3FiltersFormat } from "./conversation-filter-formats";
import { ConversationFiltersV3 } from "./conversation-filters";

export function filtersToQueryString(filters: ConversationFiltersV3): string {
  const jsonString = JSON.stringify(v3FiltersFormat.write(filters));
  const condensedString = lzString.compressToBase64(jsonString);
  return encodeURIComponent(condensedString);
}

export function queryStringToFilters(
  filtersString: string,
): ConversationFiltersV3 {
  const decoded = decodeURIComponent(filtersString);
  const jsonString = lzString.decompressFromBase64(decoded);
  const filters = v3FiltersFormat.read(JSON.parse(jsonString));
  if (!filters.sort) {
    filters.sort = {
      key: "lastResponse",
      direction: SortDirection.DESC,
    };
  }

  return filters;
}
