import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { ConciergeConversationMessageDbParser } from "./concierge-conversation-message-db-parser";
import {
  ContentType,
  SenderType,
} from "./concierge-conversation-message-definition";

export const CURRENT_CONVERSATION_VERSION = "v2";

export const ConciergeConversationDbParser = z
  .object({
    _id: zExt.objectId(),
    team: zExt.objectId(),
    email: z.string().optional(),
    messages: z.array(ConciergeConversationMessageDbParser),
    createdAt: z.date(),
    updatedAt: z.date(),
    version: z.string().default(CURRENT_CONVERSATION_VERSION),
  })
  .refine(
    (data) => {
      for (let i = 1; i < data.messages.length; i++) {
        const currentMessage = data.messages[i];

        if (currentMessage.senderType === SenderType.TOOL) {
          const originToolCallMessage = data.messages.find(
            (message) =>
              message.contentType === ContentType.TOOL_CALL &&
              message.content.find(
                (messageContent) =>
                  messageContent.id === currentMessage.toolCallId,
              ),
          );
          if (!originToolCallMessage) {
            return false;
          }
        }
      }
      return true;
    },
    (data) => ({
      message:
        "There must be a corresponding TOOL_CALL message for each tool response message.",
      path: ["messages"],
      _id: data._id,
    }),
  );

export type ConciergeConversation = z.infer<
  typeof ConciergeConversationDbParser
>;
