import { ReactNode, createContext } from "react";
import { RedoMerchantClient } from ".";

export const RedoMerchantClientContext = createContext<
  RedoMerchantClient | undefined
>(undefined);
RedoMerchantClientContext.displayName = "RedoMerchantClientContext";

export function RedoMerchantClientProvider({
  children,
  url,
  token,
}: {
  children: ReactNode | ReactNode[];
  url: string;
  token?: string;
}) {
  const client = new RedoMerchantClient(url, token);
  return (
    <RedoMerchantClientContext.Provider value={client}>
      {children}
    </RedoMerchantClientContext.Provider>
  );
}
