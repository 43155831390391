import * as classnames from "classnames";
import { ForwardedRef, ReactElement, ReactNode, forwardRef, memo } from "react";
import { StyleProps, styleClasses, stylePropKeys } from "./styles";
import { Tooltip } from "./tooltip/tooltip";

import * as iconCss from "./icon.module.css";

/** All supported icons go here. */
export type ArbiterIconSvg = (props?: any) => ReactElement;

const variantClass = {
  ["primary light"]: iconCss.primaryLight,
  ["primary dark"]: iconCss.primaryDark,
  ["primary modern"]: iconCss.primaryModern,
  ["outline"]: iconCss.outline,
};

const sizeClass = {
  large: iconCss.large,
  medium: iconCss.medium,
  compact: iconCss.compact,
  "extra-small": iconCss.extraSmall,
};

const colorClass = {
  ["brand"]: iconCss.brand,
  ["error"]: iconCss.error,
  ["ghost"]: iconCss.ghost,
  ["warning"]: iconCss.warning,
  ["success"]: iconCss.success,
};

/** Use "raw" for just the icon itself. Other ones match figma arbiter. */
export type IconVariant = keyof typeof variantClass;
export type IconColor = keyof typeof colorClass;
export type IconSize = keyof typeof sizeClass;

type IconProps = {
  children?: ReactNode;
  className?: string;
  form?: string;
  arbiterIconSvg: ArbiterIconSvg;
  variant?: IconVariant;
  size?: IconSize;
  color?: IconColor;
  style?: any;
  tooltip?: string;
} & StyleProps;

/** Arbiter-compliant icons. For performance reasons, this requires you to pass in the actual SVG from the arbiter-icons folder. NOT FULLY IMPLEMENTED - add your use cases in icon.module.css.*/
export const Icon = memo(
  forwardRef(function Icon(
    {
      children,
      className,
      form,
      arbiterIconSvg,
      variant = "primary light",
      size = "medium",
      color = "brand",
      style = undefined,
      tooltip,
      ...rest
    }: IconProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const iconClassName = classnames(
      iconCss.icon,
      variantClass[variant],
      sizeClass[size],
      colorClass[color],
      className,
    );

    // TODO: many of these styleProps, like padding, are overridden by old button styles. Remove those
    const styleProps = Object.fromEntries(
      Object.entries(rest).filter(([key]) => stylePropKeys.has(key as any)),
    );
    const otherProps = Object.fromEntries(
      Object.entries(rest).filter(([key]) => !stylePropKeys.has(key as any)),
    );

    const iconWithStyles = (
      <div
        className={classnames(iconClassName, styleClasses(styleProps))}
        ref={ref}
        style={style}
        {...otherProps}
      >
        {arbiterIconSvg()}
      </div>
    );

    return tooltip ? (
      <Tooltip title={tooltip}>
        <span>{iconWithStyles}</span>
      </Tooltip>
    ) : (
      <>{iconWithStyles}</>
    );
  }),
);
