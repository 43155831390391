import { assertNever } from "@redotech/util/type";

/**
 * @param CREATE is a fake view used to create a view.
 * It needs to be namespace reserved because of the way our URLs work at the moment.
 */
export enum SystemView {
  ALL = "all",
  ASSIGNED = "assigned",
  SENT = "sent",
  SPAM = "spam",
  DRAFTS = "drafts",
  CREATE = "create",
  MENTIONS = "mentions",
}

// This is here as a reminder that we need to support all system views in the conversationMatchesFilters function
// If you add a new system view, this should fail to compile. That means you should update the conversationMatchesFilters function to support the new view
// You'll also need to update the backend to support filtering on the new view
// If you know a better way to make sure we don't add views without supporting filtering go ahead and change it
function ensureAllSystemViewsAreSupported() {
  for (const view of Object.values(SystemView)) {
    switch (view) {
      case SystemView.ALL:
        break;
      case SystemView.ASSIGNED:
        break;
      case SystemView.SENT:
        break;
      case SystemView.SPAM:
        break;
      case SystemView.DRAFTS:
        break;
      case SystemView.MENTIONS:
        break;
      case SystemView.CREATE:
        break;
      default:
        assertNever(view);
    }
  }
}

// We have to call this somewhere for the type checker to be happy
ensureAllSystemViewsAreSupported();
