import { createTheme } from "@mui/material";
import { AutocompleteThemeOverrides } from "./autocomplete";

export const MUI_THEME = createTheme({
  typography: {
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
  },
  components: {
    MuiAutocomplete: AutocompleteThemeOverrides,
  },
});
