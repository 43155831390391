import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Flex } from "@redotech/redo-web/flex";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Text } from "@redotech/redo-web/text";
import * as classnames from "classnames";
import { memo } from "react";
import * as conversationTagInputColorPickerCss from "./conversation-tag-input-color-picker.module.css";

const pillThemeClass: Record<PillTheme, string> = {
  [PillTheme.PRIMARY]: conversationTagInputColorPickerCss.primary,
  [PillTheme.NORMAL]: conversationTagInputColorPickerCss.normal,
  [PillTheme.PRIMARY_LIGHT]: conversationTagInputColorPickerCss.primaryLight,
  [PillTheme.SUCCESS]: conversationTagInputColorPickerCss.success,
  [PillTheme.DANGER]: conversationTagInputColorPickerCss.danger,
  [PillTheme.NEUTRAL_YELLOW]: conversationTagInputColorPickerCss.neutralYellow,
  [PillTheme.NEUTRAL_BLUE]: conversationTagInputColorPickerCss.neutralBlue,
  [PillTheme.LIGHT_PINK]: conversationTagInputColorPickerCss.lightPink,
  [PillTheme.SOLID_RED]: conversationTagInputColorPickerCss.solidRed,
  [PillTheme.SOLID_YELLOW]: conversationTagInputColorPickerCss.solidYellow,
  [PillTheme.SOLID_GREEN]: conversationTagInputColorPickerCss.solidGreen,
  [PillTheme.SOLID_NAVY]: conversationTagInputColorPickerCss.solidNavy,
  [PillTheme.SOLID_LIGHT_BLUE]:
    conversationTagInputColorPickerCss.solidLightBlue,
  [PillTheme.SOLID_PINK]: conversationTagInputColorPickerCss.solidPink,
  [PillTheme.SOLID_ORANGE]: conversationTagInputColorPickerCss.solidOrange,
  [PillTheme.SOLID_BLACK]: conversationTagInputColorPickerCss.solidBlack,
  [PillTheme.SOLID_WHITE]: conversationTagInputColorPickerCss.solidWhite,
};

export const PillThemeNames: Record<PillTheme, string> = {
  [PillTheme.NORMAL]: "Gray",
  [PillTheme.PRIMARY_LIGHT]: "Purple",
  [PillTheme.LIGHT_PINK]: "Pink",
  [PillTheme.DANGER]: "Red",
  [PillTheme.NEUTRAL_YELLOW]: "Yellow",
  [PillTheme.SUCCESS]: "Green",
  [PillTheme.NEUTRAL_BLUE]: "Blue",
  [PillTheme.SOLID_RED]: "Solid red",
  [PillTheme.SOLID_YELLOW]: "Solid yellow",
  [PillTheme.SOLID_GREEN]: "Solid green",
  [PillTheme.SOLID_NAVY]: "Solid navy",
  [PillTheme.SOLID_LIGHT_BLUE]: "Solid blue",
  [PillTheme.SOLID_PINK]: "Solid pink",
  [PillTheme.SOLID_ORANGE]: "Solid orange",
  [PillTheme.SOLID_BLACK]: "Solid black",
  [PillTheme.PRIMARY]: "Solid purple",
  [PillTheme.SOLID_WHITE]: "Solid white",
};

export const PillColorPicker = memo(function PillColorPicker({
  value,
  setValue,
  options = Object.values(PillTheme),
}: {
  value: PillTheme | undefined;
  setValue(theme: PillTheme): void;
  options?: PillTheme[];
}) {
  const display = (option: PillTheme) => <ColorSquare theme={option} />;

  return (
    <SelectDropdown
      display={display}
      options={options}
      value={value}
      valueChange={setValue}
    >
      {(option) => <ColorSquare theme={option} />}
    </SelectDropdown>
  );
});

const ColorSquare = memo(function ColorSquare({ theme }: { theme: PillTheme }) {
  return (
    <Flex align="center" gap="xl">
      <div
        className={classnames(
          conversationTagInputColorPickerCss.colorSquare,
          pillThemeClass[theme],
        )}
      />
      <Text>{PillThemeNames[theme]}</Text>
    </Flex>
  );
});
