// extracted by mini-css-extract-plugin
export var actionButton = "_37-1";
export var actionButtons = "_37-2";
export var actions = "_37-0";
export var alignCenter = "_37-g";
export var backWrapper = "_37-3";
export var checkboxContainer = "_37-4";
export var contentContainer = "_37-5";
export var contentHeader = "_37-7";
export var conversationsTableWrapper = "_37-s";
export var draftMessagePreview = "_37-m";
export var draftReplyBadgeContainer = "_37-l";
export var dropdownItem = "_37-a";
export var dropdownItemsContainer = "_37-9";
export var dropdownTitle = "_37-b";
export var errorIcon = "_37-6";
export var filter = "_37-c";
export var gray = "_37-h";
export var headerActionButtons = "_37-d";
export var headerCheckboxContainer = "_37-e";
export var icon = "_37-i";
export var menuContainer = "_37-j";
export var noMoreTicketsIcon = "_37-11";
export var noMoreTicketsSubText = "_37-12";
export var oneLine = "_37-k";
export var optionsButton = "_37-n";
export var plus = "_37-o";
export var portalButtonsWrapper = "_37-f";
export var red = "_37-p";
export var scrolledTop = "_37-8";
export var search = "_37-q";
export var selectedConversationsCount = "_37-r";
export var spinner = "_37-t";
export var strong = "_37-u";
export var summaryCell = "_37-v";
export var tableContainer = "_37-10";
export var tagContainer = "_37-w";
export var threeDots = "_37-x";
export var twoLines = "_37-y";
export var unread = "_37-z";