// extracted by mini-css-extract-plugin
export var active = "_8m-b";
export var arrow = "_8m-8";
export var arrowContainer = "_8m-4";
export var container = "_8m-0";
export var disabled = "_8m-5";
export var dot = "_8m-a";
export var dotContainer = "_8m-9";
export var leftArrowContainer = "_8m-6 _8m-4";
export var rightArrowContainer = "_8m-7 _8m-4";
export var slide = "_8m-3";
export var sliderContainer = "_8m-1";
export var sliderOverflow = "_8m-2";