import { useInput } from "@redotech/react-util/form";
import { groupInput, input } from "@redotech/ui/form";
import { memo, useEffect } from "react";
import { Button, ButtonTheme } from "./button";
import * as contactInfoModalCss from "./contact-info-modal.module.css";
import { Modal, ModalSize } from "./modal";
import { FormTextInput } from "./text-input";

export interface ContactInfo {
  name: string;
  email: string;
  phone: string;
}

const contactInfoForm = groupInput({
  name: input<string>(),
  email: input<string>(),
  phone: input<string>(),
});

export const ContactInfoModal = memo(function ContactInfoModal({
  open,
  setOpen,
  setContactInfo,
  initial,
  requirePhone = true,
  includeName = true,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setContactInfo: (contactInfo: ContactInfo) => void;
  initial: any;
  requirePhone?: boolean;
  includeName?: boolean;
}) {
  const input = useInput(contactInfoForm, initial);
  useEffect(() => {
    input.setValue(initial);
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  const footer = (
    <div className={contactInfoModalCss.modalFooter}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        disabled={
          (includeName && !input.value.name) ||
          !input.value.email ||
          (requirePhone && !input.value.phone) ||
          !input.changed
        }
        onClick={() => {
          setContactInfo(input.value);
          setOpen(false);
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Save contact information
      </Button>
    </div>
  );
  return (
    <Modal
      footer={footer}
      onClose={() => setOpen(false)}
      open={open}
      size={ModalSize.SMALL}
      title="Edit contact information"
    >
      <>
        {includeName && (
          <FormTextInput input={input.inputs.name} label="Name" />
        )}
        <FormTextInput input={input.inputs.email} label="Email" />
        <FormTextInput
          input={input.inputs.phone}
          label={`Phone${!requirePhone ? " (optional)" : ""}`}
        />
      </>
    </Modal>
  );
});
