export enum BenchmarkStatsDataType {
  PERCENTAGE = "PERCENTAGE",
  DAYS = "DAYS",
}

export enum IndustryCategoryComparisonStatsType {
  RETURN_RATE_BY_ORDER = "RETURN_RATE_BY_ORDER",
  RETURN_RATE_BY_ITEM = "RETURN_RATE_BY_ITEM",
  RETURN_RATE_BY_REVENUE = "RETURN_RATE_BY_REVENUE",
  REVENUE_RETAINED_REVENUE_RATIO = "REVENUE_RETAINED_REVENUE_RATIO",
  REVENUE_REFUNDED_REVENUE_RATIO = "REVENUE_REFUNDED_REVENUE_RATIO",
  OPERATIONS_AVERAGE_DAYS_TO_PROCESS_RETURN_RETURN = "OPERATIONS_AVERAGE_DAYS_TO_PROCESS_RETURN_RETURN",
  OPERATIONS_AVERAGE_DAYS_TO_PROCESS_RETURN_CLAIM = "OPERATIONS_AVERAGE_DAYS_TO_PROCESS_RETURN_CLAIM",
}
