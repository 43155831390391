import * as amplitude from "@amplitude/analytics-browser";
import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { uploadFile } from "@redotech/redo-api-client/conversations";
import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import { UploadedFile } from "@redotech/redo-model/file/uploaded-file";
import {
  Macro,
  MACRO_VARIABLES,
  MacroAutomation,
  MacroStatusToSet,
  MacroVariable as MacroVariableType,
  SnoozeDuration,
} from "@redotech/redo-model/macro";
import { PutMacroBody } from "@redotech/redo-model/put-macro-body";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { Team } from "@redotech/redo-model/team";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoCheckbox,
  RedoCheckboxSize,
} from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import PaperclipIcon from "@redotech/redo-web/arbiter-icon/paperclip_filled.svg";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Divider } from "@redotech/redo-web/divider";
import ClearFormatIcon from "@redotech/redo-web/icon-old/clear-format.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { QuillAttachmentCarousel } from "@redotech/redo-web/quill/quill-attachment-carousel";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import { QuillToolbarOptions } from "@redotech/redo-web/quill/quill-toolbar-options";
import { QuillToolbarUploadFile } from "@redotech/redo-web/quill/quill-toolbar-upload-file";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput, TextInput } from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  nonEmptyValidator,
  optionalInput,
} from "@redotech/ui/form";
import { arrayEqual } from "@redotech/util/equal";
import { emailValidator } from "@redotech/web-util/form";
import Quill from "quill";
import {
  ChangeEvent,
  Fragment,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RedoMerchantClient } from "../../client";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { SnoozeOptionText } from "../conversation-actions/snooze-modal";
import { ConversationTagInput } from "../conversation-tags/conversation-tag-input";
import { MacroVariable } from "../rendered-message-content";
import { AddInternalNoteAutomation } from "./macro-automations/add-internal-note";
import { ForwardMessageAutomationEditMode } from "./macro-automations/forward-message";
import * as macroModalCss from "./macro-modal.module.css";
import { clearColoringAtCursor } from "./quill-macro-utils";

const Delta = Quill.import("delta");

const macroBody = groupInput({
  macroName: input<string>({ validator: nonEmptyValidator }),
  statusToSet: optionalInput(
    input<MacroStatusToSet | undefined>(),
    () => undefined,
  ),
  snoozeDuration: optionalInput(
    input<SnoozeDuration | undefined>(),
    () => undefined,
  ),
  tagsToAdd: input<ConversationTagWithId[]>({
    equal: arrayEqual((a, b) => a.name === b.name),
  }),
  emailSubject: optionalInput(
    input<string | undefined>({ validator: nonEmptyValidator }),
    () => undefined,
  ),
  shouldAddNote: optionalInput(input<boolean | undefined>(), () => undefined),
  noteToAddUsersMentioned: optionalInput(
    input<string[] | undefined>(),
    () => undefined,
  ),
  shouldForwardMessage: optionalInput(
    input<boolean | undefined>(),
    () => undefined,
  ),
  messageToForwardExtraContent: optionalInput(
    input<string | undefined>(),
    () => undefined,
  ),
  messageToForwardEmailAddress: optionalInput(
    input<string>({ validator: emailValidator }),
    () => "",
  ),
});

/**
 * Used to edit existing macros or create new ones
 */
export const MacroEditorModal = memo(function MacroEditorModal({
  client,
  cancel,
  submitMacro,
  initialState,
  team,
}: {
  client: RedoMerchantClient;
  cancel(): void;
  submitMacro(macro: PutMacroBody): Promise<void>;
  initialState: Macro | undefined;
  team: Team;
}) {
  const apiClient = useRequiredContext(RedoClientContext);

  const [conversationTags] = useLazyContext(ConversationTagsContext);

  const initialTagNames = new Set<string>(initialState?.tagsToAdd || []);

  const initialTags = useMemo(() => {
    return (
      conversationTags.value?.filter((tag) => initialTagNames.has(tag.name)) ||
      []
    );
  }, [conversationTags]);

  const macro = useInput(macroBody, {
    macroName: initialState?.name || "",
    statusToSet: initialState?.statusToSet,
    snoozeDuration: initialState?.snoozeDuration,
    tagsToAdd: initialTags,
    emailSubject: initialState?.emailSubjectToSet,
    shouldAddNote: initialState?.shouldAddNote,
    noteToAddUsersMentioned: initialState?.noteToAddUsersMentioned,
    shouldForwardMessage: initialState?.shouldForwardMessage,
    messageToForwardExtraContent: initialState?.messageToForwardExtraContent,
    messageToForwardEmailAddress: initialState?.messageToForwardEmailAddress,
  });

  const [attachments, setAttachments] = useState<UploadedFile[] | undefined>(
    initialState?.attachments,
  );

  const [submitDisabledBecauseOfQuill, setSubmitDisabledBecauseOfQuill] =
    useState<boolean>(true);
  const [quill, setQuill] = useState<Quill | null>(null);

  const [noteQuill, setNoteQuill] = useState<Quill | null>(null);

  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);

  const handleSaveMacro = async () => {
    if (!quill) {
      return;
    }

    const getContentsFromQuill = (quill: Quill | undefined | null) => {
      if (!quill) {
        return { content: "", htmlContent: "" };
      }
      const textContent = quill.getText();
      const content = textContent?.substring(0, textContent.length - 1) || ""; // Remove trailing newline added by Quill
      const htmlContent = quill.getSemanticHTML() || "";
      return { content, htmlContent };
    };
    const [
      { content, htmlContent },
      { content: noteToAddContent, htmlContent: noteToAddHtmlContent },
    ] = Object.values(whichQuillLookup)
      .map((quillData) => quillData.quill)
      .map(getContentsFromQuill);

    const inputValue = macro.value;

    const bodyToPut: PutMacroBody = {
      name: inputValue.macroName,
      content,
      htmlContent,
      statusToSet: undefined,
      emailSubjectToSet: undefined,
      snoozeDuration: undefined,
      tagsToAdd: undefined,
      attachments: attachments,
    };

    const applyAutomationChangesToPutMacroBody: Record<
      MacroAutomation,
      (body: PutMacroBody) => void
    > = {
      [MacroAutomation.FORWARD_MESSAGE]: (body: PutMacroBody) => {
        body.shouldForwardMessage = inputValue.shouldForwardMessage;
        body.messageToForwardEmailAddress =
          inputValue.messageToForwardEmailAddress?.toLowerCase();
        body.messageToForwardExtraContent =
          inputValue.messageToForwardExtraContent;
      },
      [MacroAutomation.ADD_INTERNAL_NOTE]: (body: PutMacroBody) => {
        body.shouldAddNote = inputValue.shouldAddNote;
        body.noteToAddContent = noteToAddContent;
        body.noteToAddHtmlContent = noteToAddHtmlContent;
        body.noteToAddUsersMentioned = inputValue.noteToAddUsersMentioned;
      },
      [MacroAutomation.SET_STATUS]: (body: PutMacroBody) => {
        body.statusToSet = inputValue.statusToSet;
        body.snoozeDuration = inputValue.snoozeDuration;
      },
      [MacroAutomation.ADD_TAGS]: (body: PutMacroBody) => {
        body.tagsToAdd = inputValue.tagsToAdd?.map((tag) => tag.name);
      },
      [MacroAutomation.CHANGE_EMAIL_SUBJECT]: (body: PutMacroBody) => {
        body.emailSubjectToSet = inputValue.emailSubject;
      },
    };

    for (const automation of Object.values(
      applyAutomationChangesToPutMacroBody,
    )) {
      automation(bodyToPut);
    }

    await submitMacro(bodyToPut);
  };

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: ChangeEvent<HTMLInputElement>;
    file?: File;
  }) => {
    const fileToUpload = file || event?.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      amplitude.logEvent("create-attachment", {
        file: fileToUpload.name,
      });
      const newAttachment = { ...response.body };
      setAttachments((oldAttachments) => {
        return [newAttachment, ...(oldAttachments || [])];
      });
    } else {
      toast(conversationFileUploadErrorMessages[response.error], {
        variant: "error",
      });
    }
  };
  const enum QuillEditorsOnThisComponent {
    /** Where the main content of the template is */
    MAIN = "main",
    /** Where the note is */
    NOTE = "note",
  }
  /**
   * With notes, there's now 2 quill editors on this page. We need to know which one is focused
   * so we can insert macro variables into the correct editor.
   */
  const whichQuillLookup = useMemo(() => {
    return {
      [QuillEditorsOnThisComponent.MAIN]: {
        quill,
        initialHtml: initialState?.htmlContent,
      },
      [QuillEditorsOnThisComponent.NOTE]: {
        quill: noteQuill,
        initialHtml: initialState?.noteToAddHtmlContent,
      },
    };
  }, [quill, noteQuill, initialState]);

  /**
   * Plug in the internal note automation edit mode
   * (TODO some of the quill logic could move into the automation
   * as noteToAddContent and noteToAddHtmlContent)
   */
  const internalNoteAutomationEditMode = (
    <AddInternalNoteAutomation.EditMode
      client={client}
      noteToAddUsersMentioned={macro.inputs.noteToAddUsersMentioned.value ?? []}
      quill={noteQuill}
      setNoteToAddUsersMentioned={(value: string[]) =>
        macro.inputs.noteToAddUsersMentioned.setValue(value)
      }
      setQuill={setNoteQuill}
      setShouldAddNote={(value: boolean) =>
        macro.inputs.shouldAddNote.setValue(value)
      }
      shouldAddNote={macro.inputs.shouldAddNote.value ?? false}
      team={team}
    />
  );

  const onVariableClick = (variable: MacroVariableType) => {
    const { quill: quillToInsertIn } = whichQuillLookup[whichEditorFocused];
    if (!quillToInsertIn) {
      return;
    }
    const variableText = variable.variable;
    const insertPosition = quillToInsertIn.getSelection(true)?.index || 0;
    quillToInsertIn.insertText(insertPosition, variableText, "silent");
    quillToInsertIn.formatText(
      insertPosition,
      variableText.length,
      { color: "#5f45e2", background: "#ebe9fd" },
      "silent",
    );
    // Sets current cursor to not have formatting
    // Move cursor to end of inserted variable
    clearColoringAtCursor(
      quillToInsertIn,
      insertPosition + variableText.length,
    );
  };

  const [whichEditorFocused, setWhichEditorFocused] =
    useState<QuillEditorsOnThisComponent>(QuillEditorsOnThisComponent.MAIN);

  useEffect(() => {
    const deregisters = Object.entries(whichQuillLookup).map(
      ([name, { quill: whichQuillToSetup, initialHtml }]) => {
        if (!whichQuillToSetup) {
          return () => {};
        }
        if (initialState) {
          whichQuillToSetup.clipboard.dangerouslyPasteHTML(initialHtml || "");
          clearColoringAtCursor(
            whichQuillToSetup,
            whichQuillToSetup.getText().length,
          );

          whichQuillToSetup.blur();
        }
        const focusHandler = (event: FocusEvent) => {
          if (event.target !== whichQuillToSetup.root) {
            return;
          }
          setWhichEditorFocused(name as QuillEditorsOnThisComponent);
        };
        whichQuillToSetup.root.addEventListener("focus", focusHandler);
        return () => {
          whichQuillToSetup.root.removeEventListener("focus", focusHandler);
        };
      },
    );
    return () => {
      deregisters.forEach((deregister) => {
        deregister();
      });
    };
  }, [whichQuillLookup]);

  const title = initialState
    ? `Edit template: ${initialState.name}`
    : "Create new template";

  const TemplatesForEditingMacroAutomations: Record<
    MacroAutomation,
    ReactElement
  > = {
    [MacroAutomation.SET_STATUS]: (
      <LabeledInput
        description={
          macro.value.snoozeDuration
            ? `Until ${snoozeDurationToLabel(macro.value.snoozeDuration).charAt(0).toLowerCase()}${snoozeDurationToLabel(macro.value.snoozeDuration).slice(1)}`
            : undefined
        }
        label="Set status"
      >
        <SelectDropdown
          allowNull
          options={[undefined, ...Object.values(MacroStatusToSet)]}
          placeholder="No status change"
          value={macro.value.statusToSet}
          valueChange={(value) => {
            if (value === MacroStatusToSet.SNOOZED) {
              setSnoozeModalOpen(true);
            } else {
              macro.inputs.snoozeDuration.setValue(undefined);
            }
            macro.inputs.statusToSet.setValue(value);
          }}
        >
          {(option) => {
            if (option) {
              return option.charAt(0).toUpperCase() + option.slice(1);
            } else {
              return "No status change";
            }
          }}
        </SelectDropdown>
      </LabeledInput>
    ),
    [MacroAutomation.ADD_TAGS]: (
      <LabeledInput label="Add tags">
        <ConversationTagInput
          currentTags={macro.inputs.tagsToAdd.value}
          dropdownPlacement="top"
          setCurrentTags={macro.inputs.tagsToAdd.setValue}
          showAddButtonToEnterInput
        />
      </LabeledInput>
    ),
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: (
      <>
        <RedoCheckbox
          description="This only applies when used on email tickets"
          label="Update email subject"
          setValue={(value) => {
            macro.inputs.emailSubject.setValue(value ? "" : undefined);
          }}
          size={RedoCheckboxSize.SMALL}
          value={macro.inputs.emailSubject.value !== undefined}
        />

        {macro.inputs.emailSubject.value !== undefined && (
          <TextInput
            onChange={macro.inputs.emailSubject.setValue}
            placeholder="Enter email subject"
            value={macro.inputs.emailSubject.value || ""}
          />
        )}
      </>
    ),
    [MacroAutomation.ADD_INTERNAL_NOTE]: <>{internalNoteAutomationEditMode}</>,
    [MacroAutomation.FORWARD_MESSAGE]: (
      <ForwardMessageAutomationEditMode
        content={macro.inputs.messageToForwardExtraContent.value || ""}
        email={macro.inputs.messageToForwardEmailAddress.value || ""}
        enabled={macro.inputs.shouldForwardMessage.value || false}
        setContent={(value) =>
          macro.inputs.messageToForwardExtraContent.setValue(value)
        }
        setEmail={(value) =>
          macro.inputs.messageToForwardEmailAddress.setValue(value)
        }
        setEnabled={(value) =>
          macro.inputs.shouldForwardMessage.setValue(value)
        }
      />
    ),
  };

  return (
    <>
      <Modal
        onClose={cancel}
        open
        paddingAmount={PaddingAmount.NONE}
        size={ModalSize.LARGE}
        title={title}
      >
        <div className={macroModalCss.wrapper}>
          <div className={macroModalCss.sidebar}>
            <div className={macroModalCss.sidebarHeader}>Variables</div>
            <div className={macroModalCss.sidebarList}>
              <MacroVariableList onClick={onVariableClick} />
            </div>
          </div>
          <section className={macroModalCss.mainContent}>
            <div className={macroModalCss.details}>
              <div className={macroModalCss.preview2}>
                <FormTextInput
                  input={macro.inputs.macroName}
                  label="Template name"
                  placeholder="Template name"
                />
                <div className={macroModalCss.input}>
                  <QuillEditor
                    defaultValue={new Delta().insert("")}
                    editorClassName={macroModalCss.quillEditor}
                    onQuillEditorEmptyChange={setSubmitDisabledBecauseOfQuill}
                    placeholder="Start typing..."
                    readOnly={false}
                    ref={setQuill}
                    toolbar="macro-toolbar"
                  />
                  <QuillToolbarOptions
                    additionalToolbarItems={[
                      {
                        icon: PaperclipIcon,
                        buttonTitle: "Attach file",
                        identifier: "attach-file",
                        element: (
                          <QuillToolbarUploadFile
                            clearInput={(event: any) =>
                              (event.target.value = "")
                            }
                            handleUpload={handleUpload}
                          />
                        ),
                      },
                      {
                        icon: ClearFormatIcon,
                        buttonTitle: "Clear formatting",
                        identifier: "clear-formatting",
                        element: (
                          <RedoButton
                            buttonId="clear-formatting"
                            hierarchy={RedoButtonHierarchy.TERTIARY}
                            IconLeading={ClearFormatIcon}
                            onClick={() => {
                              if (!quill) {
                                return;
                              }
                              const selection = quill.getSelection();
                              quill.removeFormat(
                                selection?.index || 0,
                                selection?.length || 0,
                              );
                            }}
                          />
                        ),
                      },
                    ]}
                    emailMode
                    toolbarId="macro-toolbar"
                  />
                  <QuillAttachmentCarousel
                    attachments={attachments}
                    removeFileFromDrafts={(fileUrl) => {
                      setAttachments((oldAttachments) => {
                        if (!oldAttachments) {
                          return;
                        }
                        return oldAttachments.filter(
                          (attachment) => attachment.url !== fileUrl,
                        );
                      });
                    }}
                  />
                </div>
              </div>
              <div className={macroModalCss.preview2}>
                <div>
                  <h3>
                    <strong>Automations</strong>
                  </h3>
                  <Text fontSize="sm" textColor="tertiary">
                    These settings will take place if you use this template in a
                    support ticket
                  </Text>
                </div>

                {Object.entries(TemplatesForEditingMacroAutomations).map(
                  ([automation, element]) => (
                    <Fragment key={automation}>{element}</Fragment>
                  ),
                )}
              </div>
            </div>
            <div className={macroModalCss.buttonSection}>
              <Divider />
              <div className={macroModalCss.buttonContainer2}>
                <Button onClick={cancel}>Cancel</Button>
                <Button
                  disabled={
                    macro.allErrors.length > 0 || submitDisabledBecauseOfQuill
                  }
                  onClick={handleSaveMacro}
                  theme={ButtonTheme.PRIMARY}
                >
                  Save template
                </Button>
              </div>
            </div>
          </section>
        </div>
      </Modal>
      <SelectSnoozeDurationModal
        onClose={() => {
          if (!macro.inputs.snoozeDuration.value) {
            macro.inputs.statusToSet.setValue(undefined);
          }
          setSnoozeModalOpen(false);
        }}
        onDurationSelect={(duration) => {
          macro.inputs.snoozeDuration.setValue(duration);
          setSnoozeModalOpen(false);
        }}
        open={snoozeModalOpen}
      />
    </>
  );
});

const MacroVariableList = memo(function MacroVariableList({
  onClick,
}: {
  onClick(variable: MacroVariableType): void;
}) {
  return (
    <>
      {MACRO_VARIABLES?.sort((a, b) =>
        a.displayName.localeCompare(b.displayName),
      ).map((variable: MacroVariableType) => {
        return (
          <div
            className={macroModalCss.sidebarListItem}
            key={variable.id}
            onClick={() => onClick(variable)}
          >
            <MacroVariable variable={variable} />
          </div>
        );
      })}
    </>
  );
});

const snoozeDurationToLabel = (duration: SnoozeDuration) => {
  switch (duration) {
    case SnoozeDuration.SAME_DAY:
      return "Later in the day";
    case SnoozeDuration.ONE_DAY:
      return "The next day";
    case SnoozeDuration.TWO_DAYS:
      return "Two days later";
    case SnoozeDuration.SATURDAY:
      return "The next Saturday";
    case SnoozeDuration.MONDAY:
      return "The next Monday";
  }
};

const SelectSnoozeDurationModal = memo(function SelectSnoozeDurationModal({
  open,
  onClose,
  onDurationSelect,
}: {
  open: boolean;
  onClose(): void;
  onDurationSelect(duration: SnoozeDuration): void;
}) {
  const today = new Date();
  today.setHours(18, 0, 0);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(8, 0, 0);

  const twoDays = new Date();
  twoDays.setDate(twoDays.getDate() + 2);
  twoDays.setHours(8, 0, 0);

  const nextSaturday = new Date();
  nextSaturday.setDate(
    nextSaturday.getDate() + ((6 + 7 - nextSaturday.getDay()) % 7 || 7),
  );
  nextSaturday.setHours(8, 0, 0);

  const nextMonday = new Date();
  nextMonday.setDate(
    nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7),
  );
  nextMonday.setHours(8, 0, 0);

  return (
    <Modal
      onClose={onClose}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      subtitle="Choose how long tickets will be snoozed for when this template is used"
      title="Snooze ticket until"
    >
      <div className={macroModalCss.snoozeDurationWrapper}>
        <Button onClick={() => onDurationSelect(SnoozeDuration.SAME_DAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.SAME_DAY)}
            time={today}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.ONE_DAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.ONE_DAY)}
            time={tomorrow}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.TWO_DAYS)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.TWO_DAYS)}
            time={twoDays}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.SATURDAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.SATURDAY)}
            time={nextSaturday}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.MONDAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.MONDAY)}
            time={nextMonday}
          />
        </Button>
      </div>
    </Modal>
  );
});
