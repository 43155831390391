/**
 * @file Lazy-loaded Stripe
 */

import { lazy } from "@redotech/util/cache";
import { Stripe } from "@stripe/stripe-js";
import { ReactNode, createContext, useMemo } from "react";

export interface StripeFn {
  (): Promise<Stripe | null>;
}

export const StripeContext = createContext<StripeFn | undefined>(undefined);

export function StripeProvider({
  children,
  publicKey,
}: {
  children?: ReactNode | ReactNode[];
  publicKey: string;
}) {
  const stripePromise = useMemo(
    () =>
      lazy(async () => {
        const { loadStripe } = await import("@stripe/stripe-js/pure");
        return await loadStripe(publicKey);
      }),
    [publicKey],
  );
  return (
    <StripeContext.Provider value={stripePromise}>
      {children}
    </StripeContext.Provider>
  );
}
