import { toast } from "@redotech/redo-web/alert";

export async function copyToClipboard(
  text: string,
  truncateAmount: number | null = 16,
) {
  await navigator.clipboard.writeText(text);
  const truncated = truncateAmount
    ? text.length <= truncateAmount
      ? text
      : text.slice(0, truncateAmount) + "..."
    : text;
  toast(`"${truncated}" copied to clipboard`, {
    variant: "success",
  });
}
