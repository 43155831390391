import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import {
  ConversationMessage,
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { ExternalLink } from "@redotech/redo-web/link";
import { Modal, PaddingAmount } from "@redotech/redo-web/modal";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import { Text } from "@redotech/redo-web/text";
import Quill from "quill";
import { memo, useEffect, useState } from "react";
import { RedoMerchantClientContext } from "../client/context";
import {
  sendPrivateMessageDraft,
  upsertPrivateMessageDraft,
} from "../client/conversations";
import * as privateReplyModalCss from "./private-reply-modal.module.css";
const Delta = Quill.import("delta");

export const PrivateReplyModal = memo(function PrivateReplyModal({
  open,
  onClose,
  conversation,
  setActiveConversation,
  message,
}: {
  open: boolean;
  onClose: () => void;
  conversation: ExpandedConversation;
  setActiveConversation: (conversation: ExpandedConversation) => void;
  message: ConversationMessage;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [replyStayPending, setReplyStayPending] = useState(false);
  const [replyGoPending, setReplyGoPending] = useState(false);

  const [quill, setQuill] = useState<Quill | null>(null);

  const handleSendReply = async (stayHere: boolean) => {
    void alertOnFailure("Sending private reply failed")(async () => {
      if (
        !quill ||
        (conversation.platform === ConversationPlatform.INSTAGRAM_COMMENTS &&
          !message.instagram?.comment?.commentId) ||
        (conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS &&
          !message.facebook?.comment?.commentId)
      ) {
        return;
      }

      const text = quill.getText();
      quill.disable();

      const commentId =
        conversation.platform === ConversationPlatform.INSTAGRAM_COMMENTS
          ? message.instagram!.comment!.commentId!
          : message.facebook!.comment!.commentId!;

      const { dmConversationId, draftMessage } =
        await upsertPrivateMessageDraft(client, {
          commentConversationId: conversation._id,
          commentId,
          message: text,
        });

      if (!dmConversationId || !draftMessage) {
        cleanup();
        return;
      }

      const newConversation = await sendPrivateMessageDraft(client, {
        draftConversationId: dmConversationId,
        draftMessageId: draftMessage._id,
        signal: new AbortController().signal,
      });

      cleanup();

      const eventTitle =
        conversation.platform === ConversationPlatform.INSTAGRAM_COMMENTS
          ? "create-instagramPrivateReply"
          : "create-facebookPrivateReply";
      amplitude.logEvent(eventTitle, {
        platform:
          conversation.platform === ConversationPlatform.INSTAGRAM_COMMENTS
            ? ConversationPlatform.INSTAGRAM
            : ConversationPlatform.FACEBOOK,
        privateReplyCommentId:
          conversation.platform === ConversationPlatform.INSTAGRAM_COMMENTS
            ? message.instagram?.comment?.commentId
            : message.facebook?.comment?.commentId,
        conversationId: newConversation._id,
      });

      if (!stayHere) {
        setActiveConversation(newConversation);
      }
      onClose();
    });
  };

  const cleanup = () => {
    setReplyStayPending(false);
    setReplyGoPending(false);
    quill?.enable();
    quill?.setText("");
  };

  useEffect(() => {
    if (!open) {
      setReplyStayPending(false);
      setReplyGoPending(false);
      if (quill) {
        quill.setText("");
      }
    }
  }, [open]);

  const footer = (
    <div className={privateReplyModalCss.footer}>
      <Button
        disabled={replyGoPending || replyStayPending}
        onClick={() => onClose()}
        theme={ButtonTheme.GHOST}
      >
        Cancel
      </Button>
      <Button
        disabled={replyStayPending}
        onClick={() => {
          setReplyGoPending(true);
          void handleSendReply(false);
        }}
        pending={replyGoPending}
        theme={ButtonTheme.OUTLINED}
      >
        Send and go to DM
      </Button>
      <Button
        disabled={replyGoPending}
        onClick={() => {
          setReplyStayPending(true);
          void handleSendReply(true);
        }}
        pending={replyStayPending}
        theme={ButtonTheme.PRIMARY}
      >
        Send and stay here
      </Button>
    </div>
  );

  return (
    <Modal
      footer={footer}
      onClose={onClose}
      open={open}
      paddingAmount={PaddingAmount.NONE}
      showFooterBorder
      title="Send a private reply"
    >
      <div className={privateReplyModalCss.modalContent}>
        <p className={privateReplyModalCss.subtitle}>
          Reply to{" "}
          {message.instagram?.comment?.username ? (
            <span>
              <ExternalLink
                className={privateReplyModalCss.inherit}
                showIcon={false}
                url={`https://instagram.com/${message.instagram.comment.username}`}
              >
                @{message.instagram.comment.username}
              </ExternalLink>
              's
            </span>
          ) : message.facebook?.comment?.facebookName ? (
            `${message.facebook.comment.facebookName}'s`
          ) : (
            "this"
          )}{" "}
          comment with a DM.
        </p>
        <Text fontSize="sm" textColor="tertiary">
          When replying to comments,{" "}
          {conversation.platform === ConversationPlatform.INSTAGRAM_COMMENTS
            ? "Instagram"
            : "Facebook"}{" "}
          allows only one private reply until the customer responds.
        </Text>
        <QuillEditor
          defaultValue={new Delta().insert("")}
          editorClassName={privateReplyModalCss.quillEditor}
          placeholder="Type your reply here..."
          readOnly={false}
          ref={setQuill}
          toolbar="macro-toolbar"
        />
      </div>
    </Modal>
  );
});
