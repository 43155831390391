import AlignHorizontalCenterIcon from "@redotech/redo-web/arbiter-icon/align-horizontal-centre-01_filled.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo } from "react";
import * as mergeSuggestionDialogCss from "./merge-suggestion-dialog.module.css";

export const MergeSuggestionDialog = memo(function MergeSuggestionDialog({
  customerName,
  onCancel,
  openMergeSuggestionSummaryModal,
  openMergeModal,
}: {
  customerName: string;
  openMergeSuggestionSummaryModal: () => void;
  openMergeModal: () => void;
  onCancel: () => void;
}) {
  return (
    <Flex
      className={classNames(mergeSuggestionDialogCss.card)}
      gap="xl"
      p="xl"
      radius="xl"
    >
      <Flex dir="row" gap="xl">
        <Flex className={mergeSuggestionDialogCss.iconContainer} radius="full">
          <AlignHorizontalCenterIcon style={{ color: "#4D35C6" }} width={20} />
        </Flex>
        <Flex
          className={mergeSuggestionDialogCss.contentColumn}
          dir="column"
          gap="lg"
        >
          <Flex dir="column" gap="xs">
            <Text fontSize="sm" fontWeight="semibold" textColor="primary">
              Merge tickets?
            </Text>
            <Text fontSize="sm" textColor="secondary">
              We found multiple support tickets for {customerName}. We can merge
              these tickets to clean up your inbox for you.
            </Text>
          </Flex>
          <Flex gap="lg">
            <Text
              as="span"
              className={mergeSuggestionDialogCss.button}
              fontSize="sm"
              fontWeight="semibold"
              hoverTextColor="secondary"
              onClick={openMergeSuggestionSummaryModal}
              textColor="tertiary"
            >
              View ticket summaries
            </Text>
            <Text
              as="span"
              className={mergeSuggestionDialogCss.button}
              fontSize="sm"
              fontWeight="semibold"
              onClick={openMergeModal}
              textColor="brand-tertiary"
            >
              Merge now
            </Text>
          </Flex>
        </Flex>
        <div
          className={mergeSuggestionDialogCss.closeButtonContainer}
          onClick={onCancel}
        >
          <XIcon height={20} style={{ color: "#a3a3a3" }} />
        </div>
      </Flex>
    </Flex>
  );
});
