import CircleClose from "@redotech/redo-web/icon-old/x.svg";
import { Link } from "@redotech/redo-web/link";
import { DATE_FORMAT } from "@redotech/redo-web/time";
import { memo, useContext, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { TeamContext } from "./app/team";
import * as failedAlertCss from "./failed-alert.module.css";

export const FailedAlert = memo(function FailedAlert() {
  const team = useContext(TeamContext);
  const [closed, setClosed] = useState(false);
  const location = useLocation();

  return team &&
    team.failed_payouts > 0 &&
    team.billingMethod !== "fake" &&
    !closed ? (
    <div className={failedAlertCss.header}>
      <div>
        We were unable to collect payment. Please update your billing info in
        the <Link to="settings/billing">billing settings.</Link>
        <br />
        The return portal will be disabled on{" "}
        {DATE_FORMAT.format(new Date(team.shutdown_date))}.
      </div>
      {location.pathname !== "/settings/invoices" ? (
        <RouterLink to="/settings/invoices">
          <button className={failedAlertCss.retryButton}>
            <span>View invoices</span>
          </button>
        </RouterLink>
      ) : null}
      <div
        className={failedAlertCss.closeButton}
        onClick={() => setClosed(true)}
      >
        <CircleClose />
      </div>
    </div>
  ) : null;
});
