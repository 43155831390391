// extracted by mini-css-extract-plugin
export var checkbox = "_3o-0";
export var circle = "_3o-3";
export var horizontalContainer = "_3o-7";
export var sm = "_3o-5";
export var switch0 = "_3o-2";
export var switchDisabled = "_3o-8";
export var switchEnabled = "_3o-1";
export var textSwitchContainer = "_3o-9";
export var textSwitchOption = "_3o-a";
export var textSwitchOptionSelected = "_3o-b";
export var verticalContainer = "_3o-6";
export var xs = "_3o-4";