import { useLazyContext } from "@redotech/react-util/context";
import { MacroStatusToSet, SnoozeDuration } from "@redotech/redo-model/macro";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  RedoBadge,
  RedoBadgeColor,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import AsteriskIcon from "@redotech/redo-web/icon-old/asterisk.svg";
import FolderCheckIcon from "@redotech/redo-web/icon-old/folder-check.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import * as capitalize from "lodash/capitalize";
import { JSXElementConstructor, memo } from "react";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { BadgePillThemeToColorMapping } from "../conversation-tags/conversation-tag-pill";

export const TagMacroAutomationPill = memo(function TagMacroAutomationPill({
  tag,
  xClicked,
}: {
  tag: string;
  xClicked?(): void;
}) {
  const [conversationTags] = useLazyContext(ConversationTagsContext);

  const teamTag = conversationTags.value?.find(
    (teamTag) => teamTag.name === tag,
  );
  const tagName = teamTag?.name || tag;
  const tagTheme =
    BadgePillThemeToColorMapping[
      teamTag?.pillTheme ? teamTag.pillTheme : PillTheme.PRIMARY_LIGHT
    ];
  return (
    <MacroAutomationPill
      color={tagTheme}
      Icon={TagIcon}
      label={tagName}
      xClicked={xClicked}
    />
  );
});

export const StatusMacroAutomationPill = memo(
  function StatusMacroAutomationPill({
    statusToSet,
    snoozeDuration,
    xClicked,
  }: {
    statusToSet: MacroStatusToSet;
    snoozeDuration?: SnoozeDuration;
    xClicked?(): void;
  }) {
    return (
      <MacroAutomationPill
        color={
          BadgePillThemeToColorMapping[
            snoozeDuration
              ? PillTheme.NEUTRAL_YELLOW
              : statusToSet === "open"
                ? PillTheme.PRIMARY_LIGHT
                : PillTheme.NORMAL
          ]
        }
        Icon={
          snoozeDuration
            ? SnoozeClockIcon
            : statusToSet === "open"
              ? AsteriskIcon
              : FolderCheckIcon
        }
        label={`Set status: ${capitalize(statusToSet)}`}
        xClicked={xClicked}
      />
    );
  },
);

export const EmailSubjectMacroAutomationPill = memo(
  function EmailSubjectMacroAutomationPill({
    emailSubject,
    xClicked,
  }: {
    emailSubject: string;
    xClicked?(): void;
  }) {
    return (
      <MacroAutomationPill
        color={BadgePillThemeToColorMapping[PillTheme.NEUTRAL_BLUE]}
        Icon={Edit04Icon}
        label={`Set email subject: ${emailSubject}`}
        xClicked={xClicked}
      />
    );
  },
);

export const MacroAutomationPill = memo(function MacroAutomationPill({
  color,
  label,
  Icon,
  xClicked,
}: {
  color: RedoBadgeColor;
  label: string;
  Icon: JSXElementConstructor<unknown>;
  xClicked?(): void;
}) {
  return (
    <RedoBadge
      color={color}
      iconTrailing={
        xClicked ? { type: "icon", Icon: XIcon, onClick: xClicked } : undefined
      }
      segmentLeading={{ type: "icon", Icon }}
      text={label}
    />
  );
});
