// extracted by mini-css-extract-plugin
export var actions = "_2o-0";
export var actionsContainer = "_2o-3";
export var border = "_2o-1";
export var collapse = "_2o-2";
export var compact = "_2o-b";
export var content = "_2o-4";
export var contentChild = "_2o-8";
export var fullscreen = "_2o-6";
export var header = "_2o-9";
export var headerContainer = "_2o-d";
export var hideHeaderBorder = "_2o-c";
export var hidePadding = "_2o-5";
export var noScrollX = "_2o-e";
export var noScrollY = "_2o-f";
export var optOutTopPadding = "_2o-7";
export var profile = "_2o-g";
export var profileContainer = "_2o-h";
export var profileImage = "_2o-i";
export var profileMain = "_2o-j";
export var profileSubtitle = "_2o-l";
export var profileTitle = "_2o-k";
export var scrolledTop = "_2o-a";
export var title = "_2o-m";