import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import {
  ExpandedConversation,
  ExpandedConversationMessage,
} from "@redotech/redo-model/conversation";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Text } from "@redotech/redo-web/text";
import { formatDate } from "@redotech/redo-web/time";
import * as classNames from "classnames";
import { Fragment, memo, useEffect, useState } from "react";
import { TeamContext } from "../app/team";
import { RedoMerchantClientContext } from "../client/context";
import { getProfilePictures } from "../client/conversations";
import { REDO_MERCHANT_SERVER_URL } from "../config";
import * as conversationMessagesCss from "./conversation-messages.module.css";
import { Message } from "./message";
import { PrivateReplyModal } from "./private-reply-modal";
import { listen } from "./utils";

const getConversationStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_MERCHANT_SERVER_URL}/conversations/${conversationId}/live`;
  return fetch(url, { signal, headers: client.authorization() });
};

export const ConversationMessages = memo(function ConversationMessages({
  conversation,
  typing,
  setErrorMessage,
  setShowErrorMessage,
  setActiveConversation,
  showFullCommentThread,
}: {
  conversation: ExpandedConversation;
  typing: Record<string, Date>;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  setActiveConversation: (conversation: ExpandedConversation) => void;
  showFullCommentThread: boolean;
}) {
  const [retryCount, setRetryCount] = useState(0);
  const [privateReplyModalOpen, setPrivateReplyModalOpen] =
    useState<boolean>(false);
  const [privateReplyMessage, setPrivateReplyMessage] =
    useState<ExpandedConversationMessage>();
  const client = useRequiredContext(RedoClientContext);
  const merchantClient = useRequiredContext(RedoMerchantClientContext);

  const profilePicturesLoad = useLoad(async (signal) => {
    const profilePictures = await getProfilePictures(merchantClient, {
      conversationId: conversation._id,
      signal,
    });

    return profilePictures;
  }, []);
  const messages: ExpandedConversationMessage[] = conversation.messages;

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const updatedConversation of listen({
          query: async () => {
            return await getConversationStream(client, {
              conversationId: conversation._id,
              signal: abortController.signal,
            });
          },
          loopCondition: !!conversation?._id,
          setErrorMessage,
          setShowErrorMessage,
        })) {
          setActiveConversation(
            updatedConversation as unknown as ExpandedConversation,
          );
        }
      } catch (e) {
        // Wait 5 seconds before trying again
        setTimeout(() => {
          // Continue polling for changes
          setRetryCount(retryCount + 1);
        }, 5000);
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () =>
      abortController.abort("Conversation changed or component unmounted");
  }, [conversation._id, retryCount]);

  let messagesToShow: ExpandedConversationMessage[] = messages || [];
  if (
    (!!conversation.instagramCommentThread ||
      !!conversation.facebookCommentThread) &&
    !showFullCommentThread
  ) {
    messagesToShow = messagesToShow.filter(
      (message) =>
        message.type === "merchant" ||
        message.type === "system" ||
        message.customer === conversation.customer.customer,
    );
  }

  // Dont show drafts
  messagesToShow = messagesToShow.filter(
    (message) => !message.draftInfo?.isDraft,
  );

  // We don't want to have to care about the order of messages in the array as it's store in the backend. Just sort them by sentAt
  messagesToShow.sort((a, b) => {
    return new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
  });

  const team = useRequiredContext(TeamContext);

  return (
    <>
      <div className={conversationMessagesCss.messagesCard}>
        <div
          className={classNames(
            conversationMessagesCss.messagesContainerWrapper,
            {
              [conversationMessagesCss.withPadding]:
                team.settings?.support?.ai?.enabled,
            },
          )}
        >
          <div className={conversationMessagesCss.messagesContainer}>
            {messagesToShow.map((message, index) => (
              <Fragment key={message._id}>
                {index === 0 && (
                  <div className={conversationMessagesCss.headerDivider}>
                    <div className={conversationMessagesCss.divider} />
                    <div className={conversationMessagesCss.createdDate}>
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        textColor="tertiary"
                      >
                        {" "}
                        {formatDate(
                          Temporal.Instant.from(conversation.createdAt),
                        )}
                      </Text>
                    </div>
                    <div className={conversationMessagesCss.divider} />
                  </div>
                )}
                <Message
                  conversation={conversation}
                  index={index}
                  message={message}
                  profilePictures={profilePicturesLoad.value}
                  setActiveConversation={setActiveConversation}
                  setPrivateReplyMessage={setPrivateReplyMessage}
                  setPrivateReplyModalOpen={setPrivateReplyModalOpen}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {privateReplyMessage && (
        <PrivateReplyModal
          conversation={conversation}
          message={privateReplyMessage}
          onClose={() => setPrivateReplyModalOpen(false)}
          open={privateReplyModalOpen}
          setActiveConversation={setActiveConversation}
        />
      )}
    </>
  );
});
