import Fuse from "fuse.js";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";

export function useSearch<DOC>(
  searcher: Fuse<DOC>,
  allDocs: DOC[] | undefined,
  searchText: string,
): DOC[] {
  const [searchResults, setSearchResults] = useState<DOC[]>([]);
  useEffect(() => {
    searcher.setCollection(allDocs || []);
  }, [allDocs, searcher]);
  useEffect(() => {
    if (searchText) {
      setSearchResults(
        searcher.search(searchText).map((result) => result.item),
      );
    } else {
      setSearchResults(allDocs || []);
    }
  }, [searchText, allDocs, searcher]);

  return searchResults;
}

export enum UseSearchBackendResultType {
  DEBOUNCING = "debouncing",
  SEARCH_PENDING = "search-pending",
  RESOLVED = "resolved",
  ERROR = "error",
}

export type UseSearchBackendResult<DOC> = {
  type: UseSearchBackendResultType;
  results: DOC[];
  error?: Error;
  trigger: symbol;
};

export function useSearchBackend<DOC>({
  memoizedDocFetcher,
  searchText,
  debounceMs = 500,
}: {
  memoizedDocFetcher: (
    searchText: string,
    abortSignal: AbortSignal,
  ) => Promise<DOC[]>;
  searchText: string;
  debounceMs?: number;
}): UseSearchBackendResult<DOC> {
  const [lastSearchResults, setLastSearchResults] = useState<DOC[]>([]);
  const debouncedSearchText = useDebounce(searchText, debounceMs);
  const currentSearchPromise = useRef<Promise<void> | null | true>(null);
  const abortController = useRef<AbortController | null>(null);
  const [lastSearchErrored, setLastSearchErrored] = useState<
    Error | null | undefined
  >(undefined);
  const [trigger, setTrigger] = useState(Symbol());
  useEffect(() => {
    setTrigger(Symbol());
    if (debouncedSearchText) {
      if (currentSearchPromise.current) {
        abortController.current?.abort();
      }
      abortController.current = new AbortController();
      currentSearchPromise.current = memoizedDocFetcher(
        debouncedSearchText,
        abortController.current.signal,
      )
        .then((results) => {
          currentSearchPromise.current = null;
          setLastSearchErrored(null);
          setLastSearchResults(results);
          setTrigger(Symbol());
        })
        .catch((error) => {
          if (error?.name === "CanceledError") {
            return;
          }
          currentSearchPromise.current = null;
          setLastSearchErrored(error);
          setLastSearchResults([]);
          setTrigger(Symbol());
        });
    } else {
      setLastSearchResults([]);
    }
  }, [debouncedSearchText, memoizedDocFetcher]);

  /** If search text changes, say we're loading (but don't clear results) */
  useEffect(() => {
    currentSearchPromise.current = true;
    setTrigger(Symbol());
  }, [searchText]);

  return {
    type: lastSearchErrored
      ? UseSearchBackendResultType.ERROR
      : !currentSearchPromise.current
        ? UseSearchBackendResultType.RESOLVED
        : searchText === debouncedSearchText
          ? UseSearchBackendResultType.DEBOUNCING
          : UseSearchBackendResultType.SEARCH_PENDING,
    results: lastSearchResults,
    error: lastSearchErrored || undefined,
    trigger,
  };
}
