import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { ReactNode, useEffect, useState } from "react";
import { Dropdown } from "../../dropdown";
import { SpacingValue } from "../../theme/box";
import { RedoList, RedoListItem } from "../list/redo-list";
import { RedoListItemSize } from "../list/redo-list-item";

export const RedoSingleSelectDropdown = genericMemo(
  function RedoSingleSelectDropdown<T>({
    dropdownButtonRef,
    options,
    optionSelected,
    children,
    selectedItem,
    keyFn,
    size,
    containerClassName,
    gap,
    itemPadding,
    isItemDisabled,
    onDropdownToggled,
    fitToAnchor = false,
  }: {
    size?: RedoListItemSize;
    dropdownButtonRef: HTMLElement | null;
    options: RedoListItem<T>[];
    optionSelected(value: RedoListItem<T>): void;
    children(item: RedoListItem<T>): ReactNode;
    selectedItem?: RedoListItem<T>;
    keyFn?: (item: RedoListItem<T>) => string | number;
    containerClassName?: string;
    gap?: SpacingValue;
    itemPadding?: SpacingValue;
    isItemDisabled?(item: RedoListItem<T>): boolean;
    onDropdownToggled?: (isOpen: boolean) => void;
    fitToAnchor?: boolean;
  }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    function itemsEqual(item1: RedoListItem<T>, item2: RedoListItem<T>) {
      if (keyFn) {
        return keyFn(item1) === keyFn(item2);
      }
      return item1.value === item2.value;
    }

    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
      undefined,
    );

    function toggleDropdownOpen() {
      setDropdownOpen(!dropdownOpen);
      onDropdownToggled?.(!dropdownOpen);
      setFocusedIndex(undefined);
    }

    function itemSelected(item: RedoListItem<T>) {
      optionSelected(item);
      setDropdownOpen(false);
      onDropdownToggled?.(false);
    }

    useEffect(() => {
      if (!dropdownButtonRef) return;
      dropdownButtonRef.addEventListener("click", toggleDropdownOpen);
      return () => {
        dropdownButtonRef.removeEventListener("click", toggleDropdownOpen);
      };
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownButtonRef]);

    return (
      <>
        {dropdownOpen && (
          <ClickAwayListener
            onClickAway={() => {
              setDropdownOpen(false);
              onDropdownToggled?.(false);
            }}
          >
            <Dropdown anchor={dropdownButtonRef} fitToAnchor={fitToAnchor} open>
              <RedoList
                containerClassName={containerClassName}
                focusedIndex={focusedIndex}
                gap={gap}
                isItemDisabled={isItemDisabled}
                isItemSelected={(item) => {
                  if (!selectedItem) {
                    return false;
                  }
                  return itemsEqual(item, selectedItem);
                }}
                itemPadding={itemPadding}
                items={options}
                itemSelected={itemSelected}
                keyFn={keyFn}
                refToListenTo={dropdownButtonRef}
                setFocusedIndex={(idx) => {
                  setFocusedIndex(idx);
                }}
                size={size}
              >
                {(item) => children(item)}
              </RedoList>
            </Dropdown>
          </ClickAwayListener>
        )}
      </>
    );
  },
);
