import { z } from "zod";

export const removeVariantExchangeItemSchema = {
  input: z.object({
    teamId: z.string(),
    returnId: z.string(),
    variantId: z.string(),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};
