import { z } from "zod";

const upsellPageAnalyticsRecordSchema = z.object({
  teamId: z.string(),
  referenceId: z.string(),
  totalUpsellRevenue: z.number(),
  productId: z.string().optional(),
  createdAt: z.date(),
});

export const getUpsellPageAnalyticsSchema = {
  input: z.null(),
  output: z.object({
    upsellPageAnalyticsRecords: z.array(upsellPageAnalyticsRecordSchema),
  }),
};
