import { useEffect, useState } from "react";

export enum OverflowDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export function useOverflow(
  ref: HTMLElement | null,
  direction: OverflowDirection,
) {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = ref;
    if (!element) {
      return;
    }

    if (direction === OverflowDirection.Horizontal) {
      setIsOverflowing(element.scrollWidth > element.clientWidth);
    } else {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }

    function handleResize() {
      if (!element) {
        return;
      }
      if (direction === OverflowDirection.Horizontal) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      } else {
        setIsOverflowing(element.scrollHeight > element.clientHeight);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [direction, ref]);

  return isOverflowing;
}
