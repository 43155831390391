import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import Stars01 from "@redotech/redo-web/arbiter-icon/stars-01.svg";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { assertNever } from "@redotech/util/type";
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RedoMerchantRpcClientContext } from "../app/redo-merchant-rpc-client-provider";
import { RedoMerchantClientContext } from "../client/context";
import { getSupportAiResponse } from "../client/support-ai";
import { hotkeyTooltip } from "./message-input/hotkeys";
import * as styles from "./writing-ai-assistant-menu.module.css";

type AiOperation = "suggest" | "polish";

export enum QuillOperation {
  INSERT = "insert",
  REPLACE = "replace",
}

interface WritingAiAssistantMenuProps {
  conversationId: string;
  draftId?: string;
  messageRespondingToId: string;
  onAiOperationComplete: (content: string, operation: QuillOperation) => void;
  onLoadingChange?: (isLoading: boolean) => void;
  onHotkeyFunctionReady: (
    hotkeyFunction: (event: KeyboardEvent) => void,
  ) => void;
}

export const WritingAiAssistantMenu: React.FC<WritingAiAssistantMenuProps> = ({
  conversationId,
  draftId,
  messageRespondingToId,
  onAiOperationComplete,
  onLoadingChange,
  onHotkeyFunctionReady,
}) => {
  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );
  const client = useRequiredContext(RedoMerchantClientContext);
  const [selectedOperation, setSelectedOperation] =
    useState<AiOperation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const aiOperations: RedoListItem<AiOperation>[] = [
    { value: "suggest" },
    { value: "polish" },
  ];
  const operationBadgeNumbers: Record<AiOperation, string> = {
    suggest: "1",
    polish: "2",
  };
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const disabled = isLoading;
  const isMac = isUserOnMac();

  const handleAiOperation = useCallback(
    async (operation: AiOperation) => {
      if (disabled) return;

      setIsLoading(true);
      if (onLoadingChange) {
        onLoadingChange(true);
      }

      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(async () => {
        try {
          let result;
          if (operation === "suggest") {
            result = await getSupportAiResponse(client, {
              type: "conversationReplySuggestion",
              conversationId,
              prevMessageId: messageRespondingToId,
            });
            if (result?.text) {
              amplitude.logEvent("generate-copilotSuggestion", {
                conversationId: conversationId,
                cost: result.cost,
              });
            }
            if (result?.text) {
              onAiOperationComplete(result.text, QuillOperation.INSERT);
            }
          } else if (operation === "polish") {
            if (!draftId) {
              return;
            }
            result = await redoMerchantRpcClient.polishMessage({
              draftId,
              conversationId,
            });
            if (result?.polishedResponse) {
              onAiOperationComplete(
                result.polishedResponse,
                QuillOperation.REPLACE,
              );
            }
          } else {
            assertNever(operation);
          }
        } catch (error) {
          console.error("AI operation failed:", error);
        } finally {
          setIsLoading(false);
          if (onLoadingChange) {
            onLoadingChange(false);
          }
          setSelectedOperation(null);
        }
      }, 300);
    },
    [
      client,
      redoMerchantRpcClient,
      conversationId,
      draftId,
      onAiOperationComplete,
      onLoadingChange,
      isLoading,
    ],
  );

  const optionSelected = useCallback(
    (item: RedoListItem<AiOperation>) => {
      setSelectedOperation(item.value);
      void handleAiOperation(item.value);
    },
    [handleAiOperation],
  );

  const getOperationDisplayText = (value: AiOperation): string => {
    switch (value) {
      case "suggest":
        return "Suggest a response";
      case "polish":
        return "Improve writing";
      default:
        assertNever(value);
    }
  };

  const isToolDisabled = useMemo(() => {
    return (operation: AiOperation): boolean => {
      if (operation === "suggest") {
        return disabled;
      }
      if (operation === "polish") {
        return disabled || !draftId;
      }
      return false;
    };
  }, [disabled, draftId]);

  const maybePerformOptionHotkeyAction = useCallback(
    (event: KeyboardEvent) => {
      if (!event.altKey) {
        return;
      }
      if (
        (event.key === "1" || event.key === "¡") &&
        !isToolDisabled("suggest")
      ) {
        event.preventDefault();
        event.stopPropagation();
        void handleAiOperation("suggest");
      }
      if (
        (event.key === "2" || event.key === "™") &&
        !isToolDisabled("polish")
      ) {
        event.preventDefault();
        event.stopPropagation();
        void handleAiOperation("polish");
      }
    },
    [handleAiOperation, isToolDisabled],
  );

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    onHotkeyFunctionReady(maybePerformOptionHotkeyAction);
  }, [onHotkeyFunctionReady, maybePerformOptionHotkeyAction]);

  const IconTrailing: React.FC = () => (
    <Flex>{isLoading ? <CircleSpinner /> : <Stars01 />}</Flex>
  );

  return (
    <Tooltip
      arrow
      title={hotkeyTooltip({
        action: "Show",
        code: "1",
        name: "AI actions",
        isMac,
        modifierKey: "alt",
      })}
    >
      <span>
        <RedoButton
          disabled={disabled}
          IconTrailing={IconTrailing}
          ref={dropdownButtonRef}
          size={RedoButtonSize.REGULAR}
        />
        <RedoSingleSelectDropdown
          containerClassName={styles.dropdownContainer}
          dropdownButtonRef={dropdownButtonRef.current}
          gap="none"
          isItemDisabled={(item) => isToolDisabled(item.value)}
          itemPadding="none"
          options={aiOperations}
          optionSelected={optionSelected}
          selectedItem={aiOperations.find(
            (op) => op.value === selectedOperation,
          )}
        >
          {(item) => (
            <Flex className={styles.listItemWrapper}>
              <Flex
                align="center"
                className={styles.listItem}
                justify="space-between"
              >
                <Text className={styles.operationText}>
                  {getOperationDisplayText(item.value)}
                </Text>
                <Flex>
                  <RedoBadge
                    color={RedoBadgeColor.GRAY}
                    size={RedoBadgeSize.MEDIUM}
                    text={operationBadgeNumbers[item.value]}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </RedoSingleSelectDropdown>
      </span>
    </Tooltip>
  );
};
