import { RouteObject } from "react-router-dom";

export const riseRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Rise.ai" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { RiseSetupInstructions } = await import("./integration-setup");
          return { Component: RiseSetupInstructions };
        },
      },
    ],
  },
];
