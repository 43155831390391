import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  SortableConversationTableColumn,
  TableSort,
} from "@redotech/redo-model/table";
import { GetUser } from "@redotech/redo-model/user";
import { YieldType } from "@redotech/redo-web/table";
import { RedoMerchantClient } from "../client";
import { getConversations } from "../client/conversations";
import { ConversationFetcher } from "./conversation-fetcher";
import {
  ConversationFetcherCache,
  ConversationFetcherResult,
} from "./conversation-fetcher-cache";

export class CachedConversationFetcher extends ConversationFetcher {
  constructor(
    private cache: ConversationFetcherCache,
    client: RedoMerchantClient,
    userCache: Map<string, GetUser>,
    onConversationsLoaded?: (() => void) | undefined,
  ) {
    super(client, userCache, onConversationsLoaded);
  }

  public invalidateCache() {
    this.cache.invalidate();
  }

  override async *data(
    primaryFilter: string,
    filters: ConversationFiltersV3,
    search: string | undefined,
    sort: TableSort<SortableConversationTableColumn> | undefined,
    pageSize: number = 50,
    pageNumber?: number,
    signal?: AbortSignal,
    passThroughValues?: ConversationFiltersV3,
  ): AsyncIterator<YieldType<ExpandedConversation>> {
    let cursor: string | undefined;

    const refresh = async (
      pageStop: string | undefined,
      signal: AbortSignal | undefined,
    ) => {
      const tempCache = new ConversationFetcherCache();
      let items: ExpandedConversation[] = [];
      for (let refreshCursor: string | undefined; ; ) {
        try {
          // We don't need to request the next page if we've already made it to the point of the page stop
          if (!!refreshCursor && refreshCursor === pageStop) {
            break;
          }
          const result = await getConversations(this.client, {
            pageContinue: refreshCursor,
            pageStop,
            pageSize,
            filters: passThroughValues,
            sort: passThroughValues?.sort || sort,
            signal,
          });

          tempCache.set(
            refreshCursor,
            pageSize,
            passThroughValues,
            sort,
            result,
          );

          const { data, pageNext } = result;

          this.updateConversationsMap(data);
          await this.fetchUsers(data, signal);
          if (data && data.length > 0) {
            items = [...items, ...data];
          }
          if (pageNext) {
            refreshCursor = pageNext;
          } else {
            break;
          }
        } catch (e: any) {
          this.cache.invalidate();
          if (e.code === "ERR_CANCELED") {
            return [];
          }
          throw e;
        }
      }
      this.cache = tempCache;
      return items;
    };
    for (let pageContinue: string | undefined; ; ) {
      try {
        let result: ConversationFetcherResult;

        const cachedResult = this.cache.get(
          pageContinue,
          pageSize,
          passThroughValues,
          sort,
        );
        if (cachedResult) {
          result = cachedResult;
        } else {
          result = await getConversations(this.client, {
            pageContinue,
            pageSize,
            filters: passThroughValues,
            sort: passThroughValues?.sort || sort,
            signal,
          });
          this.cache.set(
            pageContinue,
            pageSize,
            passThroughValues,
            sort,
            result,
          );
        }

        const { data, pageNext } = result;

        this.updateConversationsMap(data);
        await this.fetchUsers(data, signal);

        cursor = pageNext;
        this.onConversationsLoaded?.();
        yield {
          items: data,
          refresh: async (signal) => refresh(cursor, signal),
        };
        if (pageNext === undefined) {
          break;
        }
        pageContinue = pageNext;
      } catch (e: any) {
        if (e.code !== "ERR_CANCELED") {
          throw e;
        }
        return {
          items: [],
          refresh: async (signal) => refresh(cursor, signal),
          aborted: true,
        } as YieldType<ExpandedConversation>;
      }
    }
  }
}
