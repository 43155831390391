// extracted by mini-css-extract-plugin
export var attachment = "_8j-a";
export var emailContainer = "_8j-4";
export var emailContentPaddedContainer = "_8j-b";
export var emailHeader = "_8j-6";
export var emailInfoIcon = "_8j-9";
export var emailShortenedSummary = "_8j-e";
export var infoDropdown = "_8j-0";
export var infoRowBoundedContent = "_8j-d";
export var internal = "_8j-5";
export var merchant = "_8j-8";
export var messageInputContainer = "_8j-h";
export var normal = "_8j-7";
export var replyCard = "_8j-f";
export var shadowDomWrapper = "_8j-3";
export var subtleButton = "_8j-1";
export var subtleButtonIconContainer = "_8j-2";
export var toggleViewRepliesButton = "_8j-c";
export var wrappingText = "_8j-g";