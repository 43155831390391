import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import HomeIcon from "@redotech/redo-web/icon-old/home.svg";
import { memo } from "react";
import * as selectCss from "./select.module.css";
import { Step, StepId } from "./step";

export interface SelectStep {
  (step: StepId | undefined): void;
}

export const StepSelect = memo(function StepSelect({
  create,
  onHover,
  setSelectStep,
  step,
  value,
  valueChange,
  disabled = false,
  type,
}: {
  create(): StepId;
  onHover?(value: boolean): void;
  setSelectStep(fn: SelectStep | undefined): void;
  step: Step | undefined;
  value: StepId | undefined | null | "base";
  valueChange(step: StepId | undefined): void;
  disabled?: boolean;
  type?: symbol;
}) {
  const onExisting = useHandler(() => {
    setSelectStep((value: StepId | undefined) => {
      if (value !== undefined) {
        valueChange(value);
      }
      setSelectStep(undefined);
    });
  });

  const onCreate = useHandler(() => {
    valueChange(create());
  });
  const content =
    value === "base" ? (
      <div className={selectCss.value}>
        <HomeIcon className={selectCss.icon} />
        <div className={selectCss.title}>Base question</div>
      </div>
    ) : step ? (
      <div className={selectCss.value}>
        <step.Icon className={selectCss.icon} />
        <div className={selectCss.title}>{step.title()}</div>
        <div className={selectCss.subtitle}>{step.description()}</div>
      </div>
    ) : (
      <>-</>
    );
  const dropdown = (
    <>
      <DropdownOption action={onExisting} disabled={disabled}>
        Use existing step
      </DropdownOption>
      <DropdownOption action={onCreate} disabled={disabled}>
        Create new step
      </DropdownOption>
      {type && type === ModelStep.CHAT_MULTIPLE_CHOICE && (
        <DropdownOption
          action={() => {
            valueChange("base");
            setSelectStep(undefined);
          }}
          disabled={disabled}
        >
          Base question
        </DropdownOption>
      )}
    </>
  );
  return (
    <ButtonDropdown dropdown={dropdown} onHover={onHover}>
      {content}
    </ButtonDropdown>
  );
});
