export function scrolled(
  el: Element,
  allowance: number = 1,
): { top: boolean; bottom: boolean } {
  return {
    bottom:
      el.scrollHeight - el.scrollTop - el.clientHeight < Math.max(1, allowance),
    top: el.scrollTop < 1,
  };
}
