import { RedoMerchantClient } from ".";

/**
 * POST /comments
 */
export async function returnCommentCreate(
  client: RedoMerchantClient,
  {
    message,
    returnId,
    signal,
  }: { message: string; returnId: string; signal?: AbortSignal },
) {
  const response = await client.client.post(
    "comments",
    { message },
    {
      headers: client.authorization(),
      signal,
      params: { return_id: returnId },
    },
  );
  return response.data;
}

/**
 * DELETE /comments
 */
export async function returnCommentDelete(
  client: RedoMerchantClient,
  {
    commentId,
    returnId,
    signal,
  }: { commentId: string; returnId: string; signal?: AbortSignal },
) {
  const response = await client.client.delete("comments", {
    headers: client.authorization(),
    params: { comment_id: commentId, return_id: returnId },
    signal,
  });
  return response.data;
}
