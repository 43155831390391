import { IterableMap } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import LeftArrowIcon from "@redotech/redo-web/icon-old/left-arrow.svg";
import QuestionIcon from "@redotech/redo-web/icon-old/question.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { groupBy } from "@redotech/util/map";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import * as abtreatmentCss from "./ab-test.module.css";
import { RequiredMessage } from "./required-message";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface TreatmentState {
  next: StepId | undefined;
  name: string;
  percentage: number;
}

export interface State {
  treatments: TreatmentState[];
  customTitle?: string;
  name: string;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handleAdd = useHandler(() =>
    setState((state) => ({
      ...state,
      treatments: [
        ...state.treatments,
        { name: "", next: undefined, percentage: 0 },
      ],
    })),
  );
  const handleFocus = useHandler((focused: boolean) => {
    if (!focused) {
      setState((state) => ({ ...state, attempted: true }));
    }
  });
  return (
    <>
      <LabeledInput errors={!state.name ? ["Required"] : []} label="Name">
        <TextInput
          onChange={(name) => setState((state) => ({ ...state, name }))}
          theme={InputTheme.FORM}
          value={state.name}
        />
      </LabeledInput>
      <IterableMap items={state.treatments} keyFn={(_, index) => index}>
        {(treatment, index) => (
          <StateTreatmentComponent
            canEditSettings={canEditSettings}
            handleFocus={handleFocus}
            index={index}
            setState={setState}
            state={state}
            stepSelect={stepSelect}
            treatment={treatment}
          />
        )}
      </IterableMap>
      <Divider />
      <div>
        <Button
          disabled={!canEditSettings}
          onClick={handleAdd}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          + Add treatment
        </Button>
      </div>
      {!state.treatments.length && (
        <RequiredMessage>Must have at least one treatment</RequiredMessage>
      )}
    </>
  );
});

const StateTreatmentComponent = memo(function StateTreatmentComponent({
  state,
  setState,
  stepSelect,
  handleFocus,
  canEditSettings,
  treatment,
  index,
}: {
  state: StepTypeDetailsProps<State>["state"];
  setState: StepTypeDetailsProps<State>["setState"];
  stepSelect: StepTypeDetailsProps<State>["stepSelect"];
  handleFocus(focused: boolean): void;
  canEditSettings: boolean;
  treatment: TreatmentState;
  index: number;
}) {
  const setName = useHandler((name: string) =>
    setState((state) =>
      produce(state, (state) => {
        state.treatments[index].name = name;
      }),
    ),
  );
  const setPercentage = useHandler((percentage: string) =>
    setState((state) =>
      produce(state, (state) => {
        state.treatments[index].percentage = Number(percentage);
      }),
    ),
  );
  const setStep = useHandler((next: StepId | undefined) =>
    setState((state) =>
      produce(state, (state) => {
        state.treatments[index].next = next;
      }),
    ),
  );
  const handleUp = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const treatments = state.treatments.splice(index, 1);
        state.treatments.splice(index - 1, 0, ...treatments);
      }),
    );
  });
  const handleDown = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const treatments = state.treatments.splice(index, 1);
        state.treatments.splice(index + 1, 0, ...treatments);
      }),
    );
  });
  const handleDelete = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        state.treatments.splice(index, 1);
      }),
    );
  });
  return (
    <>
      <Divider />
      <LabeledInput
        label={
          <div className={abtreatmentCss.label}>
            <div>Treatment #{index + 1}</div>
            {canEditSettings && (
              <>
                <IconButton
                  disabled={!index}
                  onClick={handleUp}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={abtreatmentCss.upArrow} />
                </IconButton>
                <IconButton
                  disabled={index === state.treatments.length - 1}
                  onClick={handleDown}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={abtreatmentCss.downArrow} />
                </IconButton>
                <IconButton onClick={handleDelete} size={ButtonSize.SMALL}>
                  <TrashIcon />
                </IconButton>
              </>
            )}
          </div>
        }
      >
        <LabeledInput errors={!treatment.name ? ["Required"] : []} label="Name">
          <TextInput
            error={!treatment.name}
            onChange={setName}
            onFocus={(focused: boolean) => {
              if (!treatment.name) {
                handleFocus(focused);
              }
            }}
            readonly={!canEditSettings}
            theme={InputTheme.FORM}
            value={treatment.name}
          />
        </LabeledInput>
        <LabeledInput
          errors={!treatment.percentage ? ["Required"] : []}
          label="Percentage"
        >
          <TextInput
            error={!treatment.percentage}
            max={100}
            min={0}
            onChange={setPercentage}
            onFocus={(focused: boolean) => {
              if (!treatment.percentage) {
                handleFocus(focused);
              }
            }}
            readonly={!canEditSettings}
            theme={InputTheme.FORM}
            type="number"
            value={String(treatment.percentage)}
          />
        </LabeledInput>
      </LabeledInput>
      <LabeledInput
        errors={!treatment.next ? ["Required"] : []}
        label={`Next step #${index + 1}`}
      >
        {stepSelect({ value: treatment.next, valueChange: setStep })}
      </LabeledInput>
    </>
  );
});

export const AB_TEST: StepType<State, ModelStep.ABTest> = {
  Details: Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "A/B test",
  valid(state: State) {
    return (
      !!state.treatments.length &&
      state.treatments.every((treatment) => treatment.name && treatment.next) &&
      state.treatments.every(
        (treatment) => treatment.percentage >= 0 && treatment.percentage <= 100,
      ) &&
      state.treatments.reduce(
        (acc, treatment) => acc + treatment.percentage,
        0,
      ) === 100
    );
  },
  Icon: QuestionIcon,
  description(state) {
    return "A/B test return flow";
  },
  downstream(state) {
    const treatments = groupBy(
      state.treatments.filter((treatment) => treatment.next !== undefined),
      (treatment) => treatment.next,
    );
    const result: StepDownstream[] = [];
    for (const [next, c] of treatments.entries()) {
      result.push({
        id: next!,
        label: c.length === 1 ? c[0].name : `${c.length} treatments`,
      });
    }
    return result;
  },
  empty: { treatments: [], name: "" },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      for (const treatment of state.treatments) {
        if (treatment.next === stepId) {
          treatment.next = undefined;
        }
      }
    });
  },
  toModel(state, id): ModelStep.ABTest {
    return {
      customTitle: state.customTitle,
      type: ModelStep.AB_TEST,
      name: state.name,
      treatments: state.treatments.map((treatment) => ({
        name: treatment.name,
        percentage: treatment.percentage,
        next: id(treatment.next!),
      })),
    };
  },
  fromModel(model: ModelStep.ABTest, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      name: model.name,
      treatments: model.treatments.map((treatment) => ({
        name: treatment.name,
        percentage: treatment.percentage,
        next: id(treatment.next),
      })),
    };
  },
};

export function getReturnReasonStep(
  returnReasonTitle: string,
): StepType<State, ModelStep.ABTest> {
  return {
    ...AB_TEST,
    title: returnReasonTitle,
    toModel(state, id): ModelStep.ABTest {
      return { ...AB_TEST.toModel(state, id) };
    },
  };
}
