import { Resource } from "@redotech/util/resource";
import { sleep } from "@redotech/util/schedule";
import { withObjectUrl } from "@redotech/web-util/url";

function withDownloadLink(
  url: string,
  filename?: string,
): Resource<HTMLAnchorElement> {
  return async (fn) => {
    const element = document.createElement("a");
    element.download = filename || "";
    element.href = url;
    element.style.display = "none";
    document.body.append(element);
    try {
      return await fn(element);
    } finally {
      element.remove();
    }
  };
}

export async function downloadBlob(blob: Blob, filename?: string) {
  console.log("Downloading");
  await withObjectUrl(blob)((url) =>
    withDownloadLink(
      url,
      filename,
    )(async (element) => {
      element.click();
      await sleep(new Temporal.Duration());
    }),
  );
}
