import { useHandler } from "@redotech/react-util/hook";
import { Step } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import WaitIcon from "@redotech/redo-web/icon-old/wait.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { produce } from "immer";
import { memo, useContext, useState } from "react";
import { UserContext } from "../../app/user";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  numDays: number;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State) {
  return state.numDays > 0 && !!state.next;
}

const Details = memo(function Details({
  state,
  stepSelect,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const [numDays, setNumDays] = useState<string>("1");

  const inputChange = (value: string) => {
    setNumDays(value);
    setState((state) => ({ ...state, numDays: parseInt(value) }));
  };

  return (
    <>
      <LabeledInput label="Delay (days)">
        <TextInput
          min={1}
          onChange={inputChange}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          type="number"
          value={numDays}
        />
      </LabeledInput>
      <LabeledInput errors={!state.next ? ["Required"] : []} label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const WAIT: StepType<State, Step.Wait> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Wait",
  valid,
  Icon: WaitIcon,
  description(state: State) {
    return `${state.numDays} day${state.numDays !== 1 ? "s" : ""}`;
  },
  empty: { numDays: 0, next: undefined },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): Step.Wait {
    return {
      customTitle: state.customTitle,
      type: Step.WAIT,
      numDays: state.numDays,
      next: id(state.next!),
    };
  },
  fromModel(model: Step.Wait, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      numDays: model.numDays,
      next: id(model.next),
    };
  },
};
