import { memo, ReactElement } from "react";
import { Flex } from "../../flex";
import * as avatarLabelGroupCss from "./avatar-label-group.module.css";
export type AvatarLabelGroupProps = {
  usernameRow: ReactElement;
  avatar: (props: any) => ReactElement;
  infoRow: ReactElement;
};

/** Arbiter-compliant Avatar label group. TODO support all sizes, variants, etc. as you need. */
export const AvatarLabelGroup = memo(function AvatarLabelGroup({
  usernameRow,
  avatar,
  infoRow,
}: AvatarLabelGroupProps) {
  return (
    <Flex
      align="center"
      className={avatarLabelGroupCss.labelContainer}
      grow="1"
    >
      {avatar({})}
      <Flex
        align="flex-start"
        className={avatarLabelGroupCss.rightInfo}
        dir="column"
      >
        {usernameRow}
        <Flex align="center" className={avatarLabelGroupCss.infoRow} gap="xs">
          {infoRow}
        </Flex>
      </Flex>
    </Flex>
  );
});
