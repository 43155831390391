import { RouteObject } from "react-router-dom";

export const shopifyRoutes: RouteObject[] = [
  {
    path: "install",
    async lazy() {
      const { ShopifyCallback } = await import("./callback");
      return { Component: ShopifyCallback };
    },
  },
];
