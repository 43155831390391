import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import EyeIcon from "@redotech/redo-web/icon-old/eye.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { produce } from "immer";
import { memo } from "react";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  next?: StepId;
  customTitle?: string;
}

function valid(state: State) {
  return state.next !== undefined;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });

  return (
    <LabeledInput errors={!state.next ? ["Required"] : []} label="Next step">
      {stepSelect({ value: state.next, valueChange: nextStepChange })}
    </LabeledInput>
  );
});

export const FINAL_SALE_RETURN: StepType<State, ModelStep.FinalSaleReturn> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Mark as final sale return",
  valid,
  // TODO: What should the icon be?
  Icon: EyeIcon,
  description() {
    return "Mark as final sale return";
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  empty: { next: undefined },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): ModelStep.FinalSaleReturn {
    return {
      type: ModelStep.FINAL_SALE_RETURN,
      next: state.next ? id(state.next) : undefined,
    };
  },
  fromModel(model, id) {
    return {
      next: model.next ? id(model.next) : undefined,
    };
  },
};
