import { ExpandedConversation } from "@redotech/redo-model/conversation";
import AlignHorizontalCenterIcon from "@redotech/redo-web/arbiter-icon/align-horizontal-centre-01_filled.svg";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Carousel } from "@redotech/redo-web/carousel";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Flex } from "@redotech/redo-web/flex";
import {
  Modal,
  ModalProps,
  ModalSize,
  PaddingAmount,
} from "@redotech/redo-web/modal";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo } from "react";
import * as mergeSuggestionSummaryModalCss from "./merge-suggestion-summary-modal.module.css";

export const MergeSuggestionSummaryModal = memo(
  function MergeSuggestionSummaryModal({
    activeConversation,
    suggestedConversations,
    selectedConversationIds,
    setSelectedConversationIds,
    onClose,
    onSubmit,
  }: Omit<ModalProps, "title" | "children" | "open"> & {
    activeConversation: ExpandedConversation;
    suggestedConversations: ExpandedConversation[];
    selectedConversationIds: Set<string>;
    setSelectedConversationIds: (selectedConversationIds: Set<string>) => void;
    onClose: () => void;
    onSubmit: () => void;
  }) {
    const title = (
      <Flex dir="column" gap="xl">
        <Flex
          className={mergeSuggestionSummaryModalCss.iconContainer}
          radius="full"
        >
          <AlignHorizontalCenterIcon style={{ color: "#4D35C6" }} width={20} />
        </Flex>
        <Flex dir="column" gap="xs">
          <Text fontSize="lg" fontWeight="semibold" textColor="primary">
            Ticket summaries
          </Text>
          <Text fontSize="sm" fontWeight="regular" textColor="tertiary">
            Select which tickets you would like to merge.
          </Text>
        </Flex>
      </Flex>
    );
    return (
      <Modal
        onClose={onClose}
        open
        paddingAmount={PaddingAmount.MEDIUM}
        showHeaderBorder={false}
        size={ModalSize.LARGE}
        title={title}
      >
        <Carousel gap="3xl" showArrows verticalGap="3xl">
          {[activeConversation, ...suggestedConversations].map(
            (conversation, index) => (
              <Flex
                align="flex-start"
                className={classNames(
                  index === 0
                    ? mergeSuggestionSummaryModalCss.activeConversationContainer
                    : mergeSuggestionSummaryModalCss.conversationContainer,
                )}
                dir="column"
                gap="lg"
                key={conversation._id}
                p="3xl"
                radius="xl"
              >
                <Checkbox
                  onChange={(_) => {
                    selectedConversationIds.has(conversation._id)
                      ? selectedConversationIds.delete(conversation._id)
                      : selectedConversationIds.add(conversation._id);
                    setSelectedConversationIds(
                      new Set(selectedConversationIds),
                    );
                  }}
                  value={selectedConversationIds.has(conversation._id)}
                />
                <Text>
                  {index === 0
                    ? "Current ticket"
                    : `Suggested ticket #${index}`}
                </Text>
                <RedoSupportChannelBadge platform={conversation.platform} />
                <Text
                  className={mergeSuggestionSummaryModalCss.conversationPreview}
                  textOverflow="ellipsis"
                >
                  {conversation.messages[0].content}
                </Text>
              </Flex>
            ),
          )}
        </Carousel>
        <Flex dir="row" gap="lg" justify="center" pt="4xl">
          <Button
            className={mergeSuggestionSummaryModalCss.button}
            onClick={onClose}
            theme={ButtonTheme.OUTLINED}
          >
            <Text as="span" fontWeight="semibold">
              No, do not merge tickets
            </Text>
          </Button>
          <Button
            className={mergeSuggestionSummaryModalCss.button}
            disabled={selectedConversationIds.size < 2}
            onClick={onSubmit}
            theme={ButtonTheme.PRIMARY}
          >
            <Text as="span" fontWeight="semibold">
              Yes, merge tickets
            </Text>
          </Button>
        </Flex>
      </Modal>
    );
  },
);
