import { CopyableText } from "@redotech/redo-web/copyable-text";
import { Flex } from "@redotech/redo-web/flex";
import * as classNames from "classnames";
import { memo } from "react";
import * as orderDetailsCss from "./action-panel/order-details/order-details.module.css";

export const ProductDetailCard = memo(function ProductDetailCard({
  imageName,
  imageSrc,
  price,
  item,
  quantity,
  variant,
}: {
  imageName: string;
  imageSrc: string;
  price: string;
  item: string;
  quantity: number | string;
  variant: string;
}) {
  return (
    <div
      className={classNames(
        orderDetailsCss.product,
        orderDetailsCss.wrappingText,
      )}
    >
      <Flex align="center" className={orderDetailsCss.wrappingText}>
        <Flex>
          {imageSrc && (
            <div className={orderDetailsCss.imgContainer}>
              <img alt={imageName} src={imageSrc} />
            </div>
          )}
        </Flex>
        <Flex className={orderDetailsCss.wrappingText} grow="1">
          <Flex
            className={classNames(
              orderDetailsCss.wrappingText,
              orderDetailsCss.tightText,
            )}
            dir="column"
          >
            <CopyableText fontSize="xs" fontWeight="medium" textColor="primary">
              {price}
            </CopyableText>
            <Flex className={orderDetailsCss.wrappingText}>
              <Flex className={orderDetailsCss.wrappingText}>
                <CopyableText
                  fontSize="xs"
                  fontWeight="medium"
                  overflow="hidden"
                  placement="left"
                  textColor="primary"
                  textOverflow="ellipsis"
                >
                  {item}
                </CopyableText>
              </Flex>
            </Flex>
            <CopyableText fontSize="xxs" textColor="quaternary">
              {`${quantity}${variant !== "Default Title" && !!variant ? "  |  " + variant : ""}`}
            </CopyableText>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
});
