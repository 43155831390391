import { useHandler } from "@redotech/react-util/hook";
import {
  InfoToCollect,
  Step as ModelStep,
} from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import QuestionIcon from "@redotech/redo-web/icon-old/question.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { produce } from "immer";
import * as capitalize from "lodash/capitalize";
import Quill from "quill";
import { memo, useContext, useRef } from "react";
import { UserContext } from "../../app/user";
import { ClientUploader } from "../../client/upload";
import {
  StepDownstream,
  StepId,
  StepType,
  StepTypeDetailsProps,
} from "../return-flow/step";
import { containsImg, StepRichTextEditor } from "./step-rich-text";

export interface State {
  next: StepId | undefined;
  message: string;
  messageHtml?: string;
  attempted?: boolean;
  infoToCollect: InfoToCollect;
  customTitle?: string;
}

function valid(state: State) {
  return (
    state.next !== undefined &&
    !!state.message &&
    !containsImg(state.messageHtml)
  );
}

const options: InfoToCollect[] = [
  InfoToCollect.NAME,
  InfoToCollect.EMAIL,
  InfoToCollect.CUSTOM,
];

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

  const messageQuillRef = useRef<Quill | undefined>(undefined);

  const handleInfoToCollectChange = useHandler(
    (infoToCollect: InfoToCollect) => {
      setState((state) => ({ ...state, infoToCollect }));
    },
  );

  const handleMessageChange = useHandler(() => {
    if (!messageQuillRef.current) {
      return;
    }
    let message = messageQuillRef.current.getText();
    let messageHtml: string | undefined =
      messageQuillRef.current.getSemanticHTML();
    if (message === "\n") {
      message = "";
    }
    if (messageHtml === "<p></p>") {
      messageHtml = undefined;
    }

    if (messageHtml !== state.messageHtml || message !== state.message) {
      setState((state) => ({ ...state, message, messageHtml }));
    }
  });

  return (
    <ClientUploader>
      <LabeledInput label="Info to collect">
        <SelectDropdown
          disabled={!canEditSettings}
          options={options}
          value={state.infoToCollect}
          valueChange={handleInfoToCollectChange}
        >
          {(option) => {
            if (option === InfoToCollect.NAME) {
              return "Full name";
            } else {
              return capitalize(option);
            }
          }}
        </SelectDropdown>
      </LabeledInput>
      <LabeledInput
        errors={containsImg(state.messageHtml) ? ["Cannot contain images"] : []}
        label="Message to customer"
      >
        <StepRichTextEditor
          htmlValue={state.messageHtml}
          onTextChange={handleMessageChange}
          quillRef={messageQuillRef}
          readOnly={!canEditSettings}
          textValue={state.message}
        />
      </LabeledInput>
      <LabeledInput errors={!state.next ? ["Required"] : []} label="Next">
        {stepSelect({
          value: state.next,
          valueChange: (next) => setState({ ...state, next }),
        })}
      </LabeledInput>
    </ClientUploader>
  );
});

export const CUSTOMER_RESPONSE: StepType<State, ModelStep.CustomerResponse> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Customer response",
  valid,
  Icon: QuestionIcon,
  description(state: State) {
    return state.message;
  },
  downstream(state: State) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  layout() {
    return BlockLayout.FULL;
  },
  empty: {
    message: "",
    messageHtml: undefined,
    next: undefined,
    infoToCollect: InfoToCollect.CUSTOM,
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  toModel(
    state: State,
    id: (id: StepId) => number,
  ): ModelStep.CustomerResponse {
    return {
      customTitle: state.customTitle,
      type: ModelStep.CUSTOMER_RESPONSE,
      next: id(state.next!),
      message: state.message,
      messageHtml: state.messageHtml,
      infoToCollect: state.infoToCollect,
    };
  },
  fromModel(model: ModelStep.CustomerResponse, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      next: id(model.next),
      message: model.message,
      messageHtml: model.messageHtml,
      infoToCollect: model.infoToCollect,
    };
  },
};
