// extracted by mini-css-extract-plugin
export var danger = "_35-6";
export var lightPink = "_35-a";
export var medium = "_35-j";
export var nearSquare = "_35-m";
export var neutralBlue = "_35-8";
export var neutralYellow = "_35-7";
export var noCapitalize = "_35-1";
export var normal = "_35-2";
export var pill = "_35-0";
export var primary = "_35-3";
export var primaryLight = "_35-4";
export var shadow = "_35-n";
export var small = "_35-k";
export var solidBlack = "_35-i";
export var solidGreen = "_35-d";
export var solidLightBlue = "_35-f";
export var solidNavy = "_35-e";
export var solidOrange = "_35-h";
export var solidPink = "_35-g";
export var solidRed = "_35-b";
export var solidWhite = "_35-9";
export var solidYellow = "_35-c";
export var success = "_35-5";
export var xsmall = "_35-l";