import { abortChild } from "./abort";

/**
 * Promise.all(), but it aborts and wait for the other promises.
 */
export async function promiseAllAbortable<T extends readonly unknown[] | []>(
  fn: (signal: AbortSignal) => T,
  signal?: AbortSignal,
): Promise<{ -readonly [P in keyof T]: Awaited<T[P]> }> {
  return await abortChild(signal)(async (controller) => {
    const promises = fn(controller.signal);
    try {
      return await Promise.all(promises);
    } catch (e) {
      controller.abort(e);
      throw e;
    } finally {
      await Promise.allSettled(promises);
    }
  });
}

/**
 * Use this function when you want to fire a promise, but don't care about its result
 *
 * Will execute the promise and sink the exception if it is rejected
 */
export function sinkPromise(promise: Promise<any> | undefined): void {
  promise?.catch(() => {});
}
