import { Onboarding } from "@redotech/redo-model/onboarding";
import { RedoMerchantClient } from "./index";

export async function getCurrentOnboarding(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<Onboarding> {
  const response = await client.client.get("onboarding", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

export async function onboardingTc(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.put(
    "onboarding/tc",
    {},
    { headers: client.authorization(), signal },
  );
  return response.data;
}
