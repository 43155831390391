import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { ManagedClaimStatus } from "@redotech/redo-merchant-server/rpc/schema/get-managed-claim-status-schema";
import { MerchantAppReturn } from "@redotech/redo-model/return";
import {
  RedoBadge,
  RedoBadgeColor,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Card, CardSize } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";

const claimStatusBadgeColor = {
  [ManagedClaimStatus.SUBMITTED]: RedoBadgeColor.GRAY,
  [ManagedClaimStatus.UNDER_REVIEW]: RedoBadgeColor.GRAY,
  [ManagedClaimStatus.PENDING_DOCS]: RedoBadgeColor.GRAY,
  [ManagedClaimStatus.CLOSED]: RedoBadgeColor.GRAY,
  [ManagedClaimStatus.NOT_PROTECTED]: RedoBadgeColor.GRAY,
  [ManagedClaimStatus.APPROVED]: RedoBadgeColor.SUCCESS,
  [ManagedClaimStatus.NOT_SUBMITTED]: RedoBadgeColor.WARNING,
  [ManagedClaimStatus.REJECTED]: RedoBadgeColor.ERROR,
};

export const ManagedClaimStatusCard = memo(
  function ManagedManagedClaimStatusCard({
    return: return_,
  }: {
    return?: MerchantAppReturn;
  }) {
    const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);

    const managedClaimStatusLoad = useLoad(
      async (signal) => {
        if (!return_) {
          return null;
        }

        return await rpcClient.getManagedClaimStatus(
          {
            returnId: return_.id,
          },
          { signal },
        );
      },
      [return_],
    );

    const managedClaimLoading =
      managedClaimStatusLoad.pending ||
      (managedClaimStatusLoad.value === null && !managedClaimStatusLoad.error);
    const managedClaimStatus =
      managedClaimStatusLoad.value?.status ?? ManagedClaimStatus.NOT_SUBMITTED;

    return (
      <Card size={CardSize.MEDIUM} title="Insurance details">
        {managedClaimLoading ? (
          <SkeletonText length={30} />
        ) : (
          <>
            <Flex flexDirection="row" gap="sm">
              <Text fontSize="sm" fontWeight="semibold">
                Insurance provider:
              </Text>
              <Text fontSize="sm">Shipsurance</Text>
            </Flex>
            <Flex flexDirection="row" gap="sm">
              <Text fontSize="sm" fontWeight="semibold">
                Status:
              </Text>
              <RedoBadge
                color={claimStatusBadgeColor[managedClaimStatus]}
                text={managedClaimStatus}
              />
            </Flex>
          </>
        )}
      </Card>
    );
  },
);
