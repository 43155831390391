// extracted by mini-css-extract-plugin
export var buttons = "_4f-0";
export var buttonsActive = "_4f-h";
export var cancelConfirm = "_4f-1";
export var closeButton = "_4f-2";
export var column = "_4f-3";
export var container = "_4f-4";
export var detailHeader = "_4f-6";
export var detailTitle = "_4f-7";
export var detailsContent = "_4f-5";
export var header = "_4f-8";
export var headerWithClose = "_4f-9";
export var icon = "_4f-a";
export var infoIcon = "_4f-c";
export var informationContainer = "_4f-b";
export var sideDrawer = "_4f-d";
export var sideDrawerOpen = "_4f-e";
export var stickyFooter = "_4f-f";
export var typeOption = "_4f-g";