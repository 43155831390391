import { z } from "zod";

export const getCustomerAccountsMetricsForOverviewSchema = {
  input: z.object({
    teamId: z.string(),
    startDate: z.date(),
    endDate: z.date(),
  }),
  output: z.object({
    signedInCustomerSessions: z.number(),
    totalCustomerSessions: z.number(),
    newAccountsCreated: z.number(),
  }),
};

export type GetCustomerAccountsMetricsForOverviewOutputSchemaType = z.infer<
  typeof getCustomerAccountsMetricsForOverviewSchema.output
>;
