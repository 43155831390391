// Optional protocol, domain + subdomains, top-level domain, optional path + query
const linkRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

export function isValidLink(link: string): boolean {
  return linkRegex.test(link.toLowerCase());
}

export function ensureProtocolPresent(link: string): string {
  if (!/^https?:\/\//i.test(link)) {
    return `https://${link}`;
  }
  return link;
}
