// extracted by mini-css-extract-plugin
export var actionButton = "_8l-0";
export var bubble = "_8l-2";
export var bubbleContext = "_8l-6";
export var bubbleItems = "_8l-7";
export var customer = "_8l-3";
export var divider = "_8l-8";
export var fileList = "_8l-9";
export var infoAbove = "_8l-a";
export var internal = "_8l-4";
export var lightActionButton = "_8l-1";
export var link = "_8l-b";
export var merchant = "_8l-5";
export var message = "_8l-c";
export var profilePicture = "_8l-e";
export var quillToolbarContainer = "_8l-f";
export var senderDate = "_8l-g";
export var senderDateCustomer = "_8l-h";
export var senderName = "_8l-d";
export var time = "_8l-i";
export var uploadedImage = "_8l-j";
export var username = "_8l-k";
export var xsmall = "_8l-l";