import { NonIndexRouteObject } from "react-router-dom";

export const conciergeSettingsRoutes: NonIndexRouteObject = {
  path: "concierge",
  children: [
    {
      handle: { breadcrumb: "Data sources" },
      path: "data-sources",
      lazy: async () => {
        const { ContextSettingsPage } = await import("./context-settings");
        return { Component: ContextSettingsPage };
      },
    },
    {
      path: "general",
      handle: { breadcrumb: "General" },
      lazy: async () => {
        const { GeneralBehaviorPage } = await import("./general-behavior");
        return { Component: GeneralBehaviorPage };
      },
    },
  ],
};
