import { ClientOptions, createRpcClient } from "@redotech/rpc/client";
import { InferRpcDefinition } from "@redotech/rpc/definition";
import { getWarrantyEligibleProductsSchema } from "@redotech/server/utils/warranties/get-warranty-eligible-products-schema";
import { submitWarrantyRegistrationSchema } from "@redotech/server/utils/warranties/submit-warranty-registration-schema";
import { getAdvancedFlowByIdSchema } from "./advanced-flows/get-advanced-flow-by-id-schema";
import { calculateNewOrderValueSchema } from "./calculate-new-order-value-schema";
import { getConciergeBehaviorSettingsSchema } from "./concierge/get-concierge-behavior-settings-schema";
import { getConciergeConversationsSchema } from "./concierge/get-concierge-conversations-schema";
import { getConciergeDashboardMetricsSchema } from "./concierge/get-concierge-dashboard-metrics-schema";
import { getContextSettingsSchema } from "./concierge/get-context-settings-schema";
import { setContextSettingsSchema } from "./concierge/set-context-settings-schema";
import { updateConciergeBehaviorSettingsSchema } from "./concierge/update-concierge-behavior-settings-schema";
import {
  createCustomReport,
  deleteCustomReport,
  getCustomReport,
  getCustomReports,
  updateCustomReport,
} from "./custom-reports";
import { disconnectRivoIntegrationSchema } from "./customer-accounts/rivo/disconnect-rivo-integration-schema";
import { getRivoIntegrationSchema } from "./customer-accounts/rivo/get-rivo-integration-schema";
import { setRivoIntegrationSchema } from "./customer-accounts/rivo/set-rivo-integration-schema";
import { createArticleSchema } from "./faq/create-article-schema";
import { createCollectionSchema } from "./faq/create-collection-schema";
import { deleteArticleSchema } from "./faq/delete-article-schema";
import { deleteCollectionSchema } from "./faq/delete-collection-schema";
import { getFaqSchema } from "./faq/get-faq-schema";
import { updateArticleSchema } from "./faq/update-article-schema";
import { updateCollectionSchema } from "./faq/update-collection-schema";
import { updateThemeSchema } from "./faq/update-theme-schema";
import { getBundlesDataSchema } from "./get-bundles-data-schema";
import { getCustomerAccountsMetricsForOverviewSchema } from "./get-customer-accounts-metrics-for-overview-schema";
import { getEmailTemplateNamesSchema } from "./get-email-template-names-schema";
import { getManagedClaimStatusSchema } from "./get-managed-claim-status-schema";
import { getOrdersSchema } from "./get-orders-schema";
import { getReturnsInstantExchangeRecoveryHistorySchema } from "./get-returns-instant-exchange-recovery-history-schema";
import { getReviewsAverageByProjectSchema } from "./get-reviews-average-by-project";
import { getReviewsByProductIdSchema } from "./get-reviews-by-product-id-schema";
import { getReviewsByTeamIdSchema } from "./get-reviews-by-team-id-schema";
import { getReviewsSchema } from "./get-reviews-schema";
import { getSaveForLaterStorefrontDisplaySettingsSchema } from "./get-save-for-later-storefront-display-settings-schema";
import { getIndustryCategoryComparisonStatsSchema } from "./industry-category/get-industry-category-comparison-stats-schema";
import { getValidIndustryCategoriesSchema } from "./industry-category/get-valid-industry-categories-schema";
import { issueStoreCreditToCustomerSchema } from "./issue-store-credit-to-customer-schema";
import { createMerchantPolicySchema } from "./merchant-policies/create-merchant-policy-schema";
import { deleteMerchantPolicySchema } from "./merchant-policies/delete-merchant-policy-schema";
import { editMerchantPolicySchema } from "./merchant-policies/edit-merchant-policy-schema";
import { getMerchantPoliciesSchema } from "./merchant-policies/get-merchant-policies-schema";
import { searchProductsSchema } from "./products/search-products";
import { recreateDiscountsSchema } from "./recreate-discounts-schema";
import { removeVariantExchangeItemSchema } from "./remove-variant-exchange-item-schema";
import { resendEmailSchema } from "./resend-email-schema";
import { restockItemsForReturnSchema } from "./restock/restock-items-for-return-schema";
import { setReturnItemDispositionSchema } from "./return-item-disposition/set-return-item-disposition";
import { createReviewFormSchema } from "./review-form/create-review-form-schema";
import { deleteReviewFormSchema } from "./review-form/delete-review-form-schema";
import { getActiveReviewFormForTeamSchema } from "./review-form/get-active-review-form-for-team-schema";
import { getReviewFormSchema } from "./review-form/get-review-form-schema";
import { getReviewFormsForTeamSchema } from "./review-form/get-review-forms-for-team-schema";
import { updateReviewFormSchema } from "./review-form/update-review-form-schema";
import { getRedoSalesChannelsSchema } from "./sales-channels/get-redo-sales-channels-schema";
import { getSalesChannelOptionsSchema } from "./sales-channels/get-sales-channel-options-schema";
import { setRedoSalesChannelsSchema } from "./sales-channels/set-redo-sales-channels-schema";
import { searchProductVariantsSchema } from "./search-product-variants-schema";
import { createSignupFormSchema } from "./signup-form/create-signup-form-schema";
import { deleteSignupFormSchema } from "./signup-form/delete-signup-form-schema";
import { getMarketingSubscriberCountsSchema } from "./signup-form/get-marketing-subscriber-counts-schema";
import { getSignupFormByIdSchema } from "./signup-form/get-signup-form-by-id-schema";
import { getSignupFormsByTeamIdSchema } from "./signup-form/get-signup-forms-by-team-id-schema";
import { unpublishSignupFormSchema } from "./signup-form/unpublish-signup-form-schema";
import { updatePublishedAtSchema } from "./signup-form/update-published-at";
import { updateSignupFormSchema } from "./signup-form/update-signup-form-schema";
import { verifyOrCreateNotOnSaleCollectionSchema } from "./signup-form/verify-or-create-not-on-sale-collection-schema";
import { createCallAvailabilitySchema } from "./support/create-call-availability-schema";
import {
  getCsatResponsesForConversationSchema,
  getCsatResponsesForTeamCountSchema,
  getCsatResponsesForTeamSchema,
} from "./support/csat-schema";
import { getTeamCallAvailabilitiesSchema } from "./support/get-team-call-availabilities-schema";
import { getPhoneNumberForTeamSchema } from "./support/phone-number-schema";
import { polishMessageSchema } from "./support/polish-message-schema";
import { getTelnyxTelephonyTokenSchema } from "./support/telnyx-telephony-token-schema";
import { toggleCallOnHoldSchema } from "./support/toggle-call-on-hold-schema";
import { updateNeworderItemsSchema } from "./update-new-order-items-schema";
import { updateReviewSchema } from "./update-review-schema";
import { updateSaveForLaterStorefrontDisplaySettingsSchema } from "./update-save-for-later-storefront-display-settings-schema";
import { getUpsellPageAnalyticsSchema } from "./upsell-page-analytics.ts/get-upsell-page-analytics";
import { createUpsellPageSchema } from "./upsell-page/create-upsell-page-schema";
import { deleteUpsellPageSchema } from "./upsell-page/delete-upsell-page-schema";
import { getActiveUpsellPageForTeamSchema } from "./upsell-page/get-active-upsell-page-for-team-schema";
import { getUpsellPageSchema } from "./upsell-page/get-upsell-page-schema";
import { updateUpsellPageSchema } from "./upsell-page/update-upsell-page-schema";

const reviewForm1 = {
  createReviewForm: createReviewFormSchema,
  deleteReviewForm: deleteReviewFormSchema,
  updateReviewForm: updateReviewFormSchema,
};

const reviewForm2 = {
  getReviewForm: getReviewFormSchema,
  getReviewFormsForTeam: getReviewFormsForTeamSchema,
};

const reviewForm3 = {
  getActiveReviewFormForTeam: getActiveReviewFormForTeamSchema,
};

const upsellPage1 = {
  createUpsellPage: createUpsellPageSchema,
  deleteUpsellPage: deleteUpsellPageSchema,
  updateUpsellPage: updateUpsellPageSchema,
};

const upsellPage2 = {
  getUpsellPage: getUpsellPageSchema,
  //getUpsellPagesForTeam: getUpsellPagesForTeamSchema,
};

const upsellPage3 = {
  getActiveUpsellPageForTeam: getActiveUpsellPageForTeamSchema,
};

const upsellPageAnalytics = {
  getUpsellPageAnalytics: getUpsellPageAnalyticsSchema,
};

const industryCategory = {
  getValidIndustryCategories: getValidIndustryCategoriesSchema,
  getIndustryCategoryComparisonStats: getIndustryCategoryComparisonStatsSchema,
};

const returnItemDisposition = {
  setReturnItemDisposition: setReturnItemDispositionSchema,
};

const redoMerchantRPC1 = {
  issueStoreCreditToCustomer: issueStoreCreditToCustomerSchema,
  getCustomerAccountsMetricsForOverview:
    getCustomerAccountsMetricsForOverviewSchema,
  getSaveForLaterStorefrontDisplaySettings:
    getSaveForLaterStorefrontDisplaySettingsSchema,
  updateSaveForLaterStorefrontDisplaySettings:
    updateSaveForLaterStorefrontDisplaySettingsSchema,
  resendEmail: resendEmailSchema,
  getReviewsByProductId: getReviewsByProductIdSchema,
  getEmailTemplateNames: getEmailTemplateNamesSchema,
  getReviewsByTeamId: getReviewsByTeamIdSchema,
  getReviews: getReviewsSchema,
  updateReview: updateReviewSchema,
  getReviewsAverageByProject: getReviewsAverageByProjectSchema,
  getReturnsInstantExchangeRecoveryHistory:
    getReturnsInstantExchangeRecoveryHistorySchema,
  getOrders: getOrdersSchema,
  getCustomReport: getCustomReport,
  getCustomReports: getCustomReports,
  createCustomReport: createCustomReport,
  updateCustomReport: updateCustomReport,
  deleteCustomReport: deleteCustomReport,
};

const support = {
  createCallAvailability: createCallAvailabilitySchema,
  getTeamCallAvailabilities: getTeamCallAvailabilitiesSchema,
  polishMessage: polishMessageSchema,
  getCsatResponsesForConversation: getCsatResponsesForConversationSchema,
  getCsatResponsesForTeam: getCsatResponsesForTeamSchema,
  getCsatResponsesForTeamCount: getCsatResponsesForTeamCountSchema,
  getPhoneNumberForTeam: getPhoneNumberForTeamSchema,
  getTelnyxTelephonyToken: getTelnyxTelephonyTokenSchema,
  toggleCallOnHold: toggleCallOnHoldSchema,
};

const faq = {
  getFaq: getFaqSchema,
  createArticle: createArticleSchema,
  updateArticle: updateArticleSchema,
  updateTheme: updateThemeSchema,
  createCollection: createCollectionSchema,
  updateCollection: updateCollectionSchema,
  deleteArticle: deleteArticleSchema,
  deleteCollection: deleteCollectionSchema,
};

const restock = {
  restockItemsForReturn: restockItemsForReturnSchema,
};

const concierge = {
  updateConciergeBehaviorSettings: updateConciergeBehaviorSettingsSchema,
  getConciergeBehaviorSettings: getConciergeBehaviorSettingsSchema,
  getConciergeConversations: getConciergeConversationsSchema,
  getConciergeContextSettings: getContextSettingsSchema,
  setConciergeContextSettings: setContextSettingsSchema,
  getConciergeDashboardMetrics: getConciergeDashboardMetricsSchema,
};

const redoMerchantRPC2 = {
  createSignupForm: createSignupFormSchema,
  getSignupFormById: getSignupFormByIdSchema,
  updateSignupForm: updateSignupFormSchema,
  getSignupFormsByTeamId: getSignupFormsByTeamIdSchema,
  getMarketingSubscriberCounts: getMarketingSubscriberCountsSchema,
  unpublishSignupForm: unpublishSignupFormSchema,
  updatePublishedAt: updatePublishedAtSchema,
  deleteSignupForm: deleteSignupFormSchema,
  getSalesChannelOptions: getSalesChannelOptionsSchema,
  getRedoSalesChannels: getRedoSalesChannelsSchema,
  setRedoSalesChannels: setRedoSalesChannelsSchema,
  updateNewOrderItems: updateNeworderItemsSchema,
  removeVariantExchangeItem: removeVariantExchangeItemSchema,
  getBundlesData: getBundlesDataSchema,
  searchProductVariants: searchProductVariantsSchema,
  getWarrantyEligibleProducts: getWarrantyEligibleProductsSchema,
  submitWarrantyRegistration: submitWarrantyRegistrationSchema,
  getRivoIntegration: getRivoIntegrationSchema,
  setRivoIntegration: setRivoIntegrationSchema,
  disconnectRivoIntegration: disconnectRivoIntegrationSchema,
  calculateNewOrderValue: calculateNewOrderValueSchema,
  recreateDiscounts: recreateDiscountsSchema,
  verifyOrCreateNotOnSaleCollection: verifyOrCreateNotOnSaleCollectionSchema,
  searchProducts: searchProductsSchema,
  getAdvancedFlowById: getAdvancedFlowByIdSchema,
};

const merchantPolicies = {
  getMerchantPolicies: getMerchantPoliciesSchema,
  deleteMerchantPolicy: deleteMerchantPolicySchema,
  createMerchantPolicy: createMerchantPolicySchema,
  editMerchantPolicy: editMerchantPolicySchema,
};

const managedClaims = {
  getManagedClaimStatus: getManagedClaimStatusSchema,
};

/** This type is necessary because redoMerchantRPC is too
 *  big for typescript to infer its type. */
type MerchantRpcType = typeof redoMerchantRPC1 &
  typeof redoMerchantRPC2 &
  typeof reviewForm1 &
  typeof reviewForm2 &
  typeof reviewForm3 &
  typeof upsellPage1 &
  typeof upsellPage2 &
  typeof upsellPage3 &
  typeof upsellPageAnalytics &
  typeof industryCategory &
  typeof returnItemDisposition &
  typeof merchantPolicies &
  typeof concierge &
  typeof support &
  typeof faq &
  typeof restock &
  typeof managedClaims;

export const redoMerchantRPC: MerchantRpcType = {
  ...redoMerchantRPC1,
  ...redoMerchantRPC2,
  ...reviewForm1,
  ...reviewForm2,
  ...reviewForm3,
  ...upsellPage1,
  ...upsellPage2,
  ...upsellPage3,
  ...upsellPageAnalytics,
  ...industryCategory,
  ...returnItemDisposition,
  ...merchantPolicies,
  ...concierge,
  ...support,
  ...faq,
  ...restock,
  ...managedClaims,
};

export type RedoMerchantRpcDefinition = InferRpcDefinition<
  typeof redoMerchantRPC
>;

export const createRedoMerchantRpcClient = (
  options: ClientOptions<typeof redoMerchantRPC>,
) => createRpcClient(redoMerchantRPC, options);

export type RedoMerchantRpcClient = ReturnType<
  typeof createRedoMerchantRpcClient
>;
