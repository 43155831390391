import { refMerged } from "@redotech/react-util/ref";
import * as classnames from "classnames";
import {
  Key,
  ReactNode,
  Ref,
  forwardRef,
  memo,
  useContext,
  useRef,
} from "react";
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition";
import * as outletFadeCss from "./outlet-fade.module.css";
import {
  OutletCssTransition,
  OverlapTransitionStateContext,
} from "./outlet-transition";
import { OverlapTransitionState } from "./transition";

const transitionClassNames: CSSTransitionClassNames = {
  enter: outletFadeCss.outletEnter,
  enterActive: outletFadeCss.outletEnterActive,
  exit: outletFadeCss.outletExit,
  exitActive: outletFadeCss.outletExitActive,
};

const transitionDuration = 400;

export const OutletFade = memo(function OutletFade({
  element: Element = "div",
  childClassName,
  containerClassName,
  key_,
  children,
  style,
}: {
  element?: keyof JSX.IntrinsicElements;
  childClassName?: string;
  containerClassName?: string;
  key_: Key;
  children: ReactNode;
  style?: React.CSSProperties;
}) {
  containerClassName = classnames(
    outletFadeCss.container,
    containerClassName && {
      [containerClassName]: outletFadeCss.container,
    },
  );
  return (
    <Element className={containerClassName} style={style}>
      <OutletCssTransition
        classNames={transitionClassNames}
        key_={key_}
        outlet={children}
        timeout={transitionDuration}
      >
        {(ref, children) => (
          <OutletFadeChild childClassName={childClassName} ref={ref}>
            {children}
          </OutletFadeChild>
        )}
      </OutletCssTransition>
    </Element>
  );
});

const OutletFadeChild = forwardRef<
  HTMLElement,
  { childClassName?: string; children: ReactNode | ReactNode[] }
>(function OutletFadeChild({ childClassName, children }, forwardedRef) {
  childClassName = classnames(
    outletFadeCss.outlet,
    childClassName && {
      [childClassName]: outletFadeCss.outlet,
    },
  );
  const state = useContext(OverlapTransitionStateContext);

  const ref = useRef<HTMLElement>(null);

  const r = refMerged([forwardedRef, ref]);
  const size = useRef<{ height: number; width: number } | null>(null);

  if (state !== OverlapTransitionState.EXIT) {
    size.current = null;
  } else if (!size.current && ref.current) {
    size.current = ref.current && {
      height: ref.current.clientHeight,
      width: ref.current.clientWidth,
    };
  }

  return (
    <div
      className={childClassName}
      ref={r as Ref<HTMLDivElement>}
      style={{
        height: size.current !== null ? `${size.current.height}px` : undefined,
        width: size.current !== null ? `${size.current.width}px` : undefined,
      }}
      {...{
        inert: state === OverlapTransitionState.EXIT ? "" : undefined,
      }}
    >
      {children}
    </div>
  );
});
