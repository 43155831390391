import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import {
  isAtLeastOneMacroAutomationActive,
  MacroAutomation,
  MacroAutomationsData,
  SnoozeDuration,
} from "@redotech/redo-model/macro";
import { Team } from "@redotech/redo-model/team";
import { UpdateConversationBody } from "@redotech/redo-model/updateconversationbody";
import { assertNever } from "@redotech/util/type";
import { RedoMerchantClient } from "../../client";
import {
  changeDraftStatus,
  deleteMessageDraft,
  updateConversation,
  upsertMessageDraft,
} from "../../client/conversations";
import { AddInternalNoteAutomation } from "./macro-automations/add-internal-note";
import { PerformForwardMessageAutomation } from "./macro-automations/forward-message";
/**
 * If no automations were run, returns undefined
 */
export async function performMacroAutomationsBeforeSendingMessage({
  client,
  automations,
  conversation,
  team,
  addOrReplaceMessage,
  internalNoteDraftAddedOptimisticUpdateCallback,
  shouldSetSubjectOnConversation = true,
}: {
  client: RedoMerchantClient;
  automations: MacroAutomationsData;
  conversation: ExpandedConversation;
  team: Team;
  addOrReplaceMessage?: (
    message: ExpandedConversationMessage,
    conversationUpdatedAt: string,
  ) => void;
  internalNoteDraftAddedOptimisticUpdateCallback?: (
    draftMessage: ExpandedConversationMessage,
  ) => void;
  shouldSetSubjectOnConversation?: boolean;
}): Promise<{
  conversationAfterMacros: ExpandedConversation | undefined;
  noteMessage: ExpandedConversationMessage | undefined;
}> {
  if (!isAtLeastOneMacroAutomationActive(automations)) {
    return { conversationAfterMacros: undefined, noteMessage: undefined };
  }
  let noteMessage: ExpandedConversationMessage | undefined;
  const newConversationProperties: UpdateConversationBody = {};

  const performAutomations: Record<
    MacroAutomation,
    | ((
        newConversationProperties: UpdateConversationBody,
      ) => Promise<void> | void)
    | undefined
  > = {
    [MacroAutomation.SET_STATUS]: (newConversationProperties) => {
      let newStatus: "open" | "closed" | undefined = undefined;

      if (automations.statusToSet && automations.statusToSet !== "snoozed") {
        newStatus = automations.statusToSet;
      }

      let snoozedUntil: string | undefined = undefined;
      if (automations.snoozeDuration && automations.statusToSet === "snoozed") {
        switch (automations.snoozeDuration) {
          case SnoozeDuration.SAME_DAY: {
            const today = new Date();
            today.setHours(18, 0, 0);
            snoozedUntil = today.toISOString();
            break;
          }
          case SnoozeDuration.ONE_DAY: {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(8, 0, 0);
            snoozedUntil = tomorrow.toISOString();
            break;
          }
          case SnoozeDuration.TWO_DAYS: {
            const twoDays = new Date();
            twoDays.setDate(twoDays.getDate() + 2);
            twoDays.setHours(8, 0, 0);
            snoozedUntil = twoDays.toISOString();
            break;
          }
          case SnoozeDuration.SATURDAY: {
            const nextSaturday = new Date();
            nextSaturday.setDate(
              nextSaturday.getDate() +
                ((6 + 7 - nextSaturday.getDay()) % 7 || 7),
            );
            nextSaturday.setHours(8, 0, 0);
            snoozedUntil = nextSaturday.toISOString();
            break;
          }
          case SnoozeDuration.MONDAY: {
            const nextMonday = new Date();
            nextMonday.setDate(
              nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7),
            );
            nextMonday.setHours(8, 0, 0);
            snoozedUntil = nextMonday.toISOString();
            break;
          }
          default: {
            assertNever(automations.snoozeDuration);
          }
        }
      }

      newConversationProperties.status = newStatus;
      newConversationProperties.snoozedUntil = snoozedUntil;
    },
    [MacroAutomation.ADD_TAGS]: (newConversationProperties) => {
      let newTags: string[] | undefined = undefined;
      if (automations.tagsToAdd?.length) {
        newTags = conversation.tagIds?.map((tag) => tag.name) || [];
        automations.tagsToAdd.forEach((tag) => {
          if (!newTags!.includes(tag)) {
            newTags!.push(tag);
          }
        });
      }
      newConversationProperties.tags =
        newTags?.map((tag) => ({ name: tag })) || undefined;
    },
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: (newConversationProperties) => {
      if (!shouldSetSubjectOnConversation) {
        return;
      }
      let newSubject: string | undefined = undefined;
      if (
        automations.emailSubjectToSet &&
        conversation.platform === ConversationPlatform.EMAIL
      ) {
        newSubject = automations.emailSubjectToSet;
      }
      newConversationProperties.subject = newSubject;
    },
    [MacroAutomation.ADD_INTERNAL_NOTE]: async (newConversationProperties) => {
      if (!automations.shouldAddNote) {
        return;
      }

      const upsertNoteResult = await upsertMessageDraft(client, {
        conversationId: conversation._id,
        message: automations.noteToAddContent || "",
        usersMentioned: automations.noteToAddUsersMentioned || [],
        htmlBody: automations.noteToAddHtmlContent || "",
        visibility: MessageVisibility.INTERNAL,
        attachments: [],
      });
      noteMessage = upsertNoteResult.draftMessage;
      const {
        updatedMessage: updatedNoteMessage,
        conversationUpdatedAt: noteConversationUpdatedAt,
      } = await changeDraftStatus(client, {
        conversationId: conversation._id,
        messageId: noteMessage._id,
        setAsDraft: false,
      });

      addOrReplaceMessage?.(updatedNoteMessage, noteConversationUpdatedAt);

      if (!upsertNoteResult?.draftMessage?._id) {
        return;
      }

      await AddInternalNoteAutomation.perform({
        client,
        conversationId: conversation._id,
        shouldAddNote: automations.shouldAddNote || false,
        redoDraftMessageId: upsertNoteResult?.draftMessage?._id,
      });
    },
    [MacroAutomation.FORWARD_MESSAGE]: undefined,
  };

  for (const automation of Object.values(performAutomations)) {
    await automation?.(newConversationProperties);
  }

  const response = await updateConversation(
    client,
    conversation,
    newConversationProperties,
  );
  return { conversationAfterMacros: response.data, noteMessage };
}

/** Some automations may need the actual message to be sent and processed first.  */
export async function performMacroAutomationsAfterSendingMessage({
  client,
  automations,
  conversation,
  team,
  messageToForwardThatsAlreadySent,
  addOrReplaceMessage,
  forwardDraftSentCallback,
}: {
  client: RedoMerchantClient;
  automations: MacroAutomationsData;
  conversation: ExpandedConversation;
  team: Team;
  messageToForwardThatsAlreadySent: ExpandedConversationMessage;
  addOrReplaceMessage?: (
    message: ExpandedConversationMessage,
    conversationUpdatedAt: string,
  ) => void;
  forwardDraftSentCallback?: (
    draftMessage: ExpandedConversationMessage,
  ) => void;
}) {
  let forwardMessage: ExpandedConversationMessage | undefined;
  const performAutomations: Record<
    MacroAutomation,
    (() => Promise<void> | void) | undefined
  > = {
    [MacroAutomation.SET_STATUS]: undefined,
    [MacroAutomation.ADD_TAGS]: undefined,
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: undefined,
    [MacroAutomation.ADD_INTERNAL_NOTE]: undefined,
    [MacroAutomation.FORWARD_MESSAGE]: async () => {
      if (!automations.shouldForwardMessage) {
        return;
      }

      const { draftMessage: forwardMessageMacroAutomationDraft } =
        await upsertMessageDraft(client, {
          conversationId: conversation._id,
          message: automations.messageToForwardExtraContent || "",
          usersMentioned: [],
          htmlBody: automations.messageToForwardExtraContent || "",
          visibility: MessageVisibility.PUBLIC,
          attachments: [],
          emailEnvelopeInfo: {
            to: [
              {
                email: automations.messageToForwardEmailAddress || "",
              },
            ],
            inReplyTo: messageToForwardThatsAlreadySent.email?.messageId,
            inReplyToMongoId: messageToForwardThatsAlreadySent._id,
          },
        });

      forwardMessage = forwardMessageMacroAutomationDraft;
      const {
        updatedMessage: updatedForwardMessage,
        conversationUpdatedAt: forwardMessageConversationUpdatedAt,
      } = await changeDraftStatus(client, {
        conversationId: conversation._id,
        messageId: forwardMessage._id,
        setAsDraft: false,
      });

      addOrReplaceMessage?.(
        updatedForwardMessage,
        forwardMessageConversationUpdatedAt,
      );

      if (!forwardMessageMacroAutomationDraft?._id) {
        console.error("No forward message draft ID");
        return;
      }

      await PerformForwardMessageAutomation({
        client,
        conversationId: conversation._id,
        shouldForwardMessage: automations.shouldForwardMessage || false,
        messageToForwardMessageId: forwardMessageMacroAutomationDraft?._id,
      });

      forwardDraftSentCallback?.(forwardMessageMacroAutomationDraft);
    },
  };

  for (const automation of Object.values(performAutomations)) {
    await automation?.();
  }
}

/** Not all macro automations actually do anything if they're cancelled, but if they do, it lives here */
export const cancelMacroAutomations = async ({
  client,
  automations,
  conversation,
  addInternalNoteMacroAutomationDraft,
  forwardMessageMacroAutomationDraft,
}: {
  client: RedoMerchantClient;
  automations: MacroAutomationsData;
  conversation: ExpandedConversation;
  addInternalNoteMacroAutomationDraft: ExpandedConversationMessage | undefined;
  forwardMessageMacroAutomationDraft: ExpandedConversationMessage | undefined;
}) => {
  const performAutomations: Record<
    MacroAutomation,
    (() => Promise<void> | void) | undefined
  > = {
    [MacroAutomation.SET_STATUS]: undefined,
    [MacroAutomation.ADD_TAGS]: undefined,
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: undefined,
    [MacroAutomation.ADD_INTERNAL_NOTE]: async () => {
      if (addInternalNoteMacroAutomationDraft?._id) {
        await deleteMessageDraft(client, {
          conversationId: conversation._id,
          messageId: addInternalNoteMacroAutomationDraft?._id,
        });
      }
    },
    [MacroAutomation.FORWARD_MESSAGE]: async () => {
      if (forwardMessageMacroAutomationDraft?._id) {
        await deleteMessageDraft(client, {
          conversationId: conversation._id,
          messageId: forwardMessageMacroAutomationDraft?._id,
        });
      }
    },
  };

  for (const automation of Object.values(performAutomations)) {
    await automation?.();
  }
};
