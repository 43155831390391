import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState } from "@redotech/react-util/load";
import { Order } from "@redotech/redo-model/order";
import { Return, ReturnType } from "@redotech/redo-model/return";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { EditOrderModal } from "@redotech/redo-web/edit-order-modal";
import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import { CartData } from "@redotech/shopify-storefront";
import { assertNever } from "@redotech/util/type";
import { memo, useEffect, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { updateOrder } from "../../client/shopify";
import { MerchantCreateReturnModal } from "../../return/claim-return-shared";
import { CancelOrderModal } from "../action-panel/cancel-order-modal";
import { CartDetails } from "../action-panel/cart-details";
import { DuplicateOrderModal } from "../action-panel/duplicate-order-modal";
import { OrderDetails } from "../action-panel/order-details/order-details";
import { RefundOrderModal } from "../action-panel/refund-order-modal";
import { ReturnDetails } from "../action-panel/return-details";

export const ConversationDetailsOrders = memo(
  function ConversationDetailsOrders({
    ordersLoad,
    doOrdersLoad,
    returnsLoad,
    doReturnsLoad,
    cartsLoad,
  }: {
    ordersLoad: LoadState<any>;
    doOrdersLoad: () => void;
    returnsLoad: LoadState<any>;
    doReturnsLoad: () => void;
    cartsLoad: LoadState<CartData[] | undefined>;
  }) {
    const team = useRequiredContext(TeamContext);
    const user = useRequiredContext(UserContext);

    const [orderNeedingAction, setOrderNeedingAction] = useState<
      Order | undefined
    >(undefined);

    const [currentlyCreatingOrder, setCurrentlyCreatingOrder] = useState(false);

    const canManageOrder = permitted(user.permissions, Permission.MANAGE_ORDER);

    const handleCreateOrder = async () => {
      amplitude.logEvent("view-createOrder");
      setCurrentlyCreatingOrder(true);
      let customerId = "";
      if (ordersLoad?.value?.length) {
        customerId = ordersLoad?.value?.[0].shopify.customer.id;
      }
      window.open(
        `https://${team.storeUrl}/admin/draft_orders/new${customerId ? `?customerId=${customerId}` : ""}`,
        "_blank",
      );
      setCurrentlyCreatingOrder(false);
    };

    enum DetailType {
      RETURN = "return",
      ORDER = "order",
    }

    type Detail =
      | {
          type: DetailType.RETURN;
          returnItem: Return;
          createdAt: number;
        }
      | {
          type: DetailType.ORDER;
          order: Order;
          createdAt: number;
        };

    const combinedDetailData: Detail[] = [
      ...((returnsLoad.value || [])?.map?.(
        (returnItem: Return): Detail => ({
          type: DetailType.RETURN,
          returnItem,
          createdAt: new Date(returnItem.createdAt).getTime(),
        }),
      ) || []),
      ...((ordersLoad.value || [])?.map?.(
        (order: Order): Detail => ({
          type: DetailType.ORDER,
          order,
          createdAt: new Date(order.shopifyCreatedAt).getTime(),
        }),
      ) || []),
    ]
      .filter(Boolean)
      .sort((a, b) => b.createdAt - a.createdAt);

    const anyPending = returnsLoad.pending || ordersLoad.pending;

    const [createClaimModalOpen, setCreateClaimModalOpen] = useState(false);
    const [duplicateOrderModalOpen, setDuplicateOrderModalOpen] =
      useState(false);
    const [refundOrderModalOpen, setRefundOrderModalOpen] = useState(false);
    const [createType, setCreateType] = useState<ReturnType>("return");

    const [editOrderModalOpen, setEditOrderModalOpen] = useState(false);
    const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);

    const [searchParams, setSearchParams] = useState<{ [key: string]: string }>(
      {},
    );
    const [currentView, setCurrentView] = useState<string>();
    useEffect(() => {
      const url = new URL(window.location.href);
      const urlSearchParams = new URLSearchParams(url.search);
      const viewName = url.pathname.split("/").pop() ?? null;
      setSearchParams(Object.fromEntries(urlSearchParams.entries()));
      setCurrentView(viewName ?? undefined);
    }, []);

    useEffect(() => {
      if (orderNeedingAction) {
        document
          .getElementById(`order-${orderNeedingAction.shopify_id}-details`)
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, [anyPending]);

    const client = useRequiredContext(RedoMerchantClientContext);

    return (
      <Flex dir="column">
        <Flex align="center" justify="space-between">
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            Order history
          </Text>
          <Flex align="center">
            <RedoButton
              disabled={!canManageOrder}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={PlusIcon}
              onClick={async () => {
                await handleCreateOrder();
              }}
              pending={canManageOrder && currentlyCreatingOrder}
              size={RedoButtonSize.EXTRA_SMALL}
            />
          </Flex>
        </Flex>
        {/* Whenever the conversation is updated, and we hear about that from the listen endpoint,
            we get the latest conversation.  We should update that to also grab the latest cart(s)
            for the customers. So the customer object should probably have an array of cart IDs.
            That way we can just look at conversation.carts here and display that info. */}
        {cartsLoad.value?.length && <CartDetails carts={cartsLoad.value} />}
        <Flex dir="column">
          {combinedDetailData.map((detail, idx) => {
            switch (detail.type) {
              case DetailType.RETURN:
                return (
                  <ReturnDetails
                    key={idx}
                    reloadReturns={doReturnsLoad}
                    returnItem={detail.returnItem}
                  />
                );
              case DetailType.ORDER:
                return (
                  <OrderDetails
                    key={idx}
                    order={detail.order}
                    setCancelOrderModalOpen={setCancelOrderModalOpen}
                    setCreateClaimModalOpen={setCreateClaimModalOpen}
                    setCreateType={setCreateType}
                    setDuplicateOrderModalOpen={setDuplicateOrderModalOpen}
                    setEditOrderModalOpen={setEditOrderModalOpen}
                    setOrderNeedingAction={setOrderNeedingAction}
                    setRefundOrderModalOpen={setRefundOrderModalOpen}
                  />
                );
              default:
                assertNever(detail);
            }
          })}
          {orderNeedingAction && (
            <>
              {createClaimModalOpen && (
                <MerchantCreateReturnModal
                  open={createClaimModalOpen}
                  relocationInfo={{
                    preselectedOrder: orderNeedingAction,
                    currentView,
                    returnQueryParams:
                      createType === "return" ? searchParams : undefined,
                  }}
                  returnType={createType}
                  setOpen={setCreateClaimModalOpen}
                />
              )}
              <EditOrderModal
                onOrdersChange={doOrdersLoad}
                open={editOrderModalOpen}
                order={orderNeedingAction}
                setOpen={setEditOrderModalOpen}
                updateOrder={(data: any) => updateOrder(client, data)}
              />
              <CancelOrderModal
                onOrdersChange={doOrdersLoad}
                open={cancelOrderModalOpen}
                order={orderNeedingAction}
                setOpen={setCancelOrderModalOpen}
              />
              <DuplicateOrderModal
                open={duplicateOrderModalOpen}
                order={orderNeedingAction}
                setOpen={setDuplicateOrderModalOpen}
              />
              <RefundOrderModal
                onOrdersChange={doOrdersLoad}
                open={refundOrderModalOpen}
                order={orderNeedingAction}
                setOpen={setRefundOrderModalOpen}
              />
            </>
          )}
        </Flex>
        {anyPending && <LoadingRedoAnimation />}
      </Flex>
    );
  },
);
