import CircleClose from "@redotech/redo-web/icon-old/x.svg";
import { formatDate } from "@redotech/redo-web/time";
import { memo, useContext, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { TeamContext } from "../app/team";
import * as orderSyncCss from "./index.module.css";

export const OrderSyncProgress = memo(function OrderSyncProgress() {
  const team = useContext(TeamContext);
  const [closed, setClosed] = useState(false);
  const location = useLocation();

  let orderSyncComplete = true;
  if (team && team.orderBackfillWatermark) {
    const syncOrdersSync =
      team.orderBackfillMin ||
      Temporal.Now.zonedDateTimeISO("UTC")
        .subtract(Temporal.Duration.from({ days: 60 }))
        .toString();
    orderSyncComplete = team.orderBackfillWatermark <= syncOrdersSync;
  }

  return team &&
    team.orderBackfillWatermark &&
    !orderSyncComplete &&
    false! &&
    !closed ? (
    <div className={orderSyncCss.header}>
      <div>
        Order syncing from Shopify in progress. Orders since{" "}
        {formatDate(Temporal.Instant.from(team.orderBackfillWatermark))} have
        been synced.
      </div>
      {location.pathname !== "/orders" ? (
        <RouterLink to="/orders">
          <button className={orderSyncCss.retryButton}>
            <span>View orders</span>
          </button>
        </RouterLink>
      ) : null}
      <div className={orderSyncCss.closeButton} onClick={() => setClosed(true)}>
        <CircleClose />
      </div>
    </div>
  ) : null;
});
