import { RouteObject } from "react-router-dom";
import { ACTIVE_CONVERSATION_QUERY_PARAMETER } from "../../support/query-parameters";

const hideHeader = (url: string) => {
  return (
    url.includes(ACTIVE_CONVERSATION_QUERY_PARAMETER) ||
    /tracking\/about/.test(url)
  );
};

export const aboutTrackingRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Tracking", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../../app/page");
      return { element: <Page hideHeader={hideHeader} /> };
    },
    children: [
      {
        path: "about",
        async lazy() {
          const { AboutTracking } = await import("./about-tracking");
          return { element: <AboutTracking /> };
        },
      },
    ],
  },
];
