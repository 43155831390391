// extracted by mini-css-extract-plugin
export var active = "_1k-d";
export var blink = "_1k-e";
export var container = "_1k-5";
export var controls = "_1k-0";
export var controlsRight = "_1k-1";
export var fakeCursor = "_1k-c";
export var hidden = "_1k-b";
export var quillContainerSmall = "_1k-7";
export var quillEditor = "_1k-6";
export var quillEditorSmall = "_1k-8";
export var quillFormatButtons = "_1k-a";
export var quillToolbar = "_1k-9";
export var removePadding = "_1k-2";
export var state = "_1k-3";
export var stateTitle = "_1k-4";