import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { ConditionType } from "../condition";

export const IS_REFUND_RETURN: ConditionType<
  string,
  ModelCondition.IsRefundReturn
> = {
  name: "Requested refund on return",
  description() {
    return `Requested refund on return.`;
  },
  Details() {
    return null;
  },
  empty: String(0),
  fromModel() {
    return "";
  },
  toModel(state) {
    return { type: ModelCondition.IS_REFUND_RETURN, orderCount: state };
  },
  valid(state) {
    return state !== undefined;
  },
};
