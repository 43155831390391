import { ok } from "./fp/coerced-result";

export class UtmParameters {
  constructor(
    private params: {
      source?: string;
      medium?: string;
      campaign?: string;
    },
  ) {}

  applyToUrl(url: string): string;
  applyToUrl(url: URL): URL;
  applyToUrl(url: URL | string): URL | string {
    if (typeof url === "string") {
      const urlResult = ok(url).map((url) => new URL(url));
      if (!urlResult.ok) return url;
      url = urlResult.value;
    }
    url = new URL(url.toString()); // avoid mutation
    if (this.params.source)
      url.searchParams.set("utm_source", this.params.source);
    if (this.params.medium)
      url.searchParams.set("utm_medium", this.params.medium);
    if (this.params.campaign)
      url.searchParams.set("utm_campaign", this.params.campaign);
    return url;
  }

  getCampaign() {
    return this.params.campaign;
  }

  getSource() {
    return this.params.source;
  }

  getMedium() {
    return this.params.medium;
  }
}
