import { useRequiredContext } from "@redotech/react-util/context";
import { OverflowDirection } from "@redotech/react-util/overflow";
import { Fulfillment, Order } from "@redotech/redo-model/order";
import { RedoClientContext } from "@redotech/redo-web/client";
import { CopyableText } from "@redotech/redo-web/copyable-text";
import { ExternalLinkButton } from "@redotech/redo-web/external-link-button";
import { Text } from "@redotech/redo-web/text";
import { OverflowTooltip } from "@redotech/redo-web/tooltip/overflow-tooltip";
import { newWindow } from "@redotech/web-util/window";
import { memo, useState } from "react";
import { TeamContext } from "../../../app/team";
import { getCustomerPortalLink } from "../../../order/utils/get-customer-portal-link";
import * as orderDetailsCss from "./order-details.module.css";

export const OrderDetailsTrackingInformation = memo(
  function OrderDetailsTrackingInformation({
    order,
    fulfillment,
    index,
  }: {
    order: Order;
    fulfillment: Fulfillment;
    index: number;
  }) {
    const team = useRequiredContext(TeamContext);
    const apiClient = useRequiredContext(RedoClientContext);

    function handleOrderDetailsLinkClicked(
      event: React.MouseEvent,
      order: Order,
      index: number,
    ) {
      event.stopPropagation();
      newWindow(async () => {
        return getCustomerPortalLink(apiClient, team, order, index);
      });
    }

    const orderTrackingEnabled = !!team.settings.orderTracking?.enabled;
    const [overflowRef, setOverflowRef] = useState<HTMLElement | null>(null);

    if (orderTrackingEnabled) {
      return (
        <div className={orderDetailsCss.detailColumn}>
          <OverflowTooltip
            direction={OverflowDirection.Horizontal}
            overflowRef={overflowRef}
            tooltipProps={{
              title: <div>{order.trackers?.[0]?._tracker?.tracking_code}</div>,
            }}
          >
            <Text
              className={orderDetailsCss.link}
              fontSize="xxs"
              onClick={(clickEvent) => {
                handleOrderDetailsLinkClicked(clickEvent, order, index);
              }}
              ref={setOverflowRef}
              textColor="primary"
            >
              {order.trackers?.[0]?._tracker?.tracking_code ??
                "Tracking information"}
            </Text>
          </OverflowTooltip>
        </div>
      );
    } else {
      return (
        <>
          {!!fulfillment.tracking_numbers?.length && (
            <div className={orderDetailsCss.detailColumn}>
              {fulfillment.tracking_numbers.map((trackingNumber, index) => {
                return fulfillment.tracking_urls?.[index] ? (
                  <div className={orderDetailsCss.copyableField}>
                    <CopyableText
                      altTextToCopy={trackingNumber}
                      fontSize="xxs"
                      fontWeight="medium"
                      textColor="primary"
                    >
                      {trackingNumber}
                    </CopyableText>
                    <ExternalLinkButton
                      url={fulfillment.tracking_urls?.[index]}
                    />
                  </div>
                ) : (
                  <h2 className={orderDetailsCss.strong}>{trackingNumber}</h2>
                );
              })}
            </div>
          )}
        </>
      );
    }
  },
);
