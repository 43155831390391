import {
  ConversationTopic,
  Condition as ModelCondition,
} from "@redotech/redo-model/return-flow/condition";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { MultiSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { ConditionType } from "../return-flow/condition";
import * as selectCss from "../return-flow/select.module.css";

const options = [
  ConversationTopic.CANCEL_ORDER,
  ConversationTopic.DAMAGED_PACKAGE,
  ConversationTopic.DISCOUNTS_PROMOTIONS,
  ConversationTopic.EDIT_ORDER,
  ConversationTopic.MISSING_PACKAGE,
  ConversationTopic.ORDER_TRACKING,
  ConversationTopic.PARTNERSHIP_COLLABORATION,
  ConversationTopic.RETURNS_EXCHANGES,
  ConversationTopic.SHIPPING_INFORMATION,
  ConversationTopic.STOCK_AVAILABILITY,
];

export const TOPIC: ConditionType<ConversationTopic[], ModelCondition.Topic> = {
  name: "Message topic",
  description() {
    return "What is the topic of the customer message";
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Topic">
        <MultiSelectDropdown
          disabled={disabled}
          options={options}
          value={state}
          valueChange={(e) => {
            if (e) {
              setState([...e]);
            }
          }}
        >
          {(topic) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>{topic}</div>
            </div>
          )}
        </MultiSelectDropdown>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model) {
    return model.topics;
  },
  toModel(state) {
    return { type: ModelCondition.TOPIC, topics: state };
  },
  valid() {
    return true;
  },
};
