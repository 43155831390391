import {
  array,
  nativeEnum,
  number,
  object,
  string,
  infer as zInfer,
} from "zod";
import { zExt } from "../common/zod-util";

export enum MerchantPolicyProduct {
  SUPPORT_COPILOT = "SUPPORT_COPILOT",
  CONCIERGE = "CONCIERGE",
}

export const MerchantPolicyDbParser = object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  order: number(),
  title: string().optional(),
  text: string(),
  embedding: array(number()).optional(),
  productsApplicable: array(nativeEnum(MerchantPolicyProduct)),
});

export type IMerchantPolicy = zInfer<typeof MerchantPolicyDbParser>;

export const MAX_CONCIERGE_POLICIES = 10;
