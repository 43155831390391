// extracted by mini-css-extract-plugin
export var grid = "_9-0";
export var span1 = "_9-2";
export var span10 = "_9-b";
export var span10L = "_9-z";
export var span10M = "_9-n";
export var span10Xl = "_9-1b";
export var span11 = "_9-c";
export var span11L = "_9-10";
export var span11M = "_9-o";
export var span11Xl = "_9-1c";
export var span12 = "_9-d";
export var span12L = "_9-11";
export var span12M = "_9-p";
export var span12Xl = "_9-1d";
export var span1L = "_9-q";
export var span1M = "_9-e";
export var span1Xl = "_9-12";
export var span2 = "_9-3";
export var span2L = "_9-r";
export var span2M = "_9-f";
export var span2Xl = "_9-13";
export var span3 = "_9-4";
export var span3L = "_9-s";
export var span3M = "_9-g";
export var span3Xl = "_9-14";
export var span4 = "_9-5";
export var span4L = "_9-t";
export var span4M = "_9-h";
export var span4Xl = "_9-15";
export var span5 = "_9-6";
export var span5L = "_9-u";
export var span5M = "_9-i";
export var span5Xl = "_9-16";
export var span6 = "_9-7";
export var span6L = "_9-v";
export var span6M = "_9-j";
export var span6Xl = "_9-17";
export var span7 = "_9-8";
export var span7L = "_9-w";
export var span7M = "_9-k";
export var span7Xl = "_9-18";
export var span8 = "_9-9";
export var span8L = "_9-x";
export var span8M = "_9-l";
export var span8Xl = "_9-19";
export var span9 = "_9-a";
export var span9L = "_9-y";
export var span9M = "_9-m";
export var span9Xl = "_9-1a";
export var stretch = "_9-1";