import { genericMemo } from "@redotech/react-util/component";
import {
  ArrayPredicateExpression,
  predicateOperators,
} from "@redotech/redo-model/advanced-flow/expressions/array-expression";
import { GenericDataType } from "@redotech/redo-model/advanced-flow/type-system/data-types/generic-types";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { DeepPartial } from "@redotech/util/type";
import { useEffect } from "react";
import { FieldInspector, camelToTitleCase } from ".";

export const ArrayInspector = genericMemo(function ArrayInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: GenericDataType;
  expression: DeepPartial<ArrayPredicateExpression>;
  setExpression: (expression: DeepPartial<ArrayPredicateExpression>) => void;
}) {
  const unwrappedDataType = dataType.replace(
    "Multiple ",
    "",
  ) as GenericDataType;

  useEffect(() => {
    if (!expression.type) {
      setExpression({ ...expression, type: "array_predicate" });
    }
    if (!expression.operator) {
      setExpression({ ...expression, operator: "some" });
    }
  }, [expression]);

  const subExpresion = expression.condition ?? {};

  // kind of a hack to make sure the field is always populated
  subExpresion.field = expression.field;

  return (
    <>
      <LabeledInput label="Comparison type">
        <SelectDropdown
          options={predicateOperators}
          value={expression.operator}
          valueChange={(operator) => setExpression({ ...expression, operator })}
        >
          {(value) => camelToTitleCase(value)}
        </SelectDropdown>
      </LabeledInput>

      <FieldInspector
        dataType={unwrappedDataType}
        expression={subExpresion}
        schema={schema}
        setExpression={(condition) =>
          setExpression({ ...expression, condition })
        }
      />
    </>
  );
});
