import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { resendLabel, WidgetClient } from "@redotech/redo-api-client/widget";
import { getReturner, Return } from "@redotech/redo-model/return";
import { PickupLocation, PickupPayer } from "@redotech/redo-model/return-flow";
import { PickupContext } from "@redotech/redo-return-app/contexts/PickupContext";
import { PickupFormCard } from "@redotech/redo-return-app/order/return-flow/review/pickup";
import {
  ButtonPlacement,
  RedoModal,
  RedoModalSize,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import alertCircle from "@redotech/redo-web/arbiter-icon/alert-circle.svg";
import checkCircle from "@redotech/redo-web/arbiter-icon/check-circle.svg";
import { RedoClientContext } from "@redotech/redo-web/client";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { getDateString } from "@redotech/redo-web/date-utils";
import { ExternalLink } from "@redotech/redo-web/link";
import { memo, useContext, useEffect } from "react";
import * as pickupCss from "./schedule-pickup-modal.module.css";

export const SchedulePickupModal = memo(function SchedulePickupModal({
  return: return_,
  open,
  onClose,
  widgetSlug,
  canDeduct,
  pickupFee,
  isReschedule,
}: {
  open: boolean;
  onClose(): void;
  return: Return;
  widgetSlug?: string;
  canDeduct: boolean;
  pickupFee: number;
  isReschedule: boolean;
}) {
  const { input } = useContext(PickupContext);

  useEffect(() => {
    input.setValue({
      finalized: false,
      pickup: {
        location:
          return_?.pickup?.pickupLocation?.packageLocation ||
          PickupLocation.FRONT_DOOR,
        date: undefined!,
        specialInstructions: "",
        textReminder: false,
        pickupPayer: PickupPayer.MERCHANT,
        pickupReadyTime: undefined!,
      },
    });
  }, []);

  const client = useRequiredContext(RedoClientContext);
  const [resendLoad, doResend] = useTriggerLoad(async () => {
    const widgetClient = new WidgetClient(
      client,
      widgetSlug || return_.team.widget_slug,
    );
    const { pickup: pickupValue } = input.value;
    const pickup =
      pickupValue && pickupValue.date && pickupValue.location
        ? {
            pickupDate: pickupValue.date.toString(),
            pickupLocation: {
              packageLocation: pickupValue.location,
              specialInstructions: pickupValue.specialInstructions,
            },
            pickupPayer: pickupValue.pickupPayer,
            textReminder: pickupValue.textReminder,
            pickupReadyTime: pickupValue.pickupReadyTime,
          }
        : undefined;
    try {
      await resendLabel(widgetClient, {
        returnId: return_.id,
        pickup,
      });
    } catch (err: any) {
      // If the error was caused by the merchant missing their harmonized system codes (NoHSCodesError),
      // throw a specific error instead of just "Request failed with status code ####"
      if (err.response?.data?.description?.includes("No HS codes")) {
        throw new Error(err.response.data.description);
      }
      throw err;
    }
    return true;
  });

  const handleSchedule = useHandler(() => doResend());

  if (!input.inputs?.pickup?.input) {
    return null;
  }

  const readableDateString = input.value.pickup?.date
    ? getDateString(input.value.pickup.date)
    : "";

  if (resendLoad.value) {
    return (
      <SuccessModal
        onClose={onClose}
        open={open}
        pickupDate={readableDateString}
      />
    );
  }
  if (resendLoad.error) {
    // Extract the support code from the error response
    const supportCode = resendLoad.error?.response?.data?.support?.code;
    return (
      <FailedModal
        handleSend={handleSchedule}
        onClose={onClose}
        open={open}
        supportCode={supportCode}
      />
    );
  }
  return (
    <InputModal
      canDeduct={canDeduct}
      handleSchedule={handleSchedule}
      isReschedule={isReschedule}
      onClose={onClose}
      open={open}
      pickupFee={pickupFee}
      return_={return_}
      submitDisabled={input.inputs.pickup.input.allErrors.length > 0}
    />
  );
});

const FailedModal = memo(function FailedModal({
  open,
  onClose,
  handleSend,
  supportCode,
}: {
  open: boolean;
  onClose(): void;
  handleSend(): void;
  supportCode?: string;
}) {
  return (
    <RedoModal
      isOpen={open}
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={onClose}
      primaryButton={{ text: "Schedule", onClick: handleSend }}
      secondaryButton={{ text: "Cancel", onClick: onClose }}
      subtitle={
        <p>
          Please try to schedule again, and if the problem persists then reach
          out to customer support at{" "}
          <ExternalLink showIcon={false} url="mailto:support@getredo.com">
            support@getredo.com
          </ExternalLink>
          .
        </p>
      }
      theme={RedoModalTheme.ERROR}
      title={"We couldn't schedule a pickup for the return"}
      TitleIcon={alertCircle}
    >
      {supportCode && (
        <>
          Support code: <b className={pickupCss.boldCardText}>{supportCode}</b>
        </>
      )}
    </RedoModal>
  );
});

const SuccessModal = memo(function SuccessModal({
  open,
  onClose,
  pickupDate,
}: {
  open: boolean;
  onClose(): void;
  pickupDate: string;
}) {
  return (
    <RedoModal
      buttonPlacement={ButtonPlacement.TIGHT}
      isOpen={open}
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={onClose}
      primaryButton={{ text: "Done", onClick: onClose }}
      subtitle={
        <p>
          Your package pickup has been successfully scheduled for:{"\n"}
          <b className={pickupCss.boldCardText}>{pickupDate}</b>
        </p>
      }
      theme={RedoModalTheme.SUCCESS}
      title="Package pickup confirmed"
      TitleIcon={checkCircle}
    />
  );
});

const InputModal = memo(function InputModal({
  open,
  onClose,
  isReschedule,
  return_,
  canDeduct,
  pickupFee,
  handleSchedule,
  submitDisabled,
}: {
  open: boolean;
  onClose(): void;
  isReschedule: boolean;
  return_: Return;
  canDeduct: boolean;
  pickupFee: number;
  handleSchedule(): void;
  submitDisabled: boolean;
}) {
  const returner = getReturner(return_);
  return (
    <RedoModal
      buttonPlacement={ButtonPlacement.TIGHT}
      isOpen={open}
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={onClose}
      primaryButton={{
        text: "Schedule",
        onClick: handleSchedule,
        disabled: submitDisabled,
      }}
      secondaryButton={{ text: "Cancel", onClick: onClose }}
      theme={RedoModalTheme.INFO}
      title={isReschedule ? "Reschedule pickup" : "Schedule pickup"}
    >
      <p className={pickupCss.infoText}>
        This will send an email with the shipping label(s) to the customer at{" "}
        {returner.email}.{"\n\n"}
        {isReschedule || canDeduct
          ? "There is no additional fee to reschedule the pickup. Fill out the information below to continue:"
          : `The fee for this pickup is ${CURRENCY_FORMAT().format(pickupFee)}. The fee will be deducted from your next invoice.`}
      </p>
      <PickupFormCard
        isMerchantDashboard
        showPayer={isReschedule ? false : canDeduct}
        showPickupReadyTimeSelector={isReschedule}
      />
    </RedoModal>
  );
});
