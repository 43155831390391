import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { ConversationTagWithId } from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { RedoBaseModal } from "@redotech/redo-web/arbiter-components/modal/redo-base-modal";
import { RedoModalHeader } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { memo, useContext, useState } from "react";

import { RedoMerchantClientContext } from "../../client/context";
import { patchConversationTag } from "../../client/conversation-tags";
import { getConversation } from "../../client/conversations";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { ActiveConversationContext } from "../context/active-conversation-context";
import { ConversationTagInputFields } from "./conversation-tag-fields";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";
import { DeleteConversationTagModal } from "./delete-conversation-tag-modal";

export const EditConversationTagModal = memo(function EditConversationTagModal({
  tag,
  resolved,
}: {
  tag: ConversationTagWithId;
  resolved(): void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const { setActiveConversation, activeConversation } = useContext(
    ActiveConversationContext,
  );
  const [conversationTags, refreshConversationTags] = useLazyContext(
    ConversationTagsContext,
  );

  const existingTagNames = new Set(
    conversationTags.value?.map((tag) => tag.name.toLowerCase()),
  );
  existingTagNames.delete(tag.name.toLowerCase());

  const [pillTheme, setPillTheme] = useState<PillTheme>(
    tag.pillTheme || PillTheme.PRIMARY_LIGHT,
  );

  const [name, setName] = useState(tag.name);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const nameConflict = existingTagNames.has(name.toLowerCase());

  const footerAction = (
    <footer className={conversationTagInputCss.footerContainer}>
      <Button
        icon={(props) => (
          <TrashIcon className={conversationTagInputCss.icon} {...props} />
        )}
        onClick={() => setDeleteModalOpen(true)}
        theme={ButtonTheme.DANGER_OUTLINED}
      >
        Delete tag
      </Button>
      <Flex>
        <Button onClick={resolved} theme={ButtonTheme.OUTLINED}>
          Cancel
        </Button>
        <Button
          disabled={!name || nameConflict}
          onClick={handleSave}
          theme={ButtonTheme.PRIMARY}
        >
          Save
        </Button>
      </Flex>
    </footer>
  );

  async function handleSave() {
    if (name === tag.name && pillTheme === tag.pillTheme) {
      resolved();
      return;
    }
    await patchConversationTag(
      client,
      tag.tagId,
      {
        name,
        pillTheme,
      },
      async () => {
        refreshConversationTags();
        if (activeConversation) {
          const updatedConversation = await getConversation(client, {
            conversationId: activeConversation._id,
          });
          setActiveConversation(updatedConversation);
        }
      },
    );

    resolved();
  }

  if (deleteModalOpen) {
    return (
      <DeleteConversationTagModal
        cancelled={() => setDeleteModalOpen(false)}
        deleted={resolved}
        tag={tag}
      />
    );
  } else {
    return (
      <RedoBaseModal isOpen onModalCloseRequested={resolved}>
        <RedoModalHeader cancelClicked={resolved} title="Manage tag" />
        <section className={conversationTagInputCss.editModalBody}>
          <ConversationTagInputFields
            name={name}
            pillTheme={pillTheme}
            setName={setName}
            setPillTheme={setPillTheme}
            showNameError={nameConflict}
          />
        </section>
        {footerAction}
      </RedoBaseModal>
    );
  }
});
