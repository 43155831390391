import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";

const reportBreadcrumb = breadcrumbSlot();

export const reportsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Reports" },
    children: [
      {
        index: true,
        async lazy() {
          const { Reports } = await import("./reports");
          return { Component: Reports };
        },
      },
      {
        path: ":reportId",
        handle: { breadcrumb: reportBreadcrumb },
        async lazy() {
          const { CustomReport } = await import("./custom-report");
          return {
            element: <CustomReport breadcrumb={reportBreadcrumb} />,
          };
        },
      },
    ],
  },
];
