// extracted by mini-css-extract-plugin
export var copyableField = "_8k-4";
export var customerBadge = "_8k-0";
export var customerInfoTitle = "_8k-2";
export var details = "_8k-1";
export var dropdownContainer = "_8k-5";
export var dropdownImage = "_8k-6";
export var editAssigneeForm = "_8k-a";
export var editButton = "_8k-3";
export var email = "_8k-7";
export var hidden = "_8k-d";
export var input = "_8k-8";
export var saveButton = "_8k-b";
export var shopifyIcon = "_8k-c";
export var username = "_8k-9";