export enum PhoneNumberUseCase {
  Marketing = "marketing",
  Support = "support",
}

export enum PhoneNumberProvider {
  Telnyx = "telnyx",
  Bandwidth = "bandwidth",
}

export enum TextMessagingVerificationStatus {
  Unverified = "unverified",
  Pending = "pending",
  Verified = "verified",
}

interface MessagingMetadata {
  verificationStatus: TextMessagingVerificationStatus;
}

interface VoiceMetadata {
  billingGroupId: string;
  sipConnectionId: string;
}

export interface PhoneNumber {
  _id: string;
  number: string;
  team: string;
  provider: PhoneNumberProvider;
  useCase: PhoneNumberUseCase;
  messagingMetadata?: MessagingMetadata;
  voiceMetadata?: VoiceMetadata;
}

export type VoiceEnabledPhoneNumber = PhoneNumber & {
  voiceMetadata: VoiceMetadata;
};

export function isVoiceEnabledPhoneNumber(
  phoneNumber: PhoneNumber,
): phoneNumber is VoiceEnabledPhoneNumber {
  return !!phoneNumber.voiceMetadata;
}
