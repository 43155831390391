import { UploadedFile } from "@redotech/redo-model/file/uploaded-file";
import { memo } from "react";
import { AttachmentThumbnail } from "../attachment-thumbnail";
import { IconButton } from "../button";
import XIcon from "../icon-old/x.svg";
import * as quillAttachmentCarouselCss from "./quill-attachment-carousel.module.css";

export const QuillAttachmentCarousel = memo(function QuillAttachmentCarousel({
  attachments,
  removeFileFromDrafts,
}: {
  attachments: UploadedFile[] | undefined;
  removeFileFromDrafts?: (url: string) => void;
}) {
  return (
    <>
      {!!attachments?.length && (
        <div className={quillAttachmentCarouselCss.fileCarousel}>
          {attachments.map((attachment, index) => (
            <div
              className={quillAttachmentCarouselCss.draftAttachmentContainer}
              key={index}
            >
              <AttachmentThumbnail
                description={attachment.description}
                mimeType={attachment.mimeType}
                url={attachment.url}
              />
              {removeFileFromDrafts && (
                <div className={quillAttachmentCarouselCss.removeFileButton}>
                  <IconButton
                    onClick={() => removeFileFromDrafts(attachment.url)}
                  >
                    <XIcon />
                  </IconButton>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
});
